// =========================================================
// * Vuetify Material Dashboard - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licenses under MIT

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'
import './lib/css'
import './lib/script'
import './lib/global'
// Plugins
import './plugins'
// import { sync } from 'vuex-router-sync'
// Application imports
import App from './App'
import router from '@/router'
import store from '@/store'
import i18n from 'lang/langUtils'
import GlobalComponents from './globalComponents'
import BootstrapVue from 'bootstrap-vue'
import 'chartist/dist/chartist.min.css'
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.min'
import VueHtmlToPaper from 'vue-html-to-paper'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueClipboard from 'vue-clipboard2'
import MessageModal from './components/messagemodal/messagemodal'
import stringUtils from 'utils/stringUtils'
// import { initFbsdk } from '@/config/facebook_oAuth.js'
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    `${stringUtils.DOMAIN_BASE_PATH_ADMIN}/css/voucherprint.css`
  ]
}
Vue.use(VueClipboard)
Vue.use(MessageModal)
Vue.use(VueHtmlToPaper, options)
Vue.use(require('vue-chartist'))

Vue.use(CKEditor)
// Sync store with router
Vue.use(BootstrapVue)
Vue.config.productionTip = false
// Global components
Vue.use(GlobalComponents)
// Vue.use(initFbsdk())
/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
