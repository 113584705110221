import { Promise } from 'es6-promise'

const state = {
  snackbar: []
}

// getters
const getters = {
  GET_SNACK_BAR: state => {
    return state.snackbar
  }
}

// actions
const actions = {
  ON_SHOW_TOAST (context, payload) {
    return new Promise((resolve, reject) => {
      const id = Math.floor(1000000 * Math.random()).toString()
      const data = {
        id: id,
        message: payload.message,
        styleType: payload.styleType
      }
      context.commit('ON_SHOW_TOAST', data)
      resolve()
    })
  },
  deleteToast (context, payload) {
    return new Promise((resolve, reject) => {
      const data = payload
      context.commit('deleteToast', data)
      resolve()
    })
  }
}

// mutations
const mutations = {
  ON_SHOW_TOAST: (state, payload) => {
    state.snackbar.push(payload)
  },
  deleteToast: (state, payload) => {
    const data = payload
    state.snackbar.forEach((v, i, a) => {
      if (data.id === v.id) {
        a.splice(i, 1)
        return true
      }
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
