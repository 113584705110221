import functionUtils from 'utils/functionUtils'
import i18n from 'lang/langUtils'

const ruleRequiredValue = function (value, errorMessage = null) {
  if (value !== null && value !== undefined && value.length !== 0) {
    return true
  }
  return errorMessage || i18n.tc('validation.requiredField')
}

const ruleValidEmailFormat = function (email, errorMessage = null, allowNull = false) {
  if (allowNull && (email === null || email === undefined || email.length === 0)) {
    return true
  }
  if (functionUtils.validateEmail(email)) {
    return true
  }
  return errorMessage || i18n.tc('validation.invalidEmail')
}

const ruleValidPhoneNumber = function (phone, errorMessage = null, allowNull = true) {
  if (allowNull && (phone === null || phone === undefined || phone.length === 0)) {
    return true
  }
  if (functionUtils.validatePhone(phone)) {
    return true
  }
  return errorMessage || i18n.tc('validation.invalidPhoneNumber')
}

const ruleValidPhoneOrEmail = function (emailorPhone, errorMessage = null, allowNull = false) {
  if (allowNull && (emailorPhone === null || emailorPhone === undefined || emailorPhone.length === 0)) {
    return true
  }
  if (functionUtils.validateEmail(emailorPhone) || functionUtils.validatePhone(emailorPhone)) {
    return true
  }
  return errorMessage || i18n.tc('validation.invalidPhoneOrEmail')
}

const ruleValidUrlPath = function (url, errorMessage = null) {
  // const regexUrl = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/
  const regexURL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  //  URL value can be empty
  if (url === null || url === undefined || url.length === 0 || regexURL.test(url)) {
    return true
  }
  return errorMessage || i18n.tc('validation.invalidURL')
}

const ruleValidFullname = function (fullname, errorMessage = null) {
  if (functionUtils.validateFullname(fullname)) {
    return true
  }
  return errorMessage || i18n.tc('validation.invalidUsername')
}

const ruleValidCode = function (code, errorMessage = null) {
  // only accept printable charactor
  const regexValidChar = /^[-_0-9a-zA-Z]+$/
  if (regexValidChar.test(code)) {
    return true
  }
  return errorMessage || i18n.tc('validation.invalidCodeName')
}

const ruleSamePassword = function (password, checkPassword, errorMessage = null) {
  if ((password !== null && checkPassword !== null) && password !== checkPassword) {
    return errorMessage || i18n.tc('validation.passwordDoesNotMatch')
  }
  return true
}

const ruleValidPassword = function (password) {
  /// Full Regex
  // ((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,40})
  /// Expression
  // ( // Start of group
  //   (?=.*\d)             // must contains one digit from 0-9
  //   (?=.*[a-z])          // must contains one lowercase characters
  //   (?=.*[A-Z])          // must contains one uppercase characters
  //   (?=.*[@#$%])         // must contains one special symbols in the list "@#$%"
  //               .        // match anything with previous condition checking
  //                 {8,30} // length at least 8 characters and maximum of 30
  // )
  const regexDigit = /(?=.*\d)/
  const regexLowercase = /(?=.*[a-z])/
  const regexUppercase = /(?=.*[A-Z])/
  const regexSymbols = /(?=.*[@#$%])/
  const regexValidChar = /^[0-9a-zA-Z@#$%]+$/
  const minPassLen = 6

  if ((password === undefined) || (password === null) || (password.length === 0)) {
    return i18n.tc('validation.requiredField')
  }
  if (!regexValidChar.test(password)) {
    return i18n.tc('validation.passwordNotValidCharactor')
  }
  if (password.length < minPassLen) {
    return i18n.t('validation.passwordLengthTooShort', { min: minPassLen })
  }
  if (!regexDigit.test(password)) {
    return i18n.tc('validation.passwordMustContainsOneDigit')
  }
  if (!regexLowercase.test(password)) {
    return i18n.tc('validation.passwordMustContainsOneLowerCase')
  }
  if (!regexUppercase.test(password)) {
    return i18n.tc('validation.passwordMustContainsOneUpperCase')
  }
  if (!regexSymbols.test(password)) {
    return i18n.tc('validation.passwordMustContainsOneSymbols')
  }
  return true
}

const ruleMustGreaterThan = function (value, minValue, errorMessage = null) {
  value = functionUtils.formatInteger(value)
  minValue = functionUtils.formatInteger(minValue)
  if (value > minValue) {
    return true
  }
  return errorMessage || i18n.t('validation.valueMustBeGreaterThan', { value: minValue })
}

const ruleMustGreaterThanOrEqual = function (value, minValue, errorMessage = null) {
  value = functionUtils.formatInteger(value)
  minValue = functionUtils.formatInteger(minValue)
  if (value >= minValue) {
    return true
  }
  return errorMessage || i18n.t('validation.valueMustBeGreaterThanOrEqual', { value: minValue })
}

const ruleMustLessThan = function (value, maxValue, errorMessage = null) {
  value = functionUtils.formatInteger(value)
  maxValue = functionUtils.formatInteger(maxValue)
  if (value < maxValue) {
    return true
  }
  return errorMessage || i18n.t('validation.valueMustBeLessThan', { value: maxValue })
}

const ruleMustLessThanOrEqual = function (value, maxValue, errorMessage = null) {
  value = functionUtils.formatInteger(value)
  maxValue = functionUtils.formatInteger(maxValue)
  if (value <= maxValue) {
    return true
  }
  return errorMessage || i18n.t('validation.valueMustBeLessThanOrEqual', { value: maxValue })
}

const ruleLengthMustLessThan = function (text, maxLength, errorMessage = null) {
  if (text === null || text === undefined) {
    return true
  }
  maxLength = functionUtils.formatInteger(maxLength)
  if (text.length <= maxLength) {
    return true
  }
  return errorMessage || i18n.t('validation.ruleLengthMustLessThan', { value: maxLength })
}

export default {
  ruleRequiredValue,
  ruleValidFullname,
  ruleValidEmailFormat,
  ruleValidPhoneNumber,
  ruleValidPhoneOrEmail,
  ruleValidPassword,
  ruleSamePassword,
  ruleValidUrlPath,
  ruleValidCode,
  ruleMustGreaterThan,
  ruleMustGreaterThanOrEqual,
  ruleMustLessThan,
  ruleMustLessThanOrEqual,
  ruleLengthMustLessThan
}
