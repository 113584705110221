import axios from 'axios'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import router from '../router/index'
const getRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie('token') : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .get(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        router.push('/login')
      }
      reject(error)
    })
}

const postRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie('token') : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .post(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        router.push('/login')
      }
      reject(error)
    })
}

const deleteRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie('token') : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .delete(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        router.push('/login')
      }
      reject(error)
    })
}

const putRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie('token') : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .put(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        router.push('/login')
      }
      reject(error)
    })
}

export default {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest
}
