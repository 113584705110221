/**
 * VueShop Global Components
 */
import Pagination from './components/core/Pagination.vue'
import LoadingBar from './components/core/LoadingBar.vue'
import SnackBar from './components/core/SnackBar.vue'

const GlobalComponents = {
  install (Vue) {
    Vue.component('pagination', Pagination)
    Vue.component('loadingBar', LoadingBar)
    Vue.component('snackBar', SnackBar)
  }
}

export default GlobalComponents
