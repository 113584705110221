<template>
  <div class="row">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Row',
  created () {

  }
}
</script>
