var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"lg5":"","md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('entity.availableTitle', { name: _vm.$t(_vm.getEntityName()) }),"color":"green","flat":"","full-width":""}},[_c('div',{staticStyle:{"width":"70%"}},[_c('v-text-field',{attrs:{"label":_vm.$t('userEntity.codeOrName'),"append-icon":"mdi-magnify"},on:{"input":_vm.onCodeOrNameChange},model:{value:(_vm.codeOrName),callback:function ($$v) {_vm.codeOrName=$$v},expression:"codeOrName"}})],1),_c('v-data-table',{staticStyle:{"max-height":"250px","overflow-y":"auto"},attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.entityAvailableHeaders,"items":_vm.entitiesAvailable,"single-select":true,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(item.stt))]),_c('td',{staticStyle:{"width":"55%"}},[_vm._v(_vm._s(item.name))]),_c('td',{staticStyle:{"width":"25%"}},[_vm._v(_vm._s(item.code))]),_c('td',{staticClass:"text-xs-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.entityRoles),function(role,index){return _c('v-list-tile',{key:index,on:{"click":function($event){return _vm.onAddUserToEntity(item.id, role.value)}}},[_c('v-list-tile-title',{domProps:{"textContent":_vm._s(role.text)}})],1)}),1)],1)],1)]}}])})],1)],1),_c('v-flex',{attrs:{"lg7":"","md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('entity.assignedTitle', { name: _vm.$t(_vm.getEntityName()) }),"color":"green","flat":"","full-width":""}},[_c('v-data-table',{staticStyle:{"max-height":"250px","overflow-y":"auto"},attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.entityAssignedHeaders,"items":_vm.entitiesAssigned,"single-select":true,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(item.stt))]),_c('td',{staticStyle:{"width":"40%"}},[_vm._v(_vm._s(item.name))]),_c('td',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(item.code))]),_c('td',{staticStyle:{"white-space":"nowrap","width":"35%"}},[_c('v-select',{attrs:{"items":_vm.entityRoles,"disabled":!item.isUpdate},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.text))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.text))+" ")]}}],null,true),model:{value:(item.entityRoleId),callback:function ($$v) {_vm.$set(item, "entityRoleId", $$v)},expression:"item.entityRoleId"}})],1),_c('td',{staticClass:"text-xs-center",staticStyle:{"white-space":"nowrap"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"success"},on:{"click":function($event){return _vm.onEditUserRoleInEntity(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(item.isUpdate ? 'mdi-check' : 'mdi-square-edit-outline'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.update')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onRemoveUserFromEntity(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1)]}}])})],1)],1),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }