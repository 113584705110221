<template>
  <v-dialog
    v-model="isShowModal"
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('siteManagement.addressTitle')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl>
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm4
                md4>
                <v-autocomplete
                  :items="provinceList"
                  v-model="address.province"
                  :label="$t('siteManagement.province')"
                  class="required"
                  @change="onChangedProvince"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-autocomplete
                  :items="districtList"
                  v-model="address.district"
                  :label="$t('siteManagement.district')"
                  class="required"
                  @change="onChangedDistrict"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-autocomplete
                  :items="wardList"
                  v-model="address.ward"
                  :label="$t('siteManagement.ward')"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-textarea
                  v-model="address.street"
                  :label="$t('siteManagement.address')"
                  :name="$t('siteManagement.address')"
                  type="text"
                  rows="3"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-layout
                  layout
                  wrap>
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="address.longitude"
                      :label="$t('siteManagement.longitude')"
                      :name="$t('siteManagement.longitude')"
                      type="text"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="address.latitude"
                      :label="$t('siteManagement.latitude')"
                      :name="$t('siteManagement.latitude')"
                      type="text"
                    />
                  </v-flex>

                  <!-- <v-flex
                    v-if="address.latitude && address.longitude"
                    xs12
                    sm12
                    md12
                  >
                    <address-label
                      :longitude="address.latitude"
                      :latitude="address.longitude">
                      {{ $t('siteManagement.openGoogleMap') }}
                    </address-label>
                  </v-flex> -->
                  <v-flex
                    v-if="address.latitude && address.longitude"
                    xs12
                    sm12
                    md12
                  >
                    <iframe
                      :src="`https://maps.google.com/maps?q=${address.latitude}+${address.longitude}&layer=tc&t=m&z=19&source=embed&output=svembed`"
                      width="100%"
                      height="300"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import { FOR_CONASI_TARGET } from 'utils/constants'
// import AddressLabel from 'Components/AddressLabel.vue'
export default {
  components: {
    // AddressLabel
  },
  data () {
    return {
      isShowModal: false,
      confirmModalTitle: null,
      address: {
        longitude: null,
        latitude: null,
        country: 84,
        province: null,
        district: null,
        ward: null,
        street: ''
      },
      provinceList: [{ text: '---', value: null }],
      districtList: [{ text: '---', value: null }],
      wardList: [{ text: '---', value: null }],
      forConasi: FOR_CONASI_TARGET
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
  },
  created () {
  },
  methods: {
    getProvinceList: function () {
      this.GET_LIST_PROVINCE({ id: this.address.country }).then(
        function (res) {
          var isFound = false
          let data = res.data
          this.provinceList = [{ text: '---', value: null }]
          for (let i = 0, size = data.length; i < size; i++) {
            let provinceObj = {
              value: data[i].code,
              text: data[i].type + ' ' + data[i].name
            }
            this.provinceList.push(provinceObj)
            if (data[i].code === this.address.province) {
              isFound = true
            }
          }
          if (isFound) {
            this.getDistrictList()
          }
        }.bind(this)
      )
    },
    getDistrictList: function () {
      if (this.address.province > 0) {
        this.GET_LIST_DISTRICT({ id: this.address.province }).then(
          function (res) {
            var isFound = false
            let data = res.data
            this.districtList = [{ text: '---', value: null }]
            for (let i = 0, size = data.length; i < size; i++) {
              let districtObj = {
                value: data[i].code,
                text: data[i].type + ' ' + data[i].name
              }
              this.districtList.push(districtObj)
              if (data[i].code === this.address.district) {
                isFound = true
              }
            }
            if (isFound) {
              this.getWardList()
            }
          }.bind(this)
        )
      } else {
        this.districtList = [{ text: '---', value: null }]
        this.address.district = 0
        // this.getWardList()
      }
    },
    getWardList: function () {
      if (this.address.district) {
        this.GET_LIST_WARD({ id: this.address.district }).then(
          function (res) {
            let data = res.data
            this.wardList = [{ text: '---', value: null }]
            for (let i = 0, size = data.length; i < size; i++) {
              let wardObj = {
                value: data[i].code,
                text: data[i].type + ' ' + data[i].name
              }
              this.wardList.push(wardObj)
            }
          }.bind(this)
        )
      } else {
        this.wardList = [{ text: '---', value: null }]
        this.address.ward = 0
      }
    },
    onChangedProvince: function () {
      // this.address.district = 0
      this.districtList = [{ text: '---', value: null }]
      // this.address.ward = 0
      this.wardList = [{ text: '---', value: null }]
      this.getDistrictList()
    },
    onChangedDistrict: function () {
      // this.address.ward = 0
      this.wardList = [{ text: '---', value: null }]
      this.getWardList()
    },
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    onShowModal: function (address) {
      this.address.province = address.province
      this.address.district = address.district
      this.address.ward = address.ward
      this.address.longitude = address.longitude
      this.address.latitude = address.latitude
      this.address.street = address.street
      this.isShowModal = true
      this.getProvinceList()
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      // this.setDefaultData()
    },
    onConfirm: function (event) {
      event.preventDefault()
      this.$emit('onConfirm', this.address)
      this.isShowModal = false
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_LIST_PROVINCE',
      'GET_LIST_DISTRICT',
      'GET_LIST_WARD'
    ])
  }
}

</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
