import { set, toggle } from '@/utils/vuex'

const mutations = {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer')
}
const state = {
  drawer: null,
  color: 'success',
  image: 'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-3.3a54f533.jpg'
}
export default {
  mutations,
  state
}
