var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{style:(("width: " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
        ? ''
        : '1200px !important') + ";"))},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-md-12 col-12"},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('div',{staticClass:"primary",staticStyle:{"display":"flex"},attrs:{"flat":"","dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.callbackProductList}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{staticStyle:{"align-self":"center","margin-left":"0"},attrs:{"dark":""}},[_c('h4',{staticStyle:{"font-weight":"bold !important","color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("productOrder.order") + " " + _vm.orderCode + " " + _vm.$t("productOrder.detail"))+" ")])])],1),_c('v-data-table',{staticClass:"mt-6 elevation-1",attrs:{"headers":_vm.productHeaders,"items":_vm.productsList,"mobile-breakpoint":0,"hide-default-footer":"","hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
    var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.stt))]),_c('td',[_c('img',{staticClass:"pt-2",attrs:{"src":props.item.image,"width":"100px","height":"70px"}})]),_c('td',[_vm._v(" "+_vm._s(props.item.product)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatMoney(props.item.price) + " VND")+" ")]),_c('td',[_vm._v(" "+_vm._s("x" + props.item.quantity)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatMoney(props.item.totalPrice) + " VND")+" ")])]}}])}),(
                _vm.orderPaymentConfirmStatus ===
                  _vm.baoKimOrderConfirmStatus.COMPLETED
              )?_c('div',{staticClass:"text-center font-large pa-2",staticStyle:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.$t("baoKimConfig.paymentConfirm.completed"))+" ")]):_c('div',{staticClass:"text-center font-large pa-2",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("baoKimConfig.paymentConfirm.errorPaymentConfirm"))+" ")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.callbackProductList}},[_vm._v(_vm._s(_vm.$t("common.back")))])],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }