<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      loader="dots"
      color="#5cb860"/>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  components: {
    Loading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      fullPage: true
    }
  }
}
</script>
<style scoped>
.popup-visible {
    position: absolute;
    z-index: 10;
    visibility: visible;
}

.popup-hidden {
    position: absolute;
    z-index: 10;
    visibility: hidden;
}
</style>
