<template>
  <v-dialog
    v-model="isShowModal"
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('common.detail') + ': ' + name"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid>
          <v-layout wrap>
            <v-flex
              xs12
              md4>
              <v-text-field
                :label="$t('customer.name')"
                v-model="name"
                disabled
              />
            </v-flex>
            <v-flex
              xs12
              md4>
              <v-text-field
                :label="$t('customer.code')"
                v-model="code"
                disabled
              />
            </v-flex>
            <v-flex
              xs12
              md4>
              <v-text-field
                :label="$t('customer.email')"
                v-model="email"
                disabled
              />
            </v-flex>
            <v-flex
              xs12
              md4>
              <v-text-field
                :label="$t('customer.phone')"
                v-model="phone"
                disabled
              />
            </v-flex>
            <v-flex
              xs12
              md8>
              <v-text-field
                :label="$t('customer.address')"
                v-model="address.fullAddress"
                disabled
              />
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-btn
          color="error darken-1"
          @click="closeModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    isShowModal: false,
    name: '',
    code: '',
    email: '',
    phone: '',
    address: {
      longitude: null,
      latitude: null,
      province: null,
      district: null,
      ward: null,
      street: null,
      fullAddress: null
    }
  }),
  computed: {
    ...mapGetters([
      'ENTITY_DETAIL_DATA'
    ])
  },
  watch: {
    ENTITY_DETAIL_DATA () {
      let data = this.ENTITY_DETAIL_DATA
      this.name = data.name
      this.code = data.code
      this.email = data.email
      this.phone = data.phone
      this.address.longitude = data.longitude
      this.address.latitude = data.latitude
      this.address.province = data.province
      this.address.district = data.district
      this.address.ward = data.ward
      this.address.street = data.street
      this.getFullAddress()
    }
  },
  created () {
  },
  methods: {
    /**
     * Close modal
     */
    closeModal: function () {
      this.isShowModal = false
    },
    /**
     * Show modal entities of user
     */
    onShowModal: function (entityId) {
      this.isShowModal = true
      this.GET_DETAIL_ENTITY({ id: entityId })
    },
    getFullAddress: function () {
      this.address.fullAddress = this.address.street
      let addressFilter = {
        params: {
          countryCode: 84,
          cityCode: this.address.province,
          districtCode: this.address.district,
          wardCode: this.address.ward
        }
      }
      this.GET_ADDRESS_DETAIL(addressFilter).then(
        function (res) {
          this.address.fullAddress = functionUtils.concatFullAddress(res.data, this.address.street)
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_DETAIL_ENTITY',
      'GET_ADDRESS_DETAIL'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/table.v-table tbody td {
    padding: 0 !important;
}
</style>
