import { languages } from './data'
import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

const state = {
  languages,
  selectedLocale: languages[1],
  objectSettings: {},
  supplierAccessCode: [],
  templateEmailCategories: [],
  templateEmailList: [],
  notificationCategories: [],
  notificationContents: []
}

// getters
const getters = {
  languages: state => {
    return state.languages
  },
  selectedLocale: state => {
    return state.selectedLocale
  },
  OBJECT_SETTING_DATA: state => {
    return state.objectSettings
  },
  GET_SUPPLIER_ACCESS_CODE_LIST_DATA: state => {
    return state.supplierAccessCode
  },
  TEMPLATE_EMAIL_CATEGORIES_DATA: state => {
    return state.templateEmailCategories
  },
  TEMPLATE_EMAIL_DATA: state => {
    return state.templateEmailList
  },
  NOTIFICATION_CATEGORIES_DATA: state => {
    return state.notificationCategories
  },
  NOTIFICATION_CONTENT_DATA: state => {
    return state.notificationContents
  }
}

// actions
const actions = {
  changeLanguage (context, payload) {
    context.commit('changeLanguageHandler', payload)
  },
  CREATE_OBJECT_SETTING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/object_setting/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_OBJECT_SETTING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/object_setting/get_object_setting/`,
        payload,
        function (res) {
          context.commit('GET_OBJECT_SETTING_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SUPPLIER_ACCESS_CODE_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/supplier_access_code/`,
        payload,
        function (res) {
          context.commit('GET_SUPPLIER_ACCESS_CODE_LIST', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_SUPPLIER_ACCESS_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/supplier_access_code/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_SUPPLIER_ACCESS_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/supplier_access_code/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_EMAIL_CATEGORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/template_email/get_template_email_categories/`,
        payload,
        function (res) {
          context.commit('GET_TEMPLATE_EMAIL_CATEGORIES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_EMAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/template_email/get_template_email_by_category/`,
        payload,
        function (res) {
          context.commit('GET_TEMPLATE_EMAIL_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_TEMPLATE_EMAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/template_email/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_TEMPLATE_EMAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/template_email/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_EMAIL_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/template_email/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_TEMPLATE_EMAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/template_email/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_FIELDS_TEMPLATE_EMAIL_BY_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/template_email/get_fields_template_email_by_category/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_EMAIL_DEFAULT_BY_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/template_email/get_template_email_default_by_category/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_NOTIFICATION_CATEGORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/notification_category/`,
        payload,
        function (res) {
          context.commit('GET_NOTIFICATION_CATEGORIES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_NOTIFICATION_CONTENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/notification_content/`,
        payload,
        function (res) {
          context.commit('GET_NOTIFICATION_CONTENT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_NOTIFICATION_CONTENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/notification_content/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_NOTIFICATION_CONTENT_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/notification_content/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_NOTIFICATION_CONTENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/notification_content/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_FIELDS_NOTIFICATION_CONTENT_BY_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/notification_content/get_fields_notification_content_by_category/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_NOTIFICATION_CONTENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/notification_content/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_OBJECT_SETTING_HANDLER (state, payload) {
    state.objectSettings = payload.data
  },
  /**
    * method for setting language state
   */
  changeLanguageHandler (state, language) {
    state.selectedLocale = language
  },
  GET_SUPPLIER_ACCESS_CODE_LIST (state, payload) {
    let supplierAccessCode = payload.results !== undefined ? payload.results : payload.data
    if (payload.results !== undefined) {
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.supplierAccessCode = { results: supplierAccessCode, paginate: page }
    } else {
      state.supplierAccessCode = payload.data
    }
  },
  GET_TEMPLATE_EMAIL_CATEGORIES_HANDLER (state, payload) {
    let categories = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.templateEmailCategories = { results: categories, paginate: page }
  },
  GET_TEMPLATE_EMAIL_HANDLER (state, payload) {
    let templateEmailList = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.templateEmailList = { results: templateEmailList, paginate: page }
  },
  GET_NOTIFICATION_CATEGORIES_HANDLER (state, payload) {
    let notificationCategories = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.notificationCategories = { results: notificationCategories, paginate: page }
  },
  GET_NOTIFICATION_CONTENT_HANDLER (state, payload) {
    let notificationContents = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.notificationContents = { results: notificationContents, paginate: page }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
