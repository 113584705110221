<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="1200px">
    <v-card>
      <material-card
        :title="$t('users.userDetail') + ': ' + userSelected.fullname"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding-top: 0;">
          <v-layout
            v-if="!isNull(userSelected.id)"
            wrap>
            <v-flex
              md12
              xl12
              style="padding: 0 !important;"
            >
              <div class="pb-2">
                <tab-header
                  :tab-items="userTabItems"
                  @onSelectTab="onSelectedUserTab" />
              </div>
            </v-flex>
          </v-layout>
          <v-layout
            v-if="!isNull(userSelected.id)"
            wrap>
            <!-- User detail -->
            <v-flex
              v-if="userTabTypeSelected == userTabType.USER_DETAIL"
              md12
              lg12
              style="padding: 0 !important;"
            >
              <userDetail
                :current-page="userPaginate.currentPage"
                :user-selected="userSelected"
              />
            </v-flex>
            <!-- User supplier -->
            <v-flex
              v-else-if="userTabTypeSelected == userTabType.USER_SUPPLIER"
              md12
              lg12
              style="padding: 0 !important;"
            >
              <userInEntities
                :entity-type="entityType.SUPPLIER"
                :user-id="userSelected.id" />
            </v-flex>
            <!-- User agent -->
            <v-flex
              v-else-if="userTabTypeSelected == userTabType.USER_AGENT"
              md12
              lg12
              style="padding: 0 !important;"
            >
              <userInEntities
                :entity-type="entityType.AGENT"
                :user-id="userSelected.id" />
            </v-flex>
            <!-- User site -->
            <v-flex
              v-else-if="userTabTypeSelected == userTabType.USER_SITE"
              md12
              lg12
              style="padding: 0 !important;"
            >
              <userInEntities
                :entity-type="entityType.SITE"
                :user-id="userSelected.id" />
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import UserTabType from 'enum/userTabType'
import UserInEntities from 'Views/Users/UserInEntities'
import functionUtils from 'utils/functionUtils'
import UserDetail from 'Views/Users/UserDetail'
import { mapGetters } from 'vuex'
import EntityType from 'enum/entityType'
import TabHeader from 'Components/TabHeader.vue'
export default {
  components: {
    userInEntities: UserInEntities,
    userDetail: UserDetail,
    TabHeader
  },
  data: () => ({
    entityType: EntityType,
    userTabType: UserTabType,
    userTabTypeSelected: UserTabType.USER_DETAIL,
    userTabItems: [
      {
        title: 'users.userDetail',
        id: UserTabType.USER_DETAIL
      },
      {
        title: 'entity.supplier',
        id: UserTabType.USER_SUPPLIER
      },
      {
        title: 'entity.agent',
        id: UserTabType.USER_AGENT
      },
      {
        title: 'entity.site',
        id: UserTabType.USER_SITE
      }
    ],
    isShowModal: false,
    userSelected: {
      id: null,
      avatar: null,
      username: null,
      fullname: null,
      phone: null,
      gender: null,
      dob: null,
      sysRole: null,
      email: null,
      identification: null
    },
    userPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    // eslint-disable-next-line eqeqeq
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  methods: {
    /**
     * Get Role of Logged In User
     */
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Selected user tab
     */
    onSelectedUserTab: function ({ index, item }) {
      this.userTabTypeSelected = item.id
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (item) {
      this.userSelected.id = item.id
      this.userSelected.avatar = item.avatar
      this.userSelected.username = item.username
      this.userSelected.fullname = item.fullname
      this.userSelected.phone = item.phone
      this.userSelected.gender = item.gender
      this.userSelected.dob = item.dob
      this.userSelected.sysRole = item.sys_role
      this.userSelected.roleInEntity = item.entityRoles && item.entityRoles[0] ? item.entityRoles[0].role_type : 0
      this.userSelected.email = item.email
      this.userSelected.identification = item.identification
      this.userTabItems.forEach(element => {
        if ((element.id === UserTabType.USER_SUPPLIER) ||
            (element.id === UserTabType.USER_DISTRIBUTER) ||
            (element.id === UserTabType.USER_AGENT) ||
            (element.id === UserTabType.USER_SITE)) {
          element.hidden = !this.isAdmin()
        }
      })
      this.isShowModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/material-dashboard/_colors.scss";
/deep/ .theme--light.v-list {
  background-color: transparent;
}
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
