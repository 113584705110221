<template>
  <div>
    <v-dialog
      v-model="accessCodeDialog"
      persistent
      max-width="500px">
      <v-card>
        <material-card
          :title="$t('confirmActiveKey.verificationCodes')"
          tile
          color="green"
          full-width>
          <v-container grid-list-xl>
            <v-form
              ref="accessCodeForm"
              lazy-validation>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  md12>
                  <v-text-field
                    :rules="[activeKeyRules]"
                    v-model="accessCode"
                    :label="$t('confirmActiveKey.verificationCodes')"
                    :name="$t('confirmActiveKey.verificationCodes')"
                    prepend-icon="mdi-text"
                    type="text"
                    class="required"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </material-card>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="error darken-1"
            @click="accessCodeDialog = false"
          >{{ $t("common.close") }}
          </v-btn>
          <v-btn
            :loading="isLoading"
            small
            color="success darken-1"
            @click="onConfirmAccessCode"
          >{{ $t("common.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="emailDomainDialog"
      persistent
      max-width="500px">
      <v-card>
        <material-card
          :title="$t('partnership.emailJoinPartnership')"
          tile
          color="green"
          full-width>
          <v-container grid-list-xl>
            <v-form
              ref="emailDomainForm"
              lazy-validation>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  md12>
                  <v-text-field
                    :rules="[emailRules]"
                    v-model="emailDomain"
                    :label="$t('partnership.emailJoinPartnership')"
                    :name="$t('partnership.emailJoinPartnership')"
                    prepend-icon="mdi-text"
                    type="text"
                    class="required"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </material-card>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="error darken-1"
            @click="emailDomainDialog = false"
          >{{ $t("common.close") }}
          </v-btn>
          <v-btn
            :loading="isLoading"
            small
            color="success darken-1"
            @click="onVerifyActiveKey"
          >{{ $t("common.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <confirmModal
      ref="confirmModal"
      :title="$t('partnership.notiLogoutPartnership')"
      @onConfirm="confirmLogoutPartnership"
    />
  </div>
</template>
<script>
import { setCookie } from 'tiny-cookie'
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import StoreChannelType from 'enum/storeChannelType'
export default {
  components: {
    ConfirmModal
  },
  data: () => ({
    isLoading: false,
    accessCodeDialog: false,
    accessCode: null,
    activeKeyRules: [],
    emailDomainDialog: false,
    emailDomain: null,
    emailRules: [],
    storeChannelId: null
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    accessCode () {
      this.activeKeyRules = []
    },
    emailDomain () {
      this.emailRules = []
    }
  },
  created () {
    // this.onCheckUserIsActivated()
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    onShowModalAccessCode: function (storeChannelId) {
      this.accessCodeDialog = true
      this.storeChannelId = storeChannelId
      this.accessCode = null
    },
    onCloseModalAccessCode: function () {
      this.accessCodeDialog = false
    },
    activeKeyNotExist () {
      return this.$t('confirmActiveKey.codeNotExist')
    },
    onConfirmAccessCode: function () {
      //
      this.activeKeyRules = [
        (v) => !!v || this.$t('confirmActiveKey.enterVerificationCode')
      ]
      if (this.$refs.accessCodeForm.validate()) {
        if (functionUtils.isEmptyString(this.accessCode)) {
          this.ON_SHOW_TOAST({
            'message': this.$t('confirmActiveKey.verificationCodes'),
            'styletype': ToastType.ERROR
          })
          return false
        }
        let filter = {
          code: this.accessCode
        }
        this.VERIFY_ACTIVE_KEY(filter).then(
          function (res) {
            let data = res.data.data
            sessionStorage.setItem('partnershipEntityId', data.entity_id)
            this.activeKeyRules = []
            this.onCloseModalAccessCode()
            // this.emailDomainDialog = true
            this.onCheckUserIsActivated()
            this.emailDomain = null
          }.bind(this)
        ).catch(
          function () {
            this.activeKeyRules.push(
              this.activeKeyNotExist
            )
            this.$refs.accessCodeForm.validate()
            this.ON_SHOW_TOAST({
              'message': this.$t('confirmActiveKey.codeNotExist'),
              'styletype': ToastType.ERROR
            })
          }.bind(this)
        )
      }
    },
    onVerifyActiveKey: function () {
      this.emailRules = [
        (v) => !!v || this.$t('login.thisFieldIsRequired'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.emailMustBeValid')
      ]
      if (this.$refs.emailDomainForm.validate()) {
        if (functionUtils.isEmptyString(this.emailDomain)) {
          this.ON_SHOW_TOAST({
            'message': this.$t('confirmEmailDomain.emailDomain'),
            'styletype': ToastType.ERROR
          })
          return false
        }
        let filter = {
          email: this.emailDomain,
          domain: stringUtils.PARTNERSHIP_URL
        }
        this.isLoading = true
        this.VERIFY_EMAIL_DOMAIN(filter)
          .then(
            function (res) {
              this.isLoading = false
              let data = res.data
              if (data.isActive) {
                setCookie('isActiveLinkPartnership', data.isActive)
                this.emailDomainDialog = false
                this.$emit('loginPartnership', true)
                if (this.storeChannelId === StoreChannelType.LIEN_VIET_POST_BANK) {
                  this.$router.push({
                    name: 'LienVietProductList'
                  })
                } else {
                  this.$router.push({
                    name: 'MobilePortalProductList',
                    params: {
                      'storeChannelId': this.storeChannelsIdInRouteParams()
                    }
                  })
                }
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('confirmEmailDomain.successfulWaitForLinkConfirm'),
                  'styletype': ToastType.ERROR
                })
              }
            }.bind(this)
          )
          .catch(
            function () {
              this.isLoading = false
              this.emailRules.push(this.emailNotExists)
              this.$refs.emailDomainForm.validate()
              this.ON_SHOW_TOAST({
                'message': this.$t('confirmEmailDomain.emailDomainNotExist'),
                'styletype': ToastType.ERROR
              })
            }.bind(this)
          )
      }
    },
    onCheckUserIsActivated: function () {
      let userId = this.GET_CURRENT_ACCOUNT && this.GET_CURRENT_ACCOUNT.id
      // const userId = isCookieEnabled() ? getCookie('userId') : null
      if (!functionUtils.isNull(userId)) {
        this.CHECK_USER_IS_ACTIVATED({ id: userId }).then(
          function (res) {
            let data = res.data
            if (data.isActive) {
              setCookie('isActiveLinkPartnership', data.isActive)
              this.$emit('loginPartnership', true)
              if (this.storeChannelId === StoreChannelType.LIEN_VIET_POST_BANK) {
                if (this.$route.name !== 'LienVietProductList') {
                  this.$router.push({
                    name: 'LienVietProductList'
                  })
                }
              } else {
                if (this.$route.name !== 'MobilePortalProductList') {
                  this.$router.push({
                    name: 'MobilePortalProductList',
                    params: {
                      'storeChannelId': this.storeChannelsIdInRouteParams()
                    }
                  })
                }
              }
            } else {
              this.emailDomainDialog = true
            }
          }.bind(this)
        )
      }
    },
    // show modal confirm logout
    showModalConfirmLogout: function () {
      this.$refs.confirmModal.onShowModal()
    },
    confirmLogoutPartnership: function () {
      sessionStorage.removeItem('partnershipEntityId')
      setCookie('isActiveLinkPartnership', false)
      this.$emit('logoutPartnership', true)
      if (this.storeChannelId === StoreChannelType.LIEN_VIET_POST_BANK) {
        if (this.$route.name !== 'LienVietProductList') {
          this.$router.push({
            name: 'LienVietProductList'
          })
        }
      } else {
        if (this.$route.name !== 'MobilePortalProductList') {
          this.$router.push({
            name: 'MobilePortalProductList',
            params: {
              'storeChannelId': this.storeChannelsIdInRouteParams()
            }
          })
        }
      }
    },
    ...mapActions([
      'VERIFY_ACTIVE_KEY',
      'VERIFY_EMAIL_DOMAIN',
      'CHECK_USER_IS_ACTIVATED',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
