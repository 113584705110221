import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  accessCodeList: []
}

// getters
const getters = {
  GET_ACCESS_CODE_DATA: state => {
    return state.accessCodeList
  }
}

// actions
const actions = {
  CREATE_ACCESS_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/create_access_code/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ACCESS_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/get_access_code/`,
        payload,
        function (res) {
          context.commit('GET_ACCESS_CODE', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_ACCESS_CODE: (state, payload) => {
    state.accessCodeList = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
