<template>
  <v-container
    style="padding: 0px;"
    grid-list-xl>
    <v-layout
      wrap
      layout>
      <v-flex
        xs12
        sm8
        md6
        style="padding-bottom: 0px;">

        <material-card
          :title="$t('users.userDetail') + ': ' + (userSelected.fullname ? userSelected.fullname : '')"
          color="green"
          flat
          full-width
        >
          <v-layout wrap>
            <!-- User name -->
            <!-- <v-flex
              xs12
              md4
            >
              <v-text-field
                v-model="userSelected.username"
                :label="$t('users.username')"
                disabled
              />
            </v-flex> -->
            <!-- Email -->
            <v-flex
              xs12
              md4
            >
              <v-text-field
                v-model="userSelected.email"
                :label="$t('user.email')"
                disabled
              />
            </v-flex>
            <!-- Full name -->
            <v-flex
              xs12
              md4
            >
              <v-text-field
                v-model="userSelected.fullname"
                :label="$t('user.fullname')"
                :disabled="isUpdate"
              />
            </v-flex>
            <!-- Phone -->
            <v-flex
              xs12
              md4
            >
              <v-text-field
                v-model="userSelected.phone"
                :label="$t('user.phone')"
                :disabled="isUpdate"
              />
            </v-flex>
            <!-- Gender -->
            <v-flex
              xs12
              md4
            >
              <v-select
                v-model="userSelected.gender"
                :items="genderList"
                :label="$t('userProfile.gender')"
                :disabled="isUpdate"
                item-text="text"
                item-value="value"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-select>
            </v-flex>
            <!-- Birth date -->
            <v-flex
              xs12
              md4
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="isUpdate"
                    v-model="userSelected.dob"
                    :label="$t('user.dob')"
                    persistent-hint
                    @blur="date = parseDate(userSelected.dob)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dobDefault"
                  no-title
                  @input="menu = false"/>
              </v-menu>
            </v-flex>
          </v-layout>
        </material-card>
      </v-flex>
      <!-- Extra info -->
      <v-flex
        xs12
        sm8
        md6>
        <material-card
          :title="$t('users.extraInfo')"
          color="green"
          flat
          full-width
        >
          <v-layout wrap>
            <!-- User Identification - for Conasi only -->
            <v-flex
              v-if="forConasi"
              xs12
              md4
            >
              <v-text-field
                v-model="userSelected.identification"
                :label="$t('users.identification')"
                :disabled="isUpdate"
              />
            </v-flex>
            <!-- User System Role -->
            <v-flex
              v-if="isAdmin()"
              xs12
              md4
            >
              <v-select
                :items="sysRoleTypeList"
                v-model="userSelected.systemRole"
                :label="$t('roles.role')"
                :disabled="isUpdate || !isAdmin()"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-select>
            </v-flex>
            <!-- User In Entity Role -->
            <v-flex
              v-else
              xs12
              md8
            >
              <v-select
                :items="entityRoles"
                v-model="userSelected.roleInEntity"
                :label="$t('users.assignedRoles')"
                :disabled="isUpdate"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-select>
            </v-flex>
          </v-layout>
        </material-card>
      </v-flex>
      <!-- Edit button -->
      <v-flex
        xs12
        sm12
        md12
        style="padding: 0px; margin: 0px;">
        <v-tooltip
          v-if="hasAction('update_user') || isAdmin()"
          top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!isUpdate"
              small
              color="primary"
              v-on="on"
              @click="onUpdateUserInfo()">
              <v-icon dark>mdi-check</v-icon>
            </v-btn>
            <v-btn
              v-else
              small
              color="primary"
              v-on="on"
              @click="isUpdate = !isUpdate">
              <v-icon dark>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ !isUpdate ? $t('common.confirm') : $t('common.update') }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import stringUtils from 'utils/stringUtils'
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import SysRoleType from 'enum/sysRoleType'
import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  props: {
    userSelected: {
      type: Object,
      default: () => {
        return null
      }
    },
    currentPage: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  data: () => ({
    menu: false,
    isUpdate: true,
    dobDefault: null,
    sysRoleTypeList: [
      {
        value: SysRoleType.ADMIN,
        text: 'roles.type.admin'
      },
      {
        value: SysRoleType.USER,
        text: 'roles.type.user'
      },
      {
        value: SysRoleType.REPORTER,
        text: 'roles.type.reporter'
      }
    ],
    entityRoles: [],
    genderList: stringUtils.GenderList,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'ENTITY_ROLE_DATA'
    ]),
    computedDateFormatted () {
      return this.formatDate(this.dobDefault)
    }
  },
  watch: {
    ENTITY_ROLE_DATA () {
      let data = this.ENTITY_ROLE_DATA
      this.entityRoles = []
      for (let i = 0, size = data.length; i < size; i++) {
        let entityRoleObj = {
          value: data[i].id,
          text: data[i].name
        }
        this.entityRoles.push(entityRoleObj)
      }
    },
    dobDefault (val) {
      this.userSelected.dob = this.formatDate(this.dobDefault)
    }
  },
  created () {
    let filter = {
      params: {
        entityType: sessionStorage.getItem('entityType')
      }
    }
    this.GET_ENTITY_ROLE_LIST(filter)
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    //   Format date
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse date
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Update user info
     */
    onUpdateRoleInEntity: function () {
      let filter = {
        userId: this.userSelected.id,
        roleInEntity: this.userSelected.roleInEntity
      }
      this.SET_ROLE_IN_ENTITY_FOR_USER(filter).then(
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    onUpdateUserInfo: function () {
      this.isUpdate = !this.isUpdate
      let firstLastName = functionUtils.getFirstLastName(this.userSelected.fullname)
      let filter = {
        lastName: firstLastName.lastName,
        firstName: firstLastName.firstName,
        phone: this.userSelected.phone,
        birthDate: this.userSelected.dob,
        gender: this.userSelected.gender,
        identification: this.userSelected.identification
      }
      if (this.isAdmin()) {
        filter['systemRole'] = this.userSelected.systemRole
      } else {
        this.onUpdateRoleInEntity()
      }
      this.UPDATE_USER_CUSTOM({ id: this.userSelected.id, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          let filter = {
            params: {
              page: this.currentPage
            }
          }
          this.GET_USER_LIST(filter)
        }.bind(this)
      ).catch(
        function (error) {
          let errorMessage = error.response.data.message
          if (!functionUtils.isNull(errorMessage) && errorMessage.includes('(identification)')) {
            this.ON_SHOW_TOAST({
              'message': this.$t('users.errorText.indentAlreadyExist'),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              styletype: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'UPDATE_USER_CUSTOM',
      'GET_USER_LIST',
      'SET_ROLE_IN_ENTITY_FOR_USER',
      'ON_SHOW_TOAST',
      'GET_ENTITY_ROLE_LIST'
    ])
  }
}
</script>
