// we need our modal component
import messageModal from './MessageModal.vue'

const MessageModal = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install (Vue, options) {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emiting events from our main instance
    // this EventBus will be available as Modal.EventBus
    this.EventBus = new Vue()

    // making our modal component global
    Vue.component('message-modal', messageModal)

    // exposing global $modal object with method show()
    // method show() takes object params as argument
    // inside this object we can have modal title, text, styles... and also our callback confirm function
    Vue.prototype.$message = {
      showModal (params) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        MessageModal.EventBus.$emit('showModal', params)
      }
    }
  }
}

export default MessageModal

// Usage
// Add these lines into main.js
//   import MessageModal from './messagemodal'
//   Vue.use(MessageModal)
// Add follwing line into App.vue, inside vue decalaration
//   <message-modal />
// Showing message
//    const params = {
//      // title: '', // optional
//      message: '',
//      // confirmText: '', // optional
//      // closeText: '', // optional
//      // we are passing callback method for our confirm button
//      onConfirm: () => {}
//    }
//    // now we can call function that will reveal our modal
//    this.$message.showModal(params)
