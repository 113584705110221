import axios from 'axios'
import { Promise } from 'es6-promise'

// state
const state = {
  emailGatewayStatus: []
}

// getters
const getters = {
  GET_MAIL_GATEWAY_EVENTS_DATA: state => {
    return state.emailGatewayStatus
  }
}

const getExternalRequest = function (baseURL, apiKey, url, data, resolve, reject, timeout = 60000) {
  const encodedToken = Buffer.from(`api:${apiKey}`).toString('base64')
  // const encodedToken = window.btoa(`api:${apiKey}`) // This also work properly
  const config = {
    baseURL: baseURL,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${encodedToken}`
    }
  }
  const instance = axios.create(config)
  instance
    .get(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      reject(error)
    })
}

// actions
const actions = {
  GET_MAIL_GATEWAY_EVENTS: (context, payload) => {
    return new Promise((resolve, reject) => {
      getExternalRequest(
        payload.baseUrl,
        payload.apiKey,
        `/events`,
        { 'params': { ...payload.params } },
        function (res) {
          context.commit('GET_MAIL_GATEWAY_EVENTS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_MAIL_GATEWAY_EVENTS_PAGING: (context, payload) => {
    return new Promise((resolve, reject) => {
      getExternalRequest(
        payload.baseUrl,
        payload.apiKey,
        `/events/${payload.pagingToken}`,
        null,
        function (res) {
          context.commit('GET_MAIL_GATEWAY_EVENTS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_MAIL_GATEWAY_EVENTS_HANDLER: (state, payload) => {
    state.emailGatewayStatus = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
