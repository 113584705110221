import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

// state
const state = {
  entities: [],
  entitiesByTypeOfUser: [],
  entityRoleList: [],
  groupInOutSideEntityRoleList: [],
  entitiesOfUser: [],
  agentsAvailableForDistributer: [],
  parentEntities: [],
  childrenEntities: [],
  entityDetail: {},
  currentEntity: [],
  parentChildrenEntities: [],
  agentsSameParent: [],
  suppliersByVoucher: [],
  entitySiteList: [],
  agentsExcludeCurrentAgent: [],
  smsAgentHistory: [],
  emailAgentHistory: []
}

// getters
const getters = {
  LIST_ENTITIES_DATA: state => {
    return state.entities
  },
  LIST_ENTIIES_BY_TYPE_OF_USER_DATA: state => {
    return state.entitiesByTypeOfUser
  },
  ENTITY_ROLE_DATA: state => {
    return state.entityRoleList
  },
  GROUP_IN_OUT_SIDE_ENTITY_ROLE_LIST_DATA: state => {
    return state.groupInOutSideEntityRoleList
  },
  ENTITIES_OF_USER_DATA: state => {
    return state.entitiesOfUser
  },
  AGENTS_AVAILABLE_FOR_DISTRIBUTER_DATA: state => {
    return state.agentsAvailableForDistributer
  },
  PARENT_ENTITY_DATA: state => {
    return state.parentEntities
  },
  CHILDREN_ENTITY_DATA: state => {
    return state.childrenEntities
  },
  ENTITY_DETAIL_DATA: state => {
    return state.entityDetail
  },
  CURRENT_ENTITY_DATA: state => {
    return state.currentEntity
  },
  PARENT_CHILDREN_ENTITIES_DATA: state => {
    return state.parentChildrenEntities
  },
  AGENT_SAME_PARENT_DATA: state => {
    return state.agentsSameParent
  },
  SUPPLIER_BY_VOUCHER_OF_CUSTOMER_DATA: state => {
    return state.suppliersByVoucher
  },
  LIST_AGENT_EXCLUDE_CURRENT_AGENT_DATA: state => {
    return state.agentsExcludeCurrentAgent
  },
  GET_SMS_HISTORY_LIST_DATA: state => {
    return state.smsAgentHistory
  },
  GET_EMAIL_HISTORY_LIST_DATA: state => {
    return state.emailAgentHistory
  }
}

// actions
const actions = {
  GET_LIST_ENTITIES_BY_TYPE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/`,
        payload,
        function (res) {
          context.commit('GET_LIST_ENTITIES_BY_TYPE_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_ENTITY_BY_TYPE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ENTITY_ADDRESS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/update_entity_address/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ENTITY_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/update_entity_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITY_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/get_entity_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SET_ENTITY_MAIL_GATEWAY_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/set_entity_mail_gateway_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITY_MAIL_GATEWAY_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/get_entity_mail_gateway_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITY_SMTP_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/get_entity_smtp_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITY_ROLE_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/entity_role/`,
        payload,
        function (res) {
          context.commit('GET_ENTITY_ROLE_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ENTITY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/entity_role/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_ENTITY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/entity_role/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_ENTITY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/entity_role/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_GROUP_IN_OUT_SIDE_ENTITY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/entity_role/get_group_in_out_side_entity_role/`,
        payload,
        function (res) {
          context.commit('GET_GROUP_IN_OUT_SIDE_ENTITY_ROLE_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_GROUP_TO_ENTITY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/entity_role/add_group_to_entity_role/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REMOVE_GROUP_FROM_ENTITY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/entity_role/${payload.id}/delete_group_from_entity_role/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITIES_OF_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/entity_role/get_entities_of_user/`,
        payload,
        function (res) {
          context.commit('GET_ENTITIES_OF_USER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PARENT_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_parent_entity/`,
        payload,
        function (res) {
          context.commit('GET_PARENT_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CHILDREN_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_children_entity/`,
        payload,
        function (res) {
          context.commit('GET_CHILDREN_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DETAIL_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/`,
        payload,
        function (res) {
          context.commit('GET_DETAIL_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REGISTER_SUPPLIER_NEW_CUSTOMER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/register_supplier_new_customer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  ACTIVE_SUPPLIER_REGISTER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/active_entity_register/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CURRENT_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_current_entity/`,
        payload,
        function (res) {
          context.commit('GET_CURRENT_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PARENT_CHILDREN_ENTITIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/get_parent_children_entities/`,
        payload,
        function (res) {
          context.commit('GET_PARENT_CHILDREN_ENTITIES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REMOVED_ENTITY_FROM_PARENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/removed_entity_from_parent/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SET_USE_PRIVATE_SMTP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/entity_set_use_private_smtp/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SEND_EMAIL_FOR_TESTINNG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/send_test_mail/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_AGENT_SAME_PARENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_agents_same_parent/`,
        payload,
        function (res) {
          context.commit('GET_AGENT_SAME_PARENT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SUPPLIER_BY_VOUCHER_OF_CUSTOMER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_supplier_by_voucher_of_customer/`,
        payload,
        function (res) {
          context.commit('GET_SUPPLIER_BY_VOUCHER_OF_CUSTOMER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DISABLED_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/disabled_entity/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITY_ID_HASHED: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/get_entity_id_hashed/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SITE_GROUP_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_site_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  POST_SITE_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/create_site_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_SITE_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/update_site_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_SITE_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/delete_site_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SITE_GROUP_MAPPING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_site_group_mapping/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  POST_SITE_GROUP_MAPPING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/entity/post_site_group_mapping/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_SITE_GROUP_MAPPING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/entity/${payload.id}/delete_site_group_mapping/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_AGENT_EXCLUDE_CURRENT_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/entity/get_agent_exclude_current_agent/`,
        payload,
        function (res) {
          context.commit('GET_AGENT_EXCLUDE_CURRENT_AGENT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  POST_PRINT_TEMPLATE_ITEM_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/print_template_config/post_print_template_item_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRINT_TEMPLATE_ITEM_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/print_template_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REMOVE_PRINT_TEMPLATE_ITEM_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/print_template_config/remove_print_template_item_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRINT_TEMPLATE_CONFIG_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/print_template_config/get_print_template_config_list/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_PRINT_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/print_template_config/create_print_template/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_EMAIL_HISTORY_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/email_history/`,
        payload,
        function (res) {
          context.commit('GET_EMAIL_HISTORY_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_SMS_HISTORY_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/sms_history/`,
        payload,
        function (res) {
          context.commit('GET_SMS_HISTORY_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_EMAIL_HISTORY_CONTENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/email_history/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        10000
      )
    })
  }
}

// mutations
const mutations = {
  GET_GROUP_IN_OUT_SIDE_ENTITY_ROLE_HANDLER: (state, payload) => {
    state.groupInOutSideEntityRoleList = payload
  },
  GET_LIST_ENTITIES_BY_TYPE_HANDLER: (state, payload) => {
    let entities = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    if (payload.results) {
      state.entities = { results: entities, paginate: page }
    } else {
      state.entities = entities
    }
  },
  GET_ENTIIES_BY_TYPE_OF_USER_HANDLER: (state, payload) => {
    state.entitiesByTypeOfUser = payload
  },
  GET_ENTITY_ROLE_LIST_HANDLER: (state, payload) => {
    state.entityRoleList = payload
  },
  GET_ENTITIES_OF_USER_HANDLER: (state, payload) => {
    state.entitiesOfUser = payload
  },
  GET_AGENT_AVAILABLE_FOR_DISTRIBUTER_HANDLER: (state, payload) => {
    state.agentsAvailableForDistributer = payload
  },
  GET_PARENT_ENTITY_HANDLER: (state, payload) => {
    state.parentEntities = payload.data
  },
  GET_CHILDREN_ENTITY_HANDLER: (state, payload) => {
    if (payload.results) {
      let entities = payload.results
      let page = {
        totalPage: payload.num_pages && payload.num_pages,
        currentPage: payload.page && payload.page,
        pageSize: payload.page_size && payload.page_size,
        perPage: payload.per_page && payload.per_page
      }
      state.childrenEntities = { results: entities, paginate: page }
    } else {
      state.childrenEntities = payload.data
    }
  },
  GET_DETAIL_ENTITY_HANDLER: (state, payload) => {
    state.entityDetail = payload
  },
  GET_CURRENT_ENTITY_HANDLER: (state, payload) => {
    let entities = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.currentEntity = { results: entities, paginate: page }
  },
  GET_PARENT_CHILDREN_ENTITIES_HANDLER: (state, payload) => {
    let entities = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.parentChildrenEntities = { results: entities, paginate: page }
  },
  GET_AGENT_SAME_PARENT_HANDLER: (state, payload) => {
    state.agentsSameParent = payload
  },
  GET_SUPPLIER_BY_VOUCHER_OF_CUSTOMER_HANDLER: (state, payload) => {
    state.suppliersByVoucher = payload
  },
  GET_AGENT_EXCLUDE_CURRENT_AGENT_HANDLER: (state, payload) => {
    state.agentsExcludeCurrentAgent = payload
  },
  GET_SMS_HISTORY_LIST_HANDLER: (state, payload) => {
    let histories = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.smsAgentHistory = { results: histories, paginate: page }
  },
  GET_EMAIL_HISTORY_LIST_HANDLER: (state, payload) => {
    state.emailAgentHistory = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
