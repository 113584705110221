<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-img
      :src="$store.state.app.image"
      height="100%">
      <v-layout
        class="fill-height scroll-custom"
        tag="v-list"
        column>
        <v-list-tile
          avatar
          to="/dashboard">
          <!-- <v-img
            :src="logo"
            height="auto"
            width="100%"
            contain
          /> -->
          <div style="width: 100%; text-align: center;">
            <img
              v-if="!forConasi"
              src="../../assets/e_voucher_logo.svg"
              width="50%">
            <img
              v-else
              src="../../assets/e_voucher_logo_portrait_white_conasi.png"
              width="50%">
          </div>
        </v-list-tile>
        <v-divider />
        <template v-for="(link, i) in links">
          <v-list-tile
            v-if="!link.subLinks"
            :key="i"
            :to="link.to"
            :active-class="$store.state.app.color"
            avatar
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t(link.text) }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            v-else
            :key="link.text"
            no-action>
            <v-list-tile
              slot="activator"
              class="v-list-item">
              <v-list-tile-action>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>
                {{ $t(link.text) }}
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              :active-class="$store.state.app.color"
              class="v-list-item"
              style="padding-left: 15px; padding-bottom: 5px;"
            >
              <v-list-tile-action
                style="margin: 0 !important; padding: 0 !important; margin-right: 5px;"
              >
                <v-icon size="20">{{ sublink.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-sub-title class="font-medium">{{ $t(sublink.text) }}</v-list-tile-sub-title>
            </v-list-tile>
          </v-list-group>
        </template>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { FOR_CONASI_TARGET } from 'utils/constants'
import { mapMutations, mapState, mapGetters } from 'vuex'
import EntityRoleType from 'enum/entityRoleType'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // eslint-disable-next-line eqeqeq
    forConasi: FOR_CONASI_TARGET,
    logo: require('../../assets/logo.png'),
    // eslint-disable-next-line eqeqeq
    enablePortalModule: FOR_CONASI_TARGET && (process.env.VUE_APP_ENABLE_PORTAL_MODULE == 'true'),
    linkDashboard: [
      {
        to: '/dashboard',
        icon: 'mdi-view-dashboard',
        text: 'dashboard.title'
      }
    ],
    links: [],
    voucherManagement: {
      to: 'e-voucher',
      icon: 'mdi-ticket-percent',
      text: 'sideBar.voucherManagement',
      code: 'VOUCHER_MANAGEMENT',
      subLinks: []
    },
    voucherMenu: {
      to: '/e-voucher-list',
      icon: 'mdi-magnify',
      text: 'infoVoucher.voucherInformation'
    },
    voucherIssuanceMenu: {
      to: '/e-voucher-issuance',
      icon: 'mdi-ticket-percent',
      text: FOR_CONASI_TARGET ? 'workpiece.list' : 'workpiece.issuanceList'
    },
    suppliersMenu: {
      to: '/suppliers',
      icon: 'mdi-home-group',
      text: 'sideBar.supplierManagement'
    },
    agentsMenu: {
      to: '/agents',
      icon: 'mdi-home-account',
      text: 'sideBar.agentManagement'
    },
    customerMenu: {
      to: '/customer',
      icon: 'mdi-account-tie',
      text: 'sideBar.customerManagement'
    },
    emailHistoryMenu: {
      to: '/email-history',
      icon: 'mdi-email-lock',
      text: 'sideBar.emailHistory'
    },
    smsHistoryMenu: {
      to: '/sms-history',
      icon: 'mdi-cellphone-basic',
      text: 'sideBar.smsHistory'
    },
    settingMenu: {
      to: '/setting',
      icon: 'mdi-cogs',
      text: 'sideBar.setting',
      code: 'SETTING_MENU',
      subLinks: []
    },
    decentralization: {
      to: '/decentralization',
      icon: 'mdi-account-switch',
      text: 'sideBar.decentralization'
    },
    userMenu: {
      to: '/user-list',
      icon: 'mdi-account',
      text: 'sideBar.userManagement'
    },
    customizationMenu: {
      to: '/customization',
      icon: 'mdi-cog',
      text: 'sideBar.generalConfiguration'
    },
    paymentMenu: {
      to: '/3rd-settings',
      icon: 'mdi-cog',
      text: 'sideBar.thirdPartiesSettings'
    },
    issueEVoucherRequestMenu: {
      to: '/issue-evoucher-request',
      icon: 'mdi-forum',
      text: 'dashboard.voucherRequestList'
    },
    reportVoucherMenu: {
      to: '/report-voucher',
      icon: 'mdi-chart-areaspline-variant',
      text: 'voucherReport.title'
    },
    voucherHistoryMenu: {
      to: '/history-voucher',
      icon: 'mdi-history',
      text: 'evoucher.history'
    },
    batchJobMenu: {
      to: '/batch-job',
      icon: 'mdi-book-plus-multiple',
      text: 'evoucher.batchJob.title'
    },
    requestFromAgentWatingForAprroval: {
      to: '/request-approval-voucher-from-agent',
      icon: 'mdi-playlist-check',
      text: 'sideBar.voucherTransferRequestList'
    },
    lookupVoucherInfo: {
      to: '/lookup-voucher-info',
      icon: 'mdi-magnify',
      text: 'infoVoucher.searchForVoucherInfo'
    },
    development: {
      to: '/development',
      icon: 'mdi-console',
      text: 'sideBar.development'
    },
    supllierAccessCode: {
      to: '/supplier-access-code',
      icon: 'mdi-qrcode',
      text: 'sideBar.supplierAccessCode'
    },
    usageSiteMenu: {
      to: '/site-management',
      icon: 'mdi-store-outline',
      text: 'sideBar.siteManagement'
    },
    supplierTemplateEmailMenu: {
      to: '/template-email-categories',
      icon: 'mdi-mail',
      text: 'sideBar.configTemplateEmail'
    },
    supplierNotificationMenu: {
      to: '/notification-categories',
      icon: 'mdi-bell-outline',
      text: 'sideBar.configurationNotificationTemplate'
    },
    useVoucherPromotionMenu: {
      to: '/use-voucher-promotion',
      icon: 'mdi-gift',
      text: 'sideBar.useVoucherPromotion'
    },
    bannerAdsMenu: {
      to: '/advertisement-info',
      icon: 'mdi-star-box-outline',
      text: 'sideBar.advertisementInfo'
    },
    reportMenu: {
      to: '/report-statistic',
      icon: 'mdi-finance',
      text: 'sideBar.reportStatistic',
      subLinks: [
        // {
        //   to: '/report-statistic-voucher',
        //   icon: 'mdi-file-chart',
        //   text: 'report.voucherStatistics'
        // },
        // {
        //   to: '/report-statistic-user',
        //   icon: 'mdi-text-account',
        //   text: 'report.userStatistics'
        // }
      ]
    },
    hashtagMenu: {
      to: '/hashtag-list',
      icon: 'mdi-pound',
      text: 'sideBar.hashtagManagement'
    },
    reportQuantityVoucherSupplierToAgent: {
      to: '/report-quantity-voucher-supplier-to-agent',
      icon: 'mdi-poll',
      text: 'report.numberOfVouchersIssuedToAgent'
    },
    reportQuantityVoucherDistributerToAgent: {
      to: '/report-quantity-voucher-distributer-to-agent',
      icon: 'mdi-poll',
      text: 'report.numberOfVouchersToBeIssuedToAgentLevel2'
    },
    reportQuantityVoucherSoldInAgents: {
      to: '/report-quantity-voucher-sold-in-agent',
      icon: 'mdi-poll',
      text: 'report.quantityVoucherSoldInAgent.title'
    },
    reportQuantityVoucherSoldOfStaffInAgents: {
      to: '/report-quantity-voucher-sold-of-staff-in-agent',
      icon: 'mdi-poll',
      text: 'report.quantityVoucherSoldOfStaffInAgent.title'
    },
    reportDetailQuantityVoucherSoldOfStaffInAgents: {
      to: '/report-detail-quantity-voucher-sold-of-staff-in-agent',
      icon: 'mdi-poll',
      text: 'report.detailQuantityVoucherSoldOfStaffInAgent.title'
    },
    reportQuantityVoucherCreateInSupplier: {
      to: '/report-quantity-voucher-create-in-supplier',
      icon: 'mdi-poll',
      text: 'report.supplier.numberVoucherCreated'
    },
    reportVoucherDistributed: {
      to: '/report-voucher-distributed',
      icon: 'mdi-poll',
      text: 'evoucher.voucherDistribution'
    },
    reportVoucherUsedInSupplier: {
      to: '/report-voucher-used-in-supplier',
      icon: 'mdi-poll',
      text: 'report.supplier.voucherUsedDetail'
    },
    ReportLocalUsedVoucher: {
      to: '/report-local-use-voucher',
      icon: 'mdi-poll',
      text: 'report.supplier.voucherUsedList'
    },
    // workpieceMenu: {
    //   to: '/workpiece-list',
    //   icon: 'mdi-file-table',
    //   text: 'sideBar.evoucherIssuance'
    // },
    supplierTemplatePrintEVoucherMenu: {
      to: '/template-print-voucher',
      icon: 'mdi-table-cog',
      text: 'sideBar.voucherPrintTemplate'
    },
    testApiMenu: {
      to: '/test-api',
      icon: 'mdi-table-cog',
      text: 'Test API'
    },
    portalInfoManagementMenu: {
      to: '/',
      icon: 'mdi-web',
      text: 'sideBar.infoWebPortal',
      subLinks: []
    },
    sliderPortalMenu: {
      to: '/banner-slider',
      icon: 'mdi-image-multiple-outline',
      text: 'portal.banner.slider'
    },
    adsPortalMenu: {
      to: '/banner-ads',
      icon: 'mdi-image-outline',
      text: 'portal.banner.ads'
    },
    logoAndMenuBgColor: {
      to: '/logo-menu-bg-color',
      icon: 'mdi-palette',
      text: 'portal.banner.logoAndBgMenu'
    },
    emailDomainActiveKey: {
      to: '/email-domain-active-key',
      icon: 'mdi-account-key',
      text: 'sideBar.emailDomain'
    },
    voucherProductManagementMenu: {
      to: '/',
      icon: 'mdi-paper-cut-vertical',
      text: 'sideBar.voucherProduct',
      subLinks: []
    },
    voucherProductCategory: {
      to: '/voucher-product-category',
      icon: 'mdi-table-of-contents',
      text: 'voucherProduct.category'
    },
    voucherProduct: {
      to: '/voucher-product',
      icon: 'mdi-basket',
      text: 'voucherProduct.productTitle'
    },
    storeChannels: {
      to: '/store-channels-list',
      icon: 'mdi-store',
      text: 'storeChannel.title'
    },
    productOrderList: {
      to: '/product-orders-list',
      icon: 'mdi-receipt',
      text: 'productOrder.title'
    },
    bankTransactionList: {
      to: '/bank-transaction',
      icon: 'mdi-bank',
      text: 'bankTransactionInfo.sidebarTitle'
    },
    footerConfig: {
      to: '/footer-list',
      icon: 'mdi-page-layout-footer',
      text: 'sideBar.footerConfig'
    },
    promotionMenu: {
      to: '/promotion-code',
      icon: 'mdi-sale',
      text: 'promotionCode.promotion'
    },
    paymentMethodMenu: {
      to: '/payment-methods',
      icon: 'mdi-credit-card-outline',
      text: 'paymentMethod.title'
    },
    displayMenu: {
      to: '/display-config',
      icon: 'mdi-view-quilt',
      text: 'portal.display'
    },
    usedVoucherListMenu: {
      to: '/used-voucher-list',
      icon: 'mdi-view-quilt',
      text: 'voucherReconciliation.voucherUsed.title'
    },
    voucherReconciliation: {
      to: '/',
      icon: 'mdi-view-quilt',
      text: 'voucherReconciliation.waitForApproval',
      subLinks: []
    },
    voucherWaitForApprovalMenu: {
      to: '/voucher-wait-reconciliation-list',
      icon: 'mdi-view-quilt',
      text: 'voucherReconciliation.waitForApproval'
    },
    voucherReconciliationHistoriesMenu: {
      to: '/voucher-reconciliation-histories-list',
      icon: 'mdi-history',
      text: 'voucherReconciliation.history.title'
    },
    placesOfUsageMenu: {
      to: '/places-of-usage',
      icon: 'mdi-account-box-multiple',
      text: 'groupSites.title'
    }
  }),
  computed: {
    ...mapState(['image', 'color']),
    ...mapGetters([
      'GET_CURRENT_ACCOUNT'
    ]),
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    }
  },
  watch: {
    GET_CURRENT_ACCOUNT () {
      this.settingMenu.subLinks = []
      this.reportMenu.subLinks = []
      this.voucherProductManagementMenu.subLinks = []
      this.links = [...this.linkDashboard]
      let entityId = sessionStorage.getItem('entityId')
      if (functionUtils.isNull(entityId) && !this.isAdmin()) {
        return
      }
      this.links.push(this.voucherManagement)
      if (functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)) {
        this.links.push(this.voucherProductManagementMenu)
        this.settingMenu.subLinks.push(this.userMenu, this.decentralization, this.paymentMenu, this.customizationMenu, this.emailHistoryMenu, this.smsHistoryMenu, this.batchJobMenu)
        if (!this.forConasi) {
          this.settingMenu.subLinks.push(this.development, this.supllierAccessCode, this.testApiMenu)
        }
        this.links.push(
          this.suppliersMenu,
          this.agentsMenu,
          this.usageSiteMenu,
          this.customerMenu,
          this.settingMenu
        )
      } else if (functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)) {
        // this.links = [...this.linkDashboard]
        this.voucherProductManagementMenu.subLinks.push(this.productOrderList)
        this.voucherProductManagementMenu.subLinks.push(this.bankTransactionList)
        this.links.push(this.voucherProductManagementMenu)
        if (!this.isAdmin()) {
          // Report for agent
          this.links.push(this.reportMenu)
        }
      } else {
        if (this.enablePortalModule && (functionUtils.hasEntityRoles('hasManageWebPortal') || functionUtils.hasEntityRoles('hasGiveVoucherToHaravan'))) {
          this.links.push(this.voucherProductManagementMenu)
          this.voucherProductManagementMenu.subLinks.push(this.voucherProductCategory)
          if (!this.isSupplier()) {
            this.voucherProductManagementMenu.subLinks.push(this.voucherProduct)
          }
          this.voucherProductManagementMenu.subLinks.push(this.productOrderList)
          this.voucherProductManagementMenu.subLinks.push(this.promotionMenu)
        }
        if (this.isSupplier() || this.isUsageSite()) {
          this.voucherReconciliation.subLinks = []
          this.voucherReconciliation.subLinks.push(this.usedVoucherListMenu)
          this.voucherReconciliation.subLinks.push(this.voucherWaitForApprovalMenu)
          this.voucherReconciliation.subLinks.push(this.voucherReconciliationHistoriesMenu)
          this.links.push(this.voucherReconciliation)
        }
        if (this.isAdmin() && this.forConasi) {
          this.links.push(this.storeChannels)
        }
        if (this.isAdmin()) {
          this.links.push(this.suppliersMenu)
        }
        if (!this.isUsageSite() || this.isAdmin()) {
          this.links.push(this.agentsMenu)
        }
        if (this.isSupplier() || this.isAdmin()) {
          this.links.push(this.usageSiteMenu)
        }
        if (!this.isUsageSite() || this.isAdmin()) {
          this.links.push(this.customerMenu)
        }
        if (this.isAdmin()) {
          this.links.push(this.bannerAdsMenu)
        }
        if (!this.isAdmin()) {
          // Report for agent
          this.links.push(this.reportMenu)
        }
        // if (this.isAdmin() || functionUtils.isEntityType(EntityType.SUPPLIER)) {
        if (this.isAdmin() || functionUtils.isEntityType(EntityType.SUPPLIER) || functionUtils.isEntityType(EntityType.AGENT)) {
          this.links.push(this.hashtagMenu)
        }
        if (this.enablePortalModule && functionUtils.hasEntityRoles('hasManageWebPortal')) {
          this.links.push(this.portalInfoManagementMenu)
          this.portalInfoManagementMenu.subLinks = []
          this.portalInfoManagementMenu.subLinks.push(this.footerConfig, this.displayMenu)
        }
        if (!this.isAdmin()) {
          this.portalInfoManagementMenu.subLinks.push(this.emailDomainActiveKey)
        }
        // Setting
        if (!(functionUtils.isEntityType(EntityType.SITE) && !functionUtils.roleInEntity(EntityRoleType.MANAGER))) {
          this.links.push(this.settingMenu)
        }
        if (this.hasAction('view_user') || this.isAdmin()) {
          this.settingMenu.subLinks.push(this.userMenu)
        }
        if (this.isAdmin()) {
          this.settingMenu.subLinks.push(this.decentralization, this.supplierNotificationMenu)
        }
        if (this.isAdmin() || functionUtils.hasEntityRoles('hasGiveVoucherToHaravan')) {
          this.settingMenu.subLinks.push(this.paymentMenu)
        }
        if (!functionUtils.isEntityType(EntityType.CUSTOMER) && !this.isUsageSite()) {
          this.settingMenu.subLinks.push(this.customizationMenu)
        }
        if (this.isAdmin() && !this.forConasi) {
          this.settingMenu.subLinks.push(this.development, this.supllierAccessCode)
        }
        if (this.isAdmin() || (!this.forConasi && this.isSupplier() && functionUtils.roleInEntity(EntityRoleType.MANAGER))) {
          this.settingMenu.subLinks.push(this.emailHistoryMenu, this.smsHistoryMenu)
        }
        if (this.isSupplier() && functionUtils.roleInEntity(EntityRoleType.MANAGER)) {
          this.settingMenu.subLinks.push(this.supplierTemplateEmailMenu)
        }
        if (this.isSupplier() && this.forConasi) {
          this.settingMenu.subLinks.push(this.supplierTemplatePrintEVoucherMenu)
        }
        if (this.forConasi && functionUtils.isEntityType(EntityType.AGENT)) {
          this.settingMenu.subLinks.push(this.paymentMethodMenu)
        }
      }
      if (!this.isAdmin()) {
        // Report
        // if (functionUtils.isEntityType(EntityType.SUPPLIER)) {
        //   this.reportMenu.subLinks.push(this.reportQuantityVoucherSupplierToAgent)
        // }
        if (functionUtils.isEntityType(EntityType.AGENT)) {
          this.reportMenu.subLinks.push(this.reportQuantityVoucherSupplierToAgent)
          this.reportMenu.subLinks.push(this.reportQuantityVoucherDistributerToAgent)
          this.reportMenu.subLinks.push(this.reportQuantityVoucherSoldInAgents)
          this.reportMenu.subLinks.push(this.reportQuantityVoucherSoldOfStaffInAgents)
          this.reportMenu.subLinks.push(this.reportDetailQuantityVoucherSoldOfStaffInAgents)
        }
        if (this.isSupplier()) {
          this.reportMenu.subLinks.push(this.reportQuantityVoucherCreateInSupplier)
          this.reportMenu.subLinks.push(this.reportVoucherDistributed)
          this.reportMenu.subLinks.push(this.ReportLocalUsedVoucher)
          this.reportMenu.subLinks.push(this.reportVoucherUsedInSupplier)
          // this.reportMenu.subLinks.push(this.reportDetailQuantityVoucherSoldOfStaffInAgents)
        }
      }
      this.handleEVoucherFunctions()
    }
  },
  created () {
    this.links = [...this.linkDashboard]
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Handle e voucher functions
     */
    handleEVoucherFunctions: function () {
      let voucherManager = this.links.find(item => item.code === 'VOUCHER_MANAGEMENT')
      if (voucherManager === undefined) {
        return
      }
      // Set default
      voucherManager.subLinks = []
      if (this.isAdmin() || functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)) {
        voucherManager.subLinks.push(this.voucherMenu, this.voucherIssuanceMenu, this.voucherHistoryMenu)
      } else {
        if (this.isSupplier()) {
          // if (this.forConasi) {
          //   voucherManager.subLinks.push(this.workpieceMenu)
          // }
          voucherManager.subLinks.unshift(this.useVoucherPromotionMenu)
        }
        let workpieceManager = voucherManager.subLinks.filter((subLink) => {
          return subLink.to === '/e-voucher-issuance'
        })
        if (workpieceManager.length === 0 && this.hasAction('view_issuance')) {
          voucherManager.subLinks.unshift(this.voucherIssuanceMenu)
        }
        // Ensure that lookup voucher menu is first, then others
        let eVoucher = voucherManager.subLinks.filter((subLink) => {
          return subLink.to === '/e-voucher-list'
        })
        if ((eVoucher.length === 0 && this.hasAction('view_voucher')) || this.isUsageSite()) {
          voucherManager.subLinks.unshift(this.voucherMenu)
        }
        // voucherManager.subLinks.unshift(this.lookupVoucherInfo)
        if (this.forConasi && (!this.isUsageSite() || (functionUtils.isEntityType(EntityType.AGENT)))) {
          voucherManager.subLinks.push(this.requestFromAgentWatingForAprroval)
        }
        if (!this.isUsageSite()) {
          if (this.forConasi) {
            voucherManager.subLinks.push(this.issueEVoucherRequestMenu)
          }
          // voucherManager.subLinks.push(this.reportVoucherMenu)
          voucherManager.subLinks.push(this.voucherHistoryMenu)
        }
      }
      // Set some menu has sub menu is empty if all element in sub menu has length == 0
      for (let i = this.links.length - 1; i > 0; i--) {
        if (this.links[i].subLinks && this.links[i].subLinks.length === 0) {
          this.links.splice(i, 1)
        } else if (this.links[i].subLinks && this.links[i].subLinks.length === 0) {
          this.links.splice(i, 1)
        }
      }
    },
    /**
     * Check is agent
     */
    isAgent: function () {
      return functionUtils.isEntityType(EntityType.AGENT)
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    ...mapMutations(['setDrawer', 'toggleDrawer'])
  }
}
</script>
<style lang="scss" scoped>
.v-navigation-drawer .v-list .v-list-item .v-list__tile__action i {
  color: #ffffff !important;
}
.v-navigation-drawer .v-list .v-list-item > .v-list__tile .v-list__tile__title {
  color: #ffffff !important;
}
.theme--dark.v-list .v-list__tile__sub-title {
 white-space: normal;
}
</style>
