<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'600px'"
    persistent>
    <v-card>
      <material-card
        :title="(type == ServiceType.SMS) ? $t('smsConfig.secretKey') : $t('common.changePassword')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidPassword]"
                  v-model="password"
                  :label="(type == ServiceType.SMS) ? $t('smsConfig.secretKey') : $t('common.password')"
                  :name="(type == ServiceType.SMS) ? $t('smsConfig.secretKey') : $t('common.password')"
                  :type="(type == ServiceType.SMS) ? 'text' : 'password'"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :is-loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
// import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
import ServiceType from 'enum/serviceType'
export default {
  data: () => ({
    ServiceType: ServiceType,
    valid: false,
    isShowModal: false,
    password: null,
    isLoading: false,
    type: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidPassword (value) {
      // if (this.type === ServiceType.SMS) {
      //   return true
      // }
      // return validationRules.ruleValidPassword(value)
      return true
    },
    /**
     * Confirm change password
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line eqeqeq
        if (this.type == ServiceType.EMAIL_SMTP) {
          this.isShowModal = false
          this.$emit('updatePasswordSMTPEmail', this.password)
        } else {
          let filter = {
            type: this.type,
            password: this.password
          }
          this.isLoading = true
          this.ON_CHANGE_PASSWORD_FOR_SERVICE(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                'styleType': ToastType.SUCCESS
              })
              this.isShowModal = false
              this.password = null
              this.isLoading = false
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }.bind(this)
          )
        }
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Show modal
     */
    onShowModal: function (type) {
      this.type = type
      this.isShowModal = true
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'ON_CHANGE_PASSWORD_FOR_SERVICE'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
