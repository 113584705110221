<template>
  <v-app>
    <router-view />
    <message-modal />
  </v-app>
</template>
<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  data: () => ({
    // eslint-disable-next-line eqeqeq
    forConasi: FOR_CONASI_TARGET,
    favConasi: require('./assets/favicon_conasi.png')
  }),
  mounted () {
    var link = document.querySelector("link[rel*='shortcut icon']")
    if (!link) {
      link = document.querySelector("link[rel*='icon']")
    }
    if (link) {
      link.type = 'image/png'
      link.rel = 'icon'
      if (this.forConasi) {
        link.href = this.favConasi
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
