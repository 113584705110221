<template>
  <v-dialog
    v-model="isShowModalEventList"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('common.projectList')"
        tile
        color="green"
        full-width
      >
        <div class="mb-3">
          <tab-header
            :tab-items="tabItems"
            :selected-id="tabSelected"
            @onSelectTab="onSelectTab" />
        </div>
        <v-data-table
          :headers="projectHeaders"
          :items="selectionProjList"
          :no-data-text="$t('common.noDataAvailable')"
        >
          <template
            slot="headerCell"
            slot-scope="{ header }">
            <span
              class="text--darken-3"
              v-text="$t(header.text)" />
          </template>
          <template v-slot:items="props">
            <tr
              :style="`background-color: ${props.item.id == selected ? 'whitesmoke' : ''};`"
              @click="{selected=props.item.id}">
              <td style="width:60px">
                <v-radio-group
                  v-model="selected"
                  style="margin-top: 20px; margin-left: 5px">
                  <v-radio
                    :value="props.item.id"
                    :color="isEntityDisabled(props.item) ? 'error' : (props.item.remainDays < DEFAULT_AGENT_EXPIRED_DAYS ? 'warning' : '')"
                  />
                </v-radio-group>
              </td>
              <td :class="getWarnStyle(props.item)">{{ props.item.name }}</td>
              <!-- <td :class="getWarnStyle(props.item)">{{ $t(getEntityTypeName(props.item.type)) }}</td> -->
              <td :class="getWarnStyle(props.item)">{{ props.item.code }}</td>
              <td :class="getWarnStyle(props.item)">{{ $t(roleInEntityName(props.item.roleType)) }}</td>
              <td
                v-if="!forConasi"
                :class="getWarnStyle(props.item)">
                {{ getEntityStatus(props.item) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </material-card>
      <v-card-actions>
        <v-btn
          :disabled="!selected"
          color="primary darken-1"
          @click="saveEntitySelected"
        >{{ $t("common.goTo") }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="hasNoSelectedEntity()"
          color="error darken-1"
          @click="closeModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import { mapActions, mapGetters } from 'vuex'
import TabHeader from 'Components/TabHeader.vue'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import { FOR_CONASI_TARGET, DEFAULT_AGENT_EXPIRED_DAYS } from 'utils/constants'
import EntityType from 'enum/entityType'
export default {
  components: {
    TabHeader
  },
  data: () => ({
    projectList: [],
    selectionProjList: [],
    tabSelected: 0,
    tabItems: [],
    projectHeaders: [
      { value: 'selected', text: '', sortable: false },
      { value: 'name', text: 'entity.header.name', sortable: false },
      // { value: 'type', text: 'entity.header.type', sortable: false },
      { value: 'code', text: 'entity.header.code', sortable: false },
      { value: 'role', text: 'entity.header.role', sortable: false }
    ],
    isShowModalEventList: false,
    selected: null,
    isLoadEntitiesOfUser: false,
    DEFAULT_AGENT_EXPIRED_DAYS: DEFAULT_AGENT_EXPIRED_DAYS,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'ENTITIES_OF_USER_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    ENTITIES_OF_USER_DATA: function () {
      let data = this.ENTITIES_OF_USER_DATA
      this.projectList = []
      let hasSupplier = false
      let hasAgency = false
      let hasSite = false
      for (let i = 0, size = data.length; i < size; i++) {
        let entityObj = {
          id: data[i].id,
          name: data[i].name,
          code: data[i].code,
          type: data[i].type,
          phone: data[i].phone,
          website: data[i].website,
          image: data[i].image_url,
          parentImage: data[i].parent_image,
          hasManageWebPortal: data[i].enabled_product_portal,
          hasGiveVoucherToHaravan: data[i].enabled_haravan,
          hasGiveVoucherToLienViet: data[i].enabled_lienviet,
          hasGiveVoucherToMobilePortal: data[i].enabled_mobile_portal,
          allowSMSDelivery: data[i].allow_sms_delivery,
          deliveryEmailNotification: data[i].delivery_email_notification,
          managerNotificationEmail: data[i].manager_email_notification,
          roleType: data[i].role_type,
          remainDays: dateUtils.getRemainDays(data[i].expired_date),
          expiredDate: data[i].expired_date,
          status: data[i].status
        }
        if (entityObj.type === EntityType.SUPPLIER) {
          hasSupplier = true
        } else if (entityObj.type === EntityType.AGENT) {
          hasAgency = true
        } else if (entityObj.type === EntityType.SITE) {
          hasSite = true
        }
        this.projectList.push(entityObj)
      }
      if (hasSupplier) {
        this.tabItems.push(
          {
            title: functionUtils.getEntityTypeName(EntityType.SUPPLIER),
            id: EntityType.SUPPLIER
          }
        )
        if (this.tabSelected === 0) {
          this.tabSelected = EntityType.SUPPLIER
        }
      }
      if (hasAgency) {
        this.tabItems.push(
          {
            title: functionUtils.getEntityTypeName(EntityType.AGENT),
            id: EntityType.AGENT
          }
        )
        if (this.tabSelected === 0) {
          this.tabSelected = EntityType.AGENT
        }
      }
      if (hasSite) {
        this.tabItems.push(
          {
            title: functionUtils.getEntityTypeName(EntityType.SITE),
            id: EntityType.SITE
          }
        )
        if (this.tabSelected === 0) {
          this.tabSelected = EntityType.SITE
        }
      }
      this.onRefreshSelectionProjectList(this.tabSelected)
      if (functionUtils.isNull(sessionStorage.getItem('entityId'))) {
        if (this.projectList.length === 1) {
          this.selected = this.projectList[0].id
          this.saveSelectedEntitySessionStorage(this.projectList[0])
          this.$emit('entitySelected')
          // Callback get info user
          this.GET_USER_DATA()
        }
      } else {
        // let entitySelected = this.projectList.filter(element => {
        //   return element.id === sessionStorage.getItem('entityId')
        // })
        // this.saveSelectedEntitySessionStorage(entitySelected[0])
      }
      this.isLoadEntitiesOfUser = false
    },
    GET_CURRENT_ACCOUNT: function () {
      let token = isCookieEnabled() ? getCookie('token') : null
      if (token) {
        if (!this.isAdmin() && this.projectList.length === 0 && !this.isLoadEntitiesOfUser) {
          this.LOGOUT(token).then(
            function () {
              this.removeEntitySessionStorage()
              this.$router.push({
                name: 'login'
              })
            }.bind(this)
          ).catch(
            function () {
              this.removeEntitySessionStorage()
              this.$router.push({
                name: 'login'
              })
            }.bind(this)
          )
        }
      }
    }
  },
  created () {
    let entityId = sessionStorage.getItem('entityId')
    this.selected = Number(entityId)
    this.isLoadEntitiesOfUser = true
    if (!FOR_CONASI_TARGET) {
      this.projectHeaders.push({ value: 'remainDays', text: 'entity.header.status', sortable: false })
    }
    this.GET_ENTITIES_OF_USER()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    hasNoSelectedEntity: function () {
      let entityId = sessionStorage.getItem('entityId')
      return functionUtils.isNull(entityId)
    },
    /**
     * Get entity type name
     */
    getEntityTypeName: function (type) {
      return functionUtils.getEntityTypeName(type)
    },
    roleInEntityName: function (role) {
      return functionUtils.roleInEntityName(role)
    },
    isEntityDisabled: function (entity) {
      return functionUtils.isEntityLocked(entity.status) || functionUtils.isEntityExpired(entity.expiredDate)
    },
    getEntityStatus: function (entity) {
      if (functionUtils.isEntityLocked(entity.status)) {
        return this.$t('entity.state.inactive')
      }
      if (functionUtils.isEntityExpired(entity.expiredDate)) {
        return this.$t('entity.state.expired')
      }
      if (entity.remainDays !== null) {
        if (entity.remainDays < 0) {
          return this.$t('entity.state.expired')
        } else if (entity.remainDays < DEFAULT_AGENT_EXPIRED_DAYS) {
          return this.$t('entity.state.almostExpired', { days: entity.remainDays + 1 })
        }
      }
      return this.$t('entity.state.activated')
    },
    getWarnStyle: function (entity) {
      if (this.isEntityDisabled(entity)) {
        return 'has-expired'
      }
      return entity.remainDays != null && entity.remainDays < DEFAULT_AGENT_EXPIRED_DAYS ? 'almost-expired' : ''
    },
    /**
     * Save project selected
     */
    saveEntitySelected: function () {
      let entitySelected = this.projectList.filter(element => {
        // eslint-disable-next-line eqeqeq
        return element.id == this.selected
      })
      this.saveSelectedEntitySessionStorage(entitySelected[0])
      this.$emit('entitySelected')
      this.isShowModalEventList = false
      // Callback get info user
      this.GET_USER_DATA()
      this.$router.push({
        'path': '/dashboard'
      })
    },
    /**
     * Close modal
     */
    closeModal: function () {
      this.isShowModalEventList = false
    },
    /**
     * Click project
     */
    onClickEntity: function () {
    },
    /**
     * Show modal entities of user
     */
    onShowModal: function () {
      let entityId = sessionStorage.getItem('entityId')
      this.selected = Number(entityId)
      this.isShowModalEventList = true
    },
    onSelectTab: function ({ index, item }) {
      this.tabSelected = item.id
      this.onRefreshSelectionProjectList(item.id)
    },
    onRefreshSelectionProjectList: function (type) {
      this.selectionProjList = this.projectList.filter(element => {
        return element.type === type
      })
    },
    saveSelectedEntitySessionStorage: function (selectedEntity) {
      sessionStorage.setItem('entityId', selectedEntity.id)
      sessionStorage.setItem('entityName', selectedEntity.name)
      sessionStorage.setItem('entityType', selectedEntity.type)
      sessionStorage.setItem('entityImage', selectedEntity.image)
      sessionStorage.setItem('entityPhone', selectedEntity.phone)
      sessionStorage.setItem('entityWebsite', selectedEntity.website)
      sessionStorage.setItem('entityParentImage', selectedEntity.parentImage)
      sessionStorage.setItem('roleInEntity', selectedEntity.roleType)
      sessionStorage.setItem('expiredDateEntity', selectedEntity.expiredDate)
      sessionStorage.setItem('entityStatus', selectedEntity.status)
      let entityRoles = []
      if (selectedEntity.hasManageWebPortal) {
        entityRoles.push('hasManageWebPortal')
      }
      if (selectedEntity.hasGiveVoucherToHaravan) {
        entityRoles.push('hasGiveVoucherToHaravan')
      }
      if (selectedEntity.hasGiveVoucherToLienViet) {
        entityRoles.push('hasGiveVoucherToLienViet')
      }
      if (selectedEntity.hasGiveVoucherToMobilePortal) {
        entityRoles.push('hasGiveVoucherToMobilePortal')
      }
      if (selectedEntity.allowSMSDelivery) {
        entityRoles.push('allowSMSDelivery')
      }
      sessionStorage.setItem('entityRoles', JSON.stringify(entityRoles))
    },
    /**
     * Remove entity session storage
     */
    removeEntitySessionStorage: function () {
      sessionStorage.removeItem('entityId')
      sessionStorage.removeItem('entityName')
      sessionStorage.removeItem('entityType')
      sessionStorage.removeItem('entityImage')
      sessionStorage.removeItem('entityPhone')
      sessionStorage.removeItem('entityWebsite')
      sessionStorage.removeItem('entityParentImage')
      sessionStorage.removeItem('roleInEntity')
      sessionStorage.removeItem('entityRoles')
      sessionStorage.removeItem('expiredDateEntity')
      sessionStorage.removeItem('entityStatus')
    },
    ...mapActions([
      'GET_ENTITIES_OF_USER',
      'GET_USER_DATA',
      'LOGOUT'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.has-expired {
  color: #f00;
}
.almost-expired {
  color: #f60;
}
/deep/table.v-table tbody td {
    padding: 0 !important;
}
</style>
