import VoucherReconciliationType from 'enum/voucherReconciliationType'
import VoucherStatusType from 'enum/voucherStatusType'
import GenderType from 'enum/genderType'
import VoucherType from 'enum/voucherType'
import VoucherValue from 'enum/voucherValue'
import VoucherIssuanceStatusType from 'enum/voucherIssuanceStatusType'
import entityStatus from 'enum/entityStatus'
import customerType from 'enum/getCustomerType'
import BaoKimPaymentType from 'enum/BaoKimPaymentType'
import VoucherProductApplyType from 'enum/voucherProductApplyType'
import BankTransactionStatusEnum from 'enum/bankTransactionStatusEnum'
import EntityType from 'enum/entityType'
import SysRoleType from 'enum/sysRoleType'
import EntityRoleType from 'enum/entityRoleType'

const NETWORK_ERROR = 'Network Error'
const GenderList = [
  {
    text: 'userProfile.genderType.male',
    value: GenderType.MALE
  },
  {
    text: 'userProfile.genderType.female',
    value: GenderType.FEMALE
  },
  {
    text: 'userProfile.genderType.other',
    value: GenderType.OTHER
  }
]
const DocumentTypeList = [
  {
    text: 'Pdf',
    value: 1
  },
  {
    text: 'Video',
    value: 2
  },
  {
    text: 'Doc',
    value: 3
  }
]
const VoucherTypeList = [
  {
    text: 'Voucher',
    value: VoucherType.VOUCHER
  },
  {
    text: 'Coupon',
    value: VoucherType.COUPON
  },
  {
    text: 'evoucher.issuance.promotion',
    value: VoucherType.PROMOTION
  }
]
const VoucherValueList = [
  {
    text: 'evoucher.issuance.price',
    value: VoucherValue.PRICE
  },
  {
    text: 'evoucher.issuance.percent',
    value: VoucherValue.PERCENT
  },
  {
    text: 'evoucher.issuance.promotion',
    value: VoucherValue.PROMOTION
  }
]
const VoucherStatusList = [
  {
    text: '---',
    value: null
  },
  {
    text: 'evoucher.status.assigned',
    value: VoucherStatusType.ASSIGNED
  },
  {
    text: 'evoucher.status.notAssignedYet',
    value: VoucherStatusType.NOT_ASSIGNED_YET
  },
  // {
  //   text: 'evoucher.status.inactive',
  //   value: VoucherStatusType.INACTIVE
  // },
  {
    text: 'evoucher.status.using_expired',
    value: VoucherStatusType.USING_EXPIRED
  },
  {
    text: 'evoucher.status.activated',
    value: VoucherStatusType.ACTIVATED
  },
  {
    text: 'evoucher.status.voucherDisable',
    value: VoucherStatusType.DISABLED
  },
  {
    text: 'evoucher.status.used',
    value: VoucherStatusType.USED
  },
  // {
  //   text: 'evoucher.status.distribution_expired',
  //   value: VoucherStatusType.DISTRIBUTION_EXPIRED
  // },
  // {
  //   text: 'evoucher.status.locked',
  //   value: VoucherStatusType.LOCKED_USING
  // },
  {
    text: 'evoucher.status.waiting',
    value: VoucherStatusType.WAITING
  }
]
const EVoucherIssuanceStatus = [
  {
    text: 'user.status_data.active',
    value: VoucherIssuanceStatusType.ACTIVATED
  },
  {
    text: 'user.status_data.inactive',
    value: VoucherIssuanceStatusType.INACTIVE
  }
]

const EntityStatus = [
  {
    text: '---',
    value: null
  },
  {
    text: 'user.status_data.active',
    value: entityStatus.ACTIVATED
  },
  {
    text: 'user.status_data.inactive',
    value: entityStatus.INACTIVE
  }
]

const EntityTypeName = [
  {
    text: 'entity.supplier',
    value: EntityType.SUPPLIER
  },
  {
    text: 'entity.agent',
    value: EntityType.AGENT
  },
  {
    text: 'entity.site',
    value: EntityType.SITE
  }
]

const SystemRoleList = [
  {
    text: 'roles.type.admin',
    value: SysRoleType.ADMIN
  },
  {
    text: 'roles.type.user',
    value: SysRoleType.USER
  },
  {
    text: 'roles.type.reporter',
    value: SysRoleType.REPORTER
  }
]

const EntityRoleList = [
  {
    text: 'roles.type.manager',
    value: EntityRoleType.MANAGER
  },
  {
    text: 'roles.type.accountant',
    value: EntityRoleType.ACCOUNTANT
  },
  {
    text: 'roles.type.staff',
    value: EntityRoleType.STAFF
  }
]

const ServiceSMSProviderList = [
  {
    text: '---',
    value: null
  },
  {
    text: 'Tri Anh',
    value: 1
  },
  {
    text: 'Vi Hat',
    value: 2
  },
  {
    text: 'Moti Plus',
    value: 3
  }
]

const ObjectSettingKey = {
  DISCOUNT: 'discount',
  SUPPLIER_ACTIVE_CODE: 'supplier_active_code',
  HAS_PERM_CREATE_PROMOTION_VOUCHER: 'has_perm_create_promotion_voucher',
  COMPANY_NAME: 'company_name',
  COMPANY_ADDRESSS: 'company_addresss',
  COMPANY_EMAIL: 'company_email',
  COMPANY_HOTLINE: 'company_hotline',
  COMPANY_FACEBOOK: 'company_facebook',
  HARAVAN_ACCESS_TOKEN: 'haravan_access_token',
  HOLDING_TIME: 'holding_time',
  CANCELING_TIME: 'canceling_time',
  LIENVIET_LPB_PUB_KEY: 'lienviet_lpb_pub_key',
  LIENVET_MERCHANT_PRI_KEY: 'lienviet_merchant_pri_key',
  LIENVET_MERCHANT_PUB_KEY: 'lienviet_merchant_pub_key',
  PRODUCT_NAME: 'product_name',
  LIENVIET_MERCHANT_SITE: 'lienviet_merchant_site',
  LIENVIET_ACCESS_CODE: 'lienviet_access_code',
  PAYMENT_MOMO_ENABLE_PAYMENT: 'payment_momo_method_enabled',
  PAYMENT_MOMO_PARTNER_CODE: 'payment_momo_partner_code',
  PAYMENT_MOMO_ACCESS_CODE: 'payment_momo_access_code',
  PAYMENT_MOMO_SECRET_KEY: 'payment_momo_secret_key',
  BAO_KIM_API_KEY: 'bao_kim_api_key',
  BAO_KIM_API_SECRET: 'bao_kim_api_secret',
  BAO_KIM_MERCHANT_ID: 'bao_kim_merchant_id',
  DEFAULT_ENTITY_ID_FOR_MOBILE_APP: 'default_entity_id_for_mobile_app',
  DEFAULT_ENTITY_ID_FOR_LIEN_VIET: 'default_entity_id_for_lien_viet',
  HD_BANK_PUBLIC_KEY_SENT: 'hd_bank_public_key_sent',
  HD_BANK_PUBLIC_KEY: 'hd_bank_public_key',
  HD_BANK_PRIVATE_KEY: 'hd_bank_private_key'
}

const EVoucherReportStatus = [
  {
    text: 'evoucher.assigned',
    value: VoucherStatusType.ASSIGNED
  },
  {
    text: 'evoucher.notAssignedYet',
    value: VoucherStatusType.NOT_ASSIGNED_YET
  },
  {
    text: 'voucherReport.notUseYet',
    value: VoucherStatusType.ACTIVATED
  },
  {
    text: 'voucherReport.used',
    value: VoucherStatusType.USED
  }
]

const GetCustomerType = [
  {
    text: 'customer.allCustomer',
    value: customerType.IS_GET_ALL
  },
  {
    text: 'customer.myCustomer',
    value: customerType.GET_MY_CUSTOMER
  }
]

const ScheduleExecuteList = [
  {
    text: 'evoucher.batchJob.scheduleExecuteList.everyMinute',
    value: 1
  },
  {
    text: 'evoucher.batchJob.scheduleExecuteList.every5Minute',
    value: 2
  },
  {
    text: 'evoucher.batchJob.scheduleExecuteList.dailyAtMidnight',
    value: 3
  }
]

const DistributionStatus = [
  {
    text: 'user.status_data.distribution',
    value: 1
  },
  {
    text: 'user.status_data.noDistribution',
    value: 0
  }
]

const PayStatus = [
  {
    text: 'createVoucher.prepaid',
    value: true
  },
  {
    text: 'createVoucher.unpaid',
    value: false
  }
]
const DOMAIN_BASE_PATH_ADMIN = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BASE_PATH
const CUSTOMER_URL = process.env.VUE_APP_CUSTOMER_URL
const PARTNERSHIP_URL = process.env.VUE_APP_PARTNERSHIP_URL

const SupplierAccessCodeStatus = [
  {
    value: 0, text: 'supplierAccessCode.statusData.enable'
  },
  {
    value: 1, text: 'supplierAccessCode.statusData.disable'
  },
  {
    value: 2, text: 'supplierAccessCode.statusData.expired'
  },
  {
    value: 3, text: 'supplierAccessCode.statusData.deleted'
  }
]
const PromotionTypeList = [
  {
    value: 1, text: 'promotionVoucher.allocated'
  },
  {
    value: 2, text: 'promotionVoucher.notAllocated'
  }
]
const UserStatusList = [
  {
    text: '---',
    value: null
  },
  {
    text: 'report.active',
    value: 1
  },
  {
    text: 'report.inactive',
    value: 0
  }
]
const BannerTypeList = [
  {
    text: 'advertisementInfo.type.small',
    value: 1
  },
  {
    text: 'advertisementInfo.type.large',
    value: 2
  },
  {
    text: 'advertisementInfo.type.popupLearning',
    value: 3
  }
]
const BannerStatusList = [
  {
    text: 'advertisementInfo.status.prepare',
    value: 1
  },
  {
    text: 'advertisementInfo.status.public',
    value: 2
  },
  {
    text: 'advertisementInfo.status.close',
    value: 3
  }
]
const UserTypeList = [
  {
    text: '---',
    value: null
  },
  {
    text: 'user.type.user',
    value: 0
  },
  {
    text: 'user.type.ghost',
    value: 1
  }
]
const LayoutConfigKey = {
  LOGO: 'logo',
  BACKGROUND_MENU: 'bg_menu',
  LOGO_PARTNER: 'logo_partner'
}

const VoucherModelList = [
  {
    text: 'Thường',
    value: 1
  },
  {
    text: 'Sản phẩm',
    value: 2
  }
]

const StoreChannelsTypeList = [
  {
    text: 'Product portal',
    value: 1
  },
  {
    text: 'Haravan',
    value: 2
  },
  {
    text: 'LienViet Post Bank',
    value: 3
  }
]

const ProductOrderStatusList = [
  {
    text: 'productOrder.status.paid',
    value: 1
  },
  {
    text: 'productOrder.status.waitForPay',
    value: 2
  },
  {
    text: 'productOrder.status.cancel',
    value: 3
  },
  {
    text: 'productOrder.status.paymentOverdue',
    value: 5
  }
]

const PromotionCodeTypeList = [
  {
    text: 'promotionCode.decreaseByPrice',
    value: 1
  },
  {
    text: 'promotionCode.decreaseByPercentage',
    value: 2
  }
]

const PromotionCodeApplyTypeList = [
  {
    text: 'promotionCode.order',
    value: VoucherProductApplyType.ORDER
  },
  {
    text: 'evoucher.name',
    value: VoucherProductApplyType.VOUCHER
  }
]

const RepeatTimeList = [
  {
    text: 'voucherProduct.config.byWeek',
    value: 1
  },
  {
    text: 'voucherProduct.config.byMonth',
    value: 2
  }
]

const PositionBannerVerticalList = [
  {
    text: '---',
    value: null
  },
  {
    text: 'bannerVertical.left',
    value: 1
  },
  {
    text: 'bannerVertical.right',
    value: 2
  }
]

const VoucherReconciliationTypeList = [
  {
    text: 'voucherReconciliation.status.notCheckedYet',
    value: VoucherReconciliationType.NOT_SUBMIT_YET
  },
  {
    text: 'voucherReconciliation.status.waitForApproval',
    value: VoucherReconciliationType.WAIT_FOR_REVIEW
  },
  {
    text: 'voucherReconciliation.status.reconciled',
    value: VoucherReconciliationType.APPROVED
  }
]

const BaoKimPaymentMethodList = [
  {
    text: 'baoKimConfig.paymentMethod.BAO_KIM_WALLET',
    value: BaoKimPaymentType.BAO_KIM_WALLET
  },
  {
    text: 'baoKimConfig.paymentMethod.ATM_CARD_ONLINE',
    value: BaoKimPaymentType.ATM_CARD_ONLINE
  },
  {
    text: 'baoKimConfig.paymentMethod.VISA_MASTER_CARD',
    value: BaoKimPaymentType.VISA_MASTER_CARD
  },
  {
    text: 'baoKimConfig.paymentMethod.TRANSFER',
    value: BaoKimPaymentType.TRANSFER
  },
  {
    text: 'baoKimConfig.paymentMethod.QR_CODE',
    value: BaoKimPaymentType.QR_CODE
  },
  {
    text: 'baoKimConfig.paymentMethod.VIRTUAL_ACCOUNT',
    value: BaoKimPaymentType.VIRTUAL_ACCOUNT
  }
]

const BankTransactionStatusList = [
  {
    text: 'bankTransactionInfo.statusList.NOT_PROCESS',
    value: BankTransactionStatusEnum.NOT_PROCESS
  },
  {
    text: 'bankTransactionInfo.statusList.SUCCESS',
    value: BankTransactionStatusEnum.SUCCESS
  },
  {
    text: 'bankTransactionInfo.statusList.INVALID_ORDER',
    value: BankTransactionStatusEnum.INVALID_ORDER
  },
  {
    text: 'bankTransactionInfo.statusList.INVALID_AMOUNT',
    value: BankTransactionStatusEnum.INVALID_AMOUNT
  },
  {
    text: 'bankTransactionInfo.statusList.INVALID_APPROVE_ORDER',
    value: BankTransactionStatusEnum.INVALID_APPROVE_ORDER
  }
]

const storeChannelsKey = 'default_entity_id_for_'

export default {
  NETWORK_ERROR,
  GenderList,
  DocumentTypeList,
  SystemRoleList,
  EntityRoleList,
  VoucherTypeList,
  VoucherValueList,
  VoucherStatusList,
  EntityStatus,
  EntityTypeName,
  ObjectSettingKey,
  EVoucherIssuanceStatus,
  EVoucherReportStatus,
  GetCustomerType,
  ScheduleExecuteList,
  DistributionStatus,
  PayStatus,
  DOMAIN_BASE_PATH_ADMIN,
  CUSTOMER_URL,
  PARTNERSHIP_URL,
  SupplierAccessCodeStatus,
  PromotionTypeList,
  UserStatusList,
  BannerTypeList,
  BannerStatusList,
  UserTypeList,
  LayoutConfigKey,
  VoucherModelList,
  StoreChannelsTypeList,
  ProductOrderStatusList,
  PromotionCodeTypeList,
  PromotionCodeApplyTypeList,
  RepeatTimeList,
  PositionBannerVerticalList,
  VoucherReconciliationTypeList,
  BaoKimPaymentMethodList,
  BankTransactionStatusList,
  ServiceSMSProviderList,
  storeChannelsKey
}
