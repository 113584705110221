const PrintTemplateItemKind = {
  BACKGROUND_IMAGE: 1,
  BACKGROUND_2ND_IMAGE: 2,
  VOUCHER_QR: 3,
  VOUCHER_NAME: 4,
  CUSTOMER_NAME: 5,
  CUSTOMER_EMAIL: 6,
  VOUCHER_PRICE: 7,
  USING_EXPIRED_DATE: 8,
  VOUCHER_SERIAL: 9,
  VOUCHER_IMAGE: 10
}
export default PrintTemplateItemKind
