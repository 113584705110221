import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

const state = {
  customerGroups: [],
  customerInGroups: [],
  customerOutGroups: []
}

const getters = {
  CUSTOMER_GROUP_DATA (state) {
    return state.customerGroups
  },
  CUSTOMER_IN_GROUP_DATA (state) {
    return state.customerInGroups
  },
  CUSTOMER_OUT_GROUP_DATA (state) {
    return state.customerOutGroups
  }
}

const actions = {
  GET_CUSTOMER_GROUP_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customer_group/`,
        payload,
        function (res) {
          context.commit('GET_CUSTOMER_GROUP_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_CUSTOMER_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customer_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_CUSTOMER_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/customer_group/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_CUSTOMER_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/customer_group/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CUSTOMER_IN_OUT_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/customer_group/get_customer_in_out_group/`,
        payload,
        function (res) {
          if (payload.params.getInGroup) {
            context.commit('GET_CUSTOMER_IN_GROUP_HANDLER', res.data)
          } else {
            context.commit('GET_CUSTOMER_OUT_GROUP_HANDLER', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_CUSTOMER_INTO_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/customer_group/add_customer_into_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REMOVE_CUSTOMER_GET_OUT_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/customer_group/remove_customer_get_out_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

const mutations = {
  GET_CUSTOMER_GROUP_LIST_HANDLER (state, payload) {
    let customerGroups = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.customerGroups = { results: customerGroups, paginate: page }
  },
  GET_CUSTOMER_IN_GROUP_HANDLER (state, payload) {
    let customerInGroups = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.customerInGroups = { results: customerInGroups, paginate: page }
  },
  GET_CUSTOMER_OUT_GROUP_HANDLER (state, payload) {
    let customerOutGroups = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.customerOutGroups = { results: customerOutGroups, paginate: page }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
