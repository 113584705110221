import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  'common': {
    'termsCondition': 'Terms & Conditions',
    'privacyPolicy': 'Privacy Policy',
    'close': 'Close',
    'networkError': 'Network Connection Error',
    'success': 'Success',
    'unsuccess': 'unsuccess',
    'actions': 'Actions',
    'confirmDelete': 'Confirm Delete',
    'disagree': 'Disagree',
    'agree': 'Agree',
    'save': 'Save',
    'loading': 'Loading...',
    'search': 'Search',
    'searchData': 'Name, email, phone, code...',
    'noDataAvailable': 'No data available',
    'copyright': 'Copyright © 2020 {0}. All rights reserved.',
    'select_lang': 'EN',
    'id': 'ID',
    'nonumber': 'No',
    'code': 'Code',
    'modify': 'Modify',
    'delete': 'Delete',
    'viewDetail': 'View detail',
    'preview': 'Preview',
    'historyaction': 'Actions',
    'add': 'Add',
    'clone': 'Clone',
    'cancel': 'Cancel',
    'printPdf': 'Print pdf',
    'moreinfo': 'More Info',
    'dropdown': 'Toggle Dropdown',
    'edit': 'Edit',
    'addnew': 'Add New',
    'yes': 'yes',
    'no': 'no',
    'info': 'Info',
    'status': 'Status',
    'note': 'Note',
    'submit': 'Submit',
    'confirm': 'Confirm',
    'app': 'App',
    'by': 'By',
    'datetime': 'Date Time',
    'datefrom': 'Date From',
    'dateto': 'Date To',
    'update': 'Update',
    'phone': 'Phone',
    'email': 'Email',
    'username': 'User Name',
    'fullname': 'Full Name',
    'error_msg': [{ 'required': 'This field is required!' }],
    'error': 'Error',
    'sign_sms': 'Sign in to start your session',
    'user': 'Username',
    'password': 'Password',
    'sign_in': 'Sign In',
    'history': 'History',
    'comment': 'Comment',
    'name': 'Name',
    'logOut': 'Logout',
    'darkMode': 'Dark mode',
    'register': 'Register',
    'statusType': {
      'valid': 'Valid',
      'invalid': 'Invalid'
    },
    'copied': 'Copied to clipboard',
    'logo': 'Logo',
    'notification': 'Notification !',
    'restore': 'Restore',
    'state': 'State',
    'reset': 'Reset',
    'detail': 'Detail',
    'bulk': 'Bulk actions',
    'permissionDenied': 'Permission denied',
    'continue': 'Continue',
    'downloadTemplate': 'Download template',
    'role': 'Role',
    'goTo': 'Go to',
    'from': 'From',
    'to': 'To',
    'resetPassword': 'Reset password',
    'connect': 'Connect',
    'insert': 'Insert',
    'image': 'Image',
    'addInto': 'Add',
    'emailOrPhone': 'Email or phone',
    'website': 'Website',
    'numOfUsersExceedsTheAllowed': 'The number of users exceeds the allowed',
    'changePassword': 'Change password',
    'recommendRatio': 'Recommended ratio',
    'expectationImageRatio': 'Expectation image ratio',
    'maxSizeUpload': 'max size upload',
    'recommendImageDimension': 'recommend image dimension',
    'pixels': 'pixels',
    'imageCannotExceed10MB': 'Image size cannot exceed 10MB',
    'imageCannotExceed3MB': 'Image size cannot exceed 3MB',
    'back': 'Back',
    'selectAll': 'Select all',
    'projectList': 'Entity List',
    'noSelectedProject': 'No project',
    'loginWithGoogle': 'Login with Google',
    'googleAccountLink': 'Google Account Link',
    'facebookAccountLink': 'Facebook Account Link',
    'loginWithFacebook': 'Login with Facebook',
    'groupName': 'Group name',
    'refresh': 'Refresh',
    'approval': 'Approval'
  },
  'dashboard': {
    'title': 'Dashboard',
    'voucherPublished': 'Voucher published',
    'numberOfVoucherPublished': 'Number of Voucher published',
    'voucherReceived': 'Voucher received',
    'numberOfVoucherReceived': 'Number of Voucher received',
    'voucherUnreleased': 'Voucher unreleased',
    'numberOfVoucherUnreleased': 'Number of Voucher unreleased',
    'voucherReleased': 'Voucher released',
    'numberOfVoucherReleased': 'Number of Voucher released',
    'voucherDelivered': 'Voucher Deliveried',
    'numberOfVoucherDelivered': 'Number of Voucher deliveried',
    'voucherUsed': 'Voucher used',
    'numberOfVoucherUsed': 'Number of Voucher used',
    'voucherRequestList': 'Voucher Request List',
    'received': 'Received',
    'sent': 'Sent'
  },
  'productList': 'Product List',
  'localize': {
    'english': 'English',
    'vietnamese': 'Vietnamese'
  },
  'sideBar': {
    'area': 'Zones',
    'user': 'Users',
    'training': 'Training',
    'setting': 'Configuration',
    'productList': 'Product List',
    'staffs': 'Staffs',
    'courses': 'Courses',
    'documents': 'Documents',
    'surveys': 'Surveys',
    'certificates': 'Certificates',
    'decentralization': 'Decentralization',
    'mobileVersion': 'Mobile Version',
    'account': 'Account',
    'document': 'Document',
    'systemSettings': {
      'title': 'System Settings',
      'mobile': 'Version Mobile',
      'authorization': 'Authorization',
      'sponsor_level': 'Sponsor Type'
    },
    'decentralizationManagement': {
      'modules': {
        'perm_module_supplier': 'Management Supplier',
        'perm_module_distributor': 'Agent management level 1'
      },
      'functions': {
        'supplier': 'Supplier',
        'distributor': 'Level 1 agents'
      },
      'group_title': 'Group',
      'main_function': 'Main Functionality',
      'function': 'Function',
      'watch': 'Watch',
      'create': 'Create',
      'edit': 'Edit',
      'delete': 'Delete',
      'print': 'Print',
      'export': 'Export',
      'import': 'Import',
      'approve': 'Approve'
    },
    'roleManagement': 'Admin Privileges Management',
    'groupManagement': 'Privileges Groups Management',
    'actionManagement': 'Action Management',
    'metaManagement': 'Meta Management',
    'userManagement': 'User Management',
    'supplierManagement': 'Supplier Management',
    'agentManagement': 'Agent Management',
    'voucherManagement': 'Voucher Management',
    'evoucherIssuance': 'Voucher Issuance',
    'evoucherList': 'Voucher List',
    'customerManagement': 'Customer Management',
    'roleManagementInProject': 'Permissions Management',
    'generalConfiguration': 'General Configuration',
    'supplierConfiguration': 'Supplier Configuration',
    'thirdPartiesSettings': '3rd Parties Settings',
    'configurationProperty': 'Configuration Property',
    'voucherTransferRequestList': 'Transfer Voucher Request',
    'voucherIssuanceDetail': 'Voucher Issuance Information',
    'detailInformation': 'Detail Information',
    'development': 'Development',
    'supplierAccessCode': 'Supplier Code Activation',
    'siteManagement': 'Usage Site',
    'configTemplateEmail': 'Email Template',
    'configurationNotificationTemplate': 'Notification Template',
    'useVoucherPromotion': 'Promotion Voucher',
    'advertisementInfo': 'Advertisement Information',
    'reportStatistic': 'Report Statistic',
    'hashtagManagement': 'Hashtag Management',
    'infoWebPortal': 'Information Web Portal',
    'emailDomain': 'Email Domain',
    'emailDomainActiveCode': 'Email Domain and Activation Code',
    'emailHistory': 'Email History',
    'smsHistory': 'SMS Sent History',
    'voucherProduct': 'Voucher Product',
    'productOrderManagement': 'Product Orders Management',
    'voucherPrintTemplate': 'Voucher Print Template',
    'footerConfig': 'Footer Config',
    'bankTransactionInfo': 'Bank transaction info'
  },
  'login': {
    'somethingWentWrong': 'Something Went Wrong !',
    'loginInvalid': 'Username or password is incorrect',
    'login': 'Login',
    'username': 'Username',
    'password': 'Password',
    'customerCannotLogin': 'Customer cannot login',
    'inCorrectPassword': 'Incorrect passwork',
    'youHaveNotRegister': 'You have not registered, please register!',
    'userIsDeleted': 'User is deleted',
    'accountIsNotActive': 'User is not active',
    'pleaseActivateAccount': 'Please activate the account in the email to log into the system',
    'Userisnotactiveordeleted': 'User is not active or deleted',
    'forgotPassword': 'Forgot password?',
    'entityInactive': 'Your account management supplier has not been activated or locked!',
    'notFoundGoogleAccountLink': 'Your google account was not registered',
    'notFoundFacebookAccountLink': 'Your facebook account was not registered'
  },
  'user': {
    'username': 'UserID',
    'fullname': 'Full name',
    'firstname': 'First Name',
    'lastname': 'Last Name',
    'email': 'Email',
    'lastlogin': 'Last Login',
    'age': 'Age',
    'dob': 'Date of Birth',
    'phone': 'Phone',
    'gender': 'Gender',
    'addnew': 'Add New',
    'add': 'Add',
    'password': 'Password',
    'uppercase': 'Uppercase',
    'lowercase': 'Lowercase',
    'digit': 'Number',
    'group': 'Group',
    'title': 'User List',
    'title_organizer': 'Organizer',
    'privilege': 'Privilege',
    'country': 'Country',
    'is_active': 'Actived',
    'activate_account': 'Activate Account',
    're_send_email': 'Resent Activation',
    'joined_date': 'Joined Date',
    'device': 'Device Number',
    'device_list': 'Mobile Devices Login',
    'app_type': 'App Type',
    'app_type_data': {
      'ios': 'iOS',
      'android': 'Android',
      'other': 'Other'
    },
    'last_access': 'Last Access',
    'last_login': 'Last Login',
    'status': 'Status',
    'status_data': {
      'active': 'Active',
      'inactive': 'In Active',
      'expired': 'Expired',
      'distribution': 'Distribution',
      'noDistribution': 'No Distribution'
    },
    'info': 'Info',
    'profile': 'User Profile',
    'gender_type': ['Male', 'Female', 'Others'],
    'confirm_pass': {
      'title': 'Confirm Reset Password',
      'content': 'Are you sure want reset password?',
      'btnClose': 'Close',
      'btnConfirm': 'Confirm'
    },
    'addNewUser': 'Add new user',
    'detailInfo': 'User Information',
    'confirmDeleteUserTitle': 'Are you sure you want to delete this user?',
    'confirmRemoveUserTitle': 'Are you sure you want to remove this user from your entity?',
    'successfulActivation': 'Successful activation',
    'failedActivation': 'Activation failed, please try again later',
    'redirectLoginPage': 'Return to login page in 5 seconds',
    'activeThisAccount': 'Activate this account',
    'address': 'Address',
    'resetPassword': {
      'linkNoLongerValid': 'The reset password link is no longer valid.'
    },
    'type': {
      'user': 'Registered',
      'ghost': 'Unregistered'
    }
  },
  'userProfile': {
    'genderType': {
      'other': 'Other',
      'male': 'Male',
      'female': 'Female'
    },
    'gender': 'Gender',
    'birthday': 'Birthday',
    'phone': 'Phone',
    'address': 'Address',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'userName': 'Username',
    'email': 'Email',
    'aboutMe': 'About Me',
    'editProfile': 'Edit Profile',
    'completeYourProfile': 'Complete Your Profile',
    'updateProfile': 'Update Profile',
    'userProfile': 'User profile',
    'identificationNumber': 'Identification Number',
    'passport': 'Passport'
  },
  'decentralizations': {
    'decentralizationModule': 'Decentralization Module',
    'function': 'Function',
    'create': 'Create',
    'view': 'View',
    'update': 'Update',
    'delete': 'Delete',
    'functionInModules': {
      'staff': 'Staff',
      'visitor': 'Visitor',
      'courses': 'Courses',
      'surveys': 'Surveys'
    },
    'modules': {
      'users': 'Users',
      'training': 'Training'
    }
  },
  'documents': {
    'title': 'Document List',
    'subTitle': 'All Document In System'
  },
  'staffs': {
    'title': 'Staff List',
    'subTitle': 'All Staff In System',
    'titleAdd': 'Add Staff',
    'subTitleAdd': 'Add New Staff'
  },
  'users': {
    'add': 'User Add',
    'userList': 'User list',
    'fullName': 'Full name',
    'phone': 'Phone',
    'gender': 'gender',
    'username': 'Username',
    'userDetail': 'User detail',
    'assignedRoles': 'Assigned roles',
    'availableRoles': 'Available roles',
    'dateofbirth': 'Date Of Birth',
    'email': 'Email',
    'identification': 'Identification',
    'dateCreated': 'Created date',
    'extraInfo': 'Extra information',
    'errorText': {
      'indentAlreadyExist': 'Identification already exist',
      'noMoreThan1Role': 'No more than 1 role'
    },
    'adminPrivileges': 'Role',
    'userPrivileges': 'Privileges',
    'addUserAvailable': 'Add user available',
    'staff': 'Staff',
    'accountant': 'Accountant',
    'removeUserMessage': 'Do you really want to remove \'{user}\' from [ {entity} ]?',
    'remove': 'Remove User',
    'maximumPageSize': 'Maximum page size'
  },
  'roles': {
    'role': 'Privilege',
    'roleList': 'Role list',
    'roleName': 'Role name',
    'roleType': 'Function Type',
    'addNewRole': 'Add new role',
    'updateRole': 'Update role',
    'entityRoleList': 'Entity role list',
    'onShowModalDeleteRoleTitle': 'On Show Modal Delete Role Title?',
    'type': {
      'admin': 'Admin',
      'user': 'User',
      'manager': 'Manager',
      'staff': 'Staff',
      'accountant': 'Accountant',
      'all': 'All',
      'supplier': 'Supplier',
      'distributor': 'Distributor',
      'agent': 'Agent',
      'site': 'Usage Site',
      'entity': 'Entity',
      'reporter': 'Reporter'
    }
  },
  'roleaction': {
    'view_dashboard': 'View dashboard',
    'view_issuance': 'View issuance',
    'create_issuance': 'Create issuance',
    'update_issuance': 'Update issuance',
    'manage_issuance': 'Manage issuance',
    'delete_issuance': 'Delete issuance',
    'publish_issuance': 'Publish issuance',
    'issuance_approval': 'Issuance approval',
    'view_voucher': 'View voucher',
    'create_voucher': 'Create voucher',
    'update_voucher': 'Update voucher',
    'delete_voucher': 'Delete voucher',
    'delivery_voucher': 'Delivery voucher',
    'manage_voucher': 'Manage voucher',
    'export_voucher': 'Export voucher',
    'confirm_using': 'Confirm using',
    'view_request': 'View reconciliation request',
    'create_request': 'Create reconciliation request',
    'update_request': 'Update reconciliation request',
    'delete_request': 'Delete reconciliation request',
    'request_approval': 'Reconciliation request approval',
    'view_user': 'View user',
    'create_user': 'Create user',
    'update_user': 'Update user',
    'manage_user': 'Manage user',
    'delete_user': 'Delete user',
    'view_supplier': 'View supplier',
    'update_supplier': 'Update supplier',
    'delete_supplier': 'Delete supplier',
    'manage_supplier': 'Manage supplier',
    'view_agent': 'View agent',
    'create_agent': 'Create agent',
    'update_agent': 'Update agent',
    'delete_agent': 'Delete agent',
    'manage_agent': 'Manage agent',
    'view_site': 'View site',
    'create_site': 'Create site',
    'update_site': 'Update site',
    'delete_site': 'Delete site',
    'manage_site': 'Quản lý điểm chấp nhận',
    'view_history': 'View history',
    'view_settings': 'View settings',
    'update_settings': 'Update settings',
    'view_report': 'View report',
    'export_report': 'Export report',
    'obsoleted': 'Obsoleted'
  },
  'groups': {
    'group': 'Group',
    'title': 'Group list',
    'groupName': 'Group name',
    'addNewGroup': 'Add new group',
    'updateGroup': 'Update group',
    'assignedActions': 'Assigned actions',
    'availableActions': 'Available actions',
    'type': {
      'system_admin': 'Admin',
      'system_supplier': 'Supplier',
      'system_distributer': 'Level 1 agents',
      'system_agent': 'Level 2 agents'
    },
    'confirmRestorePermissionTitle': 'Are you sure you want to reset the default functionality for this group?'
  },
  'actions': {
    'actionList': 'Action list',
    'addNewAction': 'Add new action',
    'updateAction': 'Update action',
    'englishName': 'English name',
    'vietnameseName': 'Vietnamese name',
    'meta': 'Meta',
    'name': 'Name'
  },
  'meta': {
    'metaList': 'Meta list',
    'addNewMeta': 'Add new meta',
    'updateMeta': 'Update meta'
  },
  'decentralizationGroup': {
    'decentralizationGroupList': 'Decentralization group list',
    'addNewDecentralizationGroup': 'Add new decentralization group',
    'updateDecentralizationGroup': 'Update decentralization group',
    'functionalList': 'Functional list',
    'currentFunctionGroup': 'Current function group',
    'functionGroupNotYet': 'Function group not yet available',
    'privilege': 'Privilege',
    'availableGroups': 'Available groups',
    'assignedGroups': 'Assigned groups',
    'onShowModalDeletedecentralizationGroupTitle': 'On Show Modal Delete Decentralization Group Title?'
  },
  'register': {
    'alreadyAccount': 'Already have an account?',
    'createNewOne': 'Create a new one.',
    'notHaveAccount': 'You do not have an account?',
    'alreadyInSupplier': 'You are already in a Supplier, cannot register more',
    'supplierEmailAlreadyUsed': 'Supplier email already used in another supplier',
    'accountAlreadyExist': 'Account or email already exists',
    'notSpecialCharacterUsername': 'The username does not contain any special characters',
    'nameMust8CharacterOrMore': 'Username must be 8 characters or more',
    'passwordIncorrect': 'Password incorrect',
    'confirmPassword': 'Confirm password',
    'confirmRegisterUserDeleted': 'This account has been deleted do you want to recover?',
    'phoneAlreadyExist': 'Phone already exists'
  },
  'evoucher': {
    'name': 'Voucher',
    'issuance': {
      'title': 'Voucher issuance list',
      'addNewTitle': 'Add new Voucher issuance',
      'workpiece': 'Voucher issuance',
      'quantity': 'Voucher quantity',
      'status': 'Status',
      'startDate': 'Start date',
      'endDate': 'End date',
      'fromDate': 'From date',
      'toDate': 'To date',
      'pricePercent': 'Price / Percent',
      'price': 'Price',
      'percent': 'Percent',
      'type': 'Type',
      'code': 'Voucher issuance code',
      'supplier': 'Supplier',
      'confirmDeleteEvoucherIssuanTitle': 'Do you want to delete this voucher issuance?',
      'doYouWantToConfirm': 'Do you want to confirm using this voucher?',
      'distributeEndDate': 'Distribution expired date',
      'usedExpiredDate': 'Used expired date',
      'day': 'Day',
      'delivery': 'Delivery',
      'errorUpdateIssuance': 'The number of vouchers must not be less than the created quantity of ',
      'issuanceNotExist': 'The issuance is not exist',
      'total': 'Total',
      'quantityCreated': 'Created',
      'quantityNotCreated': 'Not created',
      'quantityDistributed': 'Distributed',
      'quantityNotDistributed': 'Not distributed',
      'fromValue': 'From value',
      'toValue': 'To value',
      'usedValidWithTypePercent': 'Valid when using vouchers in the price range',
      'distributedCannotUpdate': 'The issue for which the voucher was distributed cannot be edited',
      'promotion': 'Promotion',
      'issuanceOrSerialVoucher': 'Voucher name or code',
      'numOfUsesPerPerson': 'Number of using per person',
      'customersAreReceived': 'Customers are received',
      'enterExcelFileTheCustomerReceives': 'Enter the excel file the customer receives',
      'clickToUploadUpdateImg': 'Click the photo to add or update',
      'description': 'description',
      'voucherDescription': 'Voucher Description',
      'conditionsOfUse': 'Conditions of use',
      'details': 'Details info (for printing)',
      'noSelectedSite': 'No site selection yet',
      'previewPrint': 'Preview print',
      'previewVoucher': 'Preview voucher',
      'productAlbum': 'Product image album',
      'areYouSureYouWantToReactivateWorkpiece': 'Are you sure want to reactive workpiece?',
      'areYouSureYouWantToDisableWorkpiece': 'Are you sure want to disabled workpiece?',
      'numberOfWorkpiece': 'Number of workpiece',
      'numberOfWorkpieceRemaining': 'Number of workpiece remaining',
      'distributedToTheAgent': 'Distributed to the agent',
      'distributedToTheCustomer': 'Distributed to the customer',
      'numberOfUsedVoucher': 'Number of used',
      'transferable': 'Transferable',
      'notTransferable': 'Not transferable',
      'quantityReceived': 'Quantity received',
      'copy': 'Copy issuance code',
      'extendSelectedVouchers': 'Expand expired selected vouchers',
      'extendExpiredDate': 'Expand Expired Date',
      'extendExpiredTitle': 'Extend Voucher Expired Date',
      'extendDuration': 'Extend duration',
      'extendToDate': 'Extend to date',
      'extendDurationDescription': 'If voucher has expired, it will extern to {days} days. If voucher has not expired, it will add {days} more days to expiration date.',
      'extendToDateDescription': 'Only vouchers have expired date less than selected date will be applied.',
      'numOfVoucherDistributedToCustomer': 'Number of voucher has distributed to customer',
      'numOfVoucherDistributedHasExpired': 'Number of voucher has expired'
    },
    'customer': 'Customer',
    'list': 'Voucher list',
    'voucherDistribution': 'Distributary voucher',
    'assignVoucher': 'Issue voucher',
    'addNewTitle': 'Add new voucher list',
    'remainQuantity': 'Number of vouchers remaining',
    'addNewVoucher': 'Add new voucher',
    'voucherQuantity': 'Voucher quantity',
    'lengthCode': 'Length code',
    'characterStarts': 'Character starts',
    'characterEnd': 'Character end',
    'quantity': 'Quantity',
    'numberOfVouchersAvailable': 'Number of vouchers available',
    'quantityToAssign': 'Quantity to assign',
    'assigned': 'Assigned',
    'notAssignedYet': 'Not assigned yet',
    'historyTransaction': 'History transaction',
    'dateIssued': 'Date issued',
    'dateDistributed': 'Date Distributed',
    'fromEntity': 'From entity',
    'toEntity': 'To entity',
    'deliveryNote': 'Distribution note',
    'confirmUsedVoucher': 'Confirm using Voucher',
    'manageLockedVouchers': 'Locked Using Vouchers Management',
    'directConfirmation': 'Direct Confirmation',
    'confirmationFromCustomers': 'Confirmation From Customers',
    'referenceCode': 'Reference Code',
    'customerUsed': 'Customer using voucher',
    'unConfimred': 'Unconfimred',
    'status': {
      'active': 'Active',
      'inactive': 'Not Distributed',
      'new': 'New',
      'expired': 'Expired',
      'issuing': 'Issuing',
      'assigned': 'Assigned',
      'used': 'Used',
      'notAssignedYet': 'Not Assigned Yet',
      'waiting': 'Waiting for Confirmation',
      'using_expired': 'Using Expired',
      'distribution_expired': 'Distribution Expired',
      'locked': 'Locked Using',
      'activated': 'Customers Received',
      'reserved_order': 'Reserved Order',
      'voucherDisable': 'Voucher Disabled'
    },
    'state': {
      'active': 'Active',
      'inactive': 'Inactive'
    },
    'quantityAssignInvalid': 'The amount entered exceeds the available quantity',
    'issued': 'Voucher has been delivered',
    'evoucherExists': 'The voucher code already exists, please create it with another code',
    'viewDetail': 'View detail',
    'detail': {
      'title': 'Voucher detail',
      'fromMomentOfReceipt': 'from the moment of receipt of the voucher',
      'usageSite': 'Usage site',
      'details': 'Details info'
    },
    'expiresAfter': 'Expires after',
    'time': {
      'days': 'Days',
      'hours': 'Hours',
      'minutes': 'Minutes',
      'seconds': 'Seconds'
    },
    'timeUsingVoucher': 'Valid for customers using',
    'from': 'from',
    'to': 'to',
    'discount': 'Discount',
    'priceDiscount': 'Price discounted',
    'discountPrice': 'Discount',
    'assignFrom': 'Distributed from',
    'assignTo': 'Distributed to',
    'dateUpdated': 'Date updated',
    'canNotHasSpecialCharacter': 'Voucher code cannot contain special characters',
    'noCustomerOwnsThisVoucher': 'Voucher has not been distributed to customers',
    'used': 'Voucher has been used',
    'history': 'History',
    'voucherHasExpired': 'Voucher has expired',
    'voucherIssueExpired': 'This expired voucher is for distribution',
    'voucherWithDifferentDenominations': 'Vouchers with different denominations or discounts cannot be issued',
    'voucherWithDifferentDiscountType': 'Cannot issue vouchers with different discount types',
    'duration': 'Duration',
    'batchJob': {
      'title': 'Batch Job Configuration',
      'recoverVoucherExpired': 'Recover voucher expired',
      'batchJobs': 'Batch jobs',
      'scheduleExecute': 'Schedule execute',
      'scheduleExecuteList': {
        'everyMinute': 'Execute every minute',
        'every5Minute': 'Execute every 5 minutes',
        'dailyAtMidnight': 'Execute daily at midnight.'
      }
    },
    'invalidActivationCode': 'Activation code is invalid',
    'invalidSerialCode': 'Serial code is invalid',
    'activeCode': 'Active code',
    'voucherSerial': 'Serial number',
    'issuedCode': 'Issued Code',
    'voucherName': 'Voucher Name',
    'confirmDeleteVoucherContent': 'Are you sure you want to delete this voucher?',
    'vouchersNotExistInSystem': 'There are vouchers that do not exist in the system',
    'inputExcelToDistribute': 'Distribute by Excel',
    'transferVoucher': 'Transfer voucher',
    'pay': 'Pay',
    'usedPrice': 'Used price',
    'discountValuePrice': 'Discount price',
    'discountByPrice': 'Price',
    'discountByPercent': 'Percent',
    'printVoucher': 'Print voucher',
    'areYouSureSendThisVoucher': 'Are you sure you want to send this voucher?',
    'areYouSureCreateThisVoucher': 'Are you sure want to create voucher?',
    'confirmForceAssign': "This customer's entered information will be ignored, because it already exists in the system. Do you want to continue?",
    'voucherFromDifferentIssuesCannotBeIssued': 'Vouchers from different workpiece cannot be issued',
    'voucherFromDifferentIssuanceCannotBeIssued': 'Vouchers from different issuance cannot be issued',
    'voucherFromDifferentPrefixSuffixCannotBeIssued': 'Vouchers from different issuance cannot be issued',
    'voucherIsDeletedOrDisabled': 'This voucher is deleted or disabled',
    'confirmMultipleVouchers': 'Multiple Vouchers Confirmation',
    'addUsingVoucher': 'Add comfirmation voucher',
    'editReferenceInfo': 'Edit reference info',
    'addVoucher': 'Add voucher',
    'alreadyAdded': 'Voucher already added',
    'confirmUnlockUsingVouchers': 'Locking Vouchers',
    'confirmUsedDirectlyMessage': 'Are you sure you want to confirm to use these vouchers directly? Please be noted that after confirmation, these vouchers cannot be refund to customer.',
    'confirmLockedUsingMessage': 'Are you sure you want to confirm to locked these vouchers directly? After locking, you can refund or confirm using these vouchers later.',
    'confirmRefundLockedVouchersMessage': 'Are you sure you want to refund these vouchers? Be careful, after confirmation, these vouchers will return back to customer.',
    'confirmUsedLockedVouchersMessage': 'Are you sure you want to confirm to use these locked vouchers? Please be noted that after confirmation, these vouchers cannot be refunded to customer.',
    'stayDate': 'Stay date',
    'arrivalDate': 'Arrival date',
    'departureDate': 'Departure date',
    'voucherStatus': 'Status',
    'notiConfirmCreateVoucherWithIssuanceExists': 'The {prefix} - {suffix} issuance already exists. Please select confirm if you want to use this issuance?',
    'enableVoucher': 'Enable Voucher',
    'disableVoucher': 'Disable Voucher',
    'notiEnableDisable': 'Are you sure you want to {action} \'{voucher}\'?',
    'voucherDisable': 'Voucher has been disabled',
    'confirmTransferVoucher': 'Confirm transfer voucher',
    'importVoucher': 'Import Vouchers',
    'canNotDistributedVoucher': 'Can not distributed voucher {0}'
  },
  'customer': {
    'customerList': 'Customer list',
    'name': 'Name',
    'code': 'Code',
    'status': 'Status',
    'address': 'Address',
    'phone': 'Phone',
    'email': 'Email',
    'allCustomer': 'All customer',
    'myCustomer': 'My customer',
    'nameCustomer': 'Customer name',
    'emailCustomer': 'Customer email',
    'customer': 'Customer',
    'info': 'Customer info',
    'linkEmail': 'Link email'
  },
  'generalConfiguration': {
    'serverSMTP': 'Server SMTP',
    'smsProvider': 'Nhà cung cấp dịch vụ SMS',
    'domain': 'Domain',
    'username': 'Username',
    'password': 'Password',
    'smtpConfigurationInfo': 'SMTP configuration information',
    'name': 'Name',
    'sender_alias': 'Alias',
    'description': 'Description',
    'pageSize': 'Page size',
    'subContent': 'Sub content',
    'emailConfiguration': 'Email configuration',
    'smtp_port': 'Port',
    'emailAccount': 'Email account',
    'useTLS': 'Use TLS',
    'sendTestEmail': 'Send Test Email',
    'sendEmail': 'Send email',
    'discount': 'Discount',
    'activeSupplierCode': 'Active supplier code',
    'companyGeneral': 'General Information',
    'companyInfo': 'Company Information',
    'companyName': 'Company Name',
    'companyAddress': 'Address',
    'companyEmail': 'Email',
    'companyHotline': 'Hotline',
    'companyFacebook': 'Facebook link',
    'mailGatewayConfig': 'Mail Gateway configuration (optional for status)',
    'mailGatewayURL': 'Server URL',
    'mailGatewayApikey': 'Service API Key',
    'configurationDefaultEntityForMobileApp': 'Configuration default store for Mobile App',
    'configurationDefaultEntityForLienViet': 'Configuration default store for Lien Viet',
    'configurationDefaultEntityForStore': 'Configuration default store'
  },
  'actionPermission': {
    'create_account': 'Create account',
    'update_account': 'Update account',
    'delete_account': 'Delete account',
    'view_account': 'View account',
    'delete_voucher': 'Delete voucher',
    'update_voucher': 'Update voucher',
    'create_voucher': 'Create voucher',
    'view_voucher': 'View voucher',
    'view_user': 'View user',
    'add_user': 'Add user',
    'update_user': 'Update user',
    'delete_user': 'Delete user',
    'add_group': 'Add group',
    'view_group': 'View group',
    'update_group': 'Update group',
    'delete_group': 'Delete group',
    'view_system_role': 'View system role',
    'add_system_role': 'Add system role',
    'update_system_role': 'Update system role',
    'delete_system_role': 'Delete system role',
    'view_supplier': 'View supplier',
    'add_supplier': 'Add supplier',
    'update_supplier': 'Update supplier',
    'delete_supplier': 'Delete supplier',
    'view_distributer': 'View distributer',
    'add_distributer': 'Add distributer',
    'update_distributer': 'Update distributer',
    'delete_distributer': 'Delete distributer',
    'view_agent': 'View agent',
    'add_agent': 'Add agent',
    'update_agent': 'Update agent',
    'delete_agent': 'Delete agent',
    'create_voucher_issuance': 'Create voucher issuance',
    'generate_voucher': 'Generate voucher',
    'assign_voucher_to_distributer': 'Assign voucher to distributer',
    'report_status_voucher': 'Report status voucher',
    'assign_voucher_to_agent': 'Assign voucher to agent',
    'create_child_agent': 'Create child agent',
    'update_used_voucher': 'Update used voucher'
  },
  'issueVoucherRequest': {
    'requestTitle': 'Send request',
    'deleteTitle': 'Are you sure delete this request?',
    'sendRequestToParenTitle': 'Send request to parent level',
    'title': 'Title',
    'parent': 'Parent',
    'quantity': 'Quantity',
    'listOfRequestSent': 'List of request sent',
    'requestListReceived': 'Request list received',
    'approve': 'Approve',
    'cancel': 'Cancel',
    'updateRequestTitle': 'Update request title',
    'initial': 'Initial',
    'approved': 'Approved',
    'canceled': 'Canceled',
    'stop': 'Stop',
    'dateRequest': 'Sent date',
    'approveTitle': 'Are you sure approve this request?',
    'cancelTitle': 'Are you sure cancel this request?',
    'stopTitle': 'Are you sure stop this request?'
  },
  'supplier': {
    'supplierList': 'Supplier list',
    'addNewSupplier': 'Add new supplier',
    'updateSupplier': 'Update supplier',
    'name': 'Name',
    'code': 'Code',
    'status': 'Status',
    'address': 'Address',
    'phone': 'Phone',
    'email': 'Email',
    'confirmDeleteSupplierTitle': 'Are you sure you want to delete \'{name}\' supplier?',
    'activeSupplier': 'Active',
    'confirmActiveSuppilerTitle': 'Are you sure you want to activate \'{name}\' supplier?'
  },
  'agent': {
    'agentList': 'List of level 2 agents',
    'addNewAgent': 'Add new agent level 2',
    'updateAgent': 'Update agent level 2',
    'relatedAgents': 'Level 1 agents',
    'name': 'Name',
    'code': 'Code',
    'status': 'Status',
    'address': 'Address',
    'phone': 'Phone',
    'email': 'Email',
    'confirmDeleteAgentTitle': 'Confirm Delete Agent Title',
    'notUnderYourSubordinateLevel': 'This agent is not under your subordinate level'
  },
  'siteManagement': {
    'list': 'Usage sites',
    'addTitle': 'Add new site',
    'updateTitle': 'Update site',
    'chooseSite': 'Usage site',
    'infoTitle': 'Usage site information',
    'longitude': 'Longitude',
    'latitude': 'Latitude',
    'province': 'City / Province',
    'district': 'District',
    'ward': 'Ward',
    'address': 'Address',
    'addressTitle': 'Edit address',
    'openGoogleMap': 'Check geolocation on Google map'
  },
  'entity': {
    'state': {
      'active': 'Active',
      'inactive': 'Inactive',
      'activated': 'Activated',
      'expired': 'Expired',
      'almostExpired': 'Expire in {days} day(s)'
    },
    'configuration': '{type} information [ {name} ]',
    'supplier': 'Supplier',
    'suppliers': 'Suppliers',
    'agent': 'Agent',
    'agents': 'Agents',
    'site': 'Usage Site',
    'sites': 'Usage Sites',
    'assignedTitle': '{name} assigned',
    'availableTitle': '{name} available',
    'emailAlreadyExist': 'Email already exist !',
    'codeAlreadyExist': 'Code already exist !',
    'emailIsNotCustomer': 'Email is not customer',
    'name': 'Name',
    'phone': 'Phone',
    'email': 'Email',
    'code': 'Code',
    'status': 'Status',
    'avatar': 'Avatar',
    'supplierInfo': 'Supplier information',
    'agentInfo': 'Agent information',
    'numberOfEntity': 'Number of entity',
    'inEntities': 'In Entities',
    'delete': {
      'cannotDeleteSupplierExistAgent': 'Cannot delete supplier is exist agent delegacy'
    },
    'needForcedToCreate': 'This agent has been deleted do you want to recover?',
    'disabled': 'Disable',
    'updateActivation': 'Activation Status',
    'numberOfUsers': 'Number of users',
    'areYouSureToDisableThisSupplier': 'Are you sure you want to disable \'{name}\' supplier?',
    'areYouSureToDisableThisAgent': 'Are you sure you want to disable  \'{name}\' agent?',
    'numOfUsersInSupplier': 'Number of users allowed in the supplier',
    'numberOfVoucherCreateEach': 'Maximum number of vouchers for each creation',
    'numberOfVoucherPerMonth': 'Number of Voucher created per month',
    'numberOfVoucherCreated': 'Maximum number of Voucher for Supplier',
    'numberOfSmsSentInAMonth': 'Number of SMS sent per month',
    'numberOfAgentCreated': 'Mamximum number of agents',
    'numberOfSiteCreated': 'Maximum number of usage site',
    'create': {
      'reachedMaximumAgentAllow': 'Number of agents has reached maximum'
    },
    'header': {
      'name': 'Name',
      'type': 'Type',
      'code': 'Code',
      'role': 'Role',
      'status': 'Status'
    },
    'info': 'General Info',
    'configStoreChannel': 'Store Channels',
    'configLimitation': 'Limitation Settings',
    'enableStoreChannel': 'Enable {channel}',
    'storeChannelList': 'Store Channels List',
    'paymentConfirm': 'Payment Confirm link',
    'createOrder': 'Create Order link',
    'copyUrl': 'Copy url',
    'copied': 'Copied',
    'button': {
      'createNew': 'Create',
      'updateInfo': 'Update Information',
      'updateConfig': 'Save Configuration'
    },
    'requiredCreationApproval': 'Required approval on voucher creation before publishing',
    'allowSMSDelivery': 'Allow using SMS Brandname',
    'deliveryNotificationEmail': 'Customer delivery notification email',
    'managerNotificationEmail': 'Voucher management notification email',
    'notiApproveVoucherCreation': 'Are you sure you want to approve this issuance?'
  },
  'userEntity': {
    'entityIsExistWithRoleIsParentOrChildren': 'Entity already exist with role is parent or children',
    'codeOrName': 'Code or name'
  },
  'rangeCalendar': {
    'today': 'Today',
    'yesterday': 'Yesterday',
    'last7Days': 'Last 7 days',
    'lastWeek': 'Last week',
    'last30Days': 'Last 30 days',
    'lastMonth': 'Last month',
    'currentMonth': 'Current month'
  },
  'voucherReport': {
    'title': 'Voucher Report',
    'period': 'Period',
    'used': 'Used',
    'notUseYet': 'Not use yet',
    'exportExcel': 'Export excel',
    'statisticalTables': 'Statistical tables',
    'selectColumn': 'Select column',
    'others': 'others',
    'statisticalCharts': 'Statistical charts'
  },
  'forgotPassword': {
    'send': 'Send',
    'email': 'Email',
    'back': 'Back to login',
    'newPassword': 'New password',
    'confirmPassword': 'Confirm password',
    'pleaseCheckEmailToChangePassword': 'Please check email to retrieve password !',
    'confirmResetPassword': 'Are you sure you want to reset password for this user?'
  },
  'voucherHistory': {
    'implementAgencies': 'Implementing agencies',
    'peopleAction': 'User action',
    'receivingAgencies': 'Receiving agencies',
    'voucherUsageHistory': 'Voucher usage history',
    'dateAction': 'Date action',
    'action': 'Action',
    'customer': 'Customer',
    'receivingCustomer': 'Receiving customer',
    'type': {
      'create': 'Create voucher',
      'distribute': 'Distribute to agents',
      'assigned': 'Distribute to customers',
      'used': 'Confirm using voucher directly',
      'sendConfirmUsedRequest': 'Send a request to use the voucher to the customer',
      'customerConfirmUsed': 'Customer confirmed to use voucher',
      'customerCancelUsed': 'Customer rejected to use voucher',
      'customerReturnUsed': 'Customer returned to use voucher',
      'deleted': 'Voucher deleted',
      'usedFromThirdParties': 'Used from third parties',
      'customerDeliveryVoucher': 'Customers deliver the voucher',
      'usePromotionVoucher': 'Use promotion voucher',
      'createPromotionVoucher': 'Create promotion voucher',
      'approveReconciliation': 'Approve check',
      'cancelReconciliation': 'Cancel check',
      'lockedVoucher': 'Locked using voucher',
      'confirmUseLockedVoucher': 'Confirmed locked-using voucher',
      'refundVoucher': 'Refunded locked voucher',
      'adminSystemDeliveryVoucher': 'Delivery voucher',
      'enableVoucher': 'Enable Voucher',
      'disableVoucher': 'Disable Voucher',
      'orderVoucher': 'Order voucher',
      'cancelOrderVoucher': 'cancel order voucher',
      'confirmOrderVoucher': 'Confirm order voucher',
      'publishedStoreChannel': 'Publish onto store channel',
      'takebackStoreChannel': 'Take back from store channel',
      'purchaseOrder': 'Purchase order',
      'cancelOrder': 'Purchase order',
      'extendExpiration': 'Extend expiration'
    },
    'description': {
      'undefined': 'Undefined action',
      'create': '[{entity}] create {amount} vouchers',
      'distribute': '{entity} distribute {amount} vouchers to [{toEntity}]',
      'assigned': '[{entity}] distribute {amount} voucher to customer [{customer}]',
      'used': '[{entity}] confirm to use voucher of [{customer}]',
      'sendConfirmUsedRequest': '[{entity}] send request using voucher confirmation to: [{customer}]',
      'customerConfirmUsed': 'Customer [{customer}] connfirmed using voucher via email sent from [{entity}]',
      'customerCancelUsed': '',
      'customerReturnUsed': '',
      'deleted': '[{entity}] deleted voucher',
      'usedFromThirdParties': '[{entity}] confirmed using voucher of [{customer}]',
      'customerDeliveryVoucher': 'Customer [{owner}] send voucher to [{customer}]',
      'usePromotionVoucher': '[{entity}] conrimed using promotion voucher for customer [{customer}]',
      'createPromotionVoucher': '[{entity}] published promotion vouchers',
      'approveReconciliation': '[{entity}] approved reconciliation from [{requestEntity}]',
      'cancelReconciliation': '[{entity}] rejected reconciliation from [{requestEntity}]',
      'lockedVoucher': '[{entity}] locked using voucher of [{customer}]',
      'confirmUseLockedVoucher': '[{entity}] confirmed to unlock and use locked using voucher',
      'refundVoucher': '[{entity}] refud locked usig voucher',
      'adminSystemDeliveryVoucher': 'Voucher has been delivered to [{customer}]',
      'enableVoucher': '[{entity}] enabled voucher',
      'disableVoucher': '[{entity}] disable voucher of [{customer}]',
      'orderVoucher': '[{storeChannel}]: add voucher to cart with order code [{code}]',
      'cancelOrderVoucher': 'Cancelled order code [{code}] from [{storeChannel}]',
      'confirmOrderVoucher': 'Payment confirmation for order code [{code}] from [{storeChannel}]',
      'publishedStoreChannel': 'Published voucher onto store channel [{storeChannel}]',
      'takebackStoreChannel': 'Take back voucher from store channel [{storeChannel}]',
      'purchaseOrder': 'Payment confirmation for order code [{code}] from [{storeChannel}]',
      'cancelOrder': 'Cancelled order code [{code}] from [{storeChannel}]',
      'extendExpiration': 'Extended using expired date'
    }
  },
  'notification': {
    'new': 'New',
    'type': 'Notification type',
    'categories': 'Notification categories',
    'categoriesList': 'Notification categories list',
    'templateNotify': 'Template notification list',
    'createTemplateNotify': 'Create template notification',
    'updateTemplateNotify': 'Update template notification',
    'templateNotifyInfo': 'Information',
    'contentTemplateNotify': 'Content',
    'modal': {
      'confirmDeleteTitle': 'Are you sure you want to delete this template notification?'
    }
  },
  'infoVoucher': {
    'discountInformationFromNCC': 'Discount information from supplier',
    'voucherInformation': 'Voucher information',
    'unitPrice': 'Unit price',
    'discountInfoForDLC1': 'Discount information for level 1 agents',
    'priceOfMoneyReceivedAfterDiscount': 'Price of money received after the discount',
    'priceAfterDiscount': 'Price after discount',
    'discountInfoForDLC2': 'Discount information for level 2 agents',
    'discountInformationFromDLC1': 'Discount information from level 1 agents',
    'list': 'List of voucher information',
    'resendVoucherInfo': 'Resend voucher information',
    'searchForVoucherInfo': 'Voucher Information',
    'discountInformationFromParent': 'Discount information from parent',
    'discountInfoForChildren': 'Discount information for children'
  },
  'distributeExcelMode': {
    'dragAndDropHere': 'Drag and drop here',
    'chooseFileExcel': 'Select the excel file',
    'excelMode': 'Excel mode',
    'preview': 'Preview',
    'buggyReleaseYouWantedContinue': 'There was a buggy release that you wanted to continue?',
    'noticeReleaseContinue': 'If you continue, defective vouchers will not be sent',
    'notice': '*Notice',
    'willIgnoreInfo': 'Will ignore information',
    'becauseAlreadyExistInSystem': 'customer because already exist in system',
    'willNotSendTo': 'Will not send messages to',
    'becauseNumberSMSHasExceededAllowedNumber': 'Because the number of SMS sent by the provider has exceeded the allowed number',
    'usingSMS': 'Send SMS'
  },
  'voucherTransferRequest': {
    'transferAgent': 'Transfer agent',
    'receivingAgent': 'Receiving agent',
    'approvedAgent': 'Approved agent',
    'agentCodeOrEmail': 'Agent code or email'
  },
  'workpieceDetail': {
    'voucherInfo': 'Voucher info',
    'voucherQuantity': 'Voucher quantity',
    'price': 'Price',
    'voucherInfoDistributedToAgent': 'Voucher info distributed to agent',
    'agentCode': 'Agent code',
    'agentName': 'Agent name',
    'voucherSerial': 'Serial',
    'totalPrice': 'Total price',
    'total': 'Total',
    'quantityCreated': 'Quantity created',
    'quantityNotCreated': 'Quantity not created',
    'quantityDistributed': 'Distributed',
    'quantityNotDistributed': 'Not distributed',
    'distributedAfterDiscountPrice': 'Distributed after discount'
  },
  'createVoucher': {
    'releaseDeadlineToAgent': 'Release deadline to agent',
    'issuanceDeadlineToCustomers': 'Issuance deadline to customers',
    'releaseDeadline': 'Release deadline',
    'beforeDate': 'Before date',
    'toCustomer': 'to customers',
    'afterDistributedCustomers': 'after being distributed to customers',
    'issueDateCustomerMustGreaterAgent': 'The issue date to the customer must be greater than the issue date to the dealer',
    'expiredDateOfCustomers': 'Expiry date of customers',
    'fromDate': 'From date',
    'toDate': 'To date',
    'startDateOfUse': 'Start date of use',
    'endDateOfUse': 'End date of use',
    'expiredUsedDateMustGreaterToExpiredToCustomer': 'The expiration date must be greater than or equal to the expiration date issued to the customer',
    'keepIssuingVouchersJustCreated': 'Do you want to distribute vouchers you just created?',
    'selectPrefixSuffix': 'Select prefix and suffix',
    'createMoreVouchers': 'Create more vouchers',
    'addAndRelease': 'Issue and distribute',
    'prepaid': 'Prepaid',
    'unpaid': 'Unpaid',
    'receivedDate': 'Received date',
    'expiredDate': 'Expired date',
    'effectiveDateOfUse': 'Effective date of use',
    'availableUsingDuration': 'Available using duration'
  },
  'voucherValidate': {
    'hasNotBeenUsedYet': 'Voucher has not been used yet',
    'cannotBeUsed': 'This voucher cannot be used!'
  },
  'development': {
    'accessCode': {
      'title': 'Secret key',
      'seriCode': 'Seri code',
      'activeCode': 'Active code',
      'usedPrice': 'Used price'
    },
    'verifyVoucher': 'Verify e-voucher',
    'confirmVoucher': 'Confirm use e-voucher',
    'errorNotification': {
      'userDoesNotExist': 'User does not exists',
      'VoucherNotYetTimeToUse': 'Voucher not yet time to use',
      'evoucherExpired': 'Voucher expired',
      'evoucherUsed': 'Voucher used',
      'notFoundEvoucher': 'Not found info voucher',
      'error_used_price': 'Used price incorrect',
      'input_used_price': 'Please input used price'
    },
    'apiUseVoucher': 'Api use voucher',
    'supplierAccessCode': 'Access code',
    'confirmResetKeyModal': 'Are you sure you want to reset current Secret API Key?\nPlease be noted that the current Secret Key will no longer valid, you have to update your API call with new key'
  },
  'supplierAccessCode': {
    'title': 'Active supplier code',
    'activeCode': 'Active code',
    'maxCount': 'Total',
    'count': 'Count',
    'expiredDate': 'Expired date',
    'status': 'Status',
    'statusData': {
      'enable': 'Enable',
      'disable': 'Disable',
      'expired': 'Expired',
      'deleted': 'Deleted'
    },
    'errorExpiredDate': 'Expired date is greater than the current time',
    'activeCodeExists': 'Active code already exists',
    'confirmCreateActiveCodeExistTitle': 'Activate code already exists, do you want to continue creating?'
  },
  'printVoucher': {
    'print': 'Print',
    'chooseImage': 'Choose background image',
    'selected': 'Print the selected voucher',
    'auto': 'Batch printing',
    'providedBy': 'Provided by',
    'customerName': 'Name of customer',
    'wantToPrintRecently': 'Do you want to print recently issued vouchers?',
    'printed': 'Printed',
    'youSurePrintVoucher': 'Are you sure to print these vouchers?',
    'cannotPrintVoucherUnreleasedToCustomer': 'Unreleased vouchers cannot be printed to customers',
    'cannotPrintVoucherUnreleasedToCustomerOrUsed': 'Unreleased or used vouchers cannot be printed',
    'chooseVoucherToBePrinted': 'Please select the voucher from the list',
    'cannotPrintVoucherExpiredDate': 'Vouchers that have expired cannot be printed',
    'providedByAgentName': `Provided by (agent's name)`,
    'receivedAndSearch': 'Receive & lookup',
    'use': 'Use',
    'guideLookup': `Access email to receive voucher information or visit ${FOR_CONASI_TARGET ? 'conasi365.vn' : 'voucher.avatarnext.com'} to look up.`,
    'guideUse': `Declare voucher information when ${FOR_CONASI_TARGET ? `booking and check-in at the hotel` : 'using voucher'}.`,
    'sampleTitle': 'Voucher title, long text will display 2 lines',
    'descriptionText': 'Description text',
    'termOfConditionsText': 'Term and conditions text',
    'sampleDetails': 'Extra detail voucher information, will be print in next page',
    'actionList': {
      'backgroundImage': 'Background image',
      'secondPageBackgroundImage': 'Second page background image'
    },
    'editFormTitle': '{0} edit',
    'imageUrl': 'Image url',
    'itemName': 'Item name',
    'notiRemoveTemplateItem': 'Are you sure you want to remove item name {0}?',
    'printLayoutEditor': 'Print layout editor \'{0}\'',
    'configDefaultTemplate': 'Config default template',
    'template': 'Print template',
    'selectPrintTemplate': 'Select print template'
  },
  'layoutPrint': {
    'customer': 'Customer',
    'email': 'Email',
    'serial': 'Serial number',
    'providedBy': 'Provided by',
    'unitPrice': 'Unit price',
    'expiredDate': 'Expired date',
    'description': 'Description',
    'termConditions': 'Term & Conditions',
    'contactInfo': 'Contact information',
    'hotline': 'Hotline',
    'detailsNote': '<i><b>Noted</b>: Please refer to “Details Information” in next page before using service.</i>',
    'detailsPageTitle': 'Details Information'
  },
  'customization': {
    'configOutgoingEmail': 'Configure outgoing email',
    'projectDefault': 'Project default',
    'errorMessage': {
      'projectIdIsNone': 'Please select a project to connect',
      'notFoundProject': 'Your project could not be found',
      'infoConnectIncorrect': 'Information is not correct',
      'cannotConnectToServer': 'Can not connect to server'
    },
    'usedPrivateSmtp': 'Used private SMTP service'
  },
  'templateEmail': {
    'title': 'Template email',
    'category': {
      'title': 'Template email category',
      'list': 'Template email category list',
      'table': {
        'headerName': 'Category name',
        'headerNumberOfTemplate': 'Number of template'
      },
      'viewDetail': 'View detail'
    },
    'chooseTemplate': 'Email template sending to customer',
    'usedThisTemplate': 'Use this template',
    'name': 'Name',
    'usedDefault': 'Used default',
    'list': 'Template email list',
    'createNew': 'Create new template',
    'createTemplateEmail': 'Create template email',
    'updateTemplateEmail': 'Update template email',
    'info': 'Template email info',
    'chooseValue': 'Choose value',
    'copy': 'Copy to clipboard',
    'previewTemplate': 'Preview template',
    'chooseTemplateDefault': 'Choose template default',
    'modePreview': 'Preview mode',
    'chooseThisTemplate': 'Choose this template'
  },
  'assignVoucher': {
    'releaseToCustomer': 'Released to customers',
    'releaseToAgent': 'Released to the agent',
    'toCustomer': 'To the customer',
    'toAgent': 'To the agent',
    'totalVoucherRelease': 'Total number of vouchers released',
    'selectTheWorkPieceCode': 'Select the workpiece code',
    'selectVoucher': 'Choose Voucher',
    'selectAgent': 'Choose agent',
    'chooseIssuance': 'Choose voucher issuance',
    'totalAmountOfDiscount': 'Total amount of the discount',
    'discountedFaceValue': 'Discounted face value',
    'vouchers': 'Vouchers',
    'voucher': 'Voucher',
    'discountedFaceValueMustNotGreaterActualValue': 'The discounted face value must not be greater than the actual face value',
    'deliveryNote': 'Note for this delivery'
  },
  'transferVoucherBetweenAgent': {
    'error': {
      'outgoingAgentDoesNotExist': 'Outgoing agent does not exist',
      'agentToTransferNotExist': 'Agent to transfer does not exist',
      'emailOrCodeExistsMoreThan1Property': 'Email or code exists more than 1 property',
      'cannotTransferToYourself': 'Cannot transfer it to yourself',
      'emailOrCodeNotHaveAnySuperclassProperty': 'Email or code does not have any superclass property',
      'thisLevel2AgentNotSameLevelOfParent': 'This level 2 agent is not the same level of parent'
    }
  },
  'promotionVoucher': {
    'hasCreate': 'Create promotion voucher',
    'youHaveRunOutOfUsageTimes': 'You have run out of usage times',
    'promotionalVoucherHasExpired': 'This promotional voucher has expired!',
    'remainQuantity': 'Remain quantity',
    'confirmUse': 'Confirm use of promotional voucher',
    'doseNotExist': 'Voucher code does not exist',
    'confirmUsed': 'Are you sure you want to use this voucher?',
    'type': 'Promotion type',
    'allocated': 'Allocated',
    'notAllocated': 'Not allocated'
  },
  'advertisementInfo': {
    'title': 'Title',
    'description': 'Description',
    'addNewAds': 'Add new advertisement info',
    'updateAds': 'Update advertisement info',
    'confirmDeleteAds': 'Are you sure you want to delete this ad information?',
    'list': 'Advertisement info list',
    'type': {
      'small': 'Small banner',
      'large': 'Large banner',
      'popupLearning': 'Popup learning'
    },
    'status': {
      'prepare': 'Prepare',
      'public': 'Active',
      'close': 'Close',
      'inactive': 'Inactive'
    },
    'confirmModalTitle': {
      'prepare': 'Do you want to do in active this banner?',
      'active': 'Do you want to active this banner?'
    },
    'encouragePhotoRate': {
      'vertical': 'A vertical aspect ratio is recommended',
      'horizontal': 'Horizontal aspect ratio is recommended'
    }
  },
  'bannerInfo': {
    'title': 'Title',
    'description': 'Description',
    'addNewAds': 'Add new advertisement info',
    'updateAds': 'Update advertisement info',
    'confirmDeleteAds': 'Are you sure you want to delete this ad information?',
    'list': 'Advertisement info list',
    'type': {
      'small': 'Small banner',
      'large': 'Large banner',
      'popupLearning': 'Popup learning'
    },
    'status': {
      'prepare': 'Prepare',
      'public': 'Active',
      'close': 'Close',
      'inactive': 'Inactive'
    },
    'confirmModalTitle': {
      'prepare': 'Do you want to do in active this banner?',
      'active': 'Do you want to active this banner?'
    },
    'encouragePhotoRate': {
      'vertical': 'A vertical aspect ratio is recommended',
      'horizontal': 'Horizontal aspect ratio is recommended'
    }
  },
  'report': {
    'active': 'Active',
    'inactive': 'Inactive',
    'days': 'Days',
    'months': 'Months',
    'years': 'Years',
    'voucher': {
      'promotion': 'Voucher promotion',
      'normal': 'Voucher normal'
    },
    'voucherStatistics': 'Voucher Statistics',
    'userStatistics': 'User Statistics',
    'quantityVoucherSupplierToAgent': {
      'workpieceCode': 'Workpiece code',
      'workpiece': 'Workpiece',
      'issueNumber': 'Issue number'
    },
    'numberOfVouchersIssuedToAgent': 'Number of vouchers issued to agent',
    'numberOfVouchersToBeIssuedToAgentLevel2': 'Number of vouchers to be issued to agent level 2',
    'statisticsNumberOfVouchersIssuedToAgentLevel2': 'Statistics number of vouchers issued to DLC2',
    'statisticsNumberOfVouchersIssuedToAgent': 'Statistics number of vouchers issued to the agent',
    'quantityVoucherSoldInAgent': {
      'title': 'Number of vouchers sold in the agent levels 2',
      'list': 'Statistics number of vouchers sold in the agent levels 2',
      'numberReleased': 'Number released',
      'voucherSold': 'Voucher sold',
      'customerReceived': 'Customers have received',
      'customerHaveUsed': 'Customers have used'
    },
    'quantityVoucherSoldOfStaffInAgent': {
      'title': 'Number of vouchers sold of staff in the agent levels 2',
      'list': 'Statistics number of vouchers sold of staff in the agent levels 2'
    },
    'staffName': 'Staff name',
    'staffEmail': 'Staff email',
    'releaseAgent': 'Release agent',
    'receivedAgent': 'Received agent',
    'releaseSupplier': 'Release supplier',
    'detailQuantityVoucherSoldOfStaffInAgent': {
      'title': 'Detail number of vouchers sold of staff in the agent levels 2',
      'list': 'Statistics detail number of vouchers sold of staff in the agent levels 2'
    },
    'supplier': {
      'numberVoucherCreated': 'Vouchers Issurance',
      'voucherDistributionStatisticsToAgentsTitle': 'Voucher distribution statistics to agents',
      'voucherUsedList': 'Usage Confirmation',
      'voucherUsedDetail': 'Used Vouchers',
      'voucherConfirmationTitle': 'List of confirmation location',
      'voucherUsageTitle': 'List of used Voucher',
      'usedSite': 'Used at site',
      'dateOfUse': 'Used Date',
      'activationStaff': 'Activation Staff',
      'seriCode': 'Seri Code',
      'phone': 'Phone',
      'voucherOwner': 'Owner',
      'voucherOwnerPhone': 'Owner Phone',
      'voucherOwnerEmail': 'Owner Email',
      'customerUsedPhone': 'Customer used Phone',
      'customerUsedName': 'Customer used name',
      'customerUsedEmail': 'Customer used email',
      'numberVoucherCustomerUsed': 'Number voucher customer used',
      'totalFee': 'Total fee',
      'issuer': 'Issuer',
      'numberOfCustomersReceived': 'Number of customers received',
      'numberOfCustomersUsed': 'numberOfCustomersUsed',
      'dateAction': 'Date action',
      'implementationStaff': 'Implementation staff'
    }
  },
  'evoucherAssign': {
    'error': {
      'disabledEntity': 'This agent has been disabled!'
    }
  },
  'hashtag': {
    'customerGroupsAreReceived': 'Customer groups are received',
    'listOfCustomersInHashtag': 'List of customers in hashtag',
    'addCustomerToTheHashtag': 'Add a customer to the hashtag',
    'updateHashtag': 'Update hashtag',
    'addNewHashtag': 'Add new hashtag',
    'hashtagList': 'Hashtag list',
    'userCreated': 'User created',
    'entityCreated': 'Supplier created',
    'addCustomerToSelected': 'Add customer selected',
    'wantToAddCustomersToHashtag': 'Do you want to add these customers to the hashtag?',
    'confirmToDelete': 'Are you sure you want to delete this hashtag?',
    'isEmpty': 'Không có khách hàng trong hashtag',
    'distributeVoucher': 'Distribute voucher to all customer in this hashtag',
    'count': 'Number of Member',
    'distributeVoucherToGroup': 'Distribute voucher to customer in #{name}',
    'numberOfMember': 'Number of customer in group:',
    'quantityNotEnough': 'Quantity of voucher is not enough'
  },
  'smsConfig': {
    'info': 'SMS Service Configuration',
    'brandName': 'Brand name',
    'tokenKey': 'API Token Key',
    'secretKey': 'API Secret Key'
  },
  'workpiece': {
    'list': 'Workpiece list',
    'issuanceList': 'Issuance List',
    'issuanceInfo': 'Issuance Info',
    'issuanceHistory': 'Issuance History',
    'issuance': 'Issuance',
    'issuedVoucherList': 'List of Issued Vouchers',
    'title': 'Voucher workpiece',
    'listVoucher': 'Voucher workpiece list',
    'quantityDistributed': 'Quantity distributed',
    'name': 'Workpiece',
    'quantityDistributedAgent': 'Quantity distributed to the agent',
    'quantityDistributedCustomer': 'Quantity distributed to the customer',
    'addNew': 'Add new workpiece voucher',
    'persistentActiveCode': 'Persistent active code',
    'hidePrice': 'Hide price to customer',
    'unreleasedQuantity': 'Unreleased quantity',
    'remainQuantityInAgency': 'Remain quantity in agency'
  },
  'portal': {
    'manageTitle': 'Manage portal information',
    'banner': {
      'slider': 'Slider banner',
      'ads': 'Middle banner',
      'logoAndBgMenu': 'Logo and background menu',
      'adsList': 'List of middle banner information',
      'sliderList': 'Information list slider banner',
      'update': 'Update banner information',
      'add': 'Add banner information',
      'link': 'Link',
      'vertical': 'Vertical banner'
    },
    'display': 'Display',
    'configBackgroundMenu': 'Config background menu',
    'preview': 'Preview',
    'configLogo': 'Config logo',
    'emailDomain': {
      'activeKey': 'Active key',
      'domainList': 'Domain list',
      'add': 'Add new email domain',
      'update': 'Update email domain',
      'alreadyExists': 'Domain already exists',
      'alreadyUsedByAnotherAgent': 'Domain already used by another agent',
      'activeKeyIsRequired': 'Active key is required',
      'activeKeyAlreadyExist': 'Active key already exist',
      'domainsUsersAlreadyExistSystem': 'There are email domains/users that already exist in the system',
      'ifContinueExistingEmailDomainsUsersNotBeInitialized': 'If you continue, existing email domains/users will not be initialized',
      'addNewEmailDomain': 'Add new email domain',
      'addNewUserDomain': 'Add new user domain',
      'importExcel': 'Import excel'
    },
    'emailUser': {
      'add': 'Add new email user',
      'update': 'Update email user'
    },
    'category': {
      'add': 'Create category',
      'update': 'Update category'
    }
  },
  'voucherProduct': {
    'category': 'Categories',
    'categoriesList': 'Categories list',
    'productTitle': 'Products',
    'bestSelling': 'Best selling',
    'productListTitle': 'Products list',
    'warehouseStatus': 'Warehouse status',
    'putOnSalesChannel': 'Put on the sales channel',
    'warehouse': 'Warehouse',
    'quantityAvailable': 'Quantity available',
    'quantityPutUp': 'Quantity put up',
    'config': {
      'priceNormal': 'Normal selling price',
      'pricePromo': 'Promotion price',
      'maxNumberOfVouchersPurchasedTime': 'Maximum number of vouchers can be purchased at a time',
      'repeatTime': 'Repeat time',
      'byWeek': 'By week',
      'byMonth': 'By month'
    },
    'productInfo': 'Product information',
    'productConfig': 'Product config',
    'displayOnHomeScreen': 'Display on home screen',
    'quantityToTakeDown': 'Quantity to take down',
    'takeDownFromSalesChannel': 'Take down from sales channel',
    'numberOfVouchersRemovedExceedsQuantityPutUp': 'The number of vouchers to be removed exceeds the amount posted',
    'updateInfo': 'Update information',
    'removeFromSalesChannel': 'Take down from sales channel',
    'sold': 'Sold',
    'total': 'Product total',
    'orderPriority': {
      'moveToUp': 'Move to up',
      'moveToDown': 'Move to down',
      'moveToFirst': 'Move to first',
      'moveToLast': 'Move to last'
    },
    'infoFromSupplier': 'Information from the supplier',
    'albumImages': 'Product photo album',
    'detail': 'Voucher product detail',
    'infoFromAgent': 'Information from the agent',
    'remainItems': '[Remain {count} voucher(s)]',
    'takeDownQuantity': 'Take down quantity',
    'removeFromStore': 'Remove from store',
    'notiRemoveVoucherFromStore': 'Are you sure you want to remove voucher {0} from store channel {1}?'
  },
  'storeChannel': {
    'title': 'Store channels'
  },
  'productOrder': {
    'title': 'Product orders',
    'titleList': 'Product orders list',
    'status': {
      'paid': 'Paid',
      'waitForPay': 'Wait for pay',
      'cancel': 'Cancel',
      'processed': 'Processed',
      'noProcess': 'No processed',
      'paymentOverdue': 'Payment overdue',
      'delivered': 'Delivered'
    },
    'order': 'Order',
    'detail': 'detail',
    'code': 'Code',
    'btn': {
      'orderCancellation': 'Order cancellation',
      'orderConfirmation': 'Order confirmation'
    },
    'numberPurchaseExceedsAvailableQuantity': 'The number of vouchers to purchase exceeds the available quantity',
    'orderDate': 'Order date',
    'paidDate': 'Paid date',
    'byOrder': 'By order',
    'byVoucher': 'By Voucher',
    'approveSelectedOrders': 'Approve selected orders',
    'cannotApprovedOrdersInvalid': 'Canceled or approved orders cannot be approved',
    'modalConfirmTitle': {
      'approveSingle': 'Are you sure you want to approve this order?',
      'approveBulk': 'Are you sure you want to approve these orders?',
      'cancelOrder': 'Are you sure you want to cancel this order?'
    },
    'price': 'Price',
    'quantity': 'Quantity',
    'image': 'Image',
    'tableList': {
      'header': {
        'orderCode': 'Order code',
        'purchaseDate': 'Purchase date',
        'product': 'Product',
        'totalPrice': 'Total price',
        'status': 'Status'
      },
      'status': {
        'paid': 'Paid',
        'waitForPay': 'Wait for pay',
        'cancel': 'Cancel',
        'delivered': 'Delivered'
      }
    },
    'myOrderTitle': 'My orders',
    'haveRunOutOfPurchasesThisProduct': 'You have run out of purchases for this product',
    'promotion': {
      'hasExpired': 'Promo code has not enough usage times',
      'notExist': 'Promo code not exists',
      'expiredPromotionCode': 'Expired promotion code'
    },
    'outOfStock': 'Out of stock'
  },
  'footer': {
    'listTitle': 'Footer list',
    'actions': {
      'add': 'Add new footer',
      'update': 'Update footer',
      'confirmDeleteTitle': 'Do you want delete this footer?'
    },
    'title': 'Title',
    'content': 'Content'
  },
  'promotionCode': {
    'listTitle': 'List of promo codes',
    'usageTimes': 'Usage times',
    'usagePerAccount': 'Usage per customer',
    'promotionType': 'Promotion type',
    'applyFor': 'Apply for',
    'reducedRate': 'Reduced rate',
    'startTime': 'Start time',
    'endTime': 'End time',
    'order': 'Order',
    'price': 'Price',
    'percent': 'Percent',
    'modal': {
      'confirmDeleteTitle': 'Are you sure you want to delete this promo code?'
    },
    'btn': {
      'create': 'Create promo code',
      'update': 'Update promo code'
    },
    'form': {
      'enterPromoCodeHere': 'Enter Promo code here'
    },
    'decreaseByPrice': 'Decrease by price',
    'decreaseByPercentage': 'Decrease by percentage',
    'managePromoCodes': 'Manage promo codes',
    'promotion': 'Promotion',
    'alreadyExist': 'Promo code already exists'
  },
  'paymentMethod': {
    'list': 'Payment methods list',
    'accountInfo': 'Account information',
    'setting': 'Setting',
    'title': 'Payment method',
    'turnOn': 'Activated',
    'transferInfo': 'Transfer information',
    'addNewAccount': 'Add new account',
    'deleteAccount': 'Delete account',
    'turnOff': 'Turn off',
    'configPaymentMethod': 'Payment method configuration for \'{method}\'',
    'bankInfo': {
      'title': 'Bank account information',
      'accountName': 'Account name',
      'accountNumber': 'Account number',
      'bankName': 'Bank name',
      'bankBranch': 'Bank branch',
      'lowApproximate': 'Low approximate',
      'highApproximate': 'High approximate'
    },
    'transferInfoDetail': {
      'nameAccount': 'Name account',
      'numberAccount': 'Number account',
      'nameBank': 'Name bank',
      'branchBank': 'Branch bank'
    },
    'transferContent': 'Transfer content',
    'discountOnBill': 'Discount on bill',
    'discountOnVoucher': 'Discount on voucher',
    'applicableCode': 'Applicable code'
  },
  'configOrderHoldingTime': {
    'title': 'Config time',
    'paymentOverdueTime': 'Payment Overdue Time (Hours)',
    'orderCancellationTime': 'Order Cancellation Time (Hours)',
    'calculatedTimePaymentOverdue': 'Order cancellation time is calculated from the time of payment overdue'
  },
  'importExcelApproveOrder': {
    'thereAreInvalidOrders': 'There are invalid orders',
    'ifContinueInvalidOrdersNotBeApproved': 'If you continue, invalid orders will not be approved',
    'inputFileExcel': 'Import file excel'
  },
  'bannerVertical': {
    'position': 'Position',
    'left': 'Left',
    'right': 'Right'
  },
  'cart': {
    'title': 'Cart',
    'orderSummary': 'Order summary',
    'noProductInCart': 'There are no products in the cart! ',
    'totalPrice': 'Total price',
    'fee': 'Fee',
    'totalPriceWithFee': 'Total price with fee',
    'orderConfirmation': 'Order confirmation',
    'successfullyOrderedVoucher': 'Successfully ordered voucher !',
    'successfullyDeletedVoucher': 'Successfully deleted voucher',
    'outOfCart': 'out of cart',
    'noProductsInCart': 'There are no products in the shopping cart !',
    'voucherInfoSentViaEmail': 'Voucher information sent via email',
    'makeSureEmailIsEnteredCorrectly': 'Please make sure the email is entered correctly',
    'provisional': 'Provisional price',
    'chooseProduct': 'Choose product',
    'shipmentDetails': 'Shipment details',
    'payment': 'Payment',
    'myOrderTitle': 'My orders',
    'tableList': {
      'header': {
        'orderCode': 'Order code',
        'purchaseDate': 'Purchase date',
        'product': 'Product',
        'totalPrice': 'Total price',
        'status': 'Status'
      },
      'status': {
        'paid': 'Paid',
        'waitForPay': 'Wait for pay',
        'cancel': 'Cancel'
      }
    },
    'detail': {
      'price': 'Price',
      'quantity': 'Quantity',
      'order': 'Order',
      'detail': 'detail',
      'image': 'Image'
    },
    'promotion': {
      'hasExpired': 'Promo code has not enough usage times',
      'notExist': 'Promo code not exists',
      'code': 'Promo code',
      'apply': 'Apply',
      'discount': 'Discount',
      'totalPriceToPay': 'Total price to pay'
    },
    'haveRunOutOfPurchasesThisProduct': 'You have run out of purchases for this product',
    'infoAboutCustomersOrderingVouchers': 'Information about customers ordering vouchers',
    'voucherRecipientInfo': 'Voucher recipient information',
    'addToCartOverQuantity': 'Reached maximum voucher quantity in store',
    'paymentAmount': 'Payment amount',
    'quantityMustGreatThanZero': 'Number of voucher must be greater 0',
    'productCanBuyTimes': 'Product can buy {0} times',
    'validProductOnCart': 'Product {0} can buy {1} times',
    'promotionCodeAlreadyUsed': 'Promotion code already used'
  },
  'product': {
    'list': 'Products list',
    'category': {
      'list': 'Categories list',
      'all': 'All'
    },
    'detail': {
      'buy': 'Buy',
      'productInfo': 'Product information',
      'info': 'Information',
      'conditionsOfUse': 'Conditions of use',
      'locationOfUse': 'Location of use',
      'remainItems': 'Remain {count} voucher(s)'
    },
    'similar': 'Similar products',
    'featured': 'Featured products',
    'enterInfo': 'Enter product information',
    'filter': {
      'price': 'Filter by price',
      'inOrderOfPrice': 'In order of price',
      'type': {
        'default': 'Default order',
        'highToLow': 'In order of price: High to low',
        'lowToHigh': 'In order of price: Low to high'
      },
      'title': 'Filter'
    }
  },
  'voucherUsed': {
    'list': 'Vouchers used list',
    'orderCode': 'Order code',
    'voucherSerial': 'Serial',
    'voucherName': 'Voucher name',
    'customerReceived': 'Customer received',
    'customerUsed': 'Customer used',
    'priceUsed': 'Price used',
    'reconciliation': 'Reconciliation',
    'price': 'Price',
    'supplier': 'Supplier',
    'lockUsing': 'Lock using',
    'refundVoucher': 'Refund Locked Vouchers',
    'usedVoucher': 'Confirm Used'
  },
  'voucherReconciliation': {
    'list': 'Voucher checks list',
    'detail': 'Details waiting to be checked',
    'waitForApproval': 'Voucher Reconciliation',
    'code': 'Code',
    'voucherSerial': 'Serial',
    'createdDate': 'Created date',
    'submitDate': 'Submitted date',
    'usedSite': 'Used at site',
    'supplier': 'Supplier',
    'userCreated': 'User created',
    'totalVoucher': 'Total voucher',
    'totalPrice': 'Total price',
    'comment': 'Comment',
    'detailInfo': {
      'fromSite': 'Request from',
      'toSupplier': 'To supplier',
      'userSummitted': 'Submitted by',
      'userApproval': 'Reviewed by',
      'dateSummitted': 'Summitted date',
      'dateApproval': 'Reviewed date'
    },
    'status': {
      'title': 'Status',
      'action': 'Action',
      'notSentYet': 'Not request yet',
      'waitForApproval': 'Wait for reconciliation',
      'notCheckedYet': 'Not checked yet',
      'reconciled': 'Has Reconciled',
      'canceled': 'Canceled',
      'deleted': 'Deleted',
      'preparing': 'Initialized'
    },
    'voucherUsed': {
      'title': 'Used Vouchers'
    },
    'history': {
      'title': 'Reconciliation Histories'
    },
    'action': {
      'create': 'Create reconciliation',
      'submit': 'Submit reconciliation',
      'approve': 'Approve reconciliation',
      'cancel': 'Cancel reconciliation',
      'delete': 'Delete reconciliation',
      'view': 'Details'
    },
    'modal': {
      'confirmCreate': 'Are you sure you want to make reconciliation for these vouchers?'
    },
    'tab': {
      'waitForReview': 'Wait for review',
      'notSubmitYet': 'Not submit yet',
      'reviewed': 'Reviewed'
    },
    'plsSelectVoucherNeedToCheck': 'Please select the voucher you need to check',
    'approve': 'Approve check',
    'cancel': 'Cancel check',
    'voucherApproved': 'Approved',
    'voucherWaitForApprove': 'Waiting',
    'voucherCanceled': 'Rejected',
    'approveSelectedVoucher': 'Approve selected voucher',
    'cancelSelectedVoucher': 'Cancel selected voucher',
    'viewHistory': 'History'
  },
  'thirdParty': {
    'yourOrderSuccessfullyPaid': 'Your order has been successfully paid',
    'continueShopping': 'Continue shopping',
    'total': 'Total',
    'product': 'products',
    'infoReceiveVouchers': 'Information to receive vouchers',
    'order': 'Order',
    'transportFee': 'Transport fee',
    'updateInfoReceiveVouchers': 'Update info receive vouchers',
    'allProducts': 'All products',
    'whatAreYouLookingFor': 'What are you looking for',
    'seeMore': 'See more',
    'latestProduct': 'Latest product',
    'productHasBeenAddedToCart': 'The product has been added to cart',
    'viewCart': 'View cart',
    'continueBuy': 'Continue to buy',
    'errorBuying': 'An error occur during buying product. Please try later',
    'warningMessage': 'Email and phone number are used to receive Voucher, please double check before buying',
    'chooseBuy': 'Choose buy',
    'backToStore': 'Go to Store',
    'customSearch': 'Advance Search',
    'price': 'Price range'
  },
  'lienVietConfig': {
    'title': 'Lien Viet config',
    'productName': 'Product name',
    'lpbPublicKey': 'LPB public key',
    'merchantPublicKey': 'Merchant public key',
    'merchantPrivateKey': 'Merchant private key',
    'agentAccess': 'Agent access',
    'agentSite': 'Agent site',
    'WAPUrl': 'WAP url',
    'merchantSite': 'Merchant site',
    'accessCode': 'Access code',
    'infoValidationError': 'Information validation error'
  },
  'activation': {
    'expirationDuration': 'Validation Date',
    'duration': 'Duration (days)',
    'duration30days': '30 days',
    'duration3months': '3 months',
    'duration6months': '6 months',
    'duration1year': '1 year',
    'durationVarious': 'Custom',
    'durationUnlimited': 'Unlimited'
  },
  'payment': {
    'enableMethod': 'Enable Payment',
    'disableMethod': 'Disable Payment',
    'title': {
      'momo': 'MOMO payment configuration'
    },
    'type': {
      'cash': 'Cash',
      'transfer': 'Bank transfer',
      'onlinePayment': 'Online Payment',
      'momoWallet': 'MoMo wallet',
      'zalopay': 'ZaloPay',
      'viviet': 'ViViet',
      'baokim': 'BaoKim'
    },
    'confirmDeleteBankInfo': 'Are you sure you want to delete \'{name}\' bank account?',
    'momo': {
      'partnerCode': 'Partner Code',
      'accessCode': 'Access Code / Password',
      'secretKey': 'Secret Key',
      'infoHint': 'Please provide information from Momo:',
      'guideline': {
        'introduction': 'Instructions to configure Momo',
        'step_0': 'Please follow these steps:',
        'step_1': 'Register an account with Momo',
        'step_2': 'After completing the registration at Momo, you will have <b>Partner Code</b>, <b>Access Key</b>, <b>Secret Key</b>',
        'step_3': 'Enter <b>Partner Code</b>, <b>Access Key</b>, <b>Secret Key</b> in the right box'
      }
    },
    'confirmPayment': 'Payment confirmation',
    'cancelPayment': 'Cancel'
  },
  'responseInfo': {
    'invalidData': 'Invalid data',
    'invalidExpiredDate': 'Expired date is invalid',
    'invalidDuration': 'Duration value is invalid',
    'voucherWasReconciled': 'Existing reconciled voucher in list',
    'voucherUsgaeNotFound': 'No voucher found for reconsillation',
    'cannotProcessApprovedVoucher': 'Can not process the reconciled voucher',
    'reconciliationHasBeenProcessed': 'The voucher reconciliation has been processed'
  },
  'socialNetwork': {
    'notiAddSocialAccountLink': 'Are you sure you want to add a google account link?',
    'googleAccountLinkExists': 'The google account is already linked to another account',
    'facebookAccountLinkExists': 'The facebook account has been linked to another account'
  },
  'baoKimConfig': {
    'title': 'Bao Kim Config',
    'baoKimApiKey': 'Bao Kim Api Key',
    'baoKimSecretKey': 'Bao Kim Secret Key',
    'baoKimPaymentInfo': 'Bao Kim Payment Info',
    'baoKimMerchantId': 'Merchant Id',
    'baoKimPaymentMethodType': 'Payment Method Type',
    'baoKimPaymentMethod': 'Payment Method',
    'paymentMethod': {
      'BAO_KIM_WALLET': 'Bao Kim Wallet',
      'ATM_CARD_ONLINE': 'ATM Card',
      'VISA_MASTER_CARD': 'Visa/Master Card',
      'TRANSFER': 'Transfer',
      'QR_CODE': 'QR Code',
      'VIRTUAL_ACCOUNT': 'Virtual Account'
    },
    'paymentConfirm': {
      'completed': 'Your order has been successfully paid',
      'errorPaymentConfirm': 'Your order has not yet been paid'
    }
  },
  'confirmModal': {
    'confirmCancelOrder': 'Are you sure you want to cancel this order?',
    'title': 'Confirm',
    'btn': {
      'confirm': 'Confirm',
      'close': 'Close'
    }
  },
  'partnership': {
    'loginPartnership': 'Login Partnership',
    'logoutPartnership': 'Logout Partnership',
    'notiLogoutPartnership': 'Are you sure you want to sign out of the partnership?',
    'emailJoinPartnership': 'Email join partnership'
  },
  'confirmActiveKey': {
    'verificationCodes': 'Verification codes',
    'toContinue': 'To continue, please enter the verification code provided in the box below',
    'codeNotExist': 'Verification code does not exist'
  },
  'confirmEmailDomain': {
    'toContinueEnterYourEmailDomain': 'To continue, please enter your email domain in the box below',
    'emailDomain': 'Email domain',
    'emailDomainToLink': 'Email domain to link',
    'successfulWaitForLinkConfirm': 'Please check email and confirm activation!',
    'emailDomainNotExist': 'Email domain does not exist'
  },
  'transactionBetweenCustomer': {
    'transactionMulti': 'Transfer selected voucher',
    'transactionSingle': 'Transfer voucher',
    'errorText': {
      'cannotTransferYourself': 'Cannot transfer to yourself!',
      'noLongerOwnThisVoucher': 'You no longer own this voucher',
      'numberVoucherExceedNumberAvailable': 'The number of vouchers to be transferred exceeds the number of vouchers available',
      'existVouchersHaveBeenUsed': 'There exist vouchers that have been used',
      'voucherHasAlreadyBeenUsed': 'This voucher has already been used',
      'vouchersWaitingForConfirmationUsed': 'There are vouchers waiting for confirmation to be used',
      'cannotTransferVoucherWaitingConfirmationOfUsed': 'Vouchers awaiting confirmation of use cannot be delivered',
      'emailAlreadyExistAndNotIsCustomer': 'This email already exists in the system and is not a customer',
      'emailFailedTryAgain': 'Email failed, please try again later!',
      'nonTransferableVoucherExists': 'Non-transferable voucher exists',
      'voucherIsNonTransferable': 'This voucher is non-transferable'
    }
  },
  'registerSupplier': {
    'title': 'Register voucher supplier information',
    'subtitle': 'After registering as a voucher supplier, you can create your voucher, manage your voucher and issuance your voucher to your customers',
    'account': 'Supplier Account',
    'supplierInfo': 'Supplier information',
    'name': 'Supplier name',
    'code': 'Supplier identification',
    'address': 'Supplier address',
    'phone': 'Supplier phone',
    'email': 'Supplier email',
    'website': 'Supplier website',
    'description': 'Supplier brief information',
    'register': 'Register supplier',
    'gotoLogin': 'Back to login page'
  },
  'bankTransactionInfo': {
    'sidebarTitle': 'Bank transaction',
    'title': 'Transaction list',
    'modalTitle': 'Transaction detail',
    'requestId': 'Request id',
    'accountNo': 'Account no',
    'balanceAfterOperation': 'Balance after operation',
    'bankTransactionId': 'Transaction id',
    'currency': 'Currency',
    'narrative': 'Narrative',
    'requestTime': 'Request time',
    'transactionAmount': 'Transaction amount',
    'transactionDate': 'Transaction time',
    'transactionDetail': 'Transaction detail',
    'statusList': {
      'NOT_PROCESS': 'Not process',
      'SUCCESS': 'Success',
      'INVALID_ORDER': 'Invalid order',
      'INVALID_AMOUNT': 'Invalid Amount',
      'INVALID_APPROVE_ORDER': 'Invalid Approve order'
    }
  },
  'HDBankKeySetting': {
    'title': 'HDBank Notification Configuration',
    'publicKey': 'Public key',
    'HDBankPublicKey': 'HD bank public key'
  },
  'groupSites': {
    'title': 'Group of usage sites',
    'addRegion': 'Add usage site',
    'updateRegion': 'Update region',
    'deleteRegionMessage': 'Are you sure you want to remove this region?',
    'addSiteIntoRegion': 'Add site into group',
    'removeSiteFromRegion': 'Are you sure you want to delete site \'{0}\' from region?',
    'siteExistingInRegion': 'The site already exists in the region'
  },
  'emailHistory': {
    'title': 'Email sent history',
    'gatewayTitle': 'Delivery status from mail gateway',
    'smtpAgent': 'From SMTP Agent',
    'mailGateway': 'From mail gateway',
    'search': 'Email or subject...',
    'searchEmail': 'Recipient email...',
    'toAddress': 'To Addr.',
    'ccAddress': 'CC Addr.',
    'bccAddress': 'Bcc Addr.',
    'subject': 'Subject',
    'attached': 'Attached',
    'sender': 'Sender',
    'recipient': 'Recipient',
    'dateCreated': 'Date sent',
    'status': 'Status',
    'event': 'Event',
    'timestamp': 'Date occured',
    'description': 'Description'
  },
  'smsHistory': {
    'title': 'SMS Sent Historical',
    'search': 'Phone number...',
    'toPhone': 'Sent to number',
    'response': 'Agent response code',
    'smsid': 'Agent SMSID',
    'dateCreated': 'Date sent'
  },
  'validation': {
    'invalidEmail': 'Invalid email format',
    'invalidPhoneNumber': 'Invalid phone number format',
    'invalidPhoneOrEmail': 'Invalid phone or email format',
    'invalidURL': 'Invalid URL format',
    'invalidUsername': 'User name must have at least 2 words',
    'invalidCodeName': 'Only accept printable charactors',
    'passwordDoesNotMatch': 'Password does not match',
    'passwordNotValidCharactor': 'Password containts forbidden charactor',
    'passwordLengthTooShort': 'Password length must be greater than {min}',
    'passwordMustContainsOneDigit': 'Password must have at least one digit',
    'passwordMustContainsOneLowerCase': 'Password must have at least one lowercase',
    'passwordMustContainsOneUpperCase': 'Password must have at least one uppercase',
    'passwordMustContainsOneSymbols': 'Password must have at least one of symbol \'@#$%\'',
    'valueMustBeGreaterThan': 'Value must be greater than {value}',
    'valueMustBeGreaterThanOrEqual': 'Value must be greater than or equal {value}',
    'valueMustBeLessThan': 'Value must be less than {value}',
    'valueMustBeLessThanOrEqual': 'Value must be less than or equal {value}',
    'ruleLengthMustLessThan': 'Input length must be less than or equal {value}',
    'requiredField': 'This field is required!'
  }
}
