<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="400px">
    <v-card>
      <material-card
        :title="isNull(roleId) ? $t('roles.addNewRole') : $t('roles.updateRole')"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-model="roleName"
                :label="$t('roles.roleName')"
                class="purple-input"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
export default {
  data () {
    return {
      isShowModal: false,
      roleName: null,
      roleId: null
    }
  },
  methods: {
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (role) {
      this.roleId = role && role.id
      this.roleName = role && role.roleName
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.roleName = null
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (!functionUtils.isNull(this.roleId)) {
        this.$emit('onUpdate', this.roleId, this.roleName)
      } else {
        this.$emit('onAdd', this.roleName)
      }
      this.isShowModal = false
      this.roleName = null
      this.roleId = null
    }
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
</style>
