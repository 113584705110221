import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  'common': {
    'termsCondition': 'Chính sách Giải quyết Khiếu nại',
    'privacyPolicy': 'Chính sách bảo mật',
    'close': 'Đóng',
    'networkError': 'Lỗi Kết Nối Mạng',
    'success': 'Thành Công',
    'unsuccess': 'Không thành công',
    'actions': 'Công Cụ',
    'confirmDelete': 'Xác Nhận Xoá',
    'disagree': 'Huỷ',
    'agree': 'Đồng Ý',
    'save': 'Lưu',
    'loading': 'Đang Tải...',
    'search': 'Tìm kiếm',
    'searchData': 'Tên, email, số điện thoại, mã...',
    'noDataAvailable': 'Không có dữ liệu',
    'copyright': 'Được đăng ký bản quyền bởi {0} © 2020.',
    'select_lang': 'VI',
    'id': 'Mã',
    'nonumber': 'STT',
    'code': 'Mã',
    'modify': 'Sửa',
    'delete': 'Xóa',
    'viewDetail': 'Xem chi tiết',
    'preview': 'Xem Trước',
    'historyaction': 'Hành Động',
    'add': 'Thêm Mới',
    'clone': 'Nhân bản',
    'cancel': 'Hủy',
    'printPdf': 'In PDF',
    'moreinfo': 'Thêm Thông Tin',
    'dropdown': 'Xổ Xuống',
    'edit': 'Chỉnh Sửa',
    'addnew': 'Thêm mới',
    'yes': 'Đồng Ý',
    'no': 'Không',
    'info': 'Thông Tin',
    'status': 'Tình Trạng',
    'note': 'Ghi Chú',
    'submit': 'Đồng Ý',
    'confirm': 'Xác Nhận',
    'app': 'Ứng Dụng',
    'by': 'Bởi',
    'datetime': 'Ngày Giờ',
    'datefrom': 'Từ Ngày',
    'dateto': 'Đến Ngày',
    'update': 'Chỉnh Sửa',
    'phone': 'Số Điện Thoại',
    'email': 'Email',
    'username': 'Tên Người Dùng',
    'fullname': 'Họ Tên',
    'error_msg': [{ 'required': 'Thông Tin Bắt Buộc!' }],
    'error': 'Lỗi',
    'sign_sms': 'Đăng Nhập Để Sử Dụng',
    'user': 'Tên Đăng Nhập',
    'password': 'Mật Khẩu',
    'sign_in': 'Đăng Nhập',
    'history': 'Lịch Sử',
    'comment': 'Bình Luận',
    'name': 'Tên',
    'logOut': 'Đăng xuất',
    'darkMode': 'Chế độ tối',
    'register': 'Đăng ký',
    'statusType': {
      'valid': 'Có hiệu lực',
      'invalid': 'Vô hiệu lực'
    },
    'copied': 'Đã chép nội dug vào clipboard',
    'logo': 'Ảnh đại diện',
    'notification': 'Thông báo !',
    'restore': 'Cài lại mặc định',
    'state': 'Trạng thái',
    'reset': 'Làm lại',
    'detail': 'Chi tiết',
    'bulk': 'Chức năng nhóm',
    'permissionDenied': 'Bạn không có quyền truy cập',
    'continue': 'Tiếp tục',
    'downloadTemplate': 'Tải file mẫu',
    'role': 'Vai trò',
    'goTo': 'Đi tới',
    'from': 'Từ',
    'to': 'Đến',
    'resetPassword': 'Lấy lại mật khẩu',
    'connect': 'Kết nối',
    'insert': 'Chèn',
    'image': 'Hình ảnh',
    'addInto': 'Thêm vào',
    'emailOrPhone': 'Email hoặc số điện thoại',
    'website': 'Website',
    'numOfUsersExceedsTheAllowed': 'Số lượng người dùng vượt quá cho phép',
    'changePassword': 'Đổi mật khẩu',
    'recommendRatio': 'Tỷ lệ đề xuất',
    'expectationImageRatio': 'Tỷ lệ ảnh yêu cầu',
    'maxSizeUpload': 'kích thước tối đa',
    'recommendImageDimension': 'đề xuất kích thước ảnh',
    'pixels': 'Điểm ảnh',
    'imageCannotExceed10MB': 'Kích thước hình ảnh không được vượt quá 10MB',
    'imageCannotExceed3MB': 'Kích thước hình ảnh không được vượt quá 3MB',
    'back': 'Trở lại',
    'selectAll': 'Chọn tất cả',
    'projectList': 'Danh sách dự án',
    'noSelectedProject': 'Chưa chọn dự án',
    'loginWithGoogle': 'Đăng nhập với Google',
    'googleAccountLink': 'Liên kết Google',
    'facebookAccountLink': 'Liên kết Facebook',
    'loginWithFacebook': 'Đăng nhập với Facebook',
    'groupName': 'Tên nhóm',
    'refresh': 'Làm mới',
    'approval': 'Duyệt'
  },
  'dashboard': {
    'title': 'Bảng Điều Khiển',
    'voucherPublished': 'Voucher đã phát hành',
    'numberOfVoucherPublished': 'Số lượng Voucher đã phát hành',
    'voucherReceived': 'Voucher được cấp',
    'numberOfVoucherReceived': 'Số lượng Voucher được cấp',
    'voucherUnreleased': 'Voucher chưa phân phối',
    'numberOfVoucherUnreleased': 'Số lượng Voucher chưa phân phối',
    'voucherReleased': 'Voucher đã phân phối',
    'numberOfVoucherReleased': 'Số lượng Voucher đã phân phối',
    'voucherDelivered': 'Voucher khách hàng đã nhận',
    'numberOfVoucherDelivered': 'Số lượng Voucher khách hàng đã nhận',
    'voucherUsed': 'Voucher đã sử dụng',
    'numberOfVoucherUsed': 'Số lượng Voucher đã sử dụng',
    'voucherRequestList': 'Danh Sách Yêu Cầu ',
    'received': 'Đã nhận',
    'sent': 'Đã gửi'
  },
  'productList': 'Danh Sách Sản Phẩm',
  'localize': {
    'english': 'Tiếng Anh',
    'vietnamese': 'Tiếng Việt'
  },
  'sideBar': {
    'area': 'Khu Vực',
    'user': 'Người Dùng',
    'training': 'Khoá Đào Tạo',
    'setting': 'Cài Đặt Hệ Thống',
    'productList': 'Danh Sách Sản Phẩm',
    'staffs': 'Nhân Viên',
    'courses': 'Khoá Học',
    'documents': 'Tài Liệu',
    'surveys': 'Câu Hỏi Kiểm Tra',
    'certificates': 'Chứng Nhận',
    'decentralization': 'Phân quyền',
    'mobileVersion': 'Mobile Version',
    'account': 'Tài Khoản',
    'document': 'Tài Liệu',
    'systemSettings': {
      'title': 'Cài đặt hệ thống',
      'mobile': 'Phiên Bản Di Động',
      'authorization': 'Phân quyền',
      'sponsor_level': 'Loại Tài Trợ'
    },
    'decentralizationManagement': {
      'modules': {
        'perm_module_supplier': 'Quản lý nhà cung cấp',
        'perm_module_distributor': 'Quản lý đại lý cấp 1'
      },
      'functions': {
        'supplier': 'Nhà cung cấp',
        'distributor': 'Đại lý cấp 1'
      },
      'group_title': 'Nhóm',
      'main_function': 'Chức Năng Chính',
      'function': 'Chức Năng',
      'watch': 'Xem',
      'create': 'Tạo Mới',
      'edit': 'Sửa',
      'delete': 'Xóa',
      'print': 'In',
      'export': 'Xuất',
      'import': 'Nhập',
      'approve': 'Duyệt'
    },
    'roleManagement': 'Quản Lý Phân Quyền Admin',
    'groupManagement': 'Quản Lý Nhóm Quyền',
    'actionManagement': 'Quản Lý Chức Năng',
    'metaManagement': 'Quản Lý Meta',
    'userManagement': 'Quản Lý Người Dùng',
    'supplierManagement': 'Quản Lý Nhà Cung Cấp',
    'agentManagement': 'Quản Lý Đại Lý',
    'voucherManagement': 'Quản Lý Voucher',
    'evoucherIssuance': 'Đợt Phát Hành Voucher',
    'evoucherList': 'Danh Sách Voucher',
    'customerManagement': 'Quản Lý Khách Hàng',
    'roleManagementInProject': 'Quản Lý Phân Quyền',
    'generalConfiguration': 'Cấu Hình Chung',
    'supplierConfiguration': 'Cấu Hình Nhà Cung Cấp',
    'thirdPartiesSettings': 'Cấu Hình Liên Kết Ngoài',
    'configurationProperty': 'Cấu Hình Thuộc Tính',
    'voucherTransferRequestList': 'Yêu Cầu Chuyển Voucher',
    'voucherIssuanceDetail': 'Thông Tin Đợt Phát Hành',
    'detailInformation': 'Thông Tin Chi Tiết',
    'development': 'Nhà Phát Triển',
    'supplierAccessCode': 'Mã Kíck Hoạt Nhà Cung Cấp',
    'siteManagement': 'Địa Điểm Chấp Nhận',
    'configTemplateEmail': 'Cấu Hình Mẫu Email',
    'configurationNotificationTemplate': 'Cấu Hình Mẫu Thông Báo',
    'useVoucherPromotion': 'Voucher Ưu Đãi',
    'advertisementInfo': 'Thông Tin Quảng Cáo',
    'reportStatistic': 'Báo Cáo Thống Kê',
    'hashtagManagement': 'Quản Lý Hashtag',
    'infoWebPortal': 'Thông Tin Web Portal',
    'emailDomain': 'Email Domain',
    'emailDomainActiveCode': 'Email Domain và Mã Kích Hoạt',
    'emailHistory': 'Lịch sử email',
    'smsHistory': 'Lịch sử gởi SMS',
    'voucherProduct': 'Voucher Sản Phẩm',
    'productOrderManagement': 'Quản Lý Đơn Hàng',
    'voucherPrintTemplate': 'Cấu Hình Mẫu In Voucher',
    'footerConfig': 'Cấu Hình Footer',
    'bankTransactionInfo': 'Thông tin giao dich ngân hàng'
  },
  'login': {
    'somethingWentWrong': 'Có Lỗi Xảy Ra !',
    'loginInvalid': 'Tài Khoản Hoặc Mật Khẩu Không Đúng',
    'login': 'Đăng Nhập',
    'username': 'Tên tài khoản',
    'password': 'Mật khẩu',
    'customerCannotLogin': 'Khách hàng thì không thể đăng nhập',
    'inCorrectPassword': 'Mật khẩu không đúng',
    'youHaveNotRegister': 'Bạn chưa đăng ký, vui lòng đăng ký!',
    'userIsDeleted': 'Tài khoản đã bị xóa',
    'accountIsNotActive': 'Tài khoản chưa được kích hoạt',
    'pleaseActivateAccount': 'Vui lòng kích hoạt tài khoản trong email để đăng nhập vào hệ thống',
    'Userisnotactiveordeleted': 'Người dùng không hoạt động hoặc đã bị xóa',
    'forgotPassword': 'Quên mật khẩu?',
    'entityInactive': 'Tài khoản quản lý nhà cung cấp của bạn chưa được kích hoạt hoặc đã bị khóa!',
    'notFoundGoogleAccountLink': 'Không tìm thấy tài khoản được liên kết với tài khoản Google',
    'notFoundFacebookAccountLink': 'Không tìm thấy tài khoản được liên kết với tài khoản Facebook'
  },
  'user': {
    'username': 'Tên Đăng Nhập',
    'fullname': 'Họ và tên',
    'firstname': 'Tên',
    'lastname': 'Họ',
    'email': 'Email',
    'lastlogin': 'Lần Đăng Nhập Cuối',
    'age': 'Tuổi',
    'dob': 'Ngày sinh',
    'phone': 'Điện thoại',
    'gender': 'Giới tính',
    'addnew': 'Thêm Mới',
    'add': 'Thêm',
    'password': 'Mật Khẩu',
    'uppercase': 'Chữ Hoa',
    'lowercase': 'Chữ Thường',
    'digit': 'Số',
    'group': 'Nhóm',
    'title': 'Danh Sách Nhân Viên',
    'title_organizer': 'Danh Sách Nhà Tổ Chức',
    'privilege': 'Phân quyền',
    'country': 'Quốc Gia',
    'is_active': 'Kích Hoạt',
    'activate_account': 'Kích Hoạt Tài Khoản',
    're_send_email': 'Gửi lại thông tin kích hoạt',
    'joined_date': 'Tham Gia',
    'device': 'Số Thiết Bị',
    'device_list': 'Danh Sách Thiết Bị Kết Nối',
    'app_type': 'Loại Thiết Bị',
    'app_type_data': {
      'ios': 'iOS',
      'android': 'Android',
      'other': 'Other'
    },
    'last_access': 'Lần Truy Cập Cuối',
    'last_login': 'Lần Đăng Nhập Cuối',
    'status': 'Trạng Thái',
    'status_data': {
      'active': 'Kích Hoạt',
      'inactive': 'Không Kích Hoạt',
      'expired': 'Hết Hạn',
      'distribution': 'Phân phối',
      'noDistribution': 'Không phân phối'
    },
    'info': 'Thông Tin',
    'profile': 'Thông Tin Nhân Viên',
    'gender_type': ['Nam', 'Nữ', 'Khác'],
    'confirm_pass': {
      'title': 'Xác Nhận Thay Đổi Mật Khẩu',
      'content': 'Bạn có chắc chắn muốn thay đổi mật khẩu?',
      'btnClose': 'Đóng',
      'btnConfirm': 'Xác Nhận'
    },
    'addNewUser': 'Thêm mới người dùng',
    'detailInfo': 'Thông tin người dùng',
    'confirmDeleteUserTitle': 'Bạn có chắc chắn muốn xoá người dùng này?',
    'confirmRemoveUserTitle': 'Bạn có chắc chắn muốn gỡ bỏ người dùng này?',
    'successfulActivation': 'Kích hoạt thành công',
    'failedActivation': 'Kích hoạt thất bại, vui lòng thử lại sau',
    'redirectLoginPage': 'Quay về trang đăng nhập sau 5 giây nữa',
    'activeThisAccount': 'Kích hoạt tài khoản này',
    'address': 'Địa chỉ',
    'resetPassword': {
      'linkNoLongerValid': 'Đường dẫn xác nhận không còn tồn tại.'
    },
    'type': {
      'user': 'Đã đăng ký',
      'ghost': 'Chưa đăng ký'
    }
  },
  'userProfile': {
    'genderType': {
      'other': 'Khác',
      'male': 'Nam',
      'female': 'Nữ'
    },
    'gender': 'Giới tính',
    'birthday': 'Ngày sinh',
    'phone': 'Điện thoại',
    'address': 'Địa chỉ',
    'firstName': 'Tên',
    'lastName': 'Họ',
    'userName': 'Tài khoản',
    'email': 'Email',
    'aboutMe': 'Về tôi',
    'editProfile': 'Chỉnh sửa hồ sơ',
    'completeYourProfile': 'Hoàn thành hồ sơ của bạn',
    'updateProfile': 'Cập Nhật hồ sơ',
    'userProfile': 'Thông tin người dùng',
    'identificationNumber': 'Chứng minh thư',
    'passport': 'Hộ chiếu'
  },
  'decentralizations': {
    'decentralizationModule': 'Phân Quyền Chức Năng',
    'function': 'Chức Năng',
    'create': 'Thêm',
    'view': 'Xem',
    'update': 'Cập Nhật',
    'delete': 'Xoá',
    'functionInModules': {
      'staff': 'Nhân Viên',
      'visitor': 'Khách Tham Quan',
      'courses': 'Khoá Học',
      'surveys': 'Câu Hỏi Kiểm Tra'
    },
    'modules': {
      'users': 'Người Dùng',
      'training': 'Khoá Đào Tạo'
    }
  },
  'documents': {
    'title': 'Danh Sách Tài Liệu',
    'subTitle': 'Tất Cả Tài Liệu Trong Hệ Thống'
  },
  'staffs': {
    'title': 'Danh Sách Nhân Viên',
    'subTitle': 'Tất Cả Nhân Viên Trong Hệ Thống',
    'titleAdd': 'Thêm Nhân Viên',
    'subTitleAdd': 'Thêm Mới Nhân Viên'
  },
  'users': {
    'add': 'Thêm Người Dùng',
    'userList': 'Danh sách người dùng',
    'fullName': 'Họ và tên',
    'phone': 'Số điện thoại',
    'gender': 'Giới tính',
    'username': 'Tên người dùng',
    'userDetail': 'Chi tiết người dùng',
    'assignedRoles': 'Vai trò được giao',
    'availableRoles': 'Vai trò khả dụng',
    'dateofbirth': 'Ngày sinh',
    'email': 'Email',
    'identification': 'Định danh',
    'dateCreated': 'Ngày tạo',
    'extraInfo': 'Thông tin bổ sung',
    'errorText': {
      'indentAlreadyExist': 'Định danh này đã tồn tại',
      'noMoreThan1Role': 'Không được vượt quá 1 vai trò'
    },
    'adminPrivileges': 'Vai trò',
    'userPrivileges': 'Phân quyền',
    'addUserAvailable': 'Thêm người dùng có sẵn',
    'staff': 'Nhân viên',
    'accountant': 'Kế toán',
    'removeUserMessage': 'Bạn chắn chắn muốn loại bỏ nhân viên \'{user}\' khỏi [ {entity} ]?',
    'remove': 'Xóa người dùng',
    'maximumPageSize': 'Kích thước trang tối đa'
  },
  'roles': {
    'role': 'Phân Quyền',
    'roleList': 'Danh sách vai trò',
    'roleName': 'Tên vai trò',
    'roleType': 'Phân loại chức năng',
    'addNewRole': 'Thêm mới vai trò',
    'updateRole': 'Cập nhật vai trò',
    'entityRoleList': 'Danh sách vai trò trong dự án',
    'onShowModalDeleteRoleTitle': 'Bạn có chắc muốn xóa vai trò nay không?',
    'type': {
      'admin': 'Quản Trị',
      'user': 'Người Dùng',
      'manager': 'Quản Lý',
      'staff': 'Nhân Viên',
      'accountant': 'Kế Toán',
      'all': 'Tất cả',
      'supplier': 'Nhà cung cấp',
      'distributor': 'Nhà phân phối',
      'agent': 'Đại lý',
      'site': 'Địa điểm sử dụng',
      'entity': 'Đối tượng',
      'reporter': 'Người Thống Kê'
    }
  },
  'roleaction': {
    'view_dashboard': 'Xem bảng điều khiển',
    'view_issuance': 'Xem danh sách đợt phát hành',
    'create_issuance': 'Tạo đợt phát hành',
    'update_issuance': 'Sửa đợt phát hành',
    'manage_issuance': 'Quản lý đợt phát hành',
    'delete_issuance': 'Xoá đợt phát hành',
    'publish_issuance': 'Phân phối đợt phát hành',
    'issuance_approval': 'Phê duyệt phát hành voucher',
    'view_voucher': 'Xem danh sách voucher',
    'create_voucher': 'Tạo voucher',
    'update_voucher': 'Sửa voucher',
    'delete_voucher': 'Xoá voucher',
    'delivery_voucher': 'Phân phối voucher',
    'manage_voucher': 'Quản lý voucher',
    'export_voucher': 'Xuất excel voucher',
    'confirm_using': 'Xác nhận sử dụng voucher',
    'view_request': 'Xem danh sách đối soát',
    'create_request': 'Tạo yêu cầu đối soát',
    'update_request': 'Cập nhật yêu cầu đối soát',
    'delete_request': 'Xoá yêu cầu đối soát',
    'request_approval': 'Duyệt yêu cầu đối soát',
    'view_user': 'Xem danh sách người dùng',
    'create_user': 'Tạo người dùng',
    'update_user': 'Cập nhật người dùng',
    'manage_user': 'Quản lý người dùng',
    'delete_user': 'Xoá người dùng',
    'view_supplier': 'Xem danh sách nhà cung cấp',
    'update_supplier': 'Cập nhật nhà cung cấp',
    'delete_supplier': 'Xoá nhà cung cấp',
    'manage_supplier': 'Quản lý nhà cung cấp',
    'view_agent': 'Xem danh sách đại lý',
    'create_agent': 'Tạo đại lý',
    'update_agent': 'Cập nhật đại lý',
    'delete_agent': 'Xoá đại lý',
    'manage_agent': 'Quản lý đại lý',
    'view_site': 'Xem địa điểm chấp nhận',
    'create_site': 'Tạo địa điểm chấp nhận',
    'update_site': 'Cập nhật địa điểm chấp nhận',
    'delete_site': 'Xoá địa điểm chấp nhận',
    'manage_site': 'Quản lý điểm chấp nhận',
    'view_history': 'Xem danh sách lịch sử',
    'view_settings': 'Xem cấu hình',
    'update_settings': 'Chỉnh sửa cấu hình',
    'view_report': 'Xem báo cáo',
    'export_report': 'Xuất excel báo cáo'
  },
  'groups': {
    'group': 'Nhóm',
    'title': 'Danh sách nhóm',
    'groupName': 'Tên nhóm',
    'addNewGroup': 'Thêm mới nhóm',
    'updateGroup': 'Cập nhật nhóm',
    'assignedActions': 'Chức năng được gán',
    'availableActions': 'Chức năng khả dụng',
    'type': {
      'system_admin': 'Quản lý',
      'system_supplier': 'Nhà cung cấp',
      'system_distributer': 'Đại lý cấp 1',
      'system_agent': 'Đại lý cấp 2'
    },
    'confirmRestorePermissionTitle': 'Bạn có chắc chắn muốn cài đặt lại mặc định chức năng cho nhóm này?'
  },
  'actions': {
    'actionList': 'Danh sách chức năng',
    'addNewAction': 'Thêm mới chức năng',
    'updateAction': 'Cập nhật chức năng',
    'englishName': 'Tên tiếng Anh',
    'vietnameseName': 'Tên tiếng Việt',
    'meta': 'Meta',
    'name': 'Tên'
  },
  'meta': {
    'metaList': 'Danh sách meta',
    'addNewMeta': 'Thêm mới meta',
    'updateMeta': 'Cập nhật meta'
  },
  'decentralizationGroup': {
    'decentralizationGroupList': 'Danh sách nhóm chức năng',
    'addNewDecentralizationGroup': 'Thêm mới nhóm chức năng',
    'updateDecentralizationGroup': 'Cập nhật nhóm chức năng',
    'functionalList': 'Danh sách chức năng',
    'currentFunctionGroup': 'Nhóm chức năng hiện tại',
    'functionGroupNotYet': 'Nhóm chức năng chưa có',
    'privilege': 'Phân quyền',
    'availableGroups': 'Nhóm khả dụng',
    'assignedGroups': 'Nhóm được gán',
    'onShowModalDeletedecentralizationGroupTitle': 'Bạn có chắc muốn xóa nhóm chức năng này không?'
  },
  'register': {
    'alreadyAccount': 'Bạn đã có tài khoản?',
    'createNewOne': 'Hãy tạo tài khoản mới.',
    'notHaveAccount': 'Bạn chưa có tài khoản?',
    'alreadyInSupplier': 'Bạn đã là thành viên của Nhà cung cấp, không thể đăng ký thêm',
    'supplierEmailAlreadyUsed': 'Email nhà cung cấp đã được sử dụng bởi nhà cung cấp khác',
    'accountAlreadyExist': 'Tài khoản hoặc email đã tồn tại',
    'notSpecialCharacterUsername': 'Tên tài khoản không chứa kí tự đặc biệt',
    'nameMust8CharacterOrMore': 'Tên tài khoản phải từ 8 kí tự trở lên',
    'passwordIncorrect': 'Mật khẩu không khớp',
    'confirmPassword': 'Xác nhận mật khẩu',
    'confirmRegisterUserDeleted': 'Tài khoản này đã bị xoá bạn có muốn khôi phục?',
    'phoneAlreadyExist': 'Số điện thoại đã tồn tại'
  },
  'evoucher': {
    'name': 'Voucher',
    'issuance': {
      'title': 'Danh sách đợt phát hành Voucher',
      'addNewTitle': 'Thêm mới đợt phát hành Voucher',
      'workpiece': 'Đợt phát hành',
      'quantity': 'Số lượng Voucher',
      'status': 'Tình trạng',
      'startDate': 'Ngày bắt đầu',
      'endDate': 'Ngày kết thúc',
      'fromDate': 'Từ ngày',
      'toDate': 'Đến ngày',
      'pricePercent': 'Mệnh giá / Phần trăm',
      'price': 'Mệnh giá',
      'percent': 'Phần trăm',
      'type': 'Loại',
      'code': 'Mã đợt phát hành',
      'supplier': 'Nhà cung cấp',
      'confirmDeleteEvoucherIssuanTitle': 'Bạn có muốn xóa đợt phát hành này?',
      'doYouWantToConfirm': 'Bạn có muốn xác nhận sử dụng voucher này không?',
      'distributeEndDate': 'Ngày hết hạn phát hành',
      'usedExpiredDate': 'Ngày hết hạn sử dụng',
      'day': 'Ngày',
      'delivery': 'Phân phối',
      'errorUpdateIssuance': 'Số lượng voucher không được nhỏ hơn số lượng đã tạo là ',
      'issuanceNotExist': 'Đợt phát hành không tồn tại',
      'total': 'Tổng',
      'quantityCreated': 'Đã tạo',
      'quantityNotCreated': 'Chưa tạo',
      'quantityDistributed': 'Đã phân phối',
      'quantityNotDistributed': 'Chưa phân phối',
      'fromValue': 'Từ',
      'toValue': 'Đến',
      'usedValidWithTypePercent': 'Áp dụng khi sử dụng voucher trong khoảng giá',
      'distributedCannotUpdate': 'Không thể chỉnh sửa đợt phát hành đã phân phối voucher',
      'promotion': 'Ưu đãi',
      'issuanceOrSerialVoucher': 'Mã hoặc tên voucher',
      'numOfUsesPerPerson': 'Số lần sử dụng của mỗi người',
      'customersAreReceived': 'Khách hàng được nhận',
      'enterExcelFileTheCustomerReceives': 'Nhập file excel khách hàng được nhận',
      'clickToUploadUpdateImg': 'Nhấn vào ảnh để thêm hoặc cập nhật',
      'description': 'Mô tả',
      'voucherDescription': 'Mô tả Voucher',
      'conditionsOfUse': 'Điều kiện sử dụng',
      'details': 'Thông tin chi tiết (dùng in voucher)',
      'noSelectedSite': 'Chưa chọn điểm sử dụng',
      'previewPrint': 'Xem trước khi in',
      'previewVoucher': 'Xem trước voucher',
      'productAlbum': 'Album hình ảnh sản phẩm',
      'areYouSureYouWantToReactivateWorkpiece': 'Bạn có chắc muốn kích hoạt lại phôi này?',
      'areYouSureYouWantToDisableWorkpiece': 'Bạn có chắc muốn vô hiệu hoá phôi này?',
      'numberOfWorkpiece': 'Số lượng phôi',
      'numberOfWorkpieceRemaining': 'Số lượng phôi còn lại',
      'distributedToTheAgent': 'Phân phối tới đại lý',
      'distributedToTheCustomer': 'Phân phối tới khách hàng',
      'numberOfUsedVoucher': 'Đã sử dụng',
      'transferable': 'Chuyển nhượng',
      'notTransferable': 'Không thể chuyển nhượng',
      'quantityReceived': 'Số lượng đã nhận',
      'copy': 'Sao chép mã phát hành',
      'extendSelectedVouchers': 'Gian hạn voucher được chọn',
      'extendExpiredDate': 'Gia hạn thời gian sử dụng',
      'extendExpiredTitle': 'Gia hạn thời gian sử dụng voucher',
      'extendDuration': 'Thêm số ngày sử dụng',
      'extendToDate': 'Gia hạn tới ngày',
      'extendDurationDescription': 'Với voucher đã hết hạn sẽ được gia hạn thêm {days} ngày. Với voucher chưa hết hạn, sẽ được cộng thêm {days} ngày vào hạn sử dụng.',
      'extendToDateDescription': 'Chỉ áp dụng với các voucher có hạn sử dụng nhỏ hơn ngày được chọn.',
      'numOfVoucherDistributedToCustomer': 'Số lượng voucher khách hàng đã nhận',
      'numOfVoucherDistributedHasExpired': 'Số lượng voucher hết hạn'
    },
    'customer': 'Khách hàng',
    'list': 'Danh sách Voucher',
    'assignVoucher': 'Phát hành voucher',
    'voucherDistribution': 'Phân phối voucher',
    'addNewTitle': 'Thêm danh sách voucher',
    'remainQuantity': 'Số lượng voucher còn lại',
    'addNewVoucher': 'Thêm voucher',
    'voucherQuantity': 'Số lượng voucher',
    'lengthCode': 'Độ dài mảng',
    'characterStarts': 'Ký tự bắt đầu',
    'characterEnd': 'Ký tự kết thúc',
    'quantity': 'Số lượng',
    'numberOfVouchersAvailable': 'Số lượng khả dụng',
    'quantityToAssign': 'Số lượng cần gán',
    'assigned': 'Đã phân phối',
    'notAssignedYet': 'Chưa phân phối',
    'historyTransaction': 'Lịch sử giao dịch',
    'dateIssued': 'Ngày phát hành',
    'dateDistributed': 'Ngày phân phối',
    'fromEntity': 'Từ',
    'toEntity': 'Đến',
    'deliveryNote': 'Ghi chú',
    'confirmUsedVoucher': 'Xác nhận sử dụng Voucher',
    'manageLockedVouchers': 'Quản lý Vouchers khóa sử dụng',
    'directConfirmation': 'Xác nhận trực tiếp',
    'confirmationFromCustomers': 'Xác nhận từ khách hàng',
    'referenceCode': 'Mã tham chiếu',
    'customerUsed': 'Khách hàng sử dụng',
    'unConfimred': 'Chưa xác nhận',
    'status': {
      'active': 'Đã kích hoạt',
      'inactive': 'Chưa phân phối',
      'new': 'Mới',
      'expired': 'Đã hết hạn',
      'issuing': 'Đang phát hành',
      'assigned': 'Đã phân phối',
      'used': 'Đã sử dụng',
      'notAssignedYet': 'Chưa phân phối',
      'waiting': 'Chờ xác nhận sử dụng',
      'using_expired': 'Hết thời hạn sử dụng',
      'distribution_expired': 'Hết thời hạn phân phối',
      'locked': 'Đã khoá sử dụng',
      'activated': 'Khách hàng đã nhận',
      'reserved_order': 'Đã đặt đặt hàng',
      'voucherDisable': 'Đã vô hiệu'
    },
    'state': {
      'active': 'Đã kích hoạt',
      'inactive': 'Chưa kích hoạt'
    },
    'quantityAssignInvalid': 'Số lượng cần phân phối vượt quá số lượng khả dụng',
    'issued': 'Voucher đã được phân phối',
    'evoucherExists': 'Mã voucher đã tồn tại, vui lòng tạo với mã khác',
    'viewDetail': 'Xem chi tiết',
    'detail': {
      'title': 'Chi tiết voucher',
      'fromMomentOfReceipt': 'kể từ thời điểm nhận voucher',
      'usageSite': 'Địa điểm sử dụng',
      'details': 'Thông tin chi tiết'
    },
    'expiresAfter': 'Hết hạn sau',
    'time': {
      'days': 'Ngày',
      'hours': 'Giờ',
      'minutes': 'Phút',
      'seconds': 'Giây'
    },
    'timeUsingVoucher': 'Áp dụng cho các khách hàng sử dụng',
    'from': 'từ ngày',
    'to': 'đến',
    'discount': 'Chiết khấu',
    'priceDiscount': 'Giá được chiết khấu',
    'discountPrice': 'Giảm',
    'assignFrom': 'Phân phối từ',
    'assignTo': 'Phân phối đến',
    'dateUpdated': 'Ngày cập nhật',
    'canNotHasSpecialCharacter': 'Mã voucher không được chứa ký tự đặc biệt',
    'noCustomerOwnsThisVoucher': 'Voucher chưa được phân phối tới khách hàng',
    'used': 'Voucher đã được sử dụng',
    'history': 'Lịch Sử',
    'voucherHasExpired': 'Voucher đã hết hạn sử dụng',
    'voucherIssueExpired': 'Voucher này đã hết hạn được phân phối',
    'voucherWithDifferentDenominations': 'Không thể phát hành những voucher có mệnh giá hoặc chiết khấu khác nhau',
    'voucherWithDifferentDiscountType': 'Không thể phát hành những voucher khác loại giảm giá',
    'duration': 'Thời hạn sử dụng',
    'batchJob': {
      'title': 'Cấu Hình Công Việc',
      'recoverVoucherExpired': 'Thu hồi voucher đã hết hạn',
      'batchJobs': 'Danh sách công việc',
      'scheduleExecute': 'Lịch thực hiện',
      'scheduleExecuteList': {
        'everyMinute': 'Mỗi phút',
        'every5Minute': '5 phút 1 lần',
        'dailyAtMidnight': 'Hàng ngày lúc nửa đêm (12:00 AM)'
      }
    },
    'invalidActivationCode': 'Mã kích hoạt không đúng',
    'invalidSerialCode': 'Số seri không đúng',
    'activeCode': 'Mã kích hoạt voucher',
    'voucherSerial': 'Số seri',
    'issuedCode': 'Mã phát hành',
    'voucherName': 'Tên Voucher',
    'confirmDeleteVoucherContent': 'Bạn có chắc chắn muốn xoá voucher này?',
    'vouchersNotExistInSystem': 'Có những voucher không tồn tại trong hệ thống',
    'inputExcelToDistribute': 'Phân phối bằng Excel',
    'transferVoucher': 'Chuyển voucher',
    'pay': 'Thanh toán',
    'usedPrice': 'Giá trị sử dụng',
    'discountValuePrice': 'Giá trị được giảm',
    'discountByPrice': 'Giá tiền',
    'discountByPercent': 'Phần trăm',
    'printVoucher': 'In voucher',
    'areYouSureSendThisVoucher': 'Bạn có chắc chắn muốn gửi voucher này?',
    'areYouSureCreateThisVoucher': 'Bạn có chắc muốn tạo voucher?',
    'confirmForceAssign': 'Sẽ bỏ qua thông tin đã nhập của khách hàng này, vì đã tồn tại trong hệ thống. Bạn có muốn tiếp tục?',
    'voucherFromDifferentIssuesCannotBeIssued': 'Không thể phát hành những voucher có mã phôi khác nhau',
    'voucherFromDifferentIssuanceCannotBeIssued': 'Không thể phát hành những voucher thuộc nhà cung cấp khác nhau',
    'voucherFromDifferentPrefixSuffixCannotBeIssued': 'Không thể phát hành những voucher có đợt phát hành khác nhau',
    'voucherIsDeletedOrDisabled': 'Voucher này đã bị xoá hoặc vô hiệu hoá',
    'confirmMultipleVouchers': 'Xác nhận sử dụng Vouchers',
    'addUsingVoucher': 'Thêm voucher xác nhận sử dụng',
    'editReferenceInfo': 'Chỉnh sửa thông tin tham chiếu',
    'addVoucher': 'Thêm voucher',
    'alreadyAdded': 'Voucher đã được thêm vào danh sách',
    'confirmUnlockUsingVouchers': 'Vouchers khóa sử dụng',
    'confirmUsedDirectlyMessage': 'Vui lòng kiểm tra trước khi xác nhận sử dụng trực tiếp vouchers. Sau khi xác nhận, bạn không thể hoàn trả các voucher này cho khách hàng.',
    'confirmLockedUsingMessage': 'Bạn chắc chắn muốn khóa sử dụng các voucher này? Sau khi khóa sử dụng, bạn có thể hoàn trả hoặc xác nhận sử dụng các voucher này về sau.',
    'confirmRefundLockedVouchersMessage': 'Bạn có chắc chắn rằng bạn muốn hoàn trả các Voucher này. Vui lòng kiểm tra kỹ trước khi xác nhận hoàn trả.',
    'confirmUsedLockedVouchersMessage': 'Bạn chắc chắn muốn xác nhận sử dụng các Voucher khóa sử dụng này. Chú ý sau khi xác nhận sử dụng, các voucher này không thể hoàn trả cho khách hàng.',
    'stayDate': 'Ngày lưu trú',
    'arrivalDate': 'Ngày đến',
    'departureDate': 'Ngày đi',
    'voucherStatus': 'Trạng thái',
    'notiConfirmCreateVoucherWithIssuanceExists': 'Đợt phát hành {prefix} - {suffix} đã tồn tại. Vui lòng chọn xác nhận nếu bạn muốn sử dụng đợt phát hành này?',
    'enableVoucher': 'Mở vô hiệu voucher',
    'disableVoucher': 'Vô hiệu voucher',
    'notiEnableDisable': 'Bạn có chắc chắn muốn {action} \'{voucher}\'?',
    'voucherDisable': 'Voucher đã vô hiệu hóa',
    'confirmTransferVoucher': 'Xác nhận chuyển voucher',
    'importVoucher': 'Nhập Vouchers',
    'canNotDistributedVoucher': 'Không thể phân phối voucher {0}'
  },
  'customer': {
    'customerList': 'Danh sách khách hàng',
    'name': 'Tên',
    'code': 'Mã',
    'status': 'Tình trạng',
    'address': 'Địa chỉ',
    'phone': 'Số điện thoại',
    'email': 'Email',
    'allCustomer': 'Tất cả khách hàng',
    'myCustomer': 'Khách hàng của tôi',
    'nameCustomer': 'Tên khách hàng',
    'emailCustomer': 'Email khách hàng',
    'customer': 'Khách hàng',
    'info': 'Thông tin khách hàng',
    'linkEmail': 'Email liên kết'
  },
  'generalConfiguration': {
    'serverSMTP': 'Máy chủ SMTP',
    'smsProvider': 'Nhà cung cấp dịch vụ SMS',
    'domain': 'Tên miền',
    'username': 'Tên đăng nhập',
    'password': 'Mật khẩu',
    'smtpConfigurationInfo': 'Thông tin cấu hình SMTP',
    'name': 'Tên',
    'sender_alias': 'Bí danh',
    'description': 'Mô tả',
    'pageSize': 'Kích thước trang',
    'subContent': 'Nội dung thêm',
    'emailConfiguration': 'Cấu hình email',
    'smtp_port': 'Cổng',
    'emailAccount': 'Tài khoản email',
    'useTLS': 'Sử dụng TLS',
    'sendTestEmail': 'Gởi thử email',
    'sendEmail': 'Gởi email',
    'discount': 'Chiết khấu',
    'activeSupplierCode': 'Mã kíck hoạt nhà cung cấp',
    'companyGeneral': 'Thông Tin Chung',
    'companyInfo': 'Thông Tin Công Ty',
    'companyName': 'Tên công ty',
    'companyAddress': 'Địa chỉ',
    'companyEmail': 'Email',
    'companyHotline': 'Đường dây nóng',
    'companyFacebook': 'Liên kết Facebook',
    'mailGatewayConfig': 'Cấu hình Mail Gateway (tùy chọn để cập nhập trạng thái)',
    'mailGatewayURL': 'Đường dẫn máy chủ',
    'mailGatewayApikey': 'Mã khóa dịch vụ API',
    'configurationDefaultEntityForMobileApp': 'Cấu hình cửa hàng mặc định trên Mobile App',
    'configurationDefaultEntityForLienViet': 'Cấu hình cửa hàng mặc định trên Lien Viet',
    'configurationDefaultEntityForStore': 'Cấu hình cửa hàng mặc định'
  },
  'actionPermission': {
    'create_account': 'Tạo tài khoản',
    'update_account': 'Cập nhật tài khoản',
    'delete_account': 'Xoá tài khoản',
    'view_account': 'Xem danh sách tài khoản',
    'delete_voucher': 'Xoá voucher',
    'update_voucher': 'Cập nhật voucher',
    'create_voucher': 'Tạo voucher',
    'view_voucher': 'Xem voucher',
    'view_user': 'Xem người dùng',
    'add_user': 'Thêm người dùng',
    'update_user': 'Cập nhật người dùng',
    'delete_user': 'Xoá người dùng',
    'add_group': 'Thêm nhóm',
    'view_group': 'Xem danh sách nhóm',
    'update_group': 'Cập nhật nhóm',
    'delete_group': 'Xoá nhóm',
    'view_system_role': 'Xem vai trò trong hệ thống',
    'add_system_role': 'Thêm vài trò trong hệ thống',
    'update_system_role': 'Cập nhật vai trò trong hệ thống',
    'delete_system_role': 'Xoá vai trò trong hệ thống',
    'view_supplier': 'Xem danh sách nhà cung cấp',
    'add_supplier': 'Thêm mới nhà cung cấp',
    'update_supplier': 'Cập nhật nhà cung cấp',
    'delete_supplier': 'Xoá nhà cung cấp',
    'view_distributer': 'Xem danh sách đại lý cấp 1',
    'add_distributer': 'Thêm mới đại lý cấp 1',
    'update_distributer': 'Cập nhật đại lý cấp 1',
    'delete_distributer': 'Xoá đại lý cấp 1',
    'view_agent': 'Xem đại lý cấp 2',
    'add_agent': 'Thêm đại lý cấp 2',
    'update_agent': 'Cập nhật đại lý cấp 2',
    'delete_agent': 'Xoá đại lý cấp 2',
    'create_voucher_issuance': 'Tạo đợt phát hành voucher',
    'generate_voucher': 'Tạo voucher',
    'assign_voucher_to_distributer': 'Phát hành voucher tới đại lý cấp 1',
    'report_status_voucher': 'Báo cáo trạng thái voucher',
    'assign_voucher_to_agent': 'Phát hành voucher tới đại lý',
    'create_child_agent': 'Tạo đại lý con',
    'update_used_voucher': 'Cập nhật voucher thành đã sử dụng'
  },
  'issueVoucherRequest': {
    'requestTitle': 'Gửi yêu cầu',
    'deleteTitle': 'Bạn có chắc chắn muốn xoá yêu cầu này?',
    'sendRequestToParenTitle': 'Gửi yêu cầu tới cấp trên',
    'title': 'Tiêu đề',
    'parent': 'Cấp trên',
    'quantity': 'Số lượng',
    'listOfRequestSent': 'Danh sách yêu cầu đã gửi',
    'requestListReceived': 'Danh sách yêu cầu đã nhận',
    'approve': 'Duyệt',
    'cancel': 'Huỷ',
    'updateRequestTitle': 'Cập nhật yêu cầu',
    'initial': 'Chờ phê duyệt',
    'approved': 'Đã duyệt',
    'canceled': 'Đã huỷ',
    'stop': 'Dừng',
    'dateRequest': 'Ngày gửi',
    'approveTitle': 'Bạn có chắc chắn muốn duyệt yêu cầu này?',
    'cancelTitle': 'Bạn có chắc chắn muốn huỷ yêu cầu này?',
    'stopTitle': 'Bạn có chắc chắn muốn dừng yêu cầu này?'
  },
  'supplier': {
    'supplierList': 'Danh sách nhà cung cấp',
    'addNewSupplier': 'Thêm mới nhà cung cấp',
    'updateSupplier': 'Cập nhật nhà cung cấp',
    'name': 'Tên',
    'code': 'Mã',
    'status': 'Tình trạng',
    'address': 'Địa chỉ',
    'phone': 'Số điện thoại',
    'email': 'Email',
    'confirmDeleteSupplierTitle': 'Bạn có chắc muốn xóa nhà cung cấp \'{name}\'?',
    'activeSupplier': 'Kích hoạt',
    'confirmActiveSuppilerTitle': 'Bạn có chắc muốn kích hoạt nhà cung cấp \'{name}\'?'
  },
  'agent': {
    'agentList': 'Danh sách đại lý',
    'addNewAgent': 'Thêm mới đại lý',
    'updateAgent': 'Cập nhật đại lý',
    'relatedAgents': 'Đại lý cấp 1',
    'name': 'Tên',
    'code': 'Mã',
    'status': 'Tình trạng',
    'address': 'Địa chỉ',
    'phone': 'Số điện thoại',
    'email': 'Email',
    'confirmDeleteAgentTitle': 'Bạn có chắc muốn xóa đại lý này?',
    'notUnderYourSubordinateLevel': 'Đại lý này không thuộc cấp con của bạn'
  },
  'siteManagement': {
    'list': 'Danh sách địa điểm sử dụng',
    'addTitle': 'Thêm mới địa điểm sử dụng',
    'updateTitle': 'Cập nhật địa điểm sử dụng',
    'chooseSite': 'Địa điểm sử dụng',
    'infoTitle': 'Thông tin địa điểm sử dụng',
    'longitude': 'Kinh độ',
    'latitude': 'Vĩ độ',
    'province': 'Tỉnh / Thành Phố',
    'district': 'Quận / Huyện',
    'ward': 'Phường / Xã',
    'address': 'Địa chỉ',
    'addressTitle': 'Chỉnh sửa địa chỉ',
    'openGoogleMap': 'Kiểm tra tọa độ trên bản đồ Google'
  },
  'entity': {
    'state': {
      'active': 'Kích hoạt',
      'inactive': 'Chưa kích hoạt',
      'activated': 'Hoạt động',
      'expired': 'Đã khóa',
      'almostExpired': 'Khóa trong {days} ngày'
    },
    'configuration': 'Thông tin {type} [ {name} ]',
    'supplier': 'Nhà cung cấp',
    'suppliers': 'Nhà cung cấp',
    'agent': 'Đại lý',
    'agents': 'Đại lý',
    'site': 'Địa điểm sử dụng',
    'sites': 'Địa điểm sử dụng',
    'assignedTitle': '{name} được giao',
    'availableTitle': '{name} khả dụng',
    'emailAlreadyExist': 'Email đã tồn tại !',
    'codeAlreadyExist': 'Mã đã tồn tại !',
    'emailIsNotCustomer': 'Email này không phải là khách hàng',
    'name': 'Tên',
    'phone': 'Số điện thoại',
    'email': 'Email',
    'code': 'Mã ',
    'status': 'Trạng thái',
    'avatar': 'Ảnh đại diện',
    'supplierInfo': 'Thông tin nhà cung cấp',
    'agentInfo': 'Thông tin đại lý',
    'numberOfEntity': 'Số lượng nhà cung cấp, đại lý',
    'inEntities': 'Nhà cung cấp, đại lý',
    'delete': {
      'cannotDeleteSupplierExistAgent': 'Không thể xoá nhà cung cấp đã tồn tại đại lý uỷ quyền'
    },
    'needForcedToCreate': 'Đại lý này đã bị xóa bạn có muốn khôi phục?',
    'disabled': 'Vô hiệu hoá',
    'updateActivation': 'Thời hạn kích hoạt',
    'numberOfUsers': 'Số người dùng',
    'areYouSureToDisableThisSupplier': 'Bạn có chắc chắn muốn vô hiệu hoá nhà cung cấp \'{name}\'?',
    'areYouSureToDisableThisAgent': 'Bạn có chắc chắn muốn vô hiệu hoá đại lý \'{name}\'?',
    'numOfUsersInSupplier': 'Số lượng user được phép trong nhà cung cấp',
    'numberOfVoucherCreateEach': 'Số lượng voucher tối đa trong một lần tạo',
    'numberOfVoucherPerMonth': 'Số lượng voucher tối đa được tạo trong một tháng',
    'numberOfVoucherCreated': 'Tổng Số lượng voucher tối đa được phép tạo',
    'numberOfSmsSentInAMonth': 'Số lượng sms được gửi trong một tháng',
    'numberOfAgentCreated': 'Số lượng đại lý tối đa',
    'numberOfSiteCreated': 'Số lượng địa điểm sử dụng tối đa',
    'create': {
      'reachedMaximumAgentAllow': 'Số lượng đại lý của bạn đã tối đa'
    },
    'header': {
      'name': 'Tên',
      'type': 'Loại',
      'code': 'Mã',
      'role': 'Vai trò',
      'status': 'Trạng Thái'
    },
    'info': 'Thông Tin Chung',
    'configStoreChannel': 'Kênh Bán Hàng',
    'configLimitation': 'Cấu Hình NCC',
    'enableStoreChannel': 'Mở {channel}',
    'storeChannelList': 'Danh sách kênh bán hàng',
    'paymentConfirm': 'Liên kết Xác Nhận Thanh Toán',
    'createOrder': 'Liên kết Tạo Đơn Hàng',
    'copyUrl': 'Sao chép đường dẫn',
    'copied': 'Đã sao chép',
    'button': {
      'createNew': 'Thêm mới',
      'updateInfo': 'Cập nhập thông tin',
      'updateConfig': 'Lưu cấu hình'
    },
    'requiredCreationApproval': 'Gởi yêu cầu phê duyệt khi tạo voucher',
    'allowSMSDelivery': 'Mở tính năng gởi SMS Brandname',
    'deliveryNotificationEmail': 'Email bcc khi phân phối voucher tới khách hàng',
    'managerNotificationEmail': 'Email thông báo vận hành voucher',
    'notiApproveVoucherCreation': 'Bạn chắc chắn phê duyệt phát hành voucher này?'
  },
  'userEntity': {
    'entityIsExistWithRoleIsParentOrChildren': 'Đơn vị này đã tồn tại trong người dùng với vai trò là cấp trên hoặc dưới',
    'codeOrName': 'Mã hoặc tên'
  },
  'rangeCalendar': {
    'today': 'Hôm nay',
    'yesterday': 'Hôm qua',
    'last7Days': '7 ngày trước',
    'lastWeek': 'Tuần trước',
    'last30Days': '30 ngày trước',
    'lastMonth': 'Tháng trước',
    'currentMonth': 'Tháng này'
  },
  'voucherReport': {
    'title': 'Báo Cáo Thống Kê Voucher',
    'period': 'Khoảng thời gian',
    'used': 'Đã sử dụng',
    'notUseYet': 'Chưa sử dụng',
    'exportExcel': 'Xuất excel',
    'statisticalTables': 'Bảng thống kê',
    'selectColumn': 'Chọn cột',
    'others': 'khác',
    'statisticalCharts': 'Biểu đồ thống kê'
  },
  'forgotPassword': {
    'send': 'Gửi',
    'email': 'Email',
    'back': 'Trở lại đăng nhập',
    'newPassword': 'Mật khẩu mới',
    'confirmPassword': 'Xác nhận mật khẩu',
    'pleaseCheckEmailToChangePassword': 'Vui lòng kiểm tra email để lấy lại mật khẩu !',
    'confirmResetPassword': 'Bạn có chắc muốn lấy lại mật khẩu cho người dùng này?'
  },
  'voucherHistory': {
    'implementAgencies': 'Đơn vị thực hiện',
    'peopleAction': 'Người thực hiện',
    'receivingAgencies': 'Đơn vị nhận',
    'voucherUsageHistory': 'Lịch sử sử dụng voucher',
    'dateAction': 'Ngày thực hiện',
    'action': 'Hành động',
    'customer': 'Khách hàng',
    'receivingCustomer': 'Khách hàng nhận',
    'type': {
      'create': 'Tạo voucher',
      'distribute': 'Phân phối tới Đại lý',
      'assigned': 'Phân phối tới khách hàng',
      'used': 'Xác nhận sử dụng trực tiếp voucher',
      'sendConfirmUsedRequest': 'Gửi yêu cầu sử dụng voucher tới khách hàng',
      'customerConfirmUsed': 'Khách hàng xác nhận sử dụng voucher',
      'customerCancelUsed': 'Khách hàng từ chối sử dụng voucher',
      'customerReturnUsed': 'Khách hàng hoàn trả voucher',
      'deleted': 'Xoá voucher',
      'usedFromThirdParties': 'Sử dụng từ bên thứ ba',
      'customerDeliveryVoucher': 'Khách hàng chuyển voucher',
      'usePromotionVoucher': 'Sử dụng voucher khuyến mãi',
      'createPromotionVoucher': 'Tạo voucher khuyến mãi',
      'approveReconciliation': 'Duyệt đối soát',
      'cancelReconciliation': 'Huỷ đối soát',
      'lockedVoucher': 'Khóa sử dụng voucher',
      'confirmUseLockedVoucher': 'Sử dụng voucher khóa sử dụng',
      'refundVoucher': 'Hoàn trả voucher khóa sử dụng',
      'adminSystemDeliveryVoucher': 'Chuyển voucher',
      'enableVoucher': 'Mở vô hiệu voucher',
      'disableVoucher': 'Vô hiệu voucher',
      'orderVoucher': 'Đặt hàng voucher',
      'cancelOrderVoucher': 'Hủy đặt hàng voucher',
      'confirmOrderVoucher': 'Xác nhân đặt hàng',
      'publishedStoreChannel': 'Đưa lên kênh bán hàng',
      'takebackStoreChannel': 'Gỡ khỏi kênh bán hàng',
      'purchaseOrder': 'Thanh toán đơn hàng',
      'cancelOrder': 'Hủy đơn hàng',
      'extendExpiration': 'Gia hạn thời gian sử dụng'
    },
    'description': {
      'undefined': 'Chưa xác định',
      'create': '[{entity}] phát hành {amount} vouchers',
      'distribute': '[{entity}] phân phối {amount} vouchers tới [{toEntity}]',
      'assigned': '[{entity}] phân phối {amount} voucher tới khách hàng [{customer}]',
      'used': '[{entity}] xác nhận sử dụng voucher của khách hàng [{customer}]',
      'sendConfirmUsedRequest': '[{entity}] gửi yêu cầu xác nhận sử dụng voucher tới khách hàng [{customer}]',
      'customerConfirmUsed': 'Khách hàng [{customer}] xác nhận sử dụng voucher qua email được gửi từ [{entity}]',
      'customerCancelUsed': '',
      'customerReturnUsed': '',
      'deleted': '[{entity}] xoá voucher',
      'usedFromThirdParties': '[{entity}] xác nhận sử dụng voucher của khách hàng [{customer}]',
      'customerDeliveryVoucher': 'Khách hàng [{owner}] cho/tặng voucher tới khách hàng [{customer}]',
      'usePromotionVoucher': '[{entity}] xác nhận sử dụng voucher khuyến mãi cho khách hàng [{customer}]',
      'createPromotionVoucher': '[{entity}] phát hành voucher ưu đãi',
      'approveReconciliation': '[{entity}] duyệt đối soát của [{requestEntity}]',
      'cancelReconciliation': '[{entity}] huỷ đối soát của [{requestEntity}]',
      'lockedVoucher': '[{entity}] khóa sử dụng voucher của khách hàng [{customer}]',
      'confirmUseLockedVoucher': '[{entity}] xác nhận sử dụng voucher khóa sử dụng',
      'refundVoucher': '[{entity}] hoàn trả voucher khóa sử dụng',
      'adminSystemDeliveryVoucher': 'Voucher được chuyển tới khách hàng [{customer}]',
      'enableVoucher': '[{entity}] hoàn trả voucher vô hiệu hóa',
      'disableVoucher': '[{entity}] vô hiệu hóa voucher của khách hàng [{customer}]',
      'orderVoucher': '[{storeChannel}]: voucher được đặt hàng với mã đơn hàng [{code}]',
      'cancelOrderVoucher': 'Đã hủy mã đơn hàng [{code}] từ [{storeChannel}]',
      'confirmOrderVoucher': 'Xác nhận thanh toán mã đơn hàng [{code}] từ [{storeChannel}]',
      'publishedStoreChannel': 'Đưa voucher lên kênh bán hàng [{storeChannel}]',
      'takebackStoreChannel': 'Gỡ voucher khỏi kênh bán hàng [{storeChannel}]',
      'purchaseOrder': 'Thanh toán mã đơn hàng [{code}] từ [{storeChannel}]',
      'cancelOrder': 'Đã hủy mã đơn hàng [{code}] từ [{storeChannel}]',
      'extendExpiration': 'Gian hạn thời gian sử dụng voucher'
    }
  },
  'notification': {
    'new': 'Mới',
    'type': 'Loại thông báo',
    'categories': 'Danh mục mẫu thông báo',
    'categoriesList': 'Danh sách danh mục mẫu thông báo',
    'templateNotify': 'Danh sách mẫu thông báo',
    'createTemplateNotify': 'Tạo mẫu thông báo',
    'updateTemplateNotify': 'Cập nhật mẫu thông báo',
    'templateNotifyInfo': 'Thông tin',
    'contentTemplateNotify': 'Nội dung',
    'modal': {
      'confirmDeleteTitle': 'Bạn có chắc chắn muốn xoá mẫu thông báo này?'
    }
  },
  'infoVoucher': {
    'discountInformationFromNCC': 'Thông tin chiết khấu từ nhà cung cấp',
    'voucherInformation': 'Thông tin voucher',
    'unitPrice': 'Mệnh giá',
    'discountInfoForDLC1': 'Thông tin chiết khấu cho đại lý cấp 1',
    'priceOfMoneyReceivedAfterDiscount': 'Giá tiền nhận được sau chiết khấu',
    'priceAfterDiscount': 'Giá tiền sau chiết khấu',
    'discountInfoForDLC2': 'Thông tin chiết khấu cho đại lý cấp 2',
    'discountInformationFromDLC1': 'Thông tin chiết khấu từ đại lý cấp 1',
    'discountInformationFromParent': 'Thông tin chiết khấu từ cấp cha',
    'list': 'Danh sách thông tin voucher',
    'resendVoucherInfo': 'Gửi lại thông tin voucher',
    'searchForVoucherInfo': 'Thông Tin Voucher',
    'discountInfoForChildren': 'Thông tin chiết khấu cho đại lý cấp dưới'
  },
  'distributeExcelMode': {
    'dragAndDropHere': 'Kéo thả vào đây',
    'chooseFileExcel': 'Chọn file excel',
    'excelMode': 'Chế độ excel',
    'preview': 'Xem trước',
    'buggyReleaseYouWantedContinue': 'Tồn tại đợt phát hành bị lỗi bạn có muốn tiếp tục?',
    'noticeReleaseContinue': 'Nếu bạn tiếp tục thì những voucher bị lỗi sẽ không được gửi đi',
    'notice': '*Lưu ý',
    'willIgnoreInfo': 'Sẽ bỏ qua thông tin',
    'becauseAlreadyExistInSystem': 'khách hàng vì đã tồn tại trong hệ thống',
    'willNotSendTo': 'Sẽ không gửi tin nhắn tới',
    'becauseNumberSMSHasExceededAllowedNumber': 'Vì số lần gửi sms của nhà cung cấp đã vượt quá số lần cho phép',
    'usingSMS': 'Gởi SMS'
  },
  'voucherTransferRequest': {
    'transferAgent': 'Đại lý chuyển',
    'receivingAgent': 'Đại lý nhận',
    'approvedAgent': 'Đại lý phê duyệt',
    'agentCodeOrEmail': 'Mã đại lý hoặc email'
  },
  'workpieceDetail': {
    'voucherInfo': 'Thông tin voucher',
    'voucherQuantity': 'Số lượng voucher',
    'price': 'Mệnh giá',
    'voucherInfoDistributedToAgent': 'Thông tin voucher phân phối tới đại lý',
    'agentCode': 'Mã đại lý',
    'agentName': 'Tên đại lý',
    'voucherSerial': 'Số seri',
    'totalPrice': 'Tổng tiền',
    'total': 'Tổng',
    'quantityCreated': 'Đã tạo',
    'quantityNotCreated': 'Chưa tạo',
    'quantityDistributed': 'Phân phối',
    'quantityNotDistributed': 'Chưa phân phối',
    'distributedAfterDiscountPrice': 'Phân phối sau CK'
  },
  'createVoucher': {
    'releaseDeadlineToAgent': 'Thời hạn phát hành tới đại lý',
    'issuanceDeadlineToCustomers': 'Thời hạn phát hành tới khách hàng',
    'releaseDeadline': 'Thời hạn phát hành',
    'beforeDate': 'Trước ngày',
    'toCustomer': 'đến khách hàng',
    'afterDistributedCustomers': 'sau khi được phân phối tới khách hàng',
    'issueDateCustomerMustGreaterAgent': 'Ngày phát hành tới khách hàng phải lớn hơn ngày phát hành tới đại lý',
    'expiredDateOfCustomers': 'Thời hạn sử dụng của khách hàng',
    'fromDate': 'Từ ngày',
    'toDate': 'Đến ngày',
    'startDateOfUse': 'Ngày bắt đầu sử dụng',
    'endDateOfUse': 'Ngày hết hạn sử dụng',
    'expiredUsedDateMustGreaterToExpiredToCustomer': 'Ngày hết hạn sử dụng phải lớn hơn hoặc bằng ngày hết hạn phát hành tới khách hàng',
    'keepIssuingVouchersJustCreated': 'Bạn có muốn tiếp tục phân phối những voucher vừa phát hành?',
    'selectPrefixSuffix': 'Chọn kí tự bắt đầu và kết thúc',
    'createMoreVouchers': 'Tạo thêm voucher',
    'addAndRelease': 'Phát hành và phân phối',
    'prepaid': 'Trả trước',
    'unpaid': 'Trả sau',
    'receivedDate': 'Ngày nhận',
    'expiredDate': 'Hạn sử dụng',
    'effectiveDateOfUse': 'Ngày hiệu lực sử dụng',
    'availableUsingDuration': 'Thời gian sử dụng khả dụng'
  },
  'voucherValidate': {
    'hasNotBeenUsedYet': 'Voucher chưa tới ngày sử dụng',
    'cannotBeUsed': 'Không thể sử dụng voucher này!'
  },
  'development': {
    'accessCode': {
      'title': 'Khóa truy cập',
      'seriCode': 'Số seri',
      'activeCode': 'Mã kích hoạt',
      'usedPrice': 'Giá trị sử dụng'
    },
    'verifyVoucher': 'Kiểm tra e-voucher',
    'confirmVoucher': 'Xác nhận sử dụng e-voucher',
    'errorNotification': {
      'userDoesNotExist': 'Người dùng không tồn tại',
      'VoucherNotYetTimeToUse': 'Voucher chưa đến thời gian sử dụng',
      'evoucherExpired': 'Voucher đã hết hạn',
      'evoucherUsed': 'Voucher đã sử dụng',
      'notFoundEvoucher': 'Không tìm thấy thông tin voucher',
      'error_used_price': 'Giá trị sử dụng không chính xác',
      'input_used_price': 'Vui lòng nhập giá trị sử dụng'
    },
    'apiUseVoucher': 'Api sử dụng voucher',
    'supplierAccessCode': 'Mã truy cập',
    'confirmResetKeyModal': 'Bạn có chắc chắc muốn đổi mã truy cập API?\nChú ý: mã truy cập cũ sẽ bị vô hiệu, bạn cần phải cập nhập mã truy cập API mới để bảo đảm các kết nối API hiện tại hoạt động đúng.'
  },
  'supplierAccessCode': {
    'title': 'Mã kíck hoạt nhà cung cấp',
    'activeCode': 'Mã kíck hoạt',
    'maxCount': 'Tổng số lượng',
    'count': 'Đã sử dụng',
    'expiredDate': 'Ngày hết hạn',
    'status': 'Trạng thái',
    'statusData': {
      'enable': 'Kích hoạt',
      'disable': 'Không kích hoạt',
      'expired': 'Hết hạn',
      'deleted': 'Xóa'
    },
    'errorExpiredDate': 'Ngày hết hạn lớn hơn thời gian hiện tại',
    'activeCodeExists': 'Mã kích hoạt đã tồn tại',
    'confirmCreateActiveCodeExistTitle': 'Mã kích hoạt đã tồn tại, bạn có muốn tiếp tục thêm mới?'
  },
  'printVoucher': {
    'print': 'In',
    'chooseImage': 'Chọn hình nền',
    'selected': 'In voucher được chọn',
    'auto': 'In hàng loạt',
    'providedBy': 'Cung cấp bởi',
    'customerName': 'Tên khách hàng',
    'wantToPrintRecently': 'Bạn có muốn in những voucher vừa phát hành?',
    'printed': 'Đã in',
    'youSurePrintVoucher': 'Bạn có chắc chắn in những voucher này?',
    'cannotPrintVoucherUnreleasedToCustomer': 'Không thể in những voucher chưa được phát hành tới khách hàng',
    'cannotPrintVoucherUnreleasedToCustomerOrUsed': 'Không thể in những voucher chưa phát hành tới khách hàng hoặc đã được sử dụng',
    'chooseVoucherToBePrinted': 'Vui lòng chọn voucher trong danh sách',
    'cannotPrintVoucherExpiredDate': 'Không thể in những voucher đã hết thời hạn sử dụng',
    'providedByAgentName': `Cung cấp bởi (tên đại lý)`,
    'receivedAndSearch': 'Nhận & tra cứu',
    'use': 'Sử dụng',
    'guideLookup': `Truy cập email để nhận thông tin voucher hoặc truy cập ${FOR_CONASI_TARGET ? 'conasi365.vn' : 'voucher.avatarnext.com'} để tra cứu.`,
    'guideUse': `Khai báo thông tin voucher khi ${FOR_CONASI_TARGET ? 'đặt phòng và nhận phòng tại khách sạn' : 'sử dụng voucher'}.`,
    'sampleTitle': 'Tên voucher, nhiều ký tự sẽ hiển thị trên 2 dòng',
    'descriptionText': 'Nội dung mô tả',
    'termOfConditionsText': 'Nội dung quy định & điều kiện',
    'sampleDetails': 'Thông tin mô tả chi tiết thêm cho voucher, in ở trang thứ 2',
    'actionList': {
      'backgroundImage': 'Hình nền trang in',
      'secondPageBackgroundImage': 'Hình nền trang chi tiết'
    },
    'editFormTitle': 'Chỉnh sửa {0}',
    'imageUrl': 'Đường dẫn hình ảnh',
    'itemName': 'Tên trường',
    'notiRemoveTemplateItem': 'Bạn có chắc chắn muốn xóa trường {0}?',
    'printLayoutEditor': 'Chỉnh sửa trang in \'{0}\'',
    'configDefaultTemplate': 'Cài đặt mẫu mặc định',
    'template': 'Mẫu in',
    'selectPrintTemplate': 'Chọn mẫu in'
  },
  'layoutPrint': {
    'customer': 'Khách hàng',
    'email': 'Email',
    'serial': 'Số seri',
    'providedBy': 'Cung cấp bởi',
    'unitPrice': 'Mệnh giá',
    'expiredDate': 'Hạn sử dụng',
    'description': 'Mô tả',
    'termConditions': 'Quy định & điều kiện',
    'contactInfo': 'Thông tin liên hệ',
    'hotline': 'Điện thoại liên hệ',
    'detailsNote': '<i><b>Lưu ý</b>: Quý khách hàng vui lòng tham khảo mục “Thông tin chi tiết” kèm theo trước khi sử dụng dịch vụ.</i>',
    'detailsPageTitle': 'Thông tin chi tiết'
  },
  'customization': {
    'configOutgoingEmail': 'Cấu hình email gửi đi',
    'projectDefault': 'Dự án mặc định',
    'errorMessage': {
      'projectIdIsNone': 'Vui lòng chọn dự án cần kết nối',
      'notFoundProject': 'Không tìm thấy dự án của bạn',
      'infoConnectIncorrect': 'Thông tin kết nối không chính xác',
      'cannotConnectToServer': 'Không thể kết nối tới máy chủ'
    },
    'usedPrivateSmtp': 'Sử dụng cấu hình SMTP riêng'
  },
  'templateEmail': {
    'title': 'Mẫu email',
    'category': {
      'title': 'Danh mục mẫu email',
      'list': 'Danh sách danh mục mẫu email',
      'table': {
        'headerName': 'Tên danh mục',
        'headerNumberOfTemplate': 'Số lượng mẫu'
      },
      'viewDetail': 'Xem chi tiết'
    },
    'chooseTemplate': 'Mẫu email gởi tới khách hàng',
    'usedThisTemplate': 'Sử dụng mẫu template này',
    'name': 'Tên mẫu',
    'usedDefault': 'Sử dụng mặc định',
    'list': 'Danh sách mẫu email',
    'createNew': 'Tạo mẫu mới',
    'createTemplateEmail': 'Tạo mẫu email',
    'updateTemplateEmail': 'Cập nhật mẫu email',
    'info': 'Thông tin mẫu email',
    'chooseValue': 'Chọn giá trị',
    'copy': 'Sao chép',
    'previewTemplate': 'Xem trước mẫu này',
    'chooseTemplateDefault': 'Chọn mẫu có sẵn',
    'modePreview': 'Chế độ xem trước',
    'chooseThisTemplate': 'Sử dụng mẫu này'
  },
  'assignVoucher': {
    'releaseToCustomer': 'Phát hành tới khách hàng',
    'releaseToAgent': 'Phát hành tới đại lý',
    'toCustomer': 'Tới khách hàng',
    'toAgent': 'Tới đại lý',
    'totalVoucherRelease': 'Tổng số voucher phát hành',
    'selectTheWorkPieceCode': 'Chọn mã phôi',
    'selectVoucher': 'Chọn Voucher',
    'selectAgent': 'Chọn đại lý',
    'chooseIssuance': 'Chọn đợt phát hành',
    'totalAmountOfDiscount': 'Tổng số tiền chiết khấu',
    'discountedFaceValue': 'Mệnh giá chiết khấu',
    'vouchers': 'Vouchers',
    'voucher': 'Voucher',
    'discountedFaceValueMustNotGreaterActualValue': 'Mệnh giá chiết khấu không được lớn hơn mệnh giá thực',
    'deliveryNote': 'Ghi chú cho đợt phân phối này'
  },
  'transferVoucherBetweenAgent': {
    'error': {
      'outgoingAgentDoesNotExist': 'Đại lý chuyển đi không tồn tại',
      'agentToTransferNotExist': 'Đại lý cần chuyển không tồn tại',
      'emailOrCodeExistsMoreThan1Property': 'Email hoặc mã tồn tại hơn 1 property',
      'cannotTransferToYourself': 'Không thể chuyển cho chính bạn',
      'emailOrCodeNotHaveAnySuperclassProperty': 'Email hoặc mã không tồn tại property cấp cha nào',
      'thisLevel2AgentNotSameLevelOfParent': 'Đại lý cấp 2 này không cùng cấp cha'
    }
  },
  'promotionVoucher': {
    'hasCreate': 'Tạo voucher ưu đãi',
    'youHaveRunOutOfUsageTimes': 'Quý khách đã hết số lần sử dụng',
    'promotionalVoucherHasExpired': 'Voucher khuyến mãi này đã hết !',
    'remainQuantity': 'Số lượng còn lại',
    'confirmUse': 'Xác nhận sử dụng voucher ưu đãi',
    'doseNotExist': 'Mã voucher ưu đãi này không tồn tại',
    'confirmUsed': 'Bạn có chắc chắn muốn sử dụng voucher ưu đãi này?',
    'type': 'Loại ưu đãi',
    'allocated': 'Định danh',
    'notAllocated': 'Không định danh'
  },
  'advertisementInfo': {
    'title': 'Tiêu đề',
    'description': 'Mô tả',
    'addNewAds': 'Thêm mới thông tin quảng cáo',
    'updateAds': 'Cập nhật thông tin quảng cáo',
    'confirmDeleteAds': 'Bạn có chắc chắn muốn xoá thông tin quảng cáo này?',
    'list': 'Danh sách thông tin quảng cáo',
    'type': {
      'small': 'Banner nhỏ',
      'large': 'Banner lớn',
      'popupLearning': 'Popup learning'
    },
    'status': {
      'prepare': 'Đang chờ',
      'public': 'Kích hoạt',
      'close': 'Đóng',
      'inactive': 'Bỏ kích hoat'
    },
    'confirmModalTitle': {
      'prepare': 'Bạn có muốn bỏ kích hoạt banner này?',
      'active': 'Bạn có muốn kích hoạt banner này?'
    },
    'encouragePhotoRate': {
      'vertical': 'Khuyến khích tỷ lệ ảnh theo chiều dọc',
      'horizontal': 'Khuyến khích tỷ lệ ảnh theo chiều ngang'
    }
  },
  'bannerInfo': {
    'title': 'Tiêu đề',
    'description': 'Mô tả',
    'addNewAds': 'Thêm mới thông tin quảng cáo',
    'updateAds': 'Cập nhật thông tin quảng cáo',
    'confirmDeleteAds': 'Bạn có chắc chắn muốn xoá thông tin quảng cáo này?',
    'list': 'Danh sách thông tin quảng cáo',
    'type': {
      'small': 'Banner nhỏ',
      'large': 'Banner lớn',
      'popupLearning': 'Popup learning'
    },
    'status': {
      'prepare': 'Đang chờ',
      'public': 'Kích hoạt',
      'close': 'Đóng',
      'inactive': 'Bỏ kích hoat'
    },
    'confirmModalTitle': {
      'prepare': 'Bạn có muốn bỏ kích hoạt banner này?',
      'active': 'Bạn có muốn kích hoạt banner này?'
    },
    'encouragePhotoRate': {
      'vertical': 'Khuyến khích tỷ lệ ảnh theo chiều dọc',
      'horizontal': 'Khuyến khích tỷ lệ ảnh theo chiều ngang'
    }
  },
  'report': {
    'active': 'Hoạt động',
    'inactive': 'Không hoạt động',
    'days': 'Ngày',
    'months': 'Tháng',
    'years': 'Năm',
    'voucher': {
      'promotion': 'Voucher ưu đãi',
      'normal': 'Voucher thường'
    },
    'voucherStatistics': 'Thống Kê Voucher',
    'userStatistics': 'Thống Kê Người Dùng',
    'quantityVoucherSupplierToAgent': {
      'workpieceCode': 'Mã phôi',
      'workpiece': 'Phôi',
      'issueNumber': 'Số lượng phát hành'
    },
    'numberOfVouchersIssuedToAgent': 'Số lượng voucher phát hành tới đại lý',
    'numberOfVouchersToBeIssuedToAgentLevel2': 'Số lượng voucher phân phối tới đại lý cấp 2',
    'statisticsNumberOfVouchersIssuedToAgentLevel2': 'Thống kê số lượng voucher phát hành tới đại lý cấp 2',
    'statisticsNumberOfVouchersIssuedToAgent': 'Thống kê số lượng voucher phát hành tới đại lý',
    'quantityVoucherSoldInAgent': {
      'title': 'Số lượng voucher đã bán ở các đại lý cấp 2',
      'list': 'Thống kê số lượng voucher đã bán ở các đại lý cấp 2',
      'numberReleased': 'Số lượng đã phát hành',
      'voucherSold': 'Voucher bán',
      'customerReceived': 'Khách hàng đã nhận',
      'customerHaveUsed': 'Khách hàng sử dụng'
    },
    'quantityVoucherSoldOfStaffInAgent': {
      'title': 'Số lượng voucher nhân viên đại lý bán',
      'list': 'Thống kê số lượng voucher nhân viên đại lý bán'
    },
    'staffName': 'Tên nhân viên',
    'staffEmail': 'Email nhân viên',
    'releaseAgent': 'Đại lý phát hành',
    'receivedAgent': 'Đại lý nhận',
    'releaseSupplier': 'Nhà cung cấp phát hành',
    'detailQuantityVoucherSoldOfStaffInAgent': {
      'title': 'Chi tiết số lượng voucher nhân viên đại lý bán',
      'list': 'Thông kê chi tiết số lượng voucher nhân viên đại lý bán'
    },
    'supplier': {
      'numberVoucherCreated': 'Phát hành Voucher',
      'voucherDistributionStatisticsToAgentsTitle': 'Thống kê phân phối voucher tới đại lý',
      'voucherUsedList': 'Địa điểm chấp nhận',
      'voucherUsedDetail': 'Vouchers đã sử dụng',
      'voucherConfirmationTitle': 'Danh sách địa điểm chấp nhận sử dụng Voucher',
      'voucherUsageTitle': 'Danh sách voucher đã sử dụng',
      'usedSite': 'Địa điểm sử dụng',
      'dateOfUse': 'Ngày sử dụng',
      'activationStaff': 'Nhân viên kích hoạt',
      'seriCode': 'Số Seri',
      'phone': 'SĐT',
      'voucherOwner': 'Chủ sở hữu',
      'voucherOwnerPhone': 'SĐT chủ sở hữu',
      'voucherOwnerEmail': 'Email chủ sở hữu',
      'customerUsedPhone': 'SĐT Khách Hàng sử dụng',
      'customerUsedName': 'Khách hàng sử dụng',
      'customerUsedEmail': 'Email khách hàng sử dụng',
      'numberVoucherCustomerUsed': 'Số lượng voucher khách hàng sử dụng',
      'totalFee': 'Tổng số tiền',
      'issuer': 'Người phát hành',
      'numberOfCustomersReceived': 'Số lượng khách hàng đã nhận',
      'numberOfCustomersUsed': 'Số lượng khách hàng sử dụng',
      'dateAction': 'Ngày thực hiện',
      'implementationStaff': 'Nhân viên thực hiện'
    }
  },
  'evoucherAssign': {
    'error': {
      'disabledEntity': 'Đại lý này đã bị vô hiệu hoá !'
    }
  },
  'hashtag': {
    'customerGroupsAreReceived': 'Nhóm khách hàng được nhận',
    'listOfCustomersInHashtag': 'Danh sách khách hàng trong hashtag',
    'addCustomerToTheHashtag': 'Thêm người dùng vào hashtag',
    'updateHashtag': 'Cập nhật hashtag',
    'addNewHashtag': 'Thêm mới hashtag',
    'hashtagList': 'Danh sách hashtag',
    'userCreated': 'Người tạo',
    'entityCreated': 'Nhà cung cấp tạo',
    'addCustomerToSelected': 'Thêm khách hàng đã chọn',
    'wantToAddCustomersToHashtag': 'Bạn có muốn thêm những khách hàng này vào hashtag?',
    'confirmToDelete': 'Bạn có chắc chắn muốn xoá hashtag này?',
    'isEmpty': 'No customer in this hashtag',
    'distributeVoucher': 'Phân phối voucher tới khách hàng trong hashtag',
    'count': 'Số khách hàng',
    'distributeVoucherToGroup': 'Phân phối voucher tới khách hàng trong #{name}',
    'numberOfMember': 'Số lượng khách hàng trong nhóm:',
    'quantityNotEnough': 'Số lượng voucher không đủ'
  },
  'smsConfig': {
    'info': 'Cấu hình dịch vụ SMS',
    'brandName': 'Tên thương hiệu',
    'tokenKey': 'Khóa API Token',
    'secretKey': 'Mã đăng nhập API'
  },
  'workpiece': {
    'list': 'Danh sách phôi',
    'issuanceList': 'Danh sách phát hành',
    'issuanceInfo': 'Thông tin phát hành',
    'issuanceHistory': 'Lịch sử phân phối',
    'issuance': 'Đợt phát hành',
    'issuedVoucherList': 'Danh sách Voucher đã phát hành',
    'title': 'Phôi Voucher',
    'listVoucher': 'Danh sách phôi Voucher',
    'quantityDistributed': 'Số lượng đã phân phối',
    'name': 'Phôi',
    'quantityDistributedAgent': 'Số lượng phân phối tới đại lý',
    'quantityDistributedCustomer': 'Số lượng phân phối tới khách hàng',
    'addNew': 'Thêm mới phôi voucher',
    'persistentActiveCode': 'Không thay đổi mã kích hoạt',
    'hidePrice': 'Ẩn giá tới khách hàng',
    'unreleasedQuantity': 'Số lượng chưa phát hành',
    'remainQuantityInAgency': 'Số lượng đại lý còn lại'
  },
  'portal': {
    'manageTitle': 'Quản lý thông tin portal',
    'banner': {
      'slider': 'Banner slider',
      'ads': 'Banner giữa',
      'logoAndBgMenu': 'Logo và màu nền menu',
      'adsList': 'Danh sách thông tin banner giữa',
      'sliderList': 'Danh sách thông tin slider banner',
      'update': 'Cập nhật thông tin banner',
      'add': 'Thêm mới thông tin banner',
      'link': 'Liên kết',
      'vertical': 'Banner dọc'
    },
    'display': 'Giao diện',
    'configBackgroundMenu': 'Cấu hình màu nền menu',
    'preview': 'Xem trước',
    'configLogo': 'Cấu hình logo',
    'emailDomain': {
      'activeKey': 'Mã kích hoạt',
      'domainList': 'Danh sách domain',
      'add': 'Tạo mới email domain',
      'update': 'Cập nhật email domain',
      'alreadyExists': 'Domain đã tồn tại',
      'alreadyUsedByAnotherAgent': 'Domain đã được sử dụng bởi đại lý khác',
      'activeKeyIsRequired': 'Mã kích hoạt là bắt buộc',
      'activeKeyAlreadyExist': 'Mã kích hoạt đã tồn tại',
      'domainsUsersAlreadyExistSystem': 'Có những domain / user email đã tồn tại trong hệ thống',
      'ifContinueExistingEmailDomainsUsersNotBeInitialized': 'Nếu tiếp tục thì những domain / user email đã tồn tại sẽ không được khởi tạo',
      'addNewEmailDomain': 'Thêm email domain',
      'addNewUserDomain': 'Thêm user domain',
      'importExcel': 'Nhập excel'
    },
    'emailUser': {
      'add': 'Tạo mới email user',
      'update': 'Cập nhật email user'
    },
    'category': {
      'add': 'Tạo danh mục',
      'update': 'Cập nhật danh mục'
    }
  },
  'voucherProduct': {
    'category': 'Danh mục',
    'categoriesList': 'Danh sách danh mục',
    'productTitle': 'Sản phẩm',
    'bestSelling': 'Sản phẩm bán chạy',
    'productListTitle': 'Danh sách sản phẩm',
    'warehouseStatus': 'Trạng thái kho',
    'putOnSalesChannel': 'Đưa lên kênh bán hàng',
    'warehouse': 'Kho',
    'quantityAvailable': 'Số lượng khả dụng',
    'quantityPutUp': 'Số lượng đưa lên',
    'config': {
      'priceNormal': 'Giá bán thường',
      'pricePromo': 'Giá khuyến mãi',
      'maxNumberOfVouchersPurchasedTime': 'Số voucher được mua tối đa mỗi lần',
      'repeatTime': 'Số lần lặp',
      'byWeek': 'Theo tuần',
      'byMonth': 'Theo tháng'
    },
    'productInfo': 'Thông tin sản phẩm',
    'productConfig': 'Cấu hình sản phẩm',
    'displayOnHomeScreen': 'Hiển thị trên màn hình home',
    'quantityToTakeDown': 'Số lượng cần gỡ xuống',
    'takeDownFromSalesChannel': 'Gỡ xuống từ kênh bán hàng',
    'numberOfVouchersRemovedExceedsQuantityPutUp': 'Số lượng voucher cần gỡ vượt quá số lượng đã đưa lên',
    'updateInfo': 'Cập nhật thông tin voucher',
    'removeFromSalesChannel': 'Gỡ xuống từ kênh bán hàng',
    'sold': 'Đã bán',
    'total': 'Tổng sản phẩm',
    'orderPriority': {
      'moveToUp': 'Di chuyển lên',
      'moveToDown': 'Di chuyển xuống',
      'moveToFirst': 'Di chuyển lên đầu',
      'moveToLast': 'Di chuyển xuống cuối'
    },
    'infoFromSupplier': 'Thông tin từ nhà cung cấp',
    'albumImages': 'Album hình ảnh sản phẩm',
    'detail': 'Thông tin chi tiết sản phẩm',
    'infoFromAgent': 'Thông tin từ đại lý',
    'remainItems': '(Còn {count} sản phẩm)',
    'takeDownQuantity': 'Giảm số lượng khỏi kênh bán hàng',
    'removeFromStore': 'Xóa khỏi kênh bán hàng',
    'notiRemoveVoucherFromStore': 'Bạn có chắc chắn muốn xóa voucher {0} khỏi kênh bán hàng {1}?'
  },
  'storeChannel': {
    'title': 'Kênh bán hàng'
  },
  'productOrder': {
    'title': 'Đơn hàng',
    'titleList': 'Danh sách đơn hàng',
    'status': {
      'paid': 'Đã thanh toán',
      'waitForPay': 'Chờ thanh toán',
      'cancel': 'Đơn hàng đã huỷ',
      'processed': 'Đã xử lý',
      'noProcess': 'Chưa xử lý',
      'paymentOverdue': 'Quá thời hạn thanh toán',
      'delivered': 'Đã phân phối'
    },
    'order': 'Đặt hàng',
    'detail': 'chi tiết',
    'code': 'Mã đơn hàng',
    'btn': {
      'orderCancellation': 'Huỷ đơn hàng',
      'orderConfirmation': 'Duyệt đơn hàng'
    },
    'numberPurchaseExceedsAvailableQuantity': 'Số lượng voucher cần mua vượt quá số lượng có sẵn',
    'orderDate': 'Ngày đặt',
    'paidDate': 'Ngày thanh toán',
    'byOrder': 'Theo đơn hàng',
    'byVoucher': 'Theo Voucher',
    'approveSelectedOrders': 'Duyệt đơn hàng được chọn',
    'cannotApprovedOrdersInvalid': 'Không thể duyệt những đơn hàng đã bị huỷ hoặc đã được duyệt',
    'modalConfirmTitle': {
      'approveSingle': 'Bạn có chắc chắn muốn duyệt đơn hàng này?',
      'approveBulk': 'Bạn có chắc chắn muốn duyệt những đơn hàng này?',
      'cancelOrder': 'Bạn có chắc chắn muốn huỷ đơn hàng này?'
    },
    'price': 'Giá tiền',
    'quantity': 'Số lượng',
    'image': 'Hình ảnh',
    'tableList': {
      'header': {
        'orderCode': 'Mã đơn hàng',
        'purchaseDate': 'Ngày mua',
        'product': 'Sản phẩm',
        'totalPrice': 'Tổng tiền',
        'status': 'Trạng thái đơn'
      },
      'status': {
        'paid': 'Đã thanh toán',
        'waitForPay': 'Chờ thanh toán',
        'cancel': 'Thanh toán đã bị huỷ',
        'delivered': 'Đã phân phối'
      }
    },
    'myOrderTitle': 'Đơn hàng của tôi',
    'haveRunOutOfPurchasesThisProduct': 'Bạn đã hết số lần được mua hàng cho sản phẩm này',
    'promotion': {
      'hasExpired': 'Mã khuyến mãi đã hết số lần sử dụng',
      'notExist': 'Mã khuyến mãi không tồn tại',
      'expiredPromotionCode': 'Mã giảm giá hết hạn'
    },
    'outOfStock': 'Hết hàng'
  },
  'footer': {
    'listTitle': 'Danh sách footer',
    'actions': {
      'add': 'Thêm mới footer',
      'update': 'Cập nhật footer',
      'confirmDeleteTitle': 'Bạn có chắc chắn muốn xoá footer này?'
    },
    'title': 'Tiêu đề',
    'content': 'Nội dung'
  },
  'promotionCode': {
    'listTitle': 'Danh sách mã khuyến mãi',
    'usageTimes': 'Số lần sử dụng',
    'usagePerAccount': 'Áp dụng mỗi khách hàng',
    'promotionType': 'Loại khuyến mãi',
    'applyFor': 'Áp dụng cho',
    'reducedRate': 'Mức giảm',
    'startTime': 'Thời gian bắt đầu',
    'endTime': 'Thời gian kết thúc',
    'order': 'Đơn hàng',
    'price': 'Giá tiền',
    'percent': 'Phần trăm',
    'modal': {
      'confirmDeleteTitle': 'Bạn có chắc chắn muốn xoá mã khuyến mãi này?'
    },
    'btn': {
      'create': 'Tạo mã khuyến mãi',
      'update': 'Cập nhật mã khuyến mãi'
    },
    'form': {
      'enterPromoCodeHere': 'Nhập mã khuyến mãi tại đây'
    },
    'decreaseByPrice': 'Giảm theo số tiền',
    'decreaseByPercentage': 'Giảm theo phần trăm',
    'managePromoCodes': 'Quản lý mã khuyến mãi',
    'promotion': 'Khuyến mãi',
    'alreadyExist': 'Mã khuyến mãi đã tồn tại'
  },
  'paymentMethod': {
    'list': 'Danh sách phương thức thanh toán',
    'accountInfo': 'Thông tin tài khoản',
    'setting': 'Cài đặt',
    'title': 'Phương thức thanh toán',
    'turnOn': 'Kích hoạt',
    'transferInfo': 'Thông tin chuyển khoản',
    'addNewAccount': 'Thêm tài khoản',
    'deleteAccount': 'Xoá tài khoản',
    'turnOff': 'Tắt',
    'configPaymentMethod': 'Cấu hình phương thức thanh toán \'{method}\'',
    'bankInfo': {
      'title': 'Thông tin tài khoàn ngân hàng',
      'accountName': 'Tên tài khoản',
      'accountNumber': 'Số tài khoản',
      'bankName': 'Tên ngân hàng',
      'bankBranch': 'Chi nhánh',
      'lowApproximate': 'Ngưỡng dưới',
      'highApproximate': 'Ngưỡng trên'
    },
    'transferInfoDetail': {
      'nameAccount': 'Tên tài khoản',
      'numberAccount': 'Số tài khoản',
      'nameBank': 'Tên ngân hàng',
      'branchBank': 'Chi nhánh'
    },
    'transferContent': 'Nội dung chuyển khoản',
    'discountOnBill': 'Giảm giá trên hóa đơn',
    'discountOnVoucher': 'Giảm giá trên voucher',
    'applicableCode': 'Mã áp dụng'
  },
  'configOrderHoldingTime': {
    'title': 'Cấu hình thời gian',
    'paymentOverdueTime': 'Thời gian quá hạn thanh toán (Giờ)',
    'orderCancellationTime': 'Thời gian huỷ đơn hàng (Giờ)',
    'calculatedTimePaymentOverdue': 'Thời gian hủy đơn hàng được tính từ thời điểm quá hạn thanh toán'
  },
  'importExcelApproveOrder': {
    'thereAreInvalidOrders': 'Tồn tại những đơn hàng không hợp lệ',
    'ifContinueInvalidOrdersNotBeApproved': 'Nếu tiếp tục thì những đơn hàng không hợp lệ sẽ không được duyệt',
    'inputFileExcel': 'Nhập tệp excel'
  },
  'bannerVertical': {
    'position': 'Vị trí',
    'left': 'Bên trái',
    'right': 'Bên phải'
  },
  'cart': {
    'title': 'Giỏ hàng',
    'orderSummary': 'Tóm tắt đơn hàng',
    'noProductInCart': 'Không có sản phẩm trong giỏ hàng !',
    'totalPrice': 'Tổng giá trước thuế',
    'fee': 'Thuế',
    'totalPriceWithFee': 'Tổng giá sau thuế',
    'orderConfirmation': 'Xác nhận đặt hàng',
    'successfullyOrderedVoucher': 'Đặt voucher thành công !',
    'successfullyDeletedVoucher': 'Xoá thành công voucher',
    'outOfCart': 'ra khỏi giỏ hàng',
    'noProductsInCart': 'Không có sản phẩm nào trong giỏ hàng !',
    'voucherInfoSentViaEmail': 'Thông tin voucher được gửi qua email',
    'makeSureEmailIsEnteredCorrectly': 'Hãy chắc chắn rằng email đã nhập đúng',
    'provisional': 'Tạm tính',
    'chooseProduct': 'Chọn sản phẩm',
    'shipmentDetails': 'Thông tin giao hàng',
    'payment': 'Thanh toán',
    'myOrderTitle': 'Đơn hàng của tôi',
    'tableList': {
      'header': {
        'orderCode': 'Mã đơn hàng',
        'purchaseDate': 'Ngày mua',
        'product': 'Sản phẩm',
        'totalPrice': 'Tổng tiền',
        'status': 'Trạng thái đơn'
      },
      'status': {
        'paid': 'Đã thanh toán',
        'waitForPay': 'Chờ thanh toán',
        'cancel': 'Thanh toán đã bị huỷ'
      }
    },
    'detail': {
      'price': 'Giá tiền',
      'quantity': 'Số lượng',
      'order': 'Đặt hàng',
      'detail': 'chi tiết',
      'image': 'Hình ảnh'
    },
    'promotion': {
      'hasExpired': 'Mã khuyến mãi đã hết số lần sử dụng',
      'notExist': 'Mã khuyến mãi không tồn tại',
      'code': 'Mã giảm giá',
      'apply': 'Áp dụng',
      'discount': 'Giảm giá',
      'totalPriceToPay': 'Tổng tiền cần thanh toán'
    },
    'haveRunOutOfPurchasesThisProduct': 'Bạn đã hết số lần được mua hàng cho sản phẩm này',
    'infoAboutCustomersOrderingVouchers': 'Thông tin khách hàng đặt voucher',
    'voucherRecipientInfo': 'Thông tin người nhận voucher',
    'addToCartOverQuantity': 'Số lượng voucher muốn mua nhiều hơn số voucher hiện có trong cửa hàng',
    'paymentAmount': 'Số tiền cần thanh toán',
    'quantityMustGreatThanZero': 'Số lượng sản phẩm phải lớn hơn 0',
    'productCanBuyTimes': 'Sản phẩm còn {0} lượt mua',
    'validProductOnCart': 'Sản phẩm {0} còn {1} lượt mua',
    'promotionCodeAlreadyUsed': 'Mã giảm giá đã được sử dụng'
  },
  'product': {
    'list': 'Danh sách sản phẩm',
    'category': {
      'list': 'Danh mục sản phẩm',
      'all': 'Tất cả'
    },
    'detail': {
      'buy': 'Mua hàng',
      'productInfo': 'Thông tin sản phẩm',
      'info': 'Thông tin',
      'conditionsOfUse': 'Điều kiện sử dụng',
      'locationOfUse': 'Địa điểm sử dụng',
      'remainItems': 'Còn {count} sản phẩm'
    },
    'similar': 'Sản phẩm tương tự',
    'featured': 'Sản phẩm nổi bật',
    'enterInfo': 'Nhập thông tin sản phẩm',
    'filter': {
      'price': 'Lọc theo giá',
      'inOrderOfPrice': 'Theo thứ tự giá',
      'type': {
        'default': 'Thứ tự mặc định',
        'highToLow': 'Theo thứ tự giá: Cao xuống thấp',
        'lowToHigh': 'Theo thứ tự giá: Thấp tới cao'
      },
      'title': 'Lọc'
    }
  },
  'voucherUsed': {
    'list': 'Danh sách voucher đã sử dụng',
    'orderCode': 'Mã hoá đơn',
    'voucherSerial': 'Số seri',
    'voucherName': 'Tên voucher',
    'customerReceived': 'Khách hàng nhận',
    'customerUsed': 'Khách hàng sử dụng',
    'priceUsed': 'Số tiền đã sử dụng',
    'reconciliation': 'Đối soát',
    'price': 'Mệnh giá',
    'supplier': 'Nhà cung cấp',
    'lockUsing': 'Khóa sử dụng',
    'refundVoucher': 'Hoàn trả Vouchers khóa',
    'usedVoucher': 'Xác nhận sử dụng'
  },
  'voucherReconciliation': {
    'list': 'Danh sách chờ đối soát',
    'detail': 'Thông tin đối soát',
    'waitForApproval': 'Đối Soát Voucher',
    'code': 'Mã đối soát',
    'voucherSerial': 'Số seri',
    'createdDate': 'Ngày tạo',
    'submitDate': 'Ngày gửi',
    'usedSite': 'Địa điểm sử dụng',
    'supplier': 'Nhà cung cấp',
    'userCreated': 'Người thực hiện',
    'totalVoucher': 'Tổng số voucher',
    'totalPrice': 'Tổng tiền',
    'comment': 'Phản hồi',
    'detailInfo': {
      'fromSite': 'Gởi từ địa điểm sử dụng',
      'toSupplier': 'Tới nhà cung cấp',
      'userSummitted': 'Tạo bởi nhân viên',
      'userApproval': 'Duyệt bởi nhân viên',
      'dateSummitted': 'Ngày gởi đối soát',
      'dateApproval': 'Ngày duyệt đối soát'
    },
    'status': {
      'title': 'Trạng thái',
      'action': 'Hành động',
      'notSentYet': 'Chưa gửi',
      'waitForApproval': 'Chờ đối soát',
      'notCheckedYet': 'Chưa đối soát',
      'reconciled': 'Đã đối soát',
      'canceled': 'Đã huỷ',
      'deleted': 'Đã xoá',
      'preparing': 'Khởi tạo'
    },
    'voucherUsed': {
      'title': 'Voucher Đã Sử Dụng'
    },
    'history': {
      'title': 'Lịch Sử Đối Soát'
    },
    'action': {
      'create': 'Tạo đối soát',
      'submit': 'Gửi đối soát',
      'approve': 'Duyệt đối soát',
      'cancel': 'Huỷ đối soát',
      'delete': 'Xoá đối soát',
      'view': 'Chi tiết'
    },
    'modal': {
      'confirmCreate': 'Bạn có chắc chắn muốn tạo đối soát những voucher này?'
    },
    'tab': {
      'waitForReview': 'Chờ duyệt',
      'notSubmitYet': 'Chưa gởi',
      'reviewed': 'Đã duyệt'
    },
    'plsSelectVoucherNeedToCheck': 'Vui lòng chọn voucher cần đối soát',
    'approve': 'Duyệt đối soát',
    'cancel': 'Huỷ đối soát',
    'voucherApproved': 'Đã đối soát',
    'voucherWaitForApprove': 'Chờ đối soát',
    'voucherCanceled': 'Huỷ đối soát',
    'approveSelectedVoucher': 'Duyệt voucher đã chọn',
    'cancelSelectedVoucher': 'Huỷ voucher đã chọn',
    'viewHistory': 'Lịch sử'
  },
  'thirdParty': {
    'yourOrderSuccessfullyPaid': 'Đơn hàng của bạn đã được thanh toán thành công',
    'continueShopping': 'Tiếp tục mua hàng',
    'total': 'Tổng cộng',
    'product': 'sản phẩm',
    'infoReceiveVouchers': 'Thông tin nhận voucher',
    'order': 'Đặt hàng',
    'transportFee': 'Phí vận chuyển',
    'updateInfoReceiveVouchers': 'Cập nhật thông tin nhận voucher',
    'allProducts': 'Tất cả sản phẩm',
    'whatAreYouLookingFor': 'Bạn tìm gì',
    'seeMore': 'Xem thêm',
    'latestProduct': 'Sản phẩm mới nhất',
    'productHasBeenAddedToCart': 'Sản phẩm đã được thêm vào giỏ hàng',
    'viewCart': 'Xem giỏ hàng',
    'continueBuy': 'Tiếp tục mua',
    'errorBuying': 'Có lỗi xảy ra trong quá trình mua hàng. Vui lòng quay lại sau',
    'warningMessage': 'Thông tin email và số điện thoại được dùng để nhận voucher điện tử, quý khách vui lòng kiểm tra trước khi mua hàng',
    'chooseBuy': 'Chọn mua',
    'backToStore': 'Tìm thêm voucher',
    'customSearch': 'Tìm kiếm nâng cao',
    'price': 'Mức giá'
  },
  'lienVietConfig': {
    'title': 'Cấu hình Liên Việt',
    'productName': 'Tên sản phẩm',
    'lpbPublicKey': 'LPB public key',
    'merchantPublicKey': 'Merchant public key',
    'merchantPrivateKey': 'Merchant private key',
    'agentAccess': 'Agent access',
    'agentSite': 'Agent site',
    'WAPUrl': 'Đường dẫn WAP',
    'merchantSite': 'Merchant site',
    'accessCode': 'Access code',
    'infoValidationError': 'Lỗi xác thực thông tin'
  },
  'activation': {
    'expirationDuration': 'Thời hạn hiệu lực',
    'duration': 'Thời gian (ngày)',
    'duration30days': '30 ngày',
    'duration3months': '3 tháng',
    'duration6months': '6 tháng',
    'duration1year': '1 năm',
    'durationVarious': 'Tùy chọn',
    'durationUnlimited': 'Không giới hạn'
  },
  'payment': {
    'enableMethod': 'Mở thanh toán',
    'disableMethod': 'Khóa thanh toán',
    'title': {
      'momo': 'Cấu hình thanh toán cổng MOMO'
    },
    'type': {
      'cash': 'Trả tiền mặt',
      'transfer': 'Chuyển khoản',
      'onlinePayment': 'Thanh toán trực tuyến',
      'momoWallet': 'Ví Momo',
      'zalopay': 'ZaloPay',
      'viviet': 'Ví Việt',
      'baokim': 'Bảo Kim'
    },
    'confirmDeleteBankInfo': 'Bạn có chắc chắn muốn xoá thông tin chuyển khoản \'{name}\'?',
    'momo': {
      'partnerCode': 'Partner Code',
      'accessCode': 'Access Code / Password',
      'secretKey': 'Secret Key',
      'infoHint': 'Xin vui lòng cung cấp thông tin Momo:',
      'guideline': {
        'introduction': 'Hướng dẫn cấu hình Momo',
        'step_0': 'Để sử dụng Momo bạn cần:',
        'step_1': 'Đăng ký dịch vụ với MoMo',
        'step_2': 'Sau khi hoàn tất các bước đăng ký tại MoMo, bạn sẽ có các thông số <b>Partner Code</b>, <b>Access Key</b>, <b>Secret Key</b>',
        'step_3': 'Nhập các thông số <b>Partner Code</b>, <b>Access Key</b>, <b>Secret Key</b> vào ô bên phải'
      }
    },
    'confirmPayment': 'Xác nhận thanh toán',
    'cancelPayment': 'Hủy'
  },
  'responseInfo': {
    'invalidData': 'Dữ liệu không đúng',
    'invalidExpiredDate': 'Ngày hết hạn không phù hợp',
    'invalidDuration': 'Khoảng thời gian không phù hợp',
    'voucherWasReconciled': 'Tồn tại voucher đã được tạo đối soát',
    'voucherUsgaeNotFound': 'Không tìm thấy voucher cần đối soát',
    'cannotProcessApprovedVoucher': 'Không thể cập thông tin đối soát của Voucher đã đối soát',
    'reconciliationHasBeenProcessed': 'Voucher đối soát đã được phê duyệt'
  },
  'socialNetwork': {
    'notiAddSocialAccountLink': 'Bạn có chắc chắn muốn thêm liên kết tài khoản {0}?',
    'googleAccountLinkExists': 'Tài khoản google đã được liên kết với tài khoản khác',
    'facebookAccountLinkExists': 'Tài khoản facebook đã được liên kết với tài khoản khác'
  },
  'baoKimConfig': {
    'title': 'Cấu hình Bảo Kim',
    'baoKimApiKey': 'Bảo Kim Api Key',
    'baoKimSecretKey': 'Bảo Kim Secret Key',
    'baoKimPaymentInfo': 'Thông Tin Thanh Toán Bảo Kim',
    'baoKimMerchantId': 'Merchant Id',
    'baoKimPaymentMethodType': 'Loại Phương Thức Thanh Toán',
    'baoKimPaymentMethod': 'Phương Thức Thanh Toán',
    'paymentMethod': {
      'BAO_KIM_WALLET': 'Ví Bảo Kim',
      'ATM_CARD_ONLINE': 'Thẻ ATM',
      'VISA_MASTER_CARD': 'Thẻ Visa/Master',
      'TRANSFER': 'Chuyển Khoản',
      'QR_CODE': 'Mã QR',
      'VIRTUAL_ACCOUNT': 'Tài Khoản Ảo'
    },
    'paymentConfirm': {
      'completed': 'Đơn hàng của bạn được thanh toán thành công',
      'errorPaymentConfirm': 'Đơn hàng của bạn chưa thanh toán'
    }
  },
  'confirmModal': {
    'confirmCancelOrder': 'Bạn có chắc chắn muốn huỷ đơn hàng này?',
    'title': 'Xác nhận',
    'btn': {
      'confirm': 'Xác nhận',
      'close': 'Đóng'
    }
  },
  'partnership': {
    'loginPartnership': 'Đăng nhập Partnership',
    'logoutPartnership': 'Đăng xuất Partnership',
    'notiLogoutPartnership': 'Bạn có chắc chắn muốn đăng xuất partnership?',
    'emailJoinPartnership': 'Email tham gia partnership'
  },
  'confirmActiveKey': {
    'verificationCodes': 'Mã xác thực',
    'toContinue': 'Để tiếp tục, vui lòng điền mã xác thực đã được cung cấp vào ô bên dưới',
    'codeNotExist': 'Mã xác thực không tồn tại'
  },
  'confirmEmailDomain': {
    'toContinueEnterYourEmailDomain': 'Để tiếp tục, vui lòng điền email domain vào ô bên dưới',
    'emailDomain': 'Tên miền email',
    'emailDomainToLink': 'Tên miền email cần liên kết',
    'successfulWaitForLinkConfirm': 'Vui kiểm tra email và xác nhận liên kết !',
    'emailDomainNotExist': 'Email domain không tồn tại'
  },
  'transactionBetweenCustomer': {
    'transactionMulti': 'Chuyển voucher đã chọn',
    'transactionSingle': 'Chuyển voucher',
    'errorText': {
      'cannotTransferYourself': 'Không thể chuyển cho chính bạn!',
      'noLongerOwnThisVoucher': 'Bạn không còn sở hữu voucher này',
      'numberVoucherExceedNumberAvailable': 'Số lượng voucher voucher cần chuyển vượt quá số voucher hiện có',
      'existVouchersHaveBeenUsed': 'Tồn tại những voucher đã được sử dụng',
      'voucherHasAlreadyBeenUsed': 'Voucher này đã được sử dụng',
      'vouchersWaitingForConfirmationUsed': 'Tồn tại những voucher đang chờ xác nhận sử dụng',
      'cannotTransferVoucherWaitingConfirmationOfUsed': 'Không thể chuyển voucher đang chờ xác nhận sử dụng',
      'emailAlreadyExistAndNotIsCustomer': 'Email này đã tồn tại trong hệ thống và không phải là khách hàng',
      'emailFailedTryAgain': 'Gửi email thất bại, vui lòng thử lại sau',
      'nonTransferableVoucherExists': 'Tồn tại voucher không thể chuyển nhượng',
      'voucherIsNonTransferable': 'Voucher này không thể chuyển nhượng'
    }
  },
  'registerSupplier': {
    'title': 'Đăng ký thông tin nhà cung cấp voucher',
    'subtitle': 'Sau khi đăng ký trở thành nhà cung cấp voucher, bạn có thể tạo voucher, quản lý voucher và phát hành voucher tới khách hàng của bạn',
    'account': 'Tài khoản quản lý',
    'supplierInfo': 'Thông tin nhà cung cấp',
    'name': 'Tên nhà cung cấp',
    'code': 'Mã định danh nhà cung cấp',
    'address': 'Địa chỉ nhà cung cấp',
    'phone': 'Điện thoại nhà cung cấp',
    'email': 'Email nhà cung cấp',
    'website': 'Trang web nhà cung cấp',
    'description': 'Thông tin mô tả nhà cung cấp',
    'register': 'Đăng ký nhà cung cấp',
    'gotoLogin': 'Quay lại trang đăng nhập'
  },
  'bankTransactionInfo': {
    'sidebarTitle': 'Giao dịch ngân hàng',
    'title': 'Danh sách giao dịch',
    'modalTitle': 'Chi tiết giao dịch',
    'requestId': 'Request id',
    'accountNo': 'Số tài khoản',
    'balanceAfterOperation': 'Số dư',
    'bankTransactionId': 'Mã giao dịch',
    'currency': 'Đơn vị tiền tệ',
    'narrative': 'Nội dung chuyển khoản',
    'requestTime': 'Request time',
    'transactionAmount': 'Số tiền giao dịch',
    'transactionDate': 'Thời gian giao dịch',
    'transactionDetail': 'Chi tiết giao dịch',
    'statusList': {
      'NOT_PROCESS': 'Chưa xử lý',
      'SUCCESS': 'Thành công',
      'INVALID_ORDER': 'Đơn hàng không hợp lệ',
      'INVALID_AMOUNT': 'Số tiền không hợp lệ',
      'INVALID_APPROVE_ORDER': 'Phê duyệt không thành công'
    }
  },
  'HDBankKeySetting': {
    'title': 'Cấu hình kết nối HDBank',
    'publicKey': 'Khóa công khai',
    'HDBankPublicKey': 'HD bank public key'
  },
  'groupSites': {
    'title': 'Quản lý theo vùng',
    'addRegion': 'Thêm vùng sử dụng',
    'updateRegion': 'Cập nhật vùng sử dụng',
    'deleteRegionMessage': 'Bạn có chắc chắn muốn xóa vùng sử dụng này?',
    'addSiteIntoRegion': 'Thêm địa điểm sử dụng vào vùng này',
    'removeSiteFromRegion': 'Bạn có chắc chắn muốn xóa địa điểm sử dụng {0} khỏi vùng này?',
    'siteExistingInRegion': 'Địa điểm sử dụng đã tồn tại trong vùng này'
  },
  'emailHistory': {
    'title': 'Lịch sử email đã gởi',
    'gatewayTitle': 'Trạng thái xử lý email từ mail gateway',
    'smtpAgent': 'Từ SMTP Agent',
    'mailGateway': 'Từ mail gateway',
    'search': 'Email hoặc tiêu đề...',
    'searchEmail': 'Email người nhận...',
    'toAddress': 'To Addr.',
    'ccAddress': 'CC Addr.',
    'bccAddress': 'Bcc Addr.',
    'subject': 'Tiêu đề',
    'attached': 'Đính kèm',
    'sender': 'Người gửi',
    'recipient': 'Người nhận',
    'dateCreated': 'Ngày gửi',
    'status': 'Trạng thái',
    'event': 'Sự kiện',
    'timestamp': 'Ngày cập nhập',
    'description': 'Mô tả'
  },
  'smsHistory': {
    'title': 'Lịch sử gởi tin nhắn SMS',
    'search': 'Số điện thoại...',
    'toPhone': 'Số điện thoại nhận',
    'response': 'Mã xử lý',
    'smsid': 'ID tin nhắn',
    'dateCreated': 'Ngày gởi'
  },
  'validation': {
    'invalidEmail': 'Email không đúng định dạng',
    'invalidPhoneNumber': 'Số điện thoại không đúng định dạng',
    'invalidPhoneOrEmail': 'Không đúng định dạng số điện thoại hoặc email',
    'invalidUsername': 'Tên người dùng phải có 2 từ trở lên',
    'invalidURL': 'Đường dẫn không đúng định dạng',
    'invalidCodeName': 'Chỉ chấp nhận ký tự số và chữ',
    'passwordDoesNotMatch': 'Mật khẩu không khớp',
    'passwordNotValidCharactor': 'Mật khẩu chứa ký tự không cho phép',
    'passwordLengthTooShort': 'Mật khẩu phải có ít nhất {min} ký tự',
    'passwordMustContainsOneDigit': 'Mật khẩu phải có ít nhất 1 chữ số',
    'passwordMustContainsOneLowerCase': 'Mật khẩu phải có ít nhất 1 ký tự in thường',
    'passwordMustContainsOneUpperCase': 'Mật khẩu phải có ít nhất 1 ký tự in hoa',
    'passwordMustContainsOneSymbols': 'Mật khẩu phải có ít nhất 1 trong các ký tự \'@#$%\'',
    'valueMustBeGreaterThan': 'Giá trị phải lớn hơn {value}',
    'valueMustBeGreaterThanOrEqual': 'Giá trị phải lớn hơn hoặc bằng {value}',
    'valueMustBeLessThan': 'Giá trị phải nhỏ hơn {value}',
    'valueMustBeLessThanOrEqual': 'Giá trị phải nhỏ hơn hoặc bằng {value}',
    'ruleLengthMustLessThan': 'Chiều dài tối đa phải nhỏ hơn {value}',
    'requiredField': 'Thông tin bắt buộc!'
  }
}
