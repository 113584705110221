import { FOR_CONASI_TARGET } from '../utils/constants'
import EntityType from 'enum/entityType'

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
const Layout = () => import('Views/Layout/ContentLayout')
const Dashboard = () => import('Views/Dashboard')
const Login = () => import('Views/Users/Login')
const PageNotFound = () => import('Views/PageNotFound')
const GroupList = () => import('Views/Decentralization/Group/GroupList')
const Decentralization = () => import('Views/Decentralization/DecentralizationManager')
// const Register = () => import('Views/Users/Register')
const RegisterSupplier = () => import('Views/Users/RegisterSupplier')
const UserProfile = () => import('Views/Users/UserProfile')
const UserList = () => import('Views/Users/UserList')
const EntityManagement = () => import('Views/Entities/EntityMangement')
const EVoucherList = () => import('Views/EVoucher/EVoucherList')
const EVoucherIssuance = () => import('Views/EVoucher/EVoucherIssuanceList')
const EntityRole = () => import('Views/Entities/Role/EntityRoleList')
const Customer = () => import('Views/Customer/CustomerList')
const Customization = () => import('Views/Setting/CustomizationTable')
const ThirdPartiesSettings = () => import('Views/Setting/PaymentSettings')
const IssueEVoucherRequestList = () => import('Views/EVoucher/IssueEVoucherRequestList')
const ReportVoucher = () => import('Views/Report/VoucherReport')
const ActivatedEmail = () => import('Views/Users/ActivatedEmail')
const ForgotPassword = () => import('Views/Users/Forgotpassword')
const ConfirmResetPassword = () => import('Views/Users/ConfirmResetPassword')
const VoucherHistory = () => import('Views/EVoucher/HistoryList')
const BatchJobConfiguration = () => import('Views/BatchJobs/BatchJobConfiguration')
const RequestApprovalVoucherFromAgent = () => import('Views/EVoucher/RequestApprovalVoucherFromAgent')
const EVoucherIssuanceDetail = () => import('Views/EVoucher/EVoucherIssuanceDetail')
const LookupVoucherInfo = () => import('Views/EVoucher/LookupVoucherInfo')
const EntityDetail = () => import('Views/Entities/EntityDetail')
const Development = () => import('Views/Development/Development')
const SupplierAccessCode = () => import('Views/AccessCode/SupplierAccessCode')
const SupplierTemplateEmail = () => import('Views/Config/SupplierTemplateEmail')
const TemplateEmailCategories = () => import('Views/Config/TemplateEmailCategories')
const TemplateEmailList = () => import('Views/Config/SupplierTemplateEmailList')
const NotificationCategories = () => import('Views/Config/Notification/Categories')
const NotificationContentList = () => import('Views/Config/Notification/ContentList')
const CreateNotificationContent = () => import('Views/Config/Notification/CreateNotificationContent')
const UseVoucherPromotion = () => import('Views/EVoucher/UseVoucherPromotion')
const PrintEVoucherV2 = () => import('Views/EVoucher/PrintEVoucherV2')
const AdvertisementInfo = () => import('Views/Advertisement/AdvertisementInfo')
const UserReport = () => import('Views/Report/UserReport')
const VoucherReport = () => import('Views/Report/VoucherReportV2')
const HashtagList = () => import('Views/Hashtag/HashtagList')
const QuantityVoucherSupplierToAgent = () => import('Views/Report/QuantityVoucherSupplierToAgent')
const QuantityVoucherDistributerToAgent = () => import('Views/Report/QuantityVoucherDistributerToAgent')
const QuantityVoucherSoldInAgent = () => import('Views/Report/QuantityVoucherSoldInAgent')
const QuantityVoucherSoldOfStaffInAgent = () => import('Views/Report/QuantityVoucherSoldOfStaffInAgent')
const DetailQuantityVoucherSoldOfStaffInAgent = () => import('Views/Report/DetailQuantityVoucherSoldOfStaffInAgent')
// const WorkpieceList = () => import('Views/EVoucher/WorkpieceList')
const TemplatePrintEVoucherConfig = () => import('Views/Config/TemplatePrintEVoucher')
const TestApi = () => import('Views/Setting/TestApi')
const BannerSlider = () => import('Views/Portal/BannerSlider/List')
const BannerAds = () => import('Views/Portal/BannerAds/List')
const LogoBgMenu = () => import('Views/Portal/Config/LogoBgMenu')
const EmailDomainActiveKey = () => import('Views/Portal/Config/EmailDomainActiveKey')
const VoucherProductCategoriesList = () => import('Views/Portal/VoucherProduct/Categories/List')
const VoucherProductList = () => import('Views/Portal/VoucherProduct/Product/List')
const StoreChannelsList = () => import('Views/Portal/StoreChannels/List')
const ProductOrderList = () => import('Views/Portal/VoucherProduct/Product/OrderList')
const FooterList = () => import('Views/Portal/Footer/List')
const VoucherProductPromotionList = () => import('Views/Portal/VoucherProduct/Promotion/List')
const PaymentMethodsList = () => import('Views/Portal/PaymentMethods/List')
const TransferInfo = () => import('Views/Portal/PaymentMethods/TransferInfo')
const DisplayView = () => import('Views/Portal/Display/DisplayView')
const LienVietContent = () => import('Views/Portal/VoucherProduct/LienVietPostBank/Content')
const LienVietCart = () => import('Views/Portal/VoucherProduct/LienVietPostBank/Cart')
const LienVietProductDetail = () => import('Views/Portal/VoucherProduct/LienVietPostBank/Detail')
const UsedVoucherList = () => import('Views/EVoucher/Checks/Shop/UsedVoucherList')
const EVoucherWaitForApproval = () => import('Views/EVoucher/Checks/Shop/EVoucherWaitForApproval')
const EVoucherReconciliationHistories = () => import('Views/EVoucher/Checks/Shop/EVoucherReconciliationHistories')
// const SupplierUsedVoucherList = () => import('Views/EVoucher/Checks/Supplier/UsedVoucherList')
const PaymentResults = () => import('Views/Portal/VoucherProduct/LienVietPostBank/PaymentResults')
const ConfirmOrder = () => import('Views/Portal/VoucherProduct/LienVietPostBank/ConfirmOrder')
const DeliveryInfo = () => import('Views/Portal/VoucherProduct/LienVietPostBank/DeliveryInfo')
const LienVietProductList = () => import('Views/Portal/VoucherProduct/LienVietPostBank/List')
const WAPHome = () => import('Views/Portal/VoucherProduct/LienVietPostBank/Home')
const LPBInfoInvalid = () => import('Views/Portal/VoucherProduct/LienVietPostBank/LPBInfoInvalid')
const ReportQuantityVoucherCreateInSupplier = () => import('Views/Report/Supplier/ReportQuantityVoucherCreateInSupplier')
const ReportVoucherUsedInSupplier = () => import('Views/Report/Supplier/ReportVoucherUsedInSupplier')
const ReportLocalUsedVoucher = () => import('Views/Report/Supplier/ReportLocalUsedVoucher')
const ReportVoucherDistributed = () => import('Views/Report/Supplier/ReportVoucherDistributed')
const EmailHistory = () => import('Views/History/EmailHistoryTab.vue')
const SmsHistory = () => import('Views/History/SmsHistoryList.vue')
const MobilePortalContent = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalContent')
const MobilePortalHome = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalHome')
const MobilePortalCart = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalCart')
const MobilePortalProductList = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalProductList')
const MobilePortalProductDetail = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalProductDetail')
const MobilePortalDeliveryInfo = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalDeliveryInfo')
const MobilePortalConfirmOrder = () => import('Views/Portal/VoucherProduct/MobilePortal/MobilePortalConfirmOrder')
const OnlineOrderPaymentConfirm = () => import('Components/OnlineOrderPaymentConfirm')
const MyOrderList = () => import('Views/Order/MyOrderList')
const BankTransactionList = () => import('Views/Portal/VoucherProduct/Product/BankTransaction')
// const PlacesOfUsageGroup = () => import('Views/Entities/PlacesOfUsageGroup')
export default [
  {
    path: '/',
    component: Layout,
    name: 'Dashboard',
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard.title',
        component: Dashboard,
        meta: { requiresAuth: true }
      },
      {
        path: '/suppliers',
        name: 'sideBar.supplierManagement',
        component: EntityManagement,
        props: { entityType: EntityType.SUPPLIER },
        meta: { requiresAuth: true }
      },
      {
        path: '/decentralization',
        component: Decentralization,
        name: 'sideBar.decentralization',
        meta: { requiresAuth: true },
        children: [
          {
            path: '/decentralization',
            component: EntityRole,
            name: 'sideBar.roleManagementInProject',
            meta: { requiresAuth: true }
          },
          {
            path: '/decentralization-groups',
            component: GroupList,
            name: 'sideBar.groupManagement',
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: '/user-profile/:id',
        component: UserProfile,
        name: 'userProfile.userProfile',
        meta: { requiresAuth: true }
      },
      {
        path: '/user-list',
        component: UserList,
        name: 'sideBar.userManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/agents',
        component: EntityManagement,
        name: 'sideBar.agentManagement',
        props: { entityType: EntityType.AGENT },
        meta: { requiresAuth: true }
      },
      {
        path: '/customer',
        component: Customer,
        name: 'sideBar.customerManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/email-history',
        component: EmailHistory,
        name: 'sideBar.emailHistory',
        meta: { requiresAuth: true }
      },
      {
        path: '/sms-history',
        component: SmsHistory,
        name: 'sideBar.smsHistory',
        meta: { requiresAuth: true }
      },
      {
        path: '/e-voucher-list',
        component: EVoucherList,
        name: 'sideBar.evoucherList',
        meta: { requiresAuth: true }
      },
      {
        path: '/e-voucher-issuance',
        component: EVoucherIssuance,
        name: FOR_CONASI_TARGET ? 'workpiece.title' : 'workpiece.issuanceList',
        meta: { requiresAuth: true }
      },
      {
        path: '/customization',
        component: Customization,
        name: 'sideBar.generalConfiguration',
        meta: { requiresAuth: true }
      },
      {
        path: '/3rd-settings',
        component: ThirdPartiesSettings,
        name: 'sideBar.thirdPartiesSettings',
        meta: { requiresAuth: true }
      },
      {
        path: '/issue-evoucher-request',
        component: IssueEVoucherRequestList,
        name: 'dashboard.voucherRequestList',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-voucher',
        component: ReportVoucher,
        name: 'voucherReport.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/history-voucher',
        component: VoucherHistory,
        name: 'evoucher.history',
        meta: { requiresAuth: true }
      },
      {
        path: '/batch-job',
        component: BatchJobConfiguration,
        name: 'evoucher.batchJob.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/request-approval-voucher-from-agent',
        component: RequestApprovalVoucherFromAgent,
        name: 'sideBar.voucherTransferRequestList',
        meta: { requiresAuth: true }
      },
      {
        path: '/e-voucher-issuance-id/:id',
        component: EVoucherIssuanceDetail,
        name: 'sideBar.voucherIssuanceDetail',
        meta: { requiresAuth: true }
      },
      {
        path: '/lookup-voucher-info',
        component: LookupVoucherInfo,
        name: 'infoVoucher.searchForVoucherInfo',
        meta: { requiresAuth: true }
      },
      {
        path: '/entity-detail/:id',
        component: EntityDetail,
        name: 'sideBar.detailInformation',
        meta: { requiresAuth: true }
      },
      {
        path: '/development',
        component: Development,
        name: 'sideBar.development',
        meta: { requiresAuth: true }
      },
      {
        path: '/supplier-access-code',
        component: SupplierAccessCode,
        name: 'sideBar.supplierAccessCode',
        meta: { requiresAuth: true }
      },
      {
        path: '/site-management',
        component: EntityManagement,
        name: 'sideBar.siteManagement',
        props: { entityType: EntityType.SITE },
        meta: { requiresAuth: true }
      },
      {
        path: '/template-email-categories',
        component: TemplateEmailCategories,
        name: 'templateEmail.category.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/template-email-list/:id',
        component: TemplateEmailList,
        name: 'templateEmail.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/create-template-email/:categoryId',
        component: SupplierTemplateEmail,
        name: 'templateEmail.createTemplateEmail',
        meta: { requiresAuth: true }
      },
      {
        path: '/update-template-email/:templateId/:categoryId',
        component: SupplierTemplateEmail,
        name: 'templateEmail.updateTemplateEmail',
        meta: { requiresAuth: true }
      },
      {
        path: '/notification-categories',
        component: NotificationCategories,
        name: 'notification.categories',
        meta: { requiresAuth: true }
      },
      {
        path: '/notification-content-list/:id',
        component: NotificationContentList,
        name: 'notification.templateNotify',
        meta: { requiresAuth: true }
      },
      {
        path: '/create-notification-content/:categoryId',
        component: CreateNotificationContent,
        name: 'notification.createTemplateNotify',
        meta: { requiresAuth: true }
      },
      {
        path: '/update-notification-content/:notificationId/:categoryId',
        component: CreateNotificationContent,
        name: 'notification.updateTemplateNotify',
        meta: { requiresAuth: true }
      },
      {
        path: '/use-voucher-promotion',
        component: UseVoucherPromotion,
        name: 'sideBar.useVoucherPromotion',
        meta: { requiresAuth: true }
      },
      {
        path: '/advertisement-info',
        component: AdvertisementInfo,
        name: 'sideBar.advertisementInfo',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-statistic-user',
        component: UserReport,
        name: 'report.userStatistics',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-statistic-voucher',
        component: VoucherReport,
        name: 'report.voucherStatistics',
        meta: { requiresAuth: true }
      },
      {
        path: '/hashtag-list',
        component: HashtagList,
        name: 'sideBar.hashtagManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-quantity-voucher-supplier-to-agent',
        component: QuantityVoucherSupplierToAgent,
        name: 'report.numberOfVouchersIssuedToAgent',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-quantity-voucher-distributer-to-agent',
        component: QuantityVoucherDistributerToAgent,
        name: 'report.numberOfVouchersToBeIssuedToAgentLevel2',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-quantity-voucher-sold-in-agent',
        component: QuantityVoucherSoldInAgent,
        name: 'report.quantityVoucherSoldInAgent.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-quantity-voucher-sold-of-staff-in-agent',
        component: QuantityVoucherSoldOfStaffInAgent,
        name: 'report.quantityVoucherSoldOfStaffInAgent.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-detail-quantity-voucher-sold-of-staff-in-agent',
        component: DetailQuantityVoucherSoldOfStaffInAgent,
        name: 'report.detailQuantityVoucherSoldOfStaffInAgent.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-quantity-voucher-create-in-supplier',
        component: ReportQuantityVoucherCreateInSupplier,
        name: 'report.supplier.numberVoucherCreated',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-voucher-distributed',
        component: ReportVoucherDistributed,
        name: 'evoucher.voucherDistribution',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-voucher-used-in-supplier',
        component: ReportVoucherUsedInSupplier,
        name: 'report.supplier.voucherUsedDetail',
        meta: { requiresAuth: true }
      },
      {
        path: '/report-local-use-voucher',
        component: ReportLocalUsedVoucher,
        name: 'report.supplier.voucherUsedList',
        meta: { requiresAuth: true }
      },
      // {
      //   path: '/workpiece-list',
      //   component: WorkpieceList,
      //   name: 'sideBar.evoucherIssuance',
      //   meta: { requiresAuth: true }
      // },
      {
        path: '/template-print-voucher',
        component: TemplatePrintEVoucherConfig,
        name: 'sideBar.voucherPrintTemplate',
        meta: { requiresAuth: true }
      },
      {
        path: '/test-api',
        component: TestApi,
        name: 'Test api',
        meta: { requiresAuth: true }
      },
      {
        path: '/banner-slider',
        component: BannerSlider,
        name: 'portal.banner.slider',
        meta: { requiresAuth: true }
      },
      {
        path: '/banner-ads',
        component: BannerAds,
        name: 'portal.banner.ads',
        meta: { requiresAuth: true }
      },
      {
        path: '/logo-menu-bg-color',
        component: LogoBgMenu,
        name: 'portal.banner.logoAndBgMenu',
        meta: { requiresAuth: true }
      },
      {
        path: '/email-domain-active-key',
        component: EmailDomainActiveKey,
        name: 'sideBar.emailDomain',
        meta: { requiresAuth: true }
      },
      {
        path: '/voucher-product-category',
        component: VoucherProductCategoriesList,
        name: 'voucherProduct.category',
        meta: { requiresAuth: true }
      },
      {
        path: '/voucher-product',
        component: VoucherProductList,
        name: 'voucherProduct.productTitle',
        meta: { requiresAuth: true }
      },
      {
        path: '/store-channels-list',
        component: StoreChannelsList,
        name: 'storeChannel.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/product-orders-list',
        component: ProductOrderList,
        name: 'sideBar.productOrderManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/bank-transaction',
        component: BankTransactionList,
        name: 'sideBar.bankTransactionInfo',
        meta: { requiresAuth: true }
      },
      {
        path: '/footer-list',
        component: FooterList,
        name: 'sideBar.footerConfig',
        meta: { requiresAuth: true }
      },
      {
        path: '/promotion-code',
        component: VoucherProductPromotionList,
        name: 'promotionCode.managePromoCodes',
        meta: { requiresAuth: true }
      },
      {
        path: '/payment-methods',
        component: PaymentMethodsList,
        name: 'paymentMethod.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/transfer-info/:id',
        component: TransferInfo,
        name: 'paymentMethod.transferInfo',
        meta: { requiresAuth: true }
      },
      {
        path: '/display-config',
        component: DisplayView,
        name: 'portal.display',
        meta: { requiresAuth: true }
      },
      {
        path: '/used-voucher-list',
        component: UsedVoucherList,
        name: 'voucherReconciliation.voucherUsed.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/voucher-wait-reconciliation-list',
        component: EVoucherWaitForApproval,
        name: 'voucherReconciliation.waitForApproval',
        meta: { requiresAuth: true }
      },
      {
        path: '/voucher-reconciliation-histories-list',
        component: EVoucherReconciliationHistories,
        name: 'voucherReconciliation.history.title',
        meta: { requiresAuth: true }
      }
      // {
      //   path: '/places-of-usage',
      //   component: PlacesOfUsageGroup,
      //   name: 'groupSites.title',
      //   meta: { requiresAuth: true }
      // }
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
  // {
  //   path: '/register',
  //   component: Register,
  //   name: 'register'
  // },
  {
    path: '/register-supplier',
    component: RegisterSupplier,
    name: 'register supplier'
  },
  {
    path: '/account/email_activated/:uid/:token',
    component: ActivatedEmail,
    name: 'activatedEmail'
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'Forgot password'
  },
  {
    path: '/confirm/reset/password/:uid/:token',
    component: ConfirmResetPassword,
    name: 'Confirm reset password'
  },
  {
    path: '/print-evoucher-v2',
    component: PrintEVoucherV2,
    name: 'Print EVoucher V2',
    props: true
  },
  {
    path: '*',
    component: PageNotFound,
    name: 'NotFound'
  },
  {
    path: '/wap-content',
    component: LienVietContent,
    name: 'LienVietContent',
    redirect: '/wap-home',
    children: [
      {
        path: '/wap-home',
        name: 'WapHome',
        component: WAPHome
      },
      {
        path: '/cart',
        name: 'Cart',
        component: LienVietCart
      },
      {
        path: '/detail/:id',
        name: 'LienVietProductDetail',
        component: LienVietProductDetail
      },
      {
        path: '/payment-results',
        name: 'PaymentResults',
        component: PaymentResults
      },
      {
        path: '/confirm-order',
        name: 'ConfirmOrder',
        component: ConfirmOrder
      },
      {
        path: '/delivery-info',
        name: 'DeliveryInfo',
        component: DeliveryInfo
      },
      {
        path: '/product-list',
        name: 'LienVietProductList',
        component: LienVietProductList
      },
      {
        path: '/lien-viet-my-order',
        name: 'LienVietMyOrderList',
        component: MyOrderList,
        meta: { isActiveLink: true }
      }
    ]
  },
  {
    path: '/wap-portal/:storeChannelId',
    component: MobilePortalContent,
    name: 'MobilePortalContent',
    // redirect: '/mobile-portal-home',
    redirect: 'mobile-portal-home/:storeChannelId',
    children: [
      {
        path: '/mobile-portal-home/:storeChannelId',
        name: 'MobilePortalHome',
        component: MobilePortalHome,
        meta: { requiresAuthMobilePortal: true }
      },
      {
        path: '/mobile-portal-cart/:storeChannelId',
        name: 'MobilePortalCart',
        component: MobilePortalCart,
        meta: { requiresAuthMobilePortal: true }
      },
      {
        path: '/mobile-portal/detail/:id/:storeChannelId',
        name: 'MobilePortalProductDetail',
        component: MobilePortalProductDetail,
        meta: { requiresAuthMobilePortal: true }
      },
      // {
      //   path: '/payment-results',
      //   name: 'PaymentResults',
      //   component: PaymentResults
      // },
      {
        path: '/mobile-portal-confirm-order/:storeChannelId',
        name: 'MobilePortalConfirmOrder',
        component: MobilePortalConfirmOrder,
        meta: { requiresAuthMobilePortal: true }
      },
      {
        path: '/mobile-portal-delivery-info/:storeChannelId',
        name: 'MobilePortalDeliveryInfo',
        component: MobilePortalDeliveryInfo,
        meta: { requiresAuthMobilePortal: true }
      },
      {
        path: '/product-list/:storeChannelId',
        name: 'MobilePortalProductList',
        component: MobilePortalProductList,
        meta: { requiresAuthMobilePortal: true }
      },
      {
        path: '/online-order-payment-confirm/:storeChannelId',
        name: 'OnlineOrderPaymentConfirm',
        component: OnlineOrderPaymentConfirm,
        meta: { isActiveLink: true }
      },
      {
        path: '/wap-portal-my-order/:storeChannelId',
        name: 'WapPortalMyOrderList',
        component: MyOrderList,
        meta: { isActiveLink: true }
      }
    ]
  },
  {
    path: '/wap-portal',
    component: MobilePortalContent,
    name: 'MobilePortalContent',
    // redirect: '/mobile-portal-home',
    redirect: 'mobile-portal-home',
    children: [
      {
        path: '/mobile-portal-home',
        name: 'MobilePortalHome',
        component: MobilePortalHome,
        meta: { requiresAuthMobilePortal: true }
      }
    ]
  },
  {
    path: '/lpb-info-invalid',
    component: LPBInfoInvalid,
    name: 'LPBInfoInvalid'
  }
]
