import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'
const state = {
  quantityVoucherSupplierToAgent: [],
  quantityVoucherSoldInAgents: [],
  quantityVoucherSoldOfStaffInAgents: [],
  vouchersUsedList: [],
  localUsedVoucherList: [],
  quantityVoucherCreatedList: [],
  voucherDistributionList: []
}
const getters = {
  QUANTITY_VOUCHER_SUPPLIER_TO_AGENT_DATA: state => {
    return state.quantityVoucherSupplierToAgent
  },
  QUANTITY_VOUCHER_SOLD_IN_AGENT_DATA: state => {
    return state.quantityVoucherSoldInAgents
  },
  QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT_DATA: state => {
    return state.quantityVoucherSoldOfStaffInAgents
  },
  VOUCHERS_USED_LIST_DATA: state => {
    return state.vouchersUsedList
  },
  GET_LOCAL_USED_VOUCHERS_LIST_DATA: state => {
    return state.localUsedVoucherList
  },
  GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST_DATA: state => {
    return state.quantityVoucherCreatedList
  },
  GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS_DATA: state => {
    return state.voucherDistributionList
  }
}
const actions = {
  GET_QUANTITY_VOUCHER_SUPPLIER_TO_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_quantity_voucher_supplier_to_agent/`,
        payload,
        function (res) {
          if (res.data['page'] !== undefined) {
            context.commit('GET_QUANTITY_VOUCHER_SUPPLIER_TO_AGENT_HANDLER', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_QUANTITY_VOUCHER_SOLD_IN_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_quantity_voucher_sold_in_agents/`,
        payload,
        function (res) {
          context.commit('GET_QUANTITY_VOUCHER_SOLD_IN_AGENT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_quantity_voucher_sold_of_staff_in_agents/`,
        payload,
        function (res) {
          context.commit('GET_QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHERS_USED_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/report/get_vouchers_used/`,
        payload,
        function (res) {
          if (res.data['page'] !== undefined) {
            context.commit('GET_VOUCHERS_USED_LIST', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_LOCAL_USED_VOUCHERS_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/report/get_local_used_vouchers/`,
        payload,
        function (res) {
          if (res.data['page'] !== undefined) {
            context.commit('GET_LOCAL_USED_VOUCHERS_LIST', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/report/get_quantity_voucher_created_in_supplier/`,
        payload,
        function (res) {
          if (res.data['page'] !== undefined) {
            context.commit('GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/report/get_voucher_distribution_statistics_to_agents/`,
        payload,
        function (res) {
          if (res.data['page'] !== undefined) {
            context.commit('GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  }
}
const mutations = {
  GET_QUANTITY_VOUCHER_SUPPLIER_TO_AGENT_HANDLER: (state, payload) => {
    let voucherStatistical = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.quantityVoucherSupplierToAgent = { results: voucherStatistical, paginate: page }
  },
  GET_QUANTITY_VOUCHER_SOLD_IN_AGENT_HANDLER: (state, payload) => {
    let voucherStatistical = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.quantityVoucherSoldInAgents = { results: voucherStatistical, paginate: page }
  },
  GET_QUANTITY_VOUCHER_SOLD_OF_STAFF_IN_AGENT_HANDLER: (state, payload) => {
    let voucherStatistical = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.quantityVoucherSoldOfStaffInAgents = { results: voucherStatistical, paginate: page }
  },
  GET_VOUCHERS_USED_LIST: (state, payload) => {
    let vouchersUsedList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.vouchersUsedList = payload.results ? { results: vouchersUsedList, paginate: page } : payload
  },
  GET_LOCAL_USED_VOUCHERS_LIST: (state, payload) => {
    let localUsedVoucherList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.localUsedVoucherList = payload.results ? { results: localUsedVoucherList, paginate: page } : payload
  },
  GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST: (state, payload) => {
    let quantityVoucherCreatedList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.quantityVoucherCreatedList = payload.results ? { results: quantityVoucherCreatedList, paginate: page } : payload
  },
  GET_VOUCHER_DISTRIBUTION_STATISTICS_TO_AGENTS: (state, payload) => {
    let voucherDistributionList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherDistributionList = payload.results ? { results: voucherDistributionList, paginate: page } : payload
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
