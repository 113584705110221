<template>
  <div class="tabHeader">
    <div
      v-for="(tab, index) in tabItems"
      :key="tab.title + index"
      class="itemContainer"
    >
      <div v-if="!tab.hidden">
        <span
          v-if="index != 0"
          class="mx-2">|</span>
        <span
          :class="['tabItem text-capitalize', tabSelected == tab.id ? 'tabHeaderSelected' : '']"
          @click="onSelectTab($event, index, tab)"> {{ $t(tab.title) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabItems: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      tabSelected: 0
    }
  },
  watch: {
    selectedId () {
      this.tabSelected = this.selectedId
    }
  },
  created () {
    if (this.selectedId) {
      this.tabSelected = this.selectedId
    } else if (this.tabItems) {
      this.tabSelected = this.tabItems[0].id
    }
  },
  methods: {
    onSelectTab: function (event, index, item) {
      event.preventDefault()
      this.tabSelected = item.id
      this.$emit('onSelectTab', { index, item })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabHeader {
  display: flex;
  flex-direction: row;
}
.itemContainer {
  border: 0;
  border-bottom: 1px;
  border-color: green;
  border-style: dashed;
}
.tabItem:hover {
  font-weight: bold;
}
.tabHeader .tabItem {
  cursor: pointer;
}
.tabHeaderSelected {
  color: green;
  font-weight: bold;
}
</style>
