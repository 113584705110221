import { format } from 'date-fns'
export const ISO_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_DATE = format(new Date(), ISO_FORMAT)
export const DEFAULT_ACTION_LABELS = {
  apply: 'common.agree',
  cancel: 'common.cancel',
  reset: 'common.reset'
}
export const MAX_DISPLAY_DESCRIPTION_TEXT = 50
export const DEFAULT_AGENT_EXPIRED_DAYS = 15
export const DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS = 365
export const DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING = 7
export const DEFAULT_NUMBER_OF_DISTRIBUTING_EXPIRED_DAYS = 45
export const NUM_OF_USES_PER_PERSON_VOUCHER_PROMOTION_DEFAULT = 1
export const FOR_CONASI_TARGET = process.env.VUE_APP_FOR_CONASI.trim() === 'true'
