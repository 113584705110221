<template>
  <div
    :id="`image-picker-${key}`"
    class="picker-container">
    <v-form
      :ref="`image-picker-form-${key}`"
      lazy-validation>
      <!-- <div :for="`image-picker-input-${key}`"></div> -->
      <div
        v-if="!disabled"
        class="text-left pb-2 font-weight-bold font-small uppercase">
        <span><span class="red--text">* </span>{{ $t('common.expectationImageRatio') + ` ${exceptationRatio}` + ', ' + $t('common.maxSizeUpload') + ` ${maxSizeUpload}` + ', ' + $t('common.recommendImageDimension') + ` ${recommendDimension}` }}</span>
      </div>
      <img
        :id="`image-picker-view-${key}`"
        :src="imageUrl !== null && imageUrl !== '' ? imageUrl : noImageFile"
        :style="`width: ${imageWidth}px; height: ${imageHeight}px; background-image: url(${backgroundImageFile}); background-size: 15pt; background-repeat: repeat`"
        class="image-preview img-responsive"
        @click="onClickImage">
      <div class="text-left pt-3">
        <input
          :id="`image-picker-input-${key}`"
          :disabled="disabled"
          type="file"
          accept="image/*"
          @change="$emit('onSelectedImageFile', $event)">
        <span
          v-if="!disabled && imageUrl !== null && imageUrl !== ''"
          class="remove-image-action"
          @click="onResetImage($event)">{{ $t('common.delete') }}</span>
        <span v-if="!disabled && imageUrl !== null && imageUrl !== ''"> | </span>
        <span v-if="!disabled">{{ $t('evoucher.issuance.clickToUploadUpdateImg') }}</span>
      </div>
    </v-form>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: {
    disabled: { type: Boolean, default: () => false },
    exceptationRatio: { type: String, default: () => '3:2' },
    maxSizeUpload: { type: String, default: () => '3MB' },
    recommendDimension: { type: String, default: () => '300x200' },
    imageWidth: { type: Number, default: () => 300 },
    imageHeight: { type: Number, default: () => 200 },
    imageUrl: { type: String, default: () => null }
  },
  data () {
    return {
      noImageFile: require('../assets/noimage.png'),
      backgroundImageFile: require('../assets/bk-pattern.svg')
    }
  },
  computed: {
    key () {
      return this.$vnode.key
      // For vue3
      // return this.$.vnode.key
    }
    // noImageFile () { return require('../assets/noimage.png') },
    // backgroundImageFile () { return require('../assets/bk-pattern.svg') }
  },
  mounted () {},
  methods: {
    onClickImage: function () {
      $(`#image-picker-input-${this.key}`).click()
    },
    onResetImage: function (event) {
      let inputForm = $(`#image-picker-input-${this.key}`)
      inputForm.val('')
      this.$emit('onResetImage', event)
    }
  }
}
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
.picker-container {
  display: flex;
  flex-direction: column;
}
.image-preview {
  border: 0.5px dashed lightgrey;
  // object-fit: contain;
  cursor: pointer;
}
.image-preview:hover {
  border: 0.5px dashed green;
}
.remove-image-action{
  color: #EF5350;
  cursor: pointer;
}
.remove-image-action:hover{
  text-decoration: underline;
}
</style>
