import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'
// state
const state = {
  workpieceList: [],
  eVouchers: [],
  remainQuantityVoucherInIssuance: {},
  quantityVoucherAvailableOfEntity: {},
  historyTransactionVoucher: [],
  voucherDetail: {},
  voucherLockedUsing: {},
  issueVoucherRequestList: [],
  statisticsVoucher: [],
  statisticsDetailVoucher: [],
  voucherHistories: [],
  voucherDistributionNotifications: [],
  issuanceOfVoucherAlreadyExistList: [],
  requestTransferVoucherFromAgentList: [],
  workpieceDetail: {},
  voucherDistributedToAgent: [],
  issuanceList: [],
  voucherInfoList: [],
  vouchersPrintData: [],
  voucherPromotionInfoList: [],
  workpieceDetailList: [],
  workpieceDetailHistoriesList: [],
  vouchersOnLienViet: [],
  vouchersUsed: [],
  vouchersWaitForApproval: [],
  vouchersCheckHistories: [],
  //
  vouchersOnMobilePortal: []
}

// getters
const getters = {
  VOUCHER_ISSUANCES_DATA: state => {
    return state.workpieceList
  },
  VOUCHERS_DATA: state => {
    return state.eVouchers
  },
  REMAIN_QUANTITY_VOUCHER_IN_ISSUANCE_DATA: state => {
    return state.remainQuantityVoucherInIssuance
  },
  QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA: state => {
    return state.quantityVoucherAvailableOfEntity
  },
  HISTORY_TRANSACTION_VOUCHER_DATA: state => {
    return state.historyTransactionVoucher
  },
  VOUCHER_DETAIL_DATA: state => {
    return state.voucherDetail
  },
  VOUCHERS_LOCKED_USING_DATA: (state, payload) => {
    return state.voucherLockedUsing
  },
  ISSUE_VOUCHER_REQUEST_BY_TYPE_DATA: state => {
    return state.issueVoucherRequestList
  },
  VOUCHER_STATISTICS_DATA: state => {
    return state.statisticsVoucher
  },
  STATISTICS_VOUCHER_DETAIL_DATA: state => {
    return state.statisticsDetailVoucher
  },
  VOUCHER_HISTORY_DATA: state => {
    return state.voucherHistories
  },
  VOUCHER_DISTRIBUTION_NOTIFICATION_DATA: state => {
    return state.voucherDistributionNotifications
  },
  ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA: state => {
    return state.issuanceOfVoucherAlreadyExistList
  },
  REQUEST_TRANSFER_FROM_AGENT_DATA: state => {
    return state.requestTransferVoucherFromAgentList
  },
  VOUCHER_ISSUANCE_DETAIL_DATA: state => {
    return state.workpieceDetail
  },
  VOUCHER_INFO_DISTRIBUTED_TO_AGENT_DATA: state => {
    return state.voucherDistributedToAgent
  },
  GET_ISSUANCE_LIST_DATA: state => {
    return state.issuanceList
  },
  VOUCHER_LOOKUP_INFO_DATA: state => {
    return state.voucherInfoList
  },
  VOUCHER_PRINT_DATA: state => {
    return state.vouchersPrintData
  },
  VOUCHER_PROMOTION_LOOKUP_DATA: state => {
    return state.voucherPromotionInfoList
  },
  WORKPIECE_LIST_DETAIL_DATA: state => {
    return state.workpieceDetailList
  },
  WORKPIECE_DETAIL_HISTORIES_DATA: state => {
    return state.workpieceDetailHistoriesList
  },
  VOUCHER_PRODUCT_ON_LIEN_VIET_DATA: state => {
    return state.vouchersOnLienViet
  },
  VOUCHERS_USED_DATA: state => {
    return state.vouchersUsed
  },
  VOUCHERS_RECONCILIATION_DATA: state => {
    return state.vouchersWaitForApproval
  },
  VOUCHERS_CHECK_HISTORIES_DATA: state => {
    return state.vouchersCheckHistories
  },
  VOUCHER_PRODUCT_ON_MOBILE_PORTAL_DATA: state => {
    return state.vouchersOnMobilePortal
  }
}

// actions
const actions = {
  ADD_VOUCHER_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/create_voucher_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_ISSUANCE_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_issuance_list/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_ISSUANCE_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_issuance_of_voucher_already_exist_list/`,
        payload,
        function (res) {
          context.commit('GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_FOR_EXPORTING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_for_export/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  DELETE_VOUCHER_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/evoucher/${payload.id}/delete_voucher_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_VOUCHER_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/evoucher/${payload.id}/update_voucher_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_DISTRIBUTED_VOUCHER_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/evoucher/${payload.id}/update_distributed_voucher_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  TRANSACTION_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher/transaction/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  TRANSACTION_VOUCHER_TO_GROUP_CUSTOMER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher/transaction/distribute_to_group_customer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  CREATE_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_REMAIN_VOUCHER_QUANTITY_IN_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_remain_voucher_quantity_in_issuance/`,
        payload,
        function (res) {
          context.commit('GET_REMAIN_VOUCHER_QUANTITY_IN_ISSUANCE_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_quantity_voucher_available_of_entity/`,
        payload,
        function (res) {
          context.commit('GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_HISTORY_TRANSACTION_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_history_transaction_voucher/`,
        payload,
        function (res) {
          context.commit('GET_HISTORY_TRANSACTION_VOUCHER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONFIRM_VOUCHER_ASSIGNED: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/confirm_voucher_assigned/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  CONFIRM_USED_MULTIPLE_VOUCHERS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/confirm_used_multiple_vouchers/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  PROCESS_VOUCHERS_LOCKED_USING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/process_vouchers_locked_using/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  VALIDATE_USING_VOUCHER_STATUS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/validate_using_voucher_status/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHERS_LOCKED_USING: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/get_vouchers_locked_using/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHERS_LOCKED_USING_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_VOUCHER_USAGE_REFERENCE_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/update_voucherusage_reference_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_VOUCHER_USAGE_ORDER_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/update_voucherusage_order_code/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/${payload.id}/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_DETAIL_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SEND_VOUCHER_REQUEST_TO_PARENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/issue_voucher_request/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ISSUE_VOUCHER_REQUEST_BY_TYPE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/issue_voucher_request/`,
        payload,
        function (res) {
          context.commit('GET_ISSUE_VOUCHER_REQUEST_BY_TYPE_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ISSUE_VOUCHER_REQUEST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/${currentEntityId()}/issue_voucher_request/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_ISSUE_VOUCHER_REQUEST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/${currentEntityId()}/issue_voucher_request/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  APPROVE_CANCEL_ISSUE_VOUCHER_REQUEST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/issue_voucher_request/${payload.id}/approve_cancel_issue_voucher_request/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_STATISTICS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_statistics/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_STATISTICS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REQUEST_CONFIRM_VOUCHER_USED_FROM_CUSTOMER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/confirm_used_voucher/request_confirm_voucher_used_from_customer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DETAIL_VOUCHER_STATISTICS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/detail_voucher_statistics/`,
        payload,
        function (res) {
          context.commit('DETAIL_VOUCHER_STATISTICS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_HISTORY_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/voucher/history/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_HISTORY_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/voucher_distribution_notification/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DISTRIBUTE_VOUCHER_TO_CUSTOMER_FROM_EXCEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/distribute_voucher_to_customer_from_excel/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  DELETE_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/evoucher/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  TRANSFER_TO_ANOTHER_AGENT_SAME_LEVEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher/transaction/transfer_to_another_agent_same_level/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_REQUEST_TRANSFER_FROM_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher/transaction/get_request_transfer_from_agent/`,
        payload,
        function (res) {
          context.commit('GET_REQUEST_TRANSFER_FROM_AGENT_SUCCESS', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  APPROVE_CANCEL_REQUEST_TRANSFER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher/transaction/approve_cancel_request_transfer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_ISSUANCE_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher/workpiece/${payload.id}/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_ISSUANCE_DETAIL_SUCCESS', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_INFO_DISTRIBUTED_TO_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher/workpiece/get_voucher_info_distributed_to_agent/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_INFO_DISTRIBUTED_TO_AGENT_SUCCESS', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  STOP_SENDING_REQUESTS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_request/${payload.id}/stop_sending_requests/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_ISSUANCE_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher/issuance/`,
        payload,
        function (res) {
          context.commit('GET_ISSUANCE_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  LOOKUP_VOUCHER_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/lookup_voucher_info/`,
        payload,
        function (res) {
          context.commit('LOOKUP_VOUCHER_INFO_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  SEND_VOUCHER_INFO_TO_EMAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/resend_voucher_info/send_voucher_info_to_email/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHERS_PRINT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_vouchers_print/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHERS_PRINT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PRINT_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/print_voucher/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  LOOKUP_VOUCHER_PROMOTION_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/lookup_voucher_promotion_info/`,
        payload,
        function (res) {
          context.commit('LOOKUP_VOUCHER_PROMOTION_INFO_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  USED_VOUCHER_PROMOTION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/used_voucher_promotion_by_supplier/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  ON_ENABLE_DISABLE_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/voucher/workpiece/${payload.id}/disable/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_WORKPIECE_DETAIL_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher/workpiece/get_workpiece_detail/`,
        payload,
        function (res) {
          context.commit('GET_WORKPIECE_DETAIL_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_WORKPIECE_DETAIL_HISTORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher/workpiece/get_workpiece_detail_histories/`,
        payload,
        function (res) {
          context.commit('GET_WORKPIECE_DETAIL_HISTORIES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_INFO_FOR_EXTERNAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  BUY_VOUCHER_FOR_EXTERNAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/buy/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  ON_GIVE_VOUCHER_TO_HARAVAN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/give_voucher_to_haravan/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_PRODUCT_ON_LIEN_VIET: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_product_on_lien_viet/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_PRODUCT_ON_LIEN_VIET_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHERS_USED: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_vouchers_used/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHERS_USED_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  CREATE_VOUCHERS_RECONCILIATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/create_voucher_reconciliation/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHERS_RECONCILIATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_reconciliation/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHERS_RECONCILIATION_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  SUBMIT_RECONCILIATION_REQUEST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/submit_reconciliation_request/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  PROCESS_APPROVAL_RECONCILIATION_ITEM: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/process_approval_reconciliation_item/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  PROCESS_APPROVAL_VOUCHER_RECONCILIATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/process_approval_voucher_reconciliation/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  DELETE_RECONCILIATION_REQUEST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/evoucher/${payload.id}/delete_reconciliation_request/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_RECONCILIATION_HISTORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_reconciliation_histories/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_RECONCILIATION_HISTORIES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_CREATE_ORDER_CODE_PREPARE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_create_order_prepare_on_third_party/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHERS_RECONCILIATION_SUMMARY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_reconciliation_summary/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHERS_RECONCILIATION_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_reconciliation_detail/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  //
  CREATE_ISSUANCE_AND_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/create_issuance_and_voucher/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHERS_USING_EXPIRATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_using_expiration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHERS_USING_EXPIRATION_BY_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_using_expiration_by_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  EXTEND_VOUCHER_USING_EXPIRATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/extend_voucher_using_expiration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  EXTEND_VOUCHER_USING_EXPIRATION_BY_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/extend_voucher_using_expiration_by_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_VOUCHER_PRODUCT_ON_MOBILE_PORTAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_voucher_product_on_mobile_portal/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_PRODUCT_ON_MOBILE_PORTAL_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  GET_PRODUCT_ORDER_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/get_order_product_detail/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  APPROVE_BAO_KIM_ORDER_PAYMENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/bao_kim_payment/approve_bao_kim_order_payment/`, // TODO: entity
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  ENABLE_OR_DISABLE_VOUCHER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/enable_or_disable_voucher/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_PRODUCT_ORDER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PRODUCT_ORDER_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRODUCT_ORDER_NOT_LOGIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CANCEL_VOUCHER_PRODUCT_ORDER_NOT_LOGIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/cancel_order_product_not_login/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  TRANSACTION_FROM_CUSTOMER_TO_CUSTOMER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher/transaction/transaction_from_customer_to_customer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  USE_VOUCHER_BY_EXCEL_FILE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/issue_voucher_queue/use_voucher_by_excel_file/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  APPROVAL_VOUCHER_ISSUANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher/workpiece/approval_voucher_issuance/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  }
}

// mutations
const mutations = {
  GET_HISTORY_TRANSACTION_VOUCHER_HANDLER: (state, payload) => {
    state.historyTransactionVoucher = payload
  },
  GET_VOUCHER_ISSUANCE_LIST_HANDLER: (state, payload) => {
    let workpieceList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.workpieceList = payload.results ? { results: workpieceList, paginate: page } : payload
  },
  GET_VOUCHER_LIST_HANDLER: (state, payload) => {
    let eVouchers = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.eVouchers = { results: eVouchers, paginate: page }
  },
  GET_REMAIN_VOUCHER_QUANTITY_IN_ISSUANCE_HANDLER: (state, payload) => {
    state.remainQuantityVoucherInIssuance = payload.data
  },
  GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_HANDLER: (state, payload) => {
    state.quantityVoucherAvailableOfEntity = payload.data
  },
  GET_VOUCHERS_LOCKED_USING_HANDLER: (state, payload) => {
    let vouchersUsing = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherLockedUsing = { results: vouchersUsing, paginate: page }
  },
  GET_VOUCHER_DETAIL_HANDLER: (state, payload) => {
    state.voucherDetail = payload.data
  },
  GET_ISSUE_VOUCHER_REQUEST_BY_TYPE_HANDLER: (state, payload) => {
    let issueVoucherRequestList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.issueVoucherRequestList = { results: issueVoucherRequestList, paginate: page }
  },
  GET_VOUCHER_STATISTICS_HANDLER: (state, payload) => {
    state.statisticsVoucher = payload
  },
  DETAIL_VOUCHER_STATISTICS_HANDLER: (state, payload) => {
    state.statisticsDetailVoucher = payload
  },
  GET_VOUCHER_HISTORY_LIST_HANDLER: (state, payload) => {
    let voucherHistories = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherHistories = { results: voucherHistories, paginate: page }
  },
  GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST_HANDLER: (state, payload) => {
    state.voucherDistributionNotifications = payload
  },
  GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST_HANDLER: (state, payload) => {
    state.issuanceOfVoucherAlreadyExistList = payload
  },
  GET_REQUEST_TRANSFER_FROM_AGENT_SUCCESS: (state, payload) => {
    let requestTransferVoucher = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.requestTransferVoucherFromAgentList = payload.results ? { results: requestTransferVoucher, paginate: page } : payload
  },
  GET_VOUCHER_ISSUANCE_DETAIL_SUCCESS: (state, payload) => {
    state.workpieceDetail = payload
  },
  GET_VOUCHER_INFO_DISTRIBUTED_TO_AGENT_SUCCESS: (state, payload) => {
    let voucherDistributedToAgent = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherDistributedToAgent = payload.results ? { results: voucherDistributedToAgent, paginate: page } : payload
  },
  GET_ISSUANCE_LIST_HANDLER: (state, payload) => {
    state.issuanceList = payload
  },
  LOOKUP_VOUCHER_INFO_HANDLER: (state, payload) => {
    let voucherInfoList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherInfoList = payload.results ? { results: voucherInfoList, paginate: page } : payload
  },
  GET_VOUCHERS_PRINT_HANDLER: (state, payload) => {
    state.vouchersPrintData = payload
  },
  LOOKUP_VOUCHER_PROMOTION_INFO_HANDLER: (state, payload) => {
    let voucherPromotionInfoList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherPromotionInfoList = payload.results ? { results: voucherPromotionInfoList, paginate: page } : payload
  },
  GET_WORKPIECE_DETAIL_LIST_HANDLER: (state, payload) => {
    let workpieceDetailList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.workpieceDetailList = payload.results ? { results: workpieceDetailList, paginate: page } : payload
  },
  GET_WORKPIECE_DETAIL_HISTORIES_HANDLER: (state, payload) => {
    let workpieceDetailHistoriesList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.workpieceDetailHistoriesList = payload.results ? { results: workpieceDetailHistoriesList, paginate: page } : payload
  },
  GET_VOUCHER_PRODUCT_ON_LIEN_VIET_HANDLER: (state, payload) => {
    let vouchersOnLienViet = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.vouchersOnLienViet = payload.results ? { results: vouchersOnLienViet, paginate: page } : payload
  },
  GET_VOUCHERS_USED_HANDLER: (state, payload) => {
    let vouchersUsed = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.vouchersUsed = payload.results ? { results: vouchersUsed, paginate: page } : payload
  },
  GET_VOUCHERS_RECONCILIATION_HANDLER: (state, payload) => {
    let vouchersWaitForApproval = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.vouchersWaitForApproval = payload.results ? { results: vouchersWaitForApproval, paginate: page } : payload
  },
  GET_VOUCHER_RECONCILIATION_HISTORIES_HANDLER: (state, payload) => {
    let vouchersCheckHistories = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.vouchersCheckHistories = payload.results ? { results: vouchersCheckHistories, paginate: page } : payload
  },
  GET_VOUCHER_PRODUCT_ON_MOBILE_PORTAL_HANDLER: (state, payload) => {
    let vouchersOnMobilePortal = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.vouchersOnMobilePortal = payload.results ? { results: vouchersOnMobilePortal, paginate: page } : payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
