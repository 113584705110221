<template>
  <div class="addressContainer">
    <v-icon
      class="mr-1"
      size="16">mdi-map-marker</v-icon>
    <span
      :class="['addressTitle']"
      @click="onOpenAddress($event)">
      <slot>Address</slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    longitude: { type: Number | String, default: () => null },
    latitude: { type: Number | String, default: () => null },
    province: { type: Number | String, default: () => null },
    district: { type: Number | String, default: () => null },
    ward: { type: Number | String, default: () => null },
    street: { type: String, default: () => null }
  },
  computed: {
    googlemapUrl () {
      return `https://www.google.com/maps/place/${this.latitude},${this.longitude}/@${this.latitude},${this.longitude},19z`
      // return `https://maps.google.com/?q=${this.latitude},${this.longitude}`
    }
  },
  methods: {
    onOpenAddress: function (event) {
      if (this.latitude && this.longitude) {
        window.open(this.googlemapUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addressContainer {
  display: flex;
  flex-direction: row;
}
.addressTitle {
  display: block;
  // white-space: nowrap;
  cursor: pointer;
}
.addressTitle:hover {
  text-decoration: underline;
}
</style>
