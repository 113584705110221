<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex
        xs12
        md12>
        <v-btn
          :disabled="!hasUpdatePermission"
          small
          color="success"
          style="float: right;"
          @click="onShowModalAddUser">
          {{ $t("actionPermission.add_user") }}
        </v-btn>
      </v-flex>
      <v-flex
        xs12
        md12>
        <v-data-table
          :headers="userHeaders"
          :items="userList"
          :no-data-text="$t('common.noDataAvailable')"
          :hide-actions="true"
          :loading="isLoading"
        >
          <template
            slot="headerCell"
            slot-scope="{ header }">
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)" />
          </template>
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td class="text-xs-center">{{ item.stt }}</td>
            <td class="text-xs-center">
              <div
                v-if="isNull(item.urlAvatar) || item.urlAvatar == ''"
                style="display: inline-block;">
                <avatar
                  :username="item.fullname"
                  style="width: 45px; height: 45px;" />
              </div>
              <div v-else>
                <avatar
                  :src="item.urlAvatar"
                  style="width: 45px; height: 45px;" />
              </div>
            </td>
            <!-- <td class="text-xs-center">{{ item.username }}</td> -->
            <td class="text-xs-center">{{ item.fullname }}</td>
            <td class="text-xs-center">{{ item.email }}</td>
            <td class="text-xs-center">{{ $t(getRoleInEntityName(item.roleInEntity)) }}</td>
            <td class="text-xs-center">
              <v-tooltip
                top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="!hasUpdatePermission"
                    class="mx-2"
                    fab
                    small
                    color="error"
                    style="color: #ffffff;"
                    v-on="on"
                    @click="onShowModalRemoveUser(item)"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('users.remove') }}</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:no-results>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-box">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
        <pagination
          :total="userPaginate.totalPage"
          :current-page="userPaginate.currentPage"
          :row-per-page="userPaginate.rowPerPage"
          class="mt-2"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <userEntityManagementModal
      ref="userEntityManagementModal"
      @addUserInEntitySuccess="onAddUserInEntitySuccess" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onRemoveUserFromEntity"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import Avatar from 'vue-avatar'
import EntityType from 'enum/entityType'
import UserEntityManagementModal from 'Views/Agent/UserInEntityManagementModal'
export default {
  components: {
    Avatar,
    UserEntityManagementModal
  },
  props: {
    entityId: { type: Number, default: () => null },
    entityType: { type: Number, default: () => null },
    entityName: { type: String, default: () => '' }
  },
  data () {
    return {
      userList: [],
      userHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.logo',
          value: 'avatar',
          align: 'center'
        },
        // {
        //   sortable: false,
        //   text: 'users.username',
        //   value: 'username',
        //   align: 'center'
        // },
        {
          sortable: false,
          text: 'users.fullName',
          value: 'fullname',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.email',
          value: 'email',
          align: 'center'
        },
        {
          sortable: false,
          text: 'users.userPrivileges',
          value: 'roleInEntity',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.actions',
          value: 'actions',
          align: 'center'
        }
      ],
      userPaginate: {
        currentPage: 1,
        totalPage: 1,
        rowPerPage: 1
      },
      isLoading: false,
      entitySelectedUserId: null,
      hasUpdatePermission: false,
      confirmModalTitle: ''
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'USERS_IN_ENTITY_DATA'
    ])
  },
  watch: {
    USERS_IN_ENTITY_DATA () {
      let res = this.USERS_IN_ENTITY_DATA
      this.userPaginate.currentPage = res.paginate.currentPage
      this.userPaginate.totalPage = res.paginate.totalPage
      this.userPaginate.rowPerPage = res.paginate.perPage / 2
      let results = res.results
      for (let i = 0, size = results.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: results[i].id,
          fullname: functionUtils.concatFullname(results[i].last_name, results[i].first_name),
          email: results[i].email,
          username: results[i].username,
          urlAvatar: results[i].url_avatar,
          roleInEntity: results[i].role_type
        }
        this.userList.push(userObj)
      }
      this.isLoading = false
    },
    entityId () {
      this.getUsersInEntity()
    },
    entityType () {
      this.hasUpdatePermission = this.checkUpdatePermission()
    }
  },
  created () {
    this.getUsersInEntity()
    this.hasUpdatePermission = this.checkUpdatePermission()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Get users entity
     */
    getUsersInEntity: function () {
      this.userList = []
      if (!functionUtils.isNull(this.entityId)) {
        this.userList = []
        this.isLoading = true
        let filter = {
          params: {
            page: this.userPaginate.currentPage,
            entityId: this.entityId
          }
        }
        this.GET_USERS_IN_ENTITY(filter)
      }
    },
    getRoleInEntityName: function (role) {
      return functionUtils.roleInEntityName(role)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    checkUpdatePermission: function () {
      if (this.isAdmin()) {
        return true
      }
      if (!this.isEntityDisabled()) {
        if (this.entityType === EntityType.SITE) {
          return this.hasAction('update_site')
        }
        if (this.entityType === EntityType.SUPPLIER) {
          return this.hasAction('update_supplier')
        }
        if (this.entityType === EntityType.AGENT) {
          return this.hasAction('update_agent')
        }
      }
      return false
    },
    /**
     * Show modal add user
     */
    onShowModalAddUser: function () {
      if (this.hasUpdatePermission) {
        this.$refs.userEntityManagementModal.onShowModal(this.entityId, this.entityType)
      }
    },
    /**
     * Add user in entity success
     */
    onAddUserInEntitySuccess: function () {
      this.getUsersInEntity()
    },
    onShowModalRemoveUser: function (user) {
      if (this.hasUpdatePermission) {
        this.confirmModalTitle = this.$t('users.removeUserMessage', {
          user: user.fullname + ' <' + user.email + '>',
          entity: this.entityName })
        this.entitySelectedUserId = user.id
        this.$refs.confirmModal.onShowModal()
      }
    },
    onRemoveUserFromEntity: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (this.hasUpdatePermission) {
        let filter = {
          entityId: this.entityId,
          userId: this.entitySelectedUserId
        }
        this.DELETE_ENTITY_IN_USER(filter).then(
          function () {
            // this.ON_SHOW_TOAST({
            //   'message': this.$t('common.success'),
            //   'styleType': ToastType.SUCCESS
            // })
            this.getUsersInEntity()
          }.bind(this)
        )
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_USERS_IN_ENTITY',
      'DELETE_ENTITY_IN_USER'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
