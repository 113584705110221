<template>
  <v-toolbar
    id="core-toolbar"
    app
    flat
    prominent
    style="background: #eee;"
  >
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="tertiary--text font-weight-light"
      >
        <v-btn
          class="default v-btn--simple"
          dark
          icon
          @click.stop="onClickBtn"
        >
          <v-icon color="success">mdi-view-list</v-icon>
        </v-btn>
        {{ $t(title) }}
      </v-toolbar-title>
    </div>

    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <span class="mr-1">
          <v-icon v-if="entityType==EntityType.SUPPLIER">mdi-bank</v-icon>
          <v-icon v-else-if="entityType==EntityType.AGENT">mdi-truck-fast</v-icon>
          <v-icon v-else-if="entityType==EntityType.SITE">mdi-store</v-icon>
        </span>
        <span
          v-if="!isAdmin()"
          @click="showEntityOfUserList">{{ entityName }}
          <span
            v-if="isExpired"
            class="red--text">({{ $t('entity.state.expired') }})</span>
          <i class="ml-2 arrow down"/>
        </span>
        <!-- Notification -->
        <!-- <v-menu
          open-on-hover
          bottom
          left
          offset-y
          transition="slide-y-transition"
        >
          <router-link
            v-ripple
            slot="activator"
            class="toolbar-items"
            to=""
          >
            <v-badge
              color="error"
              overlap
            >
              <template slot="badge">
                {{ $t('notification.new') }}
              </template>
              <v-icon color="tertiary">mdi-bell</v-icon>
            </v-badge>
          </router-link>
          <v-card>
            <v-list
              dense
              style="max-height: 200px; overflow: auto;">
              <v-list-tile
                v-for="(notification, index) in notifications"
                :key="notification.title + index"
                class="break-line"
                style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #e5e5e5;"
                @click="goScreenVoucher($event)"
              >
                <v-list-tile-title
                  v-text="notification.title"
                />
                <v-list-tile-subTitle
                  v-text="notification.date_created"
                />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu> -->
        <!-- Language -->
        <v-menu
          open-on-hover
          bottom
          left
          offset-y
          transition="slide-y-transition"
        >
          <router-link
            v-ripple
            slot="activator"
            class="toolbar-items"
            to=""
          >
            <v-badge
              color="error"
              overlap
            >
              <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
            </v-badge>
          </router-link>
          <v-card>
            <v-list dense>
              <v-list-tile
                v-for="language in languages"
                :key="language.locale"
                @click="changeLanguage(language)"
              >
                <v-list-tile-title
                  v-text="$t(language.name)"
                />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
        <!-- Dashboard -->
        <router-link
          v-ripple
          class="toolbar-items"
          to="/dashboard"
        >
          <v-icon color="tertiary">mdi-view-dashboard</v-icon>
        </router-link>
        <!-- User Profile -->
        <!-- :close-on-content-click="false" -->
        <v-menu
          :dark="dark"
          :nudge-width="350"
          open-on-hover
          bottom
          left
          transition="slide-y-transition"
          offset-y>
          <template
            v-slot:activator="{ on }"
            style="float: right;">
            <div
              v-ripple
              class="toolbar-items"
              style="cursor: pointer"
              v-on="on"
            >
              <v-icon color="tertiary">mdi-account</v-icon>
            </div>
          </template>
          <v-card>
            <v-list>
              <v-list-tile avatar>
                <!-- Avatar -->
                <div class="mr-2">
                  <avatar :username="currentAccount.fullName" />
                </div>
                <!-- Content -->
                <v-list-tile-content>
                  <v-list-tile-title>{{ currentAccount.fullName }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ currentAccount.email }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile>
                  <v-list-tile-title class="group-style">{{ $t(getRoleName()) }}</v-list-tile-title>
                </v-list-tile>
              </v-list-tile>
            </v-list>
            <v-divider style="margin: 0;"/>
            <!-- Dark mode -->
            <v-list>
              <v-list-tile>
                <v-list-tile-action>
                  <v-switch
                    v-model="dark"
                    color="success"/>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('common.darkMode') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
            <v-divider style="margin: 0;"/>
            <v-list>
              <!-- Decentralization -->
              <v-list-tile
                v-if="isAdmin()"
                @click="onGoToDecentralization()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-scale-balance</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('sideBar.decentralization') }}</v-list-tile-title>
              </v-list-tile>
              <!-- Account -->
              <v-list-tile
                v-if="isAdmin()"
                @click="onGoToAccount()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-account-edit</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('sideBar.account') }}</v-list-tile-title>
              </v-list-tile>
              <!-- Profile -->
              <v-list-tile @click="onOpenUserProfile()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-account</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('userProfile.userProfile') }}</v-list-tile-title>
              </v-list-tile>
              <!-- Entity info -->
              <v-list-tile
                v-if="!isAdmin()"
                @click="onOpenEntityInfo()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-home-city</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ entityType == EntityType.SUPPLIER ? $t('entity.supplierInfo') : ( entityType == EntityType.SITE ? $t('siteManagement.infoTitle') : $t('entity.agentInfo')) }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
            <v-divider style="margin: 0;"/>
            <!-- Logout -->
            <v-list>
              <v-list-tile @click="onLogOut()">
                <v-list-tile-action>
                  <v-icon
                    color="error">mdi-logout</v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="font-red-color">{{ $t('common.logOut') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
    <div>
      <entityOfUserModal
        ref="entityOfUserModal"
        @entitySelected="onSwitchEntity()" />
    </div>
  </v-toolbar>
</template>
<script>
import {
  mapMutations,
  mapGetters,
  mapActions
} from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import EntityOfUserModal from '../EntityOfUserModal'
import functionUtils from 'utils/functionUtils'
import SysRoleType from 'enum/sysRoleType'
import Avatar from 'vue-avatar'
import dateUtils from 'utils/dateUtils'
import EntityType from 'enum/entityType'
export default {
  components: {
    EntityOfUserModal,
    Avatar
  },
  data: () => ({
    userProfiles: [
      { key: 'name' },
      { key: 'profile' },
      { key: 'logOut' }
    ],
    notifications: [],
    title: null,
    responsive: false,
    fav: false,
    currentAccount: {
      fullName: '',
      sysRole: SysRoleType.USER,
      email: ''
    },
    dark: false,
    isExpired: false,
    entityName: '',
    entityType: null,
    EntityType: EntityType,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'languages',
      'selectedLocale',
      'GET_CURRENT_ACCOUNT',
      'VOUCHER_DISTRIBUTION_NOTIFICATION_DATA',
      'ENTITIES_OF_USER_DATA'
    ]),
    role: function () {
      return this.GET_CURRENT_ACCOUNT.id
    }
  },
  watch: {
    '$route' (val) {
      this.title = val.name
    },
    GET_CURRENT_ACCOUNT: function () {
      this.currentAccount.fullName = functionUtils.concatFullname(this.GET_CURRENT_ACCOUNT.lastname, this.GET_CURRENT_ACCOUNT.firstname)
      this.currentAccount.sysRole = this.GET_CURRENT_ACCOUNT && this.GET_CURRENT_ACCOUNT.sys_role
      this.currentAccount.email = this.GET_CURRENT_ACCOUNT && this.GET_CURRENT_ACCOUNT.email
      this.entityType = sessionStorage.getItem('entityType')
      this.roleInEntity = sessionStorage.getItem('roleInEntity')
      let entityId = sessionStorage.getItem('entityId')
      if (!this.isAdmin() && functionUtils.isNull(entityId)) {
        this.showEntityOfUserList()
      }
    },
    VOUCHER_DISTRIBUTION_NOTIFICATION_DATA () {
      let data = this.VOUCHER_DISTRIBUTION_NOTIFICATION_DATA
      this.notifications = []
      for (let i = 0, size = data.length; i < size; i++) {
        data[i].date_created = dateUtils.formatBeautyDate(data[i].date_created)
        this.notifications.push(data[i])
      }
    },
    ENTITIES_OF_USER_DATA () {
      let entityId = sessionStorage.getItem('entityId')
      if (!functionUtils.isNull(entityId)) {
        let entitySelected = this.ENTITIES_OF_USER_DATA.filter(element => {
          // eslint-disable-next-line eqeqeq
          return entityId == element.id
        })
        if (entitySelected.length > 0) {
          sessionStorage.setItem('entityName', entitySelected[0].name)
          sessionStorage.setItem('entityType', entitySelected[0].type)
          sessionStorage.setItem('entityImage', entitySelected[0].image)
          sessionStorage.setItem('entityPhone', entitySelected[0].phone)
          sessionStorage.setItem('entityWebsite', entitySelected[0].website)
          sessionStorage.setItem('roleInEntity', entitySelected[0].role_type)
          sessionStorage.setItem('expiredDateEntity', entitySelected[0].expired_date)
          sessionStorage.setItem('entityStatus', entitySelected[0].status)
          this.isExpired = functionUtils.isEntityLocked(entitySelected[0].status) || functionUtils.isEntityExpired(entitySelected[0].expired_date)
          this.entityName = sessionStorage.getItem('entityName')
        } else {
          // Saved entity has been remove from user, need to select another one
          if (!this.isAdmin()) {
            this.removeEntitySessionStorage()
            this.showEntityOfUserList()
          }
        }
      }
    }
  },
  created () {
    this.entityType = sessionStorage.getItem('entityType')
    this.roleInEntity = sessionStorage.getItem('roleInEntity')
    this.GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST()
  },
  mounted () {
    // Responsive inverted
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    let entityName = sessionStorage.getItem('entityName')
    if (!functionUtils.isNull(entityName)) {
      this.entityName = entityName
    } else {
      this.entityName = this.$t('common.noSelectedProject')
    }
    this.isExpired = functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    this.title = this.$route.name
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    getRoleName: function () {
      if (this.currentAccount.sysRole === SysRoleType.ADMIN) {
        return 'roles.type.admin'
      }
      return functionUtils.roleInEntityName(this.roleInEntity)
    },
    /**
     * Open entity info
     */
    onOpenEntityInfo: function () {
      let entityId = sessionStorage.getItem('entityId')
      this.$router.push({
        path: `/entity-detail/${entityId}`,
        params: {
          id: entityId
        }
      })
    },
    /**
     * Go screen voucher
     */
    goScreenVoucher: function (event) {
      event.preventDefault()
      this.$router.push({
        path: 'e-voucher-list'
      })
    },
    /**
     * Get name project
     */
    onSwitchEntity: function () {
      let entityName = sessionStorage.getItem('entityName')
      if (!functionUtils.isNull(entityName)) {
        this.entityName = entityName
      } else {
        this.entityName = this.$t('common.noSelectedProject')
      }
      this.isExpired = functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Show entity of user list
     */
    showEntityOfUserList: function () {
      this.$refs.entityOfUserModal.onShowModal()
    },
    /**
     * On go to account
     */
    onGoToAccount: function () {
      this.$router.push({
        name: 'sideBar.userManagement'
      })
    },
    /**
     * On go to decentralization
     */
    onGoToDecentralization: function () {
      this.$router.push({
        name: 'sideBar.groupManagement'
      })
    },
    /**
     * Remove entity session storage
     */
    removeEntitySessionStorage: function () {
      sessionStorage.removeItem('entityId')
      sessionStorage.removeItem('entityName')
      sessionStorage.removeItem('entityType')
      sessionStorage.removeItem('entityImage')
      sessionStorage.removeItem('entityPhone')
      sessionStorage.removeItem('entityWebsite')
      sessionStorage.removeItem('roleInEntity')
      sessionStorage.removeItem('expiredDateEntity')
      sessionStorage.removeItem('entityStatus')
    },
    /**
     * Log out
     */
    onLogOut: function () {
      let token = isCookieEnabled() ? getCookie('token') : null
      this.LOGOUT(token).then(
        function () {
          this.removeEntitySessionStorage()
          this.$router.push({
            name: 'login'
          })
        }.bind(this)
      ).catch(
        function () {
          this.removeEntitySessionStorage()
          this.$router.push({
            name: 'login'
          })
        }.bind(this)
      )
    },
    ...mapMutations(['setDrawer', 'toggleDrawer']),
    viewEventList: function () {
      this.$refs.modalEventList.showModalEventList()
    },
    /**
     * Open user profile
     */
    onOpenUserProfile () {
      this.$router.push({
        name: 'userProfile.userProfile',
        params: { id: this.GET_CURRENT_ACCOUNT.id }
      })
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick () {
      //
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
    ...mapActions([
      'LOGOUT',
      'GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
  #core-toolbar a {
    text-decoration: none;
  }
  .group-style {
    border: 1px solid #f44336!important;
    padding: 2px 5px 25px 5px;
    border-radius: 5px;
    background-color: #f44336!important;
    color: white;
    font-size: 13px;
  }
  .font-red-color {
    color: #f44336 !important;
  }
  .v-toolbar .v-toolbar__content .v-toolbar__items .toolbar-items:hover {
    background: #e5e5e5;
  }
  .arrow {
    border: solid #495057;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .down {
    margin-bottom: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  /deep/.break-line .v-list__tile {
    display: block;
  }
</style>
