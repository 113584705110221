import moment from 'moment'

const STATIC_FORMAT_DATE = 'DD/MM/YYYY'
const STATIC_FORMAT_DATE_TIME = 'DD/MM/YYYY HH:mm'
const STATIC_FORMAT_DATE_SQL = 'YYYY-MM-DD'
const STATIC_FORMAT_DATE_TIME_ZONE = 'YYYY-MM-DD HH:mm:ss.SSSZZZ'
const STATIC_FORMAT_DATE_TIME_ZONE_T = 'YYYY-MM-DDTHH:mm:ss.sssZ'

/**
 * Format date
 * @param {*} date
 * @param {*} fromFormat
 * @param {*} toFormat
 */
const formatDate = function (date, fromFormat, toFormat) {
  if (date === undefined || date === null || date === '') {
    return ''
  }
  return moment(date, fromFormat).format(toFormat)
}

const formatSqlDate = function (date, defaultValue = '') {
  if (date === '') {
    return defaultValue
  }
  return moment(date, STATIC_FORMAT_DATE).format(STATIC_FORMAT_DATE_TIME_ZONE_T)
}

const formatBeautyDate = function (date, defaultValue = '') {
  if (date === undefined || date === null || date === '') {
    return defaultValue
  }
  return moment(date, STATIC_FORMAT_DATE_TIME_ZONE).format(STATIC_FORMAT_DATE)
}

const formatBeautyDateTime = function (date, defaultValue = '') {
  if (date === undefined || date === null || date === '') {
    return defaultValue
  }
  return moment(date, STATIC_FORMAT_DATE_TIME_ZONE).format(STATIC_FORMAT_DATE_TIME)
}

const formatCompareDate = function (date, defaultValue = '') {
  if (date === undefined || date === null || date === '') {
    return defaultValue
  }
  return moment(date, STATIC_FORMAT_DATE).format(STATIC_FORMAT_DATE_SQL)
}

const formatComponentDate = function (date, defaultValue = '') {
  if (date === undefined || date === null || date === '') {
    return defaultValue
  }
  return moment(date, STATIC_FORMAT_DATE_TIME_ZONE).format(STATIC_FORMAT_DATE_SQL)
}

// Get date array between two date
const getDaysBetweenTwoDate = function (startDate, endDate, dateFormat) {
  var dateArray = []
  var currentDate = moment(moment(startDate, dateFormat))
  var stopDate = moment(moment(endDate, dateFormat))
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(dateFormat))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

// Get date array between two date
const getDatesBetweenTwoDate = function (startDate, endDate, dateFormat, type) {
  var dateArray = []
  var currentDate = moment(moment(startDate, dateFormat))
  var stopDate = moment(moment(endDate, dateFormat))
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(dateFormat))
    currentDate = moment(currentDate).add(1, type)
  }
  return dateArray
}

/**
 * Calculate number of days between two dates
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} formatDate
 */
const calculateNumberOfDaysBetweenTwoDates = function (startDate, endDate, formatDate) {
  if (startDate && endDate) {
    var start = moment(startDate, formatDate)
    var end = moment(endDate, formatDate)
    return end.diff(start, 'days')
  }
  return 0
}

const getRemainDays = function (endDate, defaultValue = null) {
  if (endDate === undefined || endDate === null || endDate === '') {
    return defaultValue
  }
  endDate = moment(endDate, STATIC_FORMAT_DATE_TIME_ZONE)
  var end = moment(endDate.format(STATIC_FORMAT_DATE_SQL)) // another date
  var now = moment(new Date()) // todays date
  var duration = moment.duration(end.diff(now))
  var miliseconds = duration.asMilliseconds()
  // Time calculations for days, hours, minutes and seconds
  let days = Math.floor(miliseconds / (1000 * 60 * 60 * 24))
  return days
}

export default {
  // Static variable
  STATIC_FORMAT_DATE,
  STATIC_FORMAT_DATE_SQL,
  STATIC_FORMAT_DATE_TIME_ZONE,
  STATIC_FORMAT_DATE_TIME_ZONE_T,
  STATIC_FORMAT_DATE_TIME,
  // Function
  formatDate,
  formatSqlDate,
  formatBeautyDate,
  formatBeautyDateTime,
  formatCompareDate,
  formatComponentDate,
  getDaysBetweenTwoDate,
  calculateNumberOfDaysBetweenTwoDates,
  getDatesBetweenTwoDate,
  getRemainDays
}
