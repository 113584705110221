export const languages = [
  {
    name: 'localize.english',
    locale: 'en',
    dataLocale: 'en_US'
  },
  {
    name: 'localize.vietnamese',
    locale: 'vi',
    dataLocale: 'vi_VI'
  }
]
