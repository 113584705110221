<template>
  <v-dialog
    v-model="isVisible"
    max-width="600px">
    <v-card>
      <material-card
        :title="title"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <span>{{ message }}</span>
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="hideModal"
        >{{ closeText }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="pressConfirm($event)"
        >{{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MessageModal from './messagemodal.js'

export default {
  data () {
    return {
      isVisible: false,
      title: '',
      message: '',
      confirmText: '',
      closeText: '',
      onConfirm: {}
    }
  },
  beforeMount () {
    // here we need to listen for emited events we declared those events inside plugin
    MessageModal.EventBus.$on('showModal', (params) => {
      this.showModal(params)
    })
  },
  methods: {
    pressConfirm: function (event) {
      // close the modal
      this.isVisible = false
      event.preventDefault()
      // this.$emit('onConfirm')
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === 'function') {
        // then run passed function
        this.onConfirm()
      }
    },
    hideModal: function (event) {
      event.preventDefault()
      // close the modal
      this.isVisible = false
    },
    showModal: function (params) {
      // making modal visible
      this.isVisible = true

      this.message = params.message
      // setting callback function
      this.onConfirm = params.onConfirm
      if (params.title === undefined || params.title === null) {
        this.title = this.$t('common.notification')
      } else {
        // setting title and text
        this.title = params.title
      }
      if (params.confirmText === undefined || params.confirmText === null) {
        this.confirmText = this.$t('common.confirm')
      } else {
        // setting title and text
        this.confirmText = params.confirmText
      }
      if (params.closeText === undefined || params.closeText === null) {
        this.closeText = this.$t('common.close')
      } else {
        // setting title and text
        this.closeText = params.closeText
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
</style>
