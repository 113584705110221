<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="1000px">
    <v-card>
      <material-card
        :title="$t('entity.configuration', {name: entity.name, type: $t(getEntityTypeName(entity.type))})"
        tile
        color="green"
        full-width
      >
        <tab-header
          :tab-items="tabItems"
          :selected-id="tabSelected"
          @onSelectTab="onSelectTab" />
        <div v-if="tabSelected==TabID.STAFFS">
          <user-in-entity-view
            :entity-id="entity.id"
            :entity-type="entity.type"
            :entity-name="entity.name" />
        </div>
        <div v-if="tabSelected==TabID.AGENTS">
          <children-entity-view
            :entity-id="entity.id"
            :entity-type="entity.type" />
        </div>
        <div v-if="tabSelected==TabID.SITES">
          <children-entity-view
            :entity-id="entity.id"
            :entity-type="entity.type" />
        </div>
        <div v-if="tabSelected==TabID.SMTP_CUSTOM">
          <supplierSmtpConfig :entity-id="entity.id" />
        </div>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
// import SysRoleType from 'enum/sysRoleType'
import TabHeader from 'Components/TabHeader.vue'
import ChildrenEntityView from '../views/Entities/ChildrenEntityView.vue'
import UserInEntityView from '../views/Entities/UserInEntityView.vue'
import SupplierSmtpConfig from '../views/Setting/SupplierSmtpConfig.vue'
const TAB_ID = {
  STAFFS: 1,
  AGENTS: 2,
  SITES: 3,
  SMTP_CUSTOM: 4
}
export default {
  components: {
    TabHeader,
    ChildrenEntityView,
    UserInEntityView,
    SupplierSmtpConfig
  },
  data () {
    return {
      isShowModal: false,
      TabID: TAB_ID,
      tabSelected: TAB_ID.STAFFS,
      tabItems: [
        {
          title: 'staffs.title',
          id: TAB_ID.STAFFS
        },
        {
          title: 'entity.agents',
          id: TAB_ID.AGENTS,
          hidden: false
        },
        {
          title: 'entity.sites',
          id: TAB_ID.SITES,
          hidden: false
        },
        {
          title: 'generalConfiguration.emailConfiguration',
          id: TAB_ID.SMTP_CUSTOM,
          hidden: true
        }
      ],
      entity: {
        id: null,
        name: null,
        type: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
  },
  methods: {
    getEntityTypeName: function () {
      return functionUtils.getEntityTypeName(this.entity.type)
    },
    onSelectTab: function ({ index, item }) {
      this.tabSelected = item.id
    },
    onShowModal: function (entity) {
      this.tabSelected = TAB_ID.STAFFS
      this.entity.id = entity.id
      this.entity.name = entity.name
      this.entity.type = entity.type
      this.tabItems[1].hidden = entity.type !== EntityType.SUPPLIER
      // this.tabItems[2].hidden = entity.type !== EntityType.SUPPLIER
      this.tabItems[2].hidden = true
      this.tabItems[3].hidden = entity.type !== EntityType.SUPPLIER
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
