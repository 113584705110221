import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

// state
const state = {
  storeChannelsList: [],
  storeChannelsWithAutoApprove: [],
  isLoginPartnership: false
}

// getters
const getters = {
  STORE_CHANNELS_LIST_DATA: state => {
    return state.storeChannelsList
  },
  STORE_CHANNELS_WITH_AUTO_APPROVE_DATA: state => {
    return state.storeChannelsWithAutoApprove
  },
  IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP: state => {
    return state.isLoginPartnership
  }
}

// actions
const actions = {
  GET_STORE_CHANNELS_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/store_channels/`,
        payload,
        function (res) {
          context.commit('GET_STORE_CHANNELS_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_STORE_CHANNEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/store_channels/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_STORE_CHANNEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/store_channels/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_STORE_CHANNEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/store_channels/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_STORE_CHANNEL_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/store_channels/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_STORE_CHANNELS_WITH_AUTO_APPROVE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/store_channels/get_store_channels_with_auto_approve/`,
        payload,
        function (res) {
          context.commit('GET_STORE_CHANNELS_WITH_AUTO_APPROVE_HANDLER', res)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ENABLE_DISABLE_AUTO_APPROVE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/store_channels/enable_disable_auto_approve/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_STORE_CHANNELS_LIST_HANDLER: (state, payload) => {
    let storeChannelsList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.storeChannelsList = payload.results ? { results: storeChannelsList, paginate: page } : payload.data
  },
  GET_STORE_CHANNELS_WITH_AUTO_APPROVE_HANDLER: (state, payload) => {
    let storeChannelsWithAutoApprove = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.storeChannelsWithAutoApprove = payload.results ? { results: storeChannelsWithAutoApprove, paginate: page } : payload.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
