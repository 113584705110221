<template>
  <div>
    <v-container
      :style="`width: ${
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? ''
          : '1200px !important'
      };`"
    >
      <div class="row justify-center">
        <div
          class="col-md-12 col-12">
          <v-card tile>
            <v-card-text>
              <div
                flat
                dark
                class="primary"
                style="display: flex">
                <v-btn
                  icon
                  dark
                  @click="callbackProductList">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title
                  dark
                  style="align-self: center; margin-left: 0"
                >
                  <h4
                    style="font-weight: bold !important; color: white"
                  >
                    {{
                      $t("productOrder.order") +
                        " " +
                        orderCode +
                        " " +
                        $t("productOrder.detail")
                    }}
                  </h4>
                </v-toolbar-title>
              </div>
              <v-data-table
                :headers="productHeaders"
                :items="productsList"
                :mobile-breakpoint="0"
                class="mt-6 elevation-1"
                hide-default-footer
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }"
                >
                  {{ $t(header.text) }}
                </template>
                <template v-slot:items="props">
                  <td>{{ props.item.stt }}</td>
                  <td>
                    <img
                      :src="props.item.image"
                      class="pt-2"
                      width="100px"
                      height="70px"
                    >
                  </td>
                  <td>
                    {{ props.item.product }}
                  </td>
                  <td>
                    {{ formatMoney(props.item.price) + " VND" }}
                  </td>
                  <td>
                    {{ "x" + props.item.quantity }}
                  </td>
                  <td>
                    {{ formatMoney(props.item.totalPrice) + " VND" }}
                  </td>
                </template>
              </v-data-table>
              <div
                v-if="
                  orderPaymentConfirmStatus ===
                    baoKimOrderConfirmStatus.COMPLETED
                "
                class="text-center font-large pa-2"
                style="color: blue"
              >
                {{ $t("baoKimConfig.paymentConfirm.completed") }}
              </div>
              <div
                v-else
                class="text-center font-large pa-2"
                style="color: red"
              >
                {{ $t("baoKimConfig.paymentConfirm.errorPaymentConfirm") }}
              </div>
              <v-btn
                color="warning"
                @click="callbackProductList">{{ $t("common.back") }}</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import dateUtils from "Utils/dateUtils";
import functionUtils from 'utils/functionUtils'
import baoKimOrderConfirmStatus from 'enum/BaoKimOrderConfirmStatus'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  components: {},
  data: () => ({
    productHeaders: [
      { text: '#', value: 'stt' },
      // { text: "productOrder.tableList.header.orderCode", value: "orderCode" },
      { text: 'productOrder.image', value: 'image' },
      { text: 'productOrder.tableList.header.product', value: 'product' },
      {
        text: 'productOrder.price',
        value: 'price'
      },
      { text: 'productOrder.quantity', value: 'quantity' },
      { text: 'productOrder.tableList.header.totalPrice', value: 'totalPrice' }
    ],
    productsList: [],
    orderCode: null,
    orderPaymentConfirmStatus: null,
    filterData: {
      code: null
    },
    baoKimOrderConfirmStatus: baoKimOrderConfirmStatus,
    agentDefaultId: null
  }),
  async created () {
    let query = this.$route.query
    this.filterData.code = query.mrc_order_id
    await this.getProductOrderDetail()
    //
    this.orderPaymentConfirmStatus = query.stat
    if (this.orderPaymentConfirmStatus === baoKimOrderConfirmStatus.COMPLETED) {
      await this.approveOnlineOrderPayment()
    }
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    getProductOrderDetail: function () {
      let filter = {
        params: {
          code: this.filterData.code
        }
      }
      this.GET_PRODUCT_ORDER_DETAIL(filter).then(
        function (res) {
          let data = res.data.length > 0 ? res.data[0] : null
          this.productsList = []
          if (data) {
            this.orderCode = data.code
            let productsList = data.items
            productsList.forEach((e, i) => {
              let obj = {
                stt: i + 1,
                product: e.workpiece.name,
                price: e.workpiece.value,
                quantity: e.quantity,
                totalPrice: e.workpiece.value * e.quantity,
                image: e.workpiece.image_url
              }
              this.productsList.push(obj)
            })
          }
        }.bind(this)
      )
    },
    /**
     * Get total price
     */
    getTotalPrice: function (items) {
      let total = 0
      for (let i = 0, size = items.length; i < size; i++) {
        total += items[i].workpiece.value * items[i].quantity
      }
      return functionUtils.convertFormatNumberToMoney(total.toString())
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    approveOnlineOrderPayment: async function () {
      let entityId = this.getPartnershipEntityId()
      let data = {
        productOrderCode: this.filterData.code,
        entityId: entityId,
        storeChannelId: this.storeChannelsIdInRouteParams()
      }
      await this.APPROVE_BAO_KIM_ORDER_PAYMENT(data).then(
        function () {
        }
      )
    },
    callbackProductList: function () {
      this.$router.push({
        name: 'MobilePortalProductList',
        params: {
          'storeChannelId': this.storeChannelsIdInRouteParams()
        }
      })
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    ...mapActions([
      'GET_PRODUCT_ORDER_DETAIL',
      'APPROVE_BAO_KIM_ORDER_PAYMENT',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  width: 100%;
}
.v-toolbar /deep/ .v-toolbar__content{
  height: 100% !important;
  float: left;
  display: flex;
}
.v-toolbar /deep/ .v-toolbar__content .v-toolbar__title {
  color: white;
}
</style>
