<template>
  <div class="actionContainer">
    <span
      v-if="!first"
      class="mx-2 grey--text">|</span>
    <span
      :class="['actionTitle text-capitalize']"
      @click="$emit('click', $event)">
      <slot>Action</slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    first: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
.actionContainer {
  display: flex;
  flex-direction: row;
}
.actionTitle {
  display: block;
  white-space: nowrap;
  cursor: pointer;
}
.actionTitle:hover {
  text-decoration: underline;
  font-weight: 500;
  // border-bottom: 1px dashed #5cb860;
}
</style>
