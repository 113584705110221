<template>
  <div>
    <div class="voucher-print-page">
      <div class="voucher-print-layout-content">
        <!-- Background image -->
        <img
          v-if="backgroundUrl"
          :src="backgroundUrl"
          class="voucher-print-image-background">
        <img
          v-else-if="forConasi"
          src="@/assets/print-evoucher-image/print_template_conasi.svg"
          class="voucher-print-image-background">
        <img
          v-else
          src="@/assets/print-evoucher-image/print_template_avatar.svg"
          class="voucher-print-image-background">
        <!-- Top -->
        <div>
          <img
            v-if="logoSupplierUrl"
            :src="logoSupplierUrl"
            class="voucher-print-logo-supplier-top logo-size">
          <img
            v-if="logoAgentUrl"
            :src="logoAgentUrl"
            class="voucher-print-logo-agent-top logo-size">
          <div :class="forConasi ? 'voucher-print-main-content-conasi' : 'voucher-print-main-content'">
            <span class="d-block my-2"><span class="voucher-print-text-title">{{ voucherName }}</span></span>
            <span class="d-block"><span class="voucher-print-text-bold">{{ $t('layoutPrint.customer') }}:</span> {{ customerName }}</span>
            <span class="d-block"><span class="voucher-print-text-bold">{{ $t('layoutPrint.email') }}:</span> {{ customerEmail }}</span>
            <span class="d-block"><span class="voucher-print-text-bold">{{ $t('layoutPrint.serial') }}:</span> {{ voucherSerial }}</span>
          </div>
          <div>
            <div :class="[forConasi ? 'voucher-print-second-content-conasi' : 'voucher-print-second-content', isHidePrice ? 'mt-2' : '']">
              <span class="d-block my-1">{{ $t('layoutPrint.providedBy') }}: <span class="voucher-print-text-bold">{{ supplierName }}</span></span>
              <span
                v-if="!isHidePrice"
                class="d-block my-1">{{ $t('layoutPrint.unitPrice') }}: <span class="voucher-print-text-bold">{{ getValueWithType(voucherPrice, voucherType) }}</span></span>
              <span class="d-block my-1">{{ $t('layoutPrint.expiredDate') }}: <span class="voucher-print-text-bold">{{ expiredDate }}</span></span>
            </div>
            <div :class="forConasi ? 'voucher-print-qrcode-conasi' : 'voucher-print-qrcode'">
              <qrcode-vue
                :value="activationCode ? activationCode : 'VOUCHER-QR-CODE'"
                :size="forConasi ? 60 : 70"
                level="H"
                render-as="svg"
              />
            </div>
          </div>
        </div>

        <!-- Mid -->
        <div>
          <div>
            <span class="voucher-print-title-description">{{ $t('layoutPrint.description') }}</span>
            <div class="voucher-print-content-description">
              <div :class="voucherDetails ? 'voucher-print-content-paragraph-with-details' : 'voucher-print-content-paragraph'">{{ voucherDescription }}</div>
            </div>
            <span class="voucher-print-title-condition-usage voucher-print-condition-offset">{{ $t('layoutPrint.termConditions') }}</span>
            <div class="voucher-print-content-condition-usage voucher-print-condition-offset">
              <div :class="voucherDetails ? 'voucher-print-content-paragraph-with-details' : 'voucher-print-content-paragraph'">{{ voucherTermOfConditions }}</div>
            </div>
          </div>
          <div :class="forConasi ? (voucherDetails ? 'voucher-print-contact-info-container-conasi-with-details' : 'voucher-print-contact-info-container-conasi') : 'voucher-print-contact-info-container'">
            <div
              class="pt-1"
              style="font-size: 12px;">
              <span>{{ $t('layoutPrint.contactInfo') }}:</span>
              <span class="d-block">{{ $t('layoutPrint.hotline') }}: <span class="voucher-print-text-bold">{{ supplierHotline }}</span>
                <span
                  v-if="supplierWebsite"
                  class="voucher-print-text-bold"> | {{ supplierWebsite }} </span>
              </span>
              <span
                v-if="forConasi && voucherDetails"
                v-html="$t('layoutPrint.detailsNote')" />
            </div>
          </div>
        </div>

        <!-- Bottom -->
        <div>
          <!-- Logo -->
          <img
            v-if="logoSupplierUrl"
            :src="logoSupplierUrl"
            :class="forConasi ? 'voucher-print-logo-supplier-bottom-conasi' : 'voucher-print-logo-supplier-bottom'"
            class="logo-size">
          <img
            v-if="!forConasi && logoAgentUrl"
            :src="logoAgentUrl"
            :class="forConasi ? 'voucher-print-logo-agent-bottom-conasi' : 'voucher-print-logo-agent-bottom'"
            class="logo-size">
        </div>
      </div>
    </div>

    <!-- Details Info pages -->
    <div
      v-if="forConasi && voucherDetails"
      class="voucher-print-page">
      <div class="voucher-print-layout-content">
        <img
          v-if="secondBackgroundUrl"
          :src="secondBackgroundUrl"
          class="voucher-print-image-background">
        <img
          v-else
          src="@/assets/print-evoucher-image/print_template_detail.svg"
          class="voucher-print-image-background">
        <div class="voucher-details-container">
          <div class="voucher-details-content">
            <div class="voucher-details-title">{{ $t('layoutPrint.detailsPageTitle') }}</div>
            <div class="voucher-details-paragraph">{{ voucherDetails }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import VoucherValue from 'enum/voucherValue'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  props: {
    customerName: { type: String, default: () => null },
    customerEmail: { type: String, default: () => null },
    voucherName: { type: String, default: () => null },
    voucherPrice: { type: Number, default: () => 0 },
    voucherType: { type: Number, default: () => VoucherValue.PRICE },
    voucherSerial: { type: String, default: () => null },
    activationCode: { type: String, default: () => null },
    supplierName: { type: String, default: () => null },
    supplierHotline: { type: String, default: () => null },
    supplierWebsite: { type: String, default: () => null },
    expiredDate: { type: String, default: () => null },
    voucherDescription: { type: String, default: () => null },
    voucherTermOfConditions: { type: String, default: () => null },
    voucherDetails: { type: String, default: () => null },
    logoSupplierUrl: { type: String, default: () => '' },
    logoAgentUrl: { type: String, default: () => '' },
    backgroundUrl: { type: String, default: () => null },
    secondBackgroundUrl: { type: String, default: () => null },
    isHidePrice: { type: Boolean, default: () => false }
  },
  data: () => ({
    forConasi: FOR_CONASI_TARGET,
    VoucherValue: VoucherValue
  }),
  methods: {
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date, null)
    },
    getValueWithType: function (value, type) {
      if (type === VoucherValue.PRICE) {
        return functionUtils.convertFormatNumberToMoney(value) + ' VND'
      } else {
        return value + '%'
      }
    }
    // getRandomCode: function (length) {
    //   return functionUtils.randomString(length)
    // }
  }
}
</script>
<style lang="scss" scoped>
@import url("/css/voucherprint.css");
</style>
