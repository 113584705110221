<template>
  <div>
    <!--BEGIN DROPDOWN Custom-->
    <div
      class="form-group"
      style="margin-bottom:0px;">
      <label
        v-show="item.showLabel"
        :class="[item.cssClassSm1, (item.required == true || item.required == undefined) ? 'required' : '']"
        for="location"
        class="control-label"
      >{{ item.titleLabel != undefined ? item.titleLabel : $t('property.asset.type') }}</label>
      <div
        :class="item.cssClassSm2"
        class="dropdown">
        <b-form-input
          :placeholder="placeHolder"
          :value="setItemType"
          required
          class="form-control"
          type="text"
          data-toggle="dropdown"
          readonly
          style="background:white"
          @click="showAdvanceFilter"
        />
        <div
          v-click-outside="outside"
          v-if="isShowAdvanceFilter"
          v-show="isShowAdvanceFilter"
          class="dropdown-menu dropdown"
          style="margin-left:15px;width:100%;z-index:1;"
          @click="inside"
        >
          <div class="dropdown-title">
            <label
              style=" display: block;
                          text-align: center;
                          line-height: 80%;"
              for="selecttype"
              class="control-label"
            >{{ dropdown_titleLte }}</label>
            <button
              class="dropdown-title-button dropdown-menu-close"
              type="button"
              @click="closeAdvanceFilter"
            >
              <i
                aria-hidden="true"
                data-hidden="true"
                class="fa fa-times dropdown-menu-close-icon"/>
            </button>
          </div>
          <!-- Search form -->
          <div class="md-form">
            <input
              v-model="strSearch"
              style=" width:100%;
                            text-align:left;
                            margin-right: auto;
                            margin-left: auto;
                            min-height: 30px;
                            <!-- margin-top:-15px; -->
                            padding: 0 7px;
                            color: #555;
                            line-height: 30px;
                            <!-- border: 1px solid #dfdfdf; -->
                            border-radius: 2px;
                            outline: 0;"
              class="form-control dropdown-input-field"
              type="text"
              placeholder="Search"
              aria-label="Search"
              autocomplete="on"
              @input="onChangeSearchItemType"
            >
            <i
              :class="{'fa-times':changeIcon}"
              aria-hidden="true"
              data-hidden="true"
              class="fa fa-search"
              style="float:right;margin:-22px 30px;"
              @click="emptySearch"
            />
          </div>
          <!-- Content -->
          <div
            class="dropdown-content"
            style="margin-top:20px;">
            <ul>
              <div class="box-body table-responsive no-padding">
                <table
                  class="table table-hover"
                  style="width:100%;">
                  <tbody>
                    <tr>
                      <th>{{ $t('customer.fullname') }}</th>
                      <th>{{ $t('customer.email') }}</th>
                      <!-- <th>{{$t('customer.phone')}}</th>
                      <th>{{$t('customer.dob')}}</th>
                      <th>{{$t('customer.passport')}}</th> -->
                    </tr>
                    <tr
                      v-for="itemtype in filteredItemTypeItems"
                      :key="itemtype.value"
                      @click="getItemType(itemtype.value)"
                    >
                      <td>{{ itemtype.text }}</td>
                      <td>{{ itemtype.email }}</td>
                      <!-- <td>{{itemtype.phone}}</td>
                      <td>{{itemtype.dob}}</td>
                      <td>{{itemtype.passport}}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- END DROPDOWN Custom-->

    <!--BEGIN FORM ADD NEW ASSET Custom -->
    <div
      class="form-group"
      style="border:0px solid red;max-height:0px;margin-top:0px;">
      <label
        :class="item.cssClassSm1"
        for="location"
        class="control-label"/>
      <div
        :class="item.cssClassSm2"
        class="dropdown">
        <div
          v-click-outside="outside"
          v-if="isShowNewItemType"
          v-show="isShowNewItemType"
          id="your-custom-id-material"
          :style="item.modifyFromPosition"
          class="dropdown-menu dropdown"
          style="
                        width:95%;
                        z-index:2;
                        min-height: 40px;
                        max-height: 312px;"
          @click="inside"
        />
      </div>
    </div>
    <!-- END FORM ADD NEW ASSET Custom -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SelectOptionTable',
  directives: {
    'click-outside': {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          // const compName = vNode.context.name
          // let warn = `[Vue-click-outside:] provided expression '${
          //   binding.expression
          // }' is not a function, but has to be`;
          // if (compName) {
          //   warn += `Found in component '${compName}'`
          // }
          // console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        // add Event Listeners
        document.addEventListener('click', handler)
      },

      unbind: function (el, binding) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    placeHolder: {
      type: String,
      default () {
        return ''
      }
    },
    dropdown_titleLte: {
      type: String,
      default () {
        return ''
      }
    }
    // itemId: {
    //   type: Number,
    //   default() {
    //     return ''
    //   }
    // },
  },
  data () {
    return {
      data_list: null,
      isShowAdvanceFilter: false,
      isShowNewItemType: false,
      opened: false,
      item_type: [],
      strSearch: '',
      changeIcon: false,
      itemType: null,
      keepItemType: 1,
      itemtypedata: {
        name: ''
      }
    }
  },
  computed: {
    setItemType: function () {
      let setItemType = ''
      this.create()
      this.data_list.forEach(v => {
        if (v.value === this.itemType) {
          setItemType = v.text
        }
      })
      let typeValue = this.itemType
      this.$emit('onchangeselect', typeValue)
      return setItemType
    },
    filteredItemTypeItems () {
      // return this.data_list.filter(item => {
      //   return (
      //     item.text.toLowerCase().indexOf(this.strSearch.toLowerCase()) > -1
      //   );
      // });
      let data = []
      let filterKey = this.strSearch && this.strSearch.toLowerCase()
      if (filterKey) {
        data = this.data_list.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            )
          })
        })
        return data
      }
      return this.data_list
    }
  },
  watch: {},
  mounted () {
    this.create()
  },
  methods: {
    ...mapActions([
      'ERROR_TOAST',
      'SUCCESS_TOAST'
    ]),
    create () {
      this.data_list = this.item.data_list
      this.itemType = this.item.itemType
    },

    showAdvanceFilter: function (val) {
      this.isShowAdvanceFilter = true
      this.opened = true
    },
    outside: function (e) {
      if (this.opened) {
        this.opened = false
      } else {
        this.isShowAdvanceFilter = false
        this.isShowNewItemType = false
      }
    },
    inside: function (e) {
      if (this.opened) {
        this.isShowNewItemType
          ? (this.isShowAdvanceFilter = false)
          : (this.isShowAdvanceFilter = true)
      }
    },
    closeAdvanceFilter: function (e) {
      this.opened = false
      this.isShowAdvanceFilter = false
    },
    getItemType: function (value) {
      this.item.itemType = value
      this.itemType = value
      this.$emit('clickOnItem', true)
    },
    setNoItemType: function (value) {
      this.itemType = 0
    },
    onChangeSearchItemType: function (event) {
      if (this.strSearch.length > 0) {
        this.changeIcon = true
      } else {
        this.changeIcon = false
      }
    },
    emptySearch: function () {
      this.strSearch = ''
      this.changeIcon = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.dropdown .dropdown-menu,
.dropdown .dropdown-menu-nav {
  transform: translateY(0);
  display: block;
  min-height: 40px;
  // max-height: 312px;
  overflow-y: auto;
  // z-index: 300;
  margin-top: 4px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu-labels .dropdown-content {
  max-height: 135px;
}
.dropdown-menu .divider,
.dropdown-menu-nav .divider {
  height: 1px;
  margin: 4px 0;
  padding: 0;
  background-color: #dfdfdf;
}

.dropdown-content {
  max-height: 252px;
  overflow-y: auto;
}

.dropdown-menu ul,
.dropdown-menu-nav ul {
  margin: 0;
  padding: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.dropdown-menu li,
.dropdown-menu-nav li {
  display: block;
  text-align: left;
  list-style: none;
  padding: 0 1px;
}

.dropdown-menu li a,
.dropdown-menu li button,
.dropdown-menu li .menu-item,
.dropdown-menu-nav li a,
.dropdown-menu-nav li button,
.dropdown-menu-nav li .menu-item {
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: block;
  font-weight: 400;
  position: relative;
  padding: 8px 12px;
  color: #2e2e2e;
  line-height: 16px;
  white-space: normal;
  overflow: hidden;
  text-align: left;
  width: 98%;
}

.dropdown-menu a,
.dropdown-menu button,
.dropdown-menu-nav a {
  transition: none;
}
.dropdown-content ul li:hover,
.dropdown-footer ul li:hover a {
  background-color: #eee;
  color: #2e2e2e;
  outline: 0;
  text-decoration: none;
}
.dropdown-input-field:focus {
  color: #555;
  border-color: #73afea;
  box-shadow: 0 0 4px rgba(115, 175, 234, 0.4);
}

.dropdown-title {
  position: relative;
  padding: 8px 12px;
  padding-bottom: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
}

.dropdown-title-button {
  position: absolute;
  top: 0;
  padding: 0;
  color: #bfbfbf;
  font-size: 14px;
  border: 0;
  background: none;
  outline: 0;
  margin: 10px 0px 0px 40%;
}

.form-goback-title-button {
  position: absolute;
  top: 0;
  padding: 0;
  color: #bfbfbf;
  font-size: 14px;
  border: 0;
  background: none;
  outline: 0;
}

.form-close-title-button {
  position: absolute;
  top: 0;
  padding: 0;
  color: #bfbfbf;
  font-size: 14px;
  border: 0;
  background: none;
  outline: 0;
}

.dropdown-menu-close {
  right: 5px;
  width: 20px;
  height: 20px;
  top: -1px;
}

.dropdown-menu-back {
  left: 7px;
  top: 2px;
}

.dropdown-labels-error {
  padding: 5px 10px;
  margin-bottom: 10px;
  background-color: #db3b21;
  color: #fff;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  min-height: 30px;
  padding: 0px 7px;
}

label.required:not(:empty):after,
.field-header.required:after {
  content: " *";
  color: red;
  position: absolute;
  right: 5px;
  top: 0px;
}
</style>
