<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'600px'"
    persistent>
    <v-card>
      <material-card
        :title="$t('generalConfiguration.sendTestEmail')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidEmailFormat]"
                  v-model="toEmail"
                  :label="$t('common.email')"
                  :name="$t('common.email')"
                  type="text"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :is-loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("generalConfiguration.sendEmail") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
// import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    valid: false,
    isShowModal: false,
    toEmail: null,
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmailFormat (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    /**
     * Confirm change password
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          toEmail: this.toEmail
        }
        this.isLoading = true
        this.SEND_EMAIL_FOR_TESTINNG(filter).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
            this.isShowModal = false
            this.isLoading = false
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              'message': error.response.data.message,
              'styleType': ToastType.ERROR
            })
          }.bind(this)
        )
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.isShowModal = true
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'SEND_EMAIL_FOR_TESTINNG'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
