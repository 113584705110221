const VoucherStatusType = {
  USING_EXPIRED: 1,
  INACTIVE: 0,
  ISSUING: 2,
  ACTIVATED: 3,
  USED: 4,
  WAITING: 5,
  DISTRIBUTION_EXPIRED: 7,
  ASSIGNED: 9,
  NOT_ASSIGNED_YET: 10,
  LOCKED_USING: 12,
  RESERVED_ORDER: 13,
  DISABLED: 14
}
export default VoucherStatusType
