import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import {
  isCookieEnabled,
  setCookie,
  removeCookie
} from 'tiny-cookie'
import functionUtils, { currentEntityId } from 'utils/functionUtils'

// state
const state = {
  currentUser: null,
  login: {},
  users: [],
  entityInUser: [],
  usersInEntity: [],
  customerData: [],
  entityRolesInOutSideUser: [],
  usersOutEntity: [],
  shipmentDetails: []
}

// getters
const getters = {
  GET_CURRENT_ACCOUNT: state => {
    return state.login
  },
  USER_LIST_DATA: state => {
    return state.users
  },
  ENTITY_IN_USER_DATA: state => {
    return state.entityInUser
  },
  USERS_IN_ENTITY_DATA: state => {
    return state.usersInEntity
  },
  CUSTOMER_LIST_DATA: state => {
    return state.customerData
  },
  ENTITY_ROLE_IN_OUT_SIDE_USER_DATA: state => {
    return state.entityRolesInOutSideUser
  },
  USERS_OUT_ENTITY_DATA: state => {
    return state.usersOutEntity
  },
  SHIPMENT_DETAILS_DATA: (state) => {
    return state.shipmentDetails
  }
}

// actions
const actions = {
  GET_USER_DATA: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/auth/userinfo/`,
        payload,
        function (res) {
          context.commit('GET_USER_DATA_HANDLER', res.data)
          if (isCookieEnabled()) {
            setCookie('role', res.data.response.group_id)
            resolve(res)
          } else {
            reject(res)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  LOGIN (context, payload) {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/auth/jwt-auth/',
        payload,
        function (res) {
          if (isCookieEnabled()) {
            setCookie('token', res.data.response.token)
          }
          context.commit('LOGIN_USER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          if (isCookieEnabled()) {
            removeCookie('token')
          }
          reject(error)
        }
      )
    })
  },
  LOGOUT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/auth/jwt-refresh/',
        {
          token: payload
        },
        function (res) {
          context.commit('LOGOUT', res.data)
          if (isCookieEnabled()) {
            removeCookie('token')
            resolve(res)
          } else {
            let error = ''
            reject(error)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REGISTER_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/auth/signup/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_CURRENT_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/update/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/user/management/`,
        payload,
        function (res) {
          context.commit('GET_USER_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_PROFILE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_CUSTOM: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/${payload.id}/update_user_custom/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/${currentEntityId()}/user/management/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ENTITY_ROLE_FOR_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/update_entity_role_for_user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ENTITY_IN_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/get_entity_in_user/`,
        payload,
        function (res) {
          context.commit('GET_ENTITY_IN_USER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_ENTITY_TO_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/add_entity_to_user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_ENTITY_IN_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/delete_entity_in_user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USERS_IN_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/get_users_in_entity/`,
        payload,
        function (res) {
          context.commit('GET_USERS_IN_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ACTIVATE_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/accounts/activated/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SEND_EMAIL_FORGOT_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/forgot_password/send_email_reset_password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONFIRM_RESET_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/auth/confirm-reset-password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ACTIVE_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/${payload.id}/active_user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CUSTOMER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/user/management/get_customer_list/`,
        payload,
        function (res) {
          context.commit('GET_CUSTOMER_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SET_ROLE_IN_ENTITY_FOR_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/user/management/set_role_in_entity_for_user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_CUSTOMER_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/update_customer_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_OUT_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/get_user_out_entity/`,
        payload,
        function (res) {
          context.commit('GET_USER_OUT_ENTITY_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REPORT_STATISTICS_USER_OF_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/user/management/get_report_statistics_user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/get_user_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SOCIAL_NETWORK_LOGIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/social_network/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SOCIAL_NETWORK: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/social_network/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  LOGIN_WITH_SOCIAL_NETWORK: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/social_network_login/login_with_google/`,
        payload,
        function (res) {
          if (isCookieEnabled()) {
            setCookie('token', res.data.response.token)
          }
          context.commit('LOGIN_USER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          if (isCookieEnabled()) {
            removeCookie('token')
          }
          reject(error)
        }
      )
    })
  },
  POST_USER_GENERAL_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user_general_configuration/post_user_general_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_GENERAL_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user_general_configuration/get_user_general_configuration/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  LOGIN_USER_HANDLER: (state, payload) => {
    state.currentUser = payload
  },
  GET_USER_DATA_HANDLER: (state, payload) => {
    let userData = {
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      lastlogin: '',
      group_id: '',
      group_name: '',
      url_avatar: '',
      permissions: '',
      actions: '',
      phone: '',
      gender: '',
      longitude: null,
      latitude: null,
      province: null,
      district: null,
      ward: null,
      street: null,
      birth_date: '',
      sys_role: null,
      entity_role: null,
      identification: '',
      parent_entity: null,
      per_page: null
    }
    userData.id = payload.response && payload.response.id
    userData.username = payload.response && payload.response.username
    userData.firstname = payload.response && payload.response.firstname
    userData.lastname = payload.response && payload.response.lastname
    userData.email = payload.response && payload.response.email
    userData.lastlogin = payload.response && payload.response.last_login
    userData.group_id = payload.response && payload.response.group_id
    userData.group_name = payload.response && payload.response.group_name
    userData.url_avatar = payload.response && payload.response.url_avatar
    userData.permissions = payload.response && payload.response.permissions
    userData.phone = payload.response && payload.response.phone
    userData.gender = payload.response && payload.response.gender
    userData.longitude = payload.response && payload.response.longitude
    userData.latitude = payload.response && payload.response.latitude
    userData.province = payload.response && payload.response.province
    userData.district = payload.response && payload.response.district
    userData.ward = payload.response && payload.response.ward
    userData.street = payload.response && payload.response.street
    userData.actions = payload.response && payload.response.actions
    userData.birth_date = payload.response && payload.response.birth_date
    userData.sys_role = payload.response && payload.response.sys_role
    userData.entity_role = payload.response && payload.response.entity_role
    userData.identification = payload.response && payload.response.identification
    userData.parent_entity = payload.response && payload.response.parent_entity
    userData.per_page = payload.response && payload.response.per_page
    // Set state for getter
    state.login = userData
    state.shipmentDetails = {
      id: null,
      'fullname': functionUtils.concatFullname(userData.lastname, userData.firstname),
      'phone': userData.phone,
      'email': userData.email,
      'longitude': userData.longitude,
      'latitude': userData.latitude,
      'province': userData.province,
      'district': userData.district,
      'ward': userData.ward,
      'street': userData.street
    }
  },
  LOGOUT: (state, payload) => {
    state.login = {
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      lastlogin: '',
      group_id: '',
      group_name: '',
      url_avatar: '',
      permissions: [],
      actions: [],
      phone: '',
      gender: '',
      birth_date: '',
      longitude: null,
      latitude: null,
      province: null,
      district: null,
      ward: null,
      street: null,
      sys_role: null,
      entity_role: null,
      identification: '',
      parent_entity: null
    }
  },
  GET_USER_LIST_HANDLER: (state, payload) => {
    let users = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.users = { results: users, paginate: page }
  },
  GET_ENTITY_IN_USER_HANDLER: (state, payload) => {
    state.entityInUser = payload
  },
  GET_USERS_IN_ENTITY_HANDLER: (state, payload) => {
    let users = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.usersInEntity = { results: users, paginate: page }
  },
  GET_CUSTOMER_LIST_HANDLER: (state, payload) => {
    let customers = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.customerData = { results: customers, paginate: page }
  },
  GET_ENTITY_ROLE_IN_OUT_SIDE_USER_HANDLER: (state, payload) => {
    state.entityRolesInOutSideUser = payload
  },
  GET_USER_OUT_ENTITY_HANDLER: (state, payload) => {
    let users = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.usersOutEntity = { results: users, paginate: page }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
