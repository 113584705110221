/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
// import modules from './modules'
// Functions
import settings from './modules/settings'
import users from './modules/users'
import toast from './modules/toast'
import app from './modules/app'
import decentralizations from './modules/decentralizations'
import evouchers from './modules/evouchers'
import entities from './modules/entities'
import customization from './modules/customization'
import batchjobs from './modules/batchjobs'
import development from './modules/development'
import advertisement from './modules/advertisement'
import customer from './modules/customer'
import report from './modules/report'
import portal from './modules/portal'
import storeChannels from './modules/storeChannels'
import thirdParty from './modules/thirdParty'
import ad from './modules/ad'
import external from './modules/external'
Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  modules: {
    settings,
    users,
    toast,
    app,
    decentralizations,
    evouchers,
    entities,
    customization,
    batchjobs,
    development,
    advertisement,
    customer,
    report,
    portal,
    storeChannels,
    thirdParty,
    ad,
    external
  }
})

export default store
