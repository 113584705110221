<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex
        xs12
        md12>
        <v-data-table
          :headers="entityHeaders"
          :items="entityList"
          :no-data-text="$t('common.noDataAvailable')"
          :hide-actions="true"
          :loading="isLoading"
        >
          <template
            slot="headerCell"
            slot-scope="{ header }">
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)" />
          </template>
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td class="text-xs-center">{{ item.stt }}</td>
            <td class="text-xs-center">{{ item.name }}</td>
            <td class="text-xs-center">{{ item.code }}</td>
            <td class="text-xs-center">{{ item.fullAddress }}</td>
            <td class="text-xs-center">{{ item.phone }}</td>
            <td class="text-xs-center">{{ item.email }}</td>
          </template>
          <template v-slot:no-results>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-box">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
        <pagination
          :total="entityPaginate.totalPage"
          :current-page="entityPaginate.currentPage"
          :row-per-page="entityPaginate.rowPerPage"
          class="mt-2"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
// import EntityType from 'enum/entityType'
export default {
  components: {
  },
  props: {
    entityId: { type: Number, default: () => null },
    entityType: { type: Number, default: () => null }
  },
  data () {
    return {
      entityHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'actions.name',
          value: 'name',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.code',
          value: 'code',
          align: 'center'
        },
        {
          sortable: false,
          text: 'userProfile.address',
          value: 'fullAddress',
          align: 'center'
        },
        {
          sortable: false,
          text: 'userProfile.phone',
          value: 'phone',
          align: 'center'
        },
        {
          sortable: false,
          text: 'userProfile.email',
          value: 'email',
          align: 'center'
        }
      ],
      entityList: [],
      indexRetrievingAddess: -1,
      isLoading: false,
      entityPaginate: {
        currentPage: 1,
        totalPage: 1,
        rowPerPage: 1
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'PARENT_CHILDREN_ENTITIES_DATA'
    ])
  },
  watch: {
    PARENT_CHILDREN_ENTITIES_DATA () {
      let res = this.PARENT_CHILDREN_ENTITIES_DATA
      this.entityPaginate.currentPage = res.paginate.currentPage
      this.entityPaginate.totalPage = res.paginate.totalPage
      this.entityPaginate.rowPerPage = res.paginate.perPage / 2
      let results = res.results
      this.indexRetrievingAddess = -1
      this.entityList = []
      for (let i = 0, size = results.length; i < size; i++) {
        let childrenEntityObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          name: results[i].name,
          code: results[i].code,
          phone: results[i].phone,
          email: results[i].email,
          longitude: results[i].longitude,
          latitude: results[i].latitude,
          province: results[i].province,
          district: results[i].district,
          ward: results[i].ward,
          street: results[i].street,
          fullAddress: results[i].street
        }
        this.entityList.push(childrenEntityObj)
      }
      this.indexRetrievingAddess = 0
      this.doRetrievingFullAddress()
    },
    entityId () {
      this.getRelatedEntities()
    },
    entityType () {
      this.getRelatedEntities()
    }
  },
  created () {
    this.getRelatedEntities()
  },
  methods: {
    /**
     * Page change
     */
    onPageChange: function (page) {
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Get related entities
     */
    getRelatedEntities: function () {
      if (!functionUtils.isNull(this.entityId) && !functionUtils.isNull(this.entityType)) {
        let filter = {
          id: this.entityId,
          params: {
            page: this.entityPaginate.currentPage,
            type: this.entityType
          }
        }
        this.GET_PARENT_CHILDREN_ENTITIES(filter)
      }
    },
    doRetrievingFullAddress: function () {
      if (this.indexRetrievingAddess >= 0 && this.entityList && this.indexRetrievingAddess < this.entityList.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.entityList[this.indexRetrievingAddess].province,
            districtCode: this.entityList[this.indexRetrievingAddess].district,
            wardCode: this.entityList[this.indexRetrievingAddess].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.entityList[this.indexRetrievingAddess].street)
            this.entityList[this.indexRetrievingAddess].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddess++
            this.doRetrievingFullAddress()
          }.bind(this)
        )
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_PARENT_CHILDREN_ENTITIES',
      'GET_ADDRESS_DETAIL'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
