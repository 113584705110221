<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('actionPermission.add_user')"
        tile
        color="green"
        full-width
      >
        <v-form
          ref="searchForm"
          v-model="searchValid"
          lazy-validation>
          <v-layout
            wrap
          >
            <v-flex
              lg12
              md12>
              <div style="display: flex; width: 70%;">
                <v-text-field
                  v-model="email"
                  :label="$t('common.email')"
                  :rules="[ruleRequiredValue, ruleValidEmail]"
                  style="margin-right: 5px; width: 50%; clear: both; display: block;"
                  class="success-input required"
                  @keydown.enter="onSearch($event)"
                />
                <v-btn
                  style="align-self: center;"
                  small
                  color="primary darken-1"
                  @click="onSearch($event)"
                >{{ $t('common.search') }}
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-container
            grid-list-xl
            fluid
            style="padding: 0;">
            <v-progress-linear
              v-show="progressBar"
              :indeterminate="progressBar"
              color="success"
              height="5"
              value="30"
              style="position: absolute; width: 90%; top: 100px;"
            />
            <v-data-table
              :headers="userHeaders"
              :items="userList"
              :no-data-text="$t('common.noDataAvailable')"
              :hide-actions="true"
              :loading="isLoading"
              style="max-height: 250px; overflow-y: auto;"
            >
              <template
                slot="headerCell"
                slot-scope="{ header }">
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)" />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <!-- <td class="text-xs-center">{{ item.stt }}</td> -->
                <td class="text-xs-center">{{ item.fullname }}</td>
                <td class="text-xs-center">{{ item.email }}</td>
                <td
                  class="text-xs-center"
                  style="white-space: nowrap; width: 30%;">
                  <v-select
                    v-model="roleInEntitySelected"
                    :items="entityRoles"
                    :rules="[ruleRequiredValue]"
                    :placeholder="$t('user.privilege')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </td>
              </template>
              <template v-slot:no-results>
                <v-alert
                  :value="true"
                  color="error"
                  icon="mdi-alert-box">
                  Your search for "{{ search }}" found no results.
                </v-alert>
              </template>
            </v-data-table>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="primary darken-1"
            @click="addEntityInUser($event)"
          >{{ $t("common.save") }}
          </v-btn>
          <v-btn
            small
            color="error darken-1"
            @click="onCloseModal"
          >{{ $t("common.close") }}
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import Avatar from 'vue-avatar'
import EntityType from 'enum/entityType'
import ToastType from 'enum/toastType'
export default {
  components: {
    Avatar
  },
  data () {
    return {
      valid: false,
      searchValid: false,
      isShowModal: false,
      userList: [],
      userHeaders: [
        {
          sortable: false,
          text: 'users.fullName',
          value: 'fullname',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.email',
          value: 'email',
          align: 'center'
        },
        {
          sortable: false,
          text: 'user.privilege',
          value: 'privilege',
          align: 'center'
        }
      ],
      userPaginate: {
        currentPage: 1,
        totalPage: 1,
        rowPerPage: 1
      },
      isLoading: false,
      entityTypeObj: EntityType,
      entityPaginate: {
        currentPage: 1,
        totalPage: 1,
        rowPerPage: 1
      },
      entityId: null,
      email: null,
      progressBar: false,
      roleInEntitySelected: null,
      entityRoles: [],
      entityType: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'USERS_OUT_ENTITY_DATA',
      'ENTITY_ROLE_DATA'
    ])
  },
  watch: {
    ENTITY_ROLE_DATA () {
      let data = this.ENTITY_ROLE_DATA
      this.entityRoles = []
      for (let i = 0, size = data.length; i < size; i++) {
        let entityRoleObj = {
          value: data[i].id,
          text: data[i].name
        }
        this.entityRoles.push(entityRoleObj)
      }
    },
    USERS_OUT_ENTITY_DATA () {
      let res = this.USERS_OUT_ENTITY_DATA
      this.userPaginate.currentPage = res.paginate.currentPage
      this.userPaginate.totalPage = res.paginate.totalPage
      this.userPaginate.rowPerPage = res.paginate.perPage / 2
      let results = res.results
      this.userList = []
      for (let i = 0, size = results.length; i < size; i++) {
        let userObj = {
          id: results[i].id,
          fullname: functionUtils.concatFullname(results[i].last_name, results[i].first_name),
          email: results[i].email
        }
        this.userList.push(userObj)
      }
      this.progressBar = false
    }
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    /**
     * Add entity in user
     */
    addEntityInUser: function (event) {
      event.preventDefault()
      if (this.userList.length !== 0) {
        // eslint-disable-next-line eqeqeq
        if (this.$refs.form.validate()) {
          let filter = {
            entityId: this.entityId,
            userId: this.userList[0].id,
            roleInEntity: this.roleInEntitySelected
          }
          this.ADD_ENTITY_TO_USER(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                'styleType': ToastType.SUCCESS
              })
              this.email = null
              this.userList = []
              this.$emit('addUserInEntitySuccess')
              this.isShowModal = false
            }.bind(this)
          )
        }
      }
    },
    /**
     * On search
     */
    onSearch: function (event) {
      event.preventDefault()
      if (this.$refs.searchForm.validate()) {
        this.getUsersInEntity()
      }
    },
    /**
     * Change email
     */
    // onChangeEmail: function () {
    //   this.getUsersInEntity()
    // },
    /**
     * Get users entity
     */
    getUsersInEntity: function (entityId) {
      this.progressBar = true
      let filter = {
        params: {
          page: this.userPaginate.currentPage,
          entityId: this.entityId,
          userEmail: this.email
        }
      }
      this.GET_USER_OUT_ENTITY(filter)
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (entityId, entityType) {
      this.entityType = entityType
      this.entityId = entityId
      this.email = null
      this.userList = []
      let filter = {
        params: {
          entityType: entityType
        }
      }
      this.GET_ENTITY_ROLE_LIST(filter)
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_USER_OUT_ENTITY',
      'GET_ENTITY_ROLE_LIST',
      'ADD_ENTITY_TO_USER'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
