<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t(getModalTitle())"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl>
          <div class="mb-3">
            <tab-header
              :tab-items="tabItems"
              :selected-id="tabSelected"
              @onSelectTab="onSelectTab" />
          </div>
          <v-form
            v-if="tabSelected == tabs.INFO"
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="entity.name"
                  :label="$t('actions.name')"
                  :name="$t('actions.name')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue,ruleValidCode]"
                  v-model="entity.code"
                  :label="$t('common.code')"
                  :name="$t('common.code')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidEmail]"
                  v-model="entity.email"
                  :label="$t('userProfile.email')"
                  :name="$t('userProfile.email')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleValidPhoneNumber]"
                  v-model="entity.phone"
                  :label="$t('userProfile.phone')"
                  :name="$t('userProfile.phone')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="entity.type==EntityType.SUPPLIER"
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[ruleValidUrlPath]"
                  v-model="entity.website"
                  :label="$t('common.website')"
                  :name="$t('common.website')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-text-field
                  v-model="fullAddress"
                  :label="$t('userProfile.address')"
                  :name="$t('userProfile.address')"
                  :append-outer-icon="'mdi-dots-horizontal'"
                  readonly
                  type="text"
                  @click:append-outer="onEditAddress"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-textarea
                  v-model="entity.description"
                  :label="$t('generalConfiguration.description')"
                  :name="$t('generalConfiguration.description')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="forConasi && isAdmin() && entity.type == EntityType.AGENT && isCreatingMode()"
                xs12
                md6>
                <v-autocomplete
                  :items="agents"
                  v-model="agentParentId"
                  :label="$t('issueVoucherRequest.parent')"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </v-form>
          <v-form
            v-if="tabSelected == tabs.CONFIG_STORE_CHANNELS"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout
              v-if="isAdmin() && forConasi && (entity.type == EntityType.AGENT)"
              row
              wrap>
              <!-- <v-flex
                xs12
                sm6
                md6
              >
                <v-checkbox
                  v-model="entity.hasGiveVoucherToLienViet"
                  :label="$t('entity.enableStoreChannel', { channel: getChannelStoreName(storeType.LIEN_VIET_POST_BANK) })"
                />
              </v-flex> -->
              <v-flex
                xs12
                sm6
                md6
              >
                <v-layout column>
                  <v-flex>
                    <v-checkbox
                      v-model="entity.hasGiveVoucherToHaravan"
                      :label="$t('entity.enableStoreChannel', { channel: getChannelStoreName(storeType.HARAVAN) })"
                    />
                    <div
                      v-if="entity.hasGiveVoucherToHaravan"
                      class="ml-3 pl-3">
                      <div style="overflow-wrap: break-word;">
                        {{ $t('entity.paymentConfirm') }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              class="ml-2 copy-url-btn"
                              color="success darken-1"
                              max-width="40px"
                              v-on="on"
                              @click="copyHaravanPaymentConfirmUrl"
                            ><v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                          </template>
                          <span >{{ $t('entity.copyUrl') }}</span>
                        </v-tooltip>
                      </div>
                      <div style="overflow-wrap: break-word; padding-top: 1em">
                        {{ $t('entity.createOrder') }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              class="ml-2 copy-url-btn"
                              color="success darken-1"
                              v-on="on"
                              @click="copyHaravanCreateOrderUrl"
                            ><v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                          </template>
                          <span >{{ $t('entity.copyUrl') }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-checkbox
                  v-model="entity.hasManageWebPortal"
                  :label="$t('entity.enableStoreChannel', { channel: getChannelStoreName(storeType.PRODUCT_PORTAL) })"
                />
              </v-flex>
              <v-flex
                v-for="(item, index) in entity.storeChannelsAllowChangeEntity"
                :key="item + index"
                xs12
                sm6
                md6
              >
                <v-checkbox
                  v-model="item.id"
                  :label="$t('entity.enableStoreChannel', { channel: item.name })"
                  :value="item.id"
                  disabled
                />
              </v-flex>
            </v-layout>
          </v-form>
          <v-form
            v-if="tabSelected == tabs.CONFIG_SUPPLIER_LIMIT"
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              v-if="isAdmin() && entity.type == EntityType.SUPPLIER"
              row
              wrap>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-autocomplete
                  v-model="agentIds"
                  :items="agents"
                  :clearable="true"
                  :label="$t('entity.agent')"
                  multiple>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 4"
                      class="grey--text caption">(+{{ agentIds.length - 4 }} {{ $t('voucherReport.others') }})</span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ ruleValidEmailAllowNull ]"
                  v-model="entity.deliveryNotificationEmail"
                  :label="$t('entity.deliveryNotificationEmail')"
                  :name="$t('entity.deliveryNotificationEmail')"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ ruleValidEmailAllowNull ]"
                  v-model="entity.managerNotificationEmail"
                  :label="$t('entity.managerNotificationEmail')"
                  :name="$t('entity.managerNotificationEmail')"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfVoucherCreateEach"
                  :label="$t('entity.numberOfVoucherCreateEach')"
                  :name="$t('entity.numberOfVoucherCreateEach')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfVoucherPerMonth"
                  :label="$t('entity.numberOfVoucherPerMonth')"
                  :name="$t('entity.numberOfVoucherPerMonth')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfVoucherCreated"
                  :label="$t('entity.numberOfVoucherCreated')"
                  :name="$t('entity.numberOfVoucherCreated')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfAgentCreated"
                  :label="$t('entity.numberOfAgentCreated')"
                  :name="$t('entity.numberOfAgentCreated')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfSiteCreated"
                  :label="$t('entity.numberOfSiteCreated')"
                  :name="$t('entity.numberOfSiteCreated')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfSmsSent"
                  :label="$t('entity.numberOfSmsSentInAMonth')"
                  :name="$t('entity.numberOfSmsSentInAMonth')"
                  :disabled="!entity.allowSMSDelivery"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="entity.numberOfUsersInEntity"
                  :label="$t('entity.numOfUsersInSupplier')"
                  :name="$t('entity.numOfUsersInSupplier')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="entity.smsProviderType"
                  :items="ServiceSMSProviderList"
                  :label="$t('generalConfiguration.smsProvider')"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ data.item.text }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ data.item.text }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                v-if="!forConasi"
                xs12
                sm12
                md12
              >
                <v-checkbox
                  v-model="entity.requiredCreationApproval"
                  :label="$t('entity.requiredCreationApproval')"
                />
                <v-checkbox
                  v-model="entity.allowSMSDelivery"
                  :label="$t('entity.allowSMSDelivery')"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          v-if="!isEntityDisabled()"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t(getConfirmButtonTitle()) }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onForceCreateEntity"
    />
    <update-address-modal
      ref="updateAddressModal"
      @onConfirm="onUpdateAddress"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import validationRules from 'utils/validationRules'
import EntityType from 'enum/entityType'
import SysRoleType from 'enum/sysRoleType'
import ConfirmModal from 'Components/ConfirmModal'
import UpdateAddressModal from 'Components/UpdateAddressModal'
import StoreChannelType from 'enum/storeChannelType'
import { FOR_CONASI_TARGET } from 'utils/constants'
import TabHeader from 'Components/TabHeader.vue'
export default {
  components: {
    ConfirmModal,
    UpdateAddressModal,
    TabHeader
  },
  data () {
    return {
      isShowModal: false,
      fullAddress: null,
      entity: {
        id: null,
        name: null,
        code: null,
        status: null,
        phone: null,
        email: null,
        website: null,
        imageUrl: null,
        type: null,
        description: null,
        numberOfUsersInEntity: null,
        numberOfVoucherCreateEach: null,
        numberOfVoucherPerMonth: null,
        numberOfVoucherCreated: null,
        numberOfSmsSent: null,
        numberOfAgentCreated: null,
        numberOfSiteCreated: null,
        hasManageWebPortal: false,
        hasGiveVoucherToHaravan: false,
        address: {
          longitude: null,
          latitude: null,
          province: null,
          district: null,
          ward: null,
          street: null
        },
        hasGiveVoucherToLienViet: false,
        hasGiveVoucherToMobilePortal: false,
        storeChannelsAllowChangeEntity: [],
        requiredCreationApproval: false,
        allowSMSDelivery: false,
        smsProviderType: null,
        deliveryNotificationEmail: null,
        managerNotificationEmail: null
      },
      EntityType: EntityType,
      SysRoleType: {
        ADMIN: SysRoleType.ADMIN
      },
      ServiceSMSProviderList: stringUtils.ServiceSMSProviderList,
      agents: [],
      agentIds: [],
      valid: false,
      forConasi: FOR_CONASI_TARGET,
      confirmModalTitle: null,
      agentParentId: null,
      storeChannels: [],
      storeType: {
        PRODUCT_PORTAL: StoreChannelType.PRODUCT_PORTAL,
        HARAVAN: StoreChannelType.HARAVAN,
        LIEN_VIET_POST_BANK: StoreChannelType.LIEN_VIET_POST_BANK,
        MOBILE_PORTAL: StoreChannelType.MOBILE_PORTAL
      },
      tabSelected: 0,
      tabs: {
        INFO: 0,
        CONFIG_SUPPLIER_LIMIT: 1,
        CONFIG_STORE_CHANNELS: 2
      },
      tabItems: [
        {
          title: 'entity.info',
          id: 0
        },
        {
          title: 'entity.configLimitation',
          id: 1,
          hidden: true
        },
        {
          title: 'entity.configStoreChannel',
          id: 2,
          hidden: true
        }
      ],
      entityIdHashed: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'LIST_AGENT_EXCLUDE_CURRENT_AGENT_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    LIST_AGENT_EXCLUDE_CURRENT_AGENT_DATA () {
      this.agents = []
      let data = this.LIST_AGENT_EXCLUDE_CURRENT_AGENT_DATA
      if (this.entity.type !== EntityType.SUPPLIER) {
        this.agents.push({ value: null, text: '---' })
      }
      for (let i = 0, size = data.length; i < size; i++) {
        let agentObj = {
          value: data[i].id,
          text: data[i].name
        }
        this.agents.push(agentObj)
      }
    }
  },
  created () {
    this.GET_STORE_CHANNELS_LIST().then(
      function (res) {
        let data = res.data
        this.storeChannels = []
        for (let i = 0, size = data.length; i < size; i++) {
          let storeChannelObj = {
            value: data[i].id,
            name: data[i].name,
            type: data[i].type
          }
          this.storeChannels.push(storeChannelObj)
        }
      }.bind(this)
    )
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Get url
     */
    copyHaravanPaymentConfirmUrl: function () {
      navigator.clipboard.writeText(functionUtils.removeDoubleSlashesFromUrl(process.env.VUE_APP_BACKEND_URL + '/api/v1/haravan/payment_confirm/' + this.entityIdHashed))
    },
    copyHaravanCreateOrderUrl: function () {
      navigator.clipboard.writeText(functionUtils.removeDoubleSlashesFromUrl(process.env.VUE_APP_BACKEND_URL + '/api/v1/haravan/order/' + this.entityIdHashed))
    },
    /**
     * Select tab
     */
    onSelectTab: function ({ index, item }) {
      this.tabSelected = item.id
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Entity type selected change
     */
    entityTypeSelectedChange: function () {
    },
    /**
     * Force create entity
     */
    onForceCreateEntity: function () {
      this.validate(true)
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    ruleValidEmailAllowNull (value) {
      return validationRules.ruleValidEmailFormat(value, null, true)
    },
    ruleValidCode (value) {
      return validationRules.ruleValidCode(value)
    },
    ruleValidPhoneNumber (value) {
      return validationRules.ruleValidPhoneNumber(value)
    },
    ruleValidUrlPath (value) {
      return validationRules.ruleValidUrlPath(value)
    },
    isCreatingMode: function () {
      return (functionUtils.isNull(this.entity.id) || this.entity.id === 0)
    },
    getModalTitle: function () {
      if (this.entity.type === EntityType.SUPPLIER) {
        return this.isCreatingMode() ? 'supplier.addNewSupplier' : 'supplier.updateSupplier'
      } else if (this.entity.type === EntityType.AGENT) {
        return this.isCreatingMode() ? 'agent.addNewAgent' : 'agent.updateAgent'
      } else if (this.entity.type === EntityType.SITE) {
        return this.isCreatingMode() ? 'siteManagement.addTitle' : 'siteManagement.updateTitle'
      }
      return ''
    },
    getConfirmButtonTitle: function () {
      if (this.tabSelected === this.tabs.INFO) {
        return this.isCreatingMode() ? 'entity.button.createNew' : 'entity.button.updateInfo'
      } else {
        return 'entity.button.updateConfig'
      }
    },
    /**
     * Get current entity
     */
    getCurrentEntity: function () {
      let filter = {
        params: {
          type: this.entity.type,
          is_supplier: this.isSupplier()
        }
      }
      this.isLoading = true
      this.GET_CURRENT_ENTITY(filter).then(
        function (res) {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getFullAddress: function () {
      this.fullAddress = this.entity.address.street
      let addressFilter = {
        params: {
          countryCode: 84,
          cityCode: this.entity.address.province,
          districtCode: this.entity.address.district,
          wardCode: this.entity.address.ward
        }
      }
      this.GET_ADDRESS_DETAIL(addressFilter).then(
        function (res) {
          this.fullAddress = functionUtils.concatFullAddress(res.data, this.entity.address.street)
        }.bind(this)
      )
    },
    getEntityConfiguration: function () {
      this.GET_ENTITY_CONFIGURATION({ id: this.entity.id }).then(
        function (res) {
          let data = res.data
          this.entity.numberOfVoucherCreateEach = data.maximum_voucher_create_each_time
          this.entity.numberOfVoucherPerMonth = data.maximum_voucher_per_month
          this.entity.numberOfVoucherCreated = data.maximum_voucher_created
          this.entity.numberOfSmsSent = data.maximum_sms_sent
          this.entity.numberOfAgentCreated = data.maximum_agent_created
          this.entity.numberOfSiteCreated = data.maximum_site_created
          this.entity.numberOfUsersInEntity = data.maximum_users_in_entity
          this.entity.hasManageWebPortal = data.enabled_product_portal
          this.entity.hasGiveVoucherToHaravan = data.enabled_haravan
          this.entity.hasGiveVoucherToLienViet = data.enabled_lienviet
          this.entity.hasGiveVoucherToMobilePortal = data.enabled_mobile_portal
          this.entity.storeChannelsAllowChangeEntity = data.store_channels_allow_change_entity
          this.entity.requiredCreationApproval = data.required_creation_approval
          this.entity.allowSMSDelivery = data.allow_sms_delivery
          this.entity.smsProviderType = data.sms_provider_type
          this.entity.deliveryNotificationEmail = data.delivery_email_notification
          this.entity.managerNotificationEmail = data.manager_email_notification
          this.agentIds = []
          if (this.entity.type === EntityType.SUPPLIER) {
            let agentDelegacyIds = []
            for (let j = 0, agentSize = data.agents_delegacy.length; j < agentSize; j++) {
              agentDelegacyIds.push(data.agents_delegacy[j].agent_id)
            }
            this.agentIds = agentDelegacyIds
          }
        }.bind(this)
      )
    },
    updateEntityConfiguration: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          agentIds: this.agentIds,
          numberOfUsersInEntity: this.entity.numberOfUsersInEntity,
          numberOfVoucherCreateEach: this.entity.numberOfVoucherCreateEach,
          numberOfVoucherPerMonth: this.entity.numberOfVoucherPerMonth,
          numberOfVoucherCreated: this.entity.numberOfVoucherCreated,
          numberOfSmsSent: this.entity.numberOfSmsSent,
          numberOfAgentCreated: this.entity.numberOfAgentCreated,
          numberOfSiteCreated: this.entity.numberOfSiteCreated,
          hasManageWebPortal: this.entity.hasManageWebPortal,
          hasGiveVoucherToHaravan: this.entity.hasGiveVoucherToHaravan,
          hasGiveVoucherToLienViet: this.entity.hasGiveVoucherToLienViet,
          hasGiveVoucherToMobilePortal: this.entity.hasGiveVoucherToMobilePortal,
          requiredCreationApproval: this.entity.requiredCreationApproval,
          allowSMSDelivery: this.entity.allowSMSDelivery,
          smsProviderType: this.entity.smsProviderType,
          deliveryNotificationEmail: this.entity.deliveryNotificationEmail,
          managerNotificationEmail: this.entity.managerNotificationEmail
        }
        this.UPDATE_ENTITY_CONFIGURATION({ id: this.entity.id, ...filter }).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }.bind(this)
        )
      }
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.agentParentId = null
      this.entity.id = null
      this.entity.type = null
      this.entity.name = null
      this.entity.code = null
      this.entity.phone = null
      this.entity.email = null
      this.entity.website = null
      this.entity.imageUrl = null
      this.entity.description = null
      this.entity.address.longitude = null
      this.entity.address.latitude = null
      this.entity.address.province = null
      this.entity.address.district = null
      this.entity.address.ward = null
      this.entity.address.street = null
      this.fullAddress = null

      this.entity.numberOfUsersInEntity = null
      this.entity.numberOfVoucherCreateEach = null
      this.entity.numberOfVoucherPerMonth = null
      this.entity.numberOfVoucherCreated = null
      this.entity.numberOfSmsSent = null
      this.entity.hasManageWebPortal = false
      this.entity.hasGiveVoucherToHaravan = false
      this.entity.hasGiveVoucherToLienViet = false
      this.entity.hasGiveVoucherToMobilePortal = false
      this.entity.storeChannelsAllowChangeEntity = []

      this.tabItems[1].hidden = true
      this.tabItems[2].hidden = true
    },
    updateTabVisibility: function () {
      if (this.isAdmin() && !this.isCreatingMode()) {
        this.tabItems[1].hidden = (this.entity.type !== EntityType.SUPPLIER)
        if (FOR_CONASI_TARGET) {
          this.tabItems[2].hidden = (this.entity.type !== EntityType.AGENT)
        } else {
          this.tabItems[2].hidden = true
        }
      } else {
        this.tabItems[1].hidden = true
        this.tabItems[2].hidden = true
      }
    },
    getEntityHash: function () {
      this.GET_ENTITY_ID_HASHED({ id: this.entity.id }).then(
        function (res) {
          let data = res.data
          this.entityIdHashed = data.data
        }.bind(this)
      )
    },
    getChannelStoreName: function (type) {
      for (let i = 0, size = this.storeChannels.length; i < size; i++) {
        if (this.storeChannels[i].type === type) {
          return this.storeChannels[i].name
        }
      }
      return functionUtils.getDefaultStoreChannelName(type)
    },
    /**
     * Check is distributer
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Show modal
     */
    onShowModal: function (entityId, entityData, currentPage, type) {
      this.isShowModal = true
      this.setDefaultData()
      this.currentPage = currentPage
      this.entity.type = type
      this.agentIds = []
      if (!functionUtils.isNull(entityId) && !functionUtils.isNull(entityData)) {
        this.entity.id = entityId
        this.entity.name = entityData.name
        this.entity.code = entityData.code
        this.entity.phone = entityData.phone
        this.entity.email = entityData.email
        this.entity.website = entityData.website
        this.entity.imageUrl = entityData.imageUrl
        this.entity.description = entityData.description
        this.entity.address.longitude = entityData.longitude
        this.entity.address.latitude = entityData.latitude
        this.entity.address.province = entityData.province
        this.entity.address.district = entityData.district
        this.entity.address.ward = entityData.ward
        this.entity.address.street = entityData.street
        this.getEntityHash()
        this.getFullAddress()
        this.getEntityConfiguration()
      }
      this.tabSelected = this.tabs.INFO
      this.updateTabVisibility()
      if (this.isAdmin()) {
        let filter = {
          params: {
            onlyAgentLevel1: this.forConasi
          }
        }
        this.GET_AGENT_EXCLUDE_CURRENT_AGENT(filter)
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefaultData()
    },
    validate: function (forceCreate) {
      if (this.$refs.form.validate()) {
        let filter = {
          name: this.entity.name,
          code: this.entity.code,
          phone: this.entity.phone,
          email: this.entity.email.trim().toLowerCase(),
          website: this.entity.website,
          description: this.entity.description ? this.entity.description : '',
          imageUrl: this.entity.imageUrl
        }
        // Update entity
        if (!this.isCreatingMode()) {
          this.UPDATE_ENTITY({ id: this.entity.id, ...filter }).then(
            function () {
              this.$emit('addUpdateSuccess')
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                'styleType': ToastType.SUCCESS
              })
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  styletype: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        } else {
          // Add entity
          if (this.entity.type === EntityType.SUPPLIER) {
            filter['parentEntityId'] = null
          } else {
            if (this.isAdmin()) {
              filter['parentEntityId'] = this.agentParentId
            } else {
              filter['parentEntityId'] = sessionStorage.getItem('entityId')
            }
          }
          filter['forceCreate'] = forceCreate
          filter['type'] = this.entity.type
          filter['longitude'] = this.entity.address.longitude
          filter['latitude'] = this.entity.address.latitude
          filter['province'] = this.entity.address.province
          filter['district'] = this.entity.address.district
          filter['ward'] = this.entity.address.ward
          filter['street'] = this.entity.address.street
          this.ADD_ENTITY_BY_TYPE(filter).then(
            function (res) {
              this.entity.id = res.data.entityId
              this.updateTabVisibility()
              this.getEntityHash()
              this.$emit('addUpdateSuccess')
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                'styleType': ToastType.SUCCESS
              })
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                if (errorText === 'entity.needForcedToCreate') {
                  this.confirmModalTitle = this.$t('entity.needForcedToCreate')
                  this.$refs.confirmModal.onShowModal()
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                }
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        }
      }
    },
    onEditAddress: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.updateAddressModal.onShowModal(this.entity.address)
    },
    onUpdateAddress: function (address) {
      if (!functionUtils.isNull(this.entity.id) && this.entity.id !== 0) {
        let filter = {
          longitude: address.longitude,
          latitude: address.latitude,
          province: address.province,
          district: address.district,
          ward: address.ward,
          street: address.street
        }
        this.UPDATE_ENTITY_ADDRESS({ id: this.entity.id, ...filter }).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }.bind(this)
        )
      }
      this.entity.address.province = address.province
      this.entity.address.district = address.district
      this.entity.address.ward = address.ward
      this.entity.address.longitude = address.longitude
      this.entity.address.latitude = address.latitude
      this.entity.address.street = address.street
      this.getFullAddress()
      this.$emit('addUpdateSuccess')
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (this.tabSelected === this.tabs.INFO) {
        this.validate(false)
      } else {
        this.updateEntityConfiguration()
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'ADD_ENTITY_BY_TYPE',
      'UPDATE_ENTITY',
      'GET_AGENT_EXCLUDE_CURRENT_AGENT',
      'GET_CURRENT_ENTITY',
      'GET_ENTITY_ID_HASHED',
      'GET_STORE_CHANNELS_LIST',
      'GET_ADDRESS_DETAIL',
      'GET_ENTITY_CONFIGURATION',
      'UPDATE_ENTITY_CONFIGURATION',
      'UPDATE_ENTITY_ADDRESS'
    ])
  }
}

</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.copy-url-btn {
  min-width: 0;
}

</style>
