<template>
  <div
    class="v-btn"
    style="will-change: ''; box-shadow: ''; padding: 0">
    <div
      id="g_id_onload"
      :data-client_id="VUE_APP_GOOGLE_CLIENT_ID"
      data-context="signin"
      data-ux_mode="popup"
      data-callback="handleCredentialResponse"
      data-nonce=""
      data-auto_prompt="false"
      style="margin: 0"/>
    <div
      :data-width="dataWidth"
      :data-locale="getDataLocale()"
      :data-text="dataText"
      class="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      data-size="large"
      data-logo_alignment="left"/>
  </div>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import { mapGetters } from 'vuex'
export default {
  props: {
    dataWidth: {
      type: Number,
      default: null
    },
    dataText: {
      type: String,
      default: null
    }
  },
  data: () => ({
    VUE_APP_GOOGLE_CLIENT_ID: process.env.VUE_APP_GOOGLE_CLIENT_ID
  }),
  computed: {
    ...mapGetters([
    ])
  },
  mounted () {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(recaptchaScript)

    window.handleCredentialResponse = (response) => {
      let responsePayload = functionUtils.decodeJwtResponse(response.credential)
      if (responsePayload !== null) {
        let userProfile = {
          id: responsePayload.sub,
          fullName: responsePayload.name,
          avatarUrl: responsePayload.picture,
          email: responsePayload.email
        }
        this.$emit('onEmitData', userProfile)
      }
    }
  },
  methods: {
    getDataLocale: function () {
      return functionUtils.getDataLocale(this.$i18n.locale)
    }
  }
}
</script>
