<template>
  <v-layout
    wrap
  >
    <v-flex
      lg5
      md12
    >
      <material-card
        :title="$t('entity.availableTitle', { name: $t(getEntityName()) })"
        color="green"
        flat
        full-width
      >
        <div
          style="width: 70%;">
          <v-text-field
            v-model="codeOrName"
            :label="$t('userEntity.codeOrName')"
            append-icon="mdi-magnify"
            @input="onCodeOrNameChange"/>
        </div>
        <v-data-table
          :no-data-text="$t('common.noDataAvailable')"
          :headers="entityAvailableHeaders"
          :items="entitiesAvailable"
          :single-select="true"
          hide-actions
          style="max-height: 250px; overflow-y: auto;"
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)"
            />
          </template>
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td class="text-xs-center">{{ item.stt }}</td>
            <td style="width: 55%;">{{ item.name }}</td>
            <td style="width: 25%;">{{ item.code }}</td>
            <td class="text-xs-center">
              <v-menu
                bottom
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    class="mx-2"
                    fab
                    small
                    color="success"
                    style="color: #ffffff;"
                    v-on="on" >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile
                    v-for="(role, index) in entityRoles"
                    :key="index"
                    @click="onAddUserToEntity(item.id, role.value)"
                  >
                    <v-list-tile-title
                      v-text="role.text"
                    />
                  </v-list-tile>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </material-card>
    </v-flex>
    <v-flex
      lg7
      md12
    >
      <material-card
        :title="$t('entity.assignedTitle', { name: $t(getEntityName()) })"
        color="green"
        flat
        full-width
      >
        <v-data-table
          :no-data-text="$t('common.noDataAvailable')"
          :headers="entityAssignedHeaders"
          :items="entitiesAssigned"
          :single-select="true"
          hide-actions
          style="max-height: 250px; overflow-y: auto;"
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)"
            />
          </template>
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td class="text-xs-center">{{ item.stt }}</td>
            <td style="width: 40%;">{{ item.name }}</td>
            <td style="width: 15%;">{{ item.code }}</td>
            <td style="white-space: nowrap; width: 35%;">
              <v-select
                v-model="item.entityRoleId"
                :items="entityRoles"
                :disabled="!item.isUpdate"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ $t(data.item.text) }}
                </template>
              </v-select>
            </td>
            <td
              class="text-xs-center"
              style="white-space: nowrap;">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    color="success"
                    style="color: #ffffff;"
                    v-on="on"
                    @click="onEditUserRoleInEntity(item)">
                    <v-icon dark>{{ item.isUpdate ? 'mdi-check' : 'mdi-square-edit-outline' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.update') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    color="red"
                    style="color: #ffffff;"
                    v-on="on"
                    @click="onRemoveUserFromEntity(item.id)">
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.delete') }}</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import EntityType from 'enum/entityType'
import functionUtils from 'utils/functionUtils'
export default {
  props: {
    userId: {
      type: Number,
      default: () => { return null }
    },
    entityType: {
      type: EntityType,
      default: () => { return 0 }
    }
  },
  data: () => ({
    EntityType: EntityType,
    entityAssignedHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.name',
        value: 'name',
        align: 'left'
      },
      {
        sortable: false,
        text: 'common.code',
        value: 'code',
        align: 'left'
      },
      {
        sortable: false,
        text: 'user.privilege',
        value: 'privilege',
        align: 'left'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    entitiesAssigned: [],
    entityAvailableHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.name',
        value: 'name',
        align: 'left'
      },
      {
        sortable: false,
        text: 'common.code',
        value: 'code',
        align: 'left'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    entitiesAvailable: [],
    entityRoles: [],
    codeOrName: null,
    entitiesAvailableClone: []
  }),
  computed: {
    ...mapGetters([
      'ENTITY_IN_USER_DATA',
      'GET_SNACK_BAR',
      'ENTITY_ROLE_DATA'
    ])
  },
  watch: {
    ENTITY_ROLE_DATA () {
      let data = this.ENTITY_ROLE_DATA
      this.entityRoles = []
      for (let i = 0, size = data.length; i < size; i++) {
        let entityRoleObj = {
          value: data[i].id,
          text: data[i].name
        }
        this.entityRoles.push(entityRoleObj)
      }
    },
    ENTITY_IN_USER_DATA () {
      let data = this.ENTITY_IN_USER_DATA.data
      // Handle entities available
      let entitiesAvailable = data.entities_available
      this.entitiesAvailable = []
      for (let i = 0, entitiesAvailableSize = entitiesAvailable.length; i < entitiesAvailableSize; i++) {
        let object = {
          stt: i + 1,
          id: entitiesAvailable[i].id,
          name: entitiesAvailable[i].name,
          code: entitiesAvailable[i].code
          // parentEntities: entitiesAvailable[i].parents
        }
        this.entitiesAvailable.push(object)
      }
      this.entitiesAvailableClone = [...this.entitiesAvailable]
      // Handle entities assigned
      let entitiesAssigned = data.entities_assigned
      this.entitiesAssigned = []
      for (let i = 0, entityAssignedSize = entitiesAssigned.length; i < entityAssignedSize; i++) {
        let object = {
          stt: i + 1,
          id: entitiesAssigned[i].id,
          name: entitiesAssigned[i].name,
          code: entitiesAssigned[i].code,
          // parentEntities: entitiesAssigned[i].parents,
          entityRoleId: entitiesAssigned[i].entity_role_id,
          isUpdate: false
        }
        this.entitiesAssigned.push(object)
      }
    },
    userId: function () {
      this.getEntityRoleList()
      this.getUserInEntities()
    },
    entityType: function () {
      this.getEntityRoleList()
      this.getUserInEntities()
    }
  },
  created () {
    this.getEntityRoleList()
    this.getUserInEntities()
  },
  methods: {
    getEntityName: function () {
      return functionUtils.getEntityTypeName(this.entityType)
    },
    /**
     * Code or name change
     */
    onCodeOrNameChange: function () {
      if (functionUtils.isNull(this.codeOrName) || this.codeOrName === '') {
        this.entitiesAvailable = this.entitiesAvailableClone
      } else {
        this.entitiesAvailable = this.entitiesAvailableClone.filter(item => {
          return (item.name?.toLowerCase().includes(this.codeOrName?.toLowerCase())) || (item.code?.toLowerCase().includes(this.codeOrName?.toLowerCase()))
        })
      }
    },
    /**
     * Edit user role in entity
     */
    onEditUserRoleInEntity: function (item) {
      item.isUpdate = !item.isUpdate
      if (!item.isUpdate) {
        let filter = {
          entityId: item.id,
          userId: this.userId,
          roleId: item.entityRoleId ? item.entityRoleId : null
        }
        this.UPDATE_ENTITY_ROLE_FOR_USER(filter).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
          }.bind(this)
        )
      }
    },
    /**
     * Get entity role list
     */
    getEntityRoleList: function () {
      if (this.entityType !== 0) {
        let filter = {
          params: {
            entityType: this.entityType
          }
        }
        this.GET_ENTITY_ROLE_LIST(filter)
      }
    },
    /**
     * Get user in entities
     */
    getUserInEntities: function () {
      if (this.entityType !== 0 && this.userId !== 0) {
        let filter = {
          params: {
            userId: this.userId,
            type: this.entityType
          }
        }
        this.GET_ENTITY_IN_USER(filter)
      }
    },
    /**
     * Add user to entity
     */
    onAddUserToEntity: function (entityId, roleInEntity) {
      let filter = {
        entityId: entityId,
        userId: this.userId,
        roleInEntity: roleInEntity
      }
      this.ADD_ENTITY_TO_USER(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getUserInEntities()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Remove user from entity
     */
    onRemoveUserFromEntity: function (entityId) {
      let filter = {
        entityId: entityId,
        userId: this.userId
      }
      this.DELETE_ENTITY_IN_USER(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getUserInEntities()
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_ENTITY_IN_USER',
      'ADD_ENTITY_TO_USER',
      'DELETE_ENTITY_IN_USER',
      'ON_SHOW_TOAST',
      'GET_ENTITY_ROLE_LIST',
      'UPDATE_ENTITY_ROLE_FOR_USER'
    ])
  }
}
</script>
<style lang='scss' scoped>
/deep/table.v-table tbody td {
  padding: 0 !important;
}
/deep/.break-line .v-list__tile {
  display: block;
}
.v-btn--floating.v-btn--small {
  height: 35px;
  width: 35px;
}
</style>
