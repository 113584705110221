<template>
  <div :class="this['class']">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Column',
  props: {
    'class': {
      type: String,
      default: ''
    }
  },
  created () {

  }
}
</script>
