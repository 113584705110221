import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  metaList: [],
  decentralizationGroups: [],
  decentralizationGroupCustoms: [],
  decentralizationActionInOutSideGroup: []
}

// getters
const getters = {
  META_LIST_DATA: state => {
    return state.metaList
  },
  DECENTRALIZATION_GROUP_LIST_DATA: state => {
    return state.decentralizationGroups
  },
  DECENTRALIZATION_GROUP_IN_OUT_SIDE_ROLE_DATA: state => {
    return state.decentralizationGroupCustoms
  },
  DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP_DATA: state => {
    return state.decentralizationActionInOutSideGroup
  }
}

// actions
const actions = {
  ADD_DECENTRALIZATION_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/decentralization_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DECENTRALIZATION_GROUP_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/decentralization_group/`,
        payload,
        function (res) {
          context.commit('GET_DECENTRALIZATION_GROUP_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_DECENTRALIZATION_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/decentralization_group/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_DECENTRALIZATION_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/decentralization_group/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/decentralization_group/get_action_in_out_side_group/`,
        payload,
        function (res) {
          context.commit('GET_DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_ACTION_TO_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/decentralization_group/add_action_to_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_ACTION_FROM_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/decentralization_group/${payload.id}/delete_action_from_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  RESTORE_PERMISSION_IN_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/decentralization_group/restore_permission/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  SET_FUNCTIONS: (state, payload) => {
    state.functions = payload
  },
  GET_META_LIST_HANDLER: (state, payload) => {
    state.metaList = payload
  },
  GET_DECENTRALIZATION_GROUP_LIST_HANDLER: (state, payload) => {
    state.decentralizationGroups = payload
  },
  GET_DECENTRALIZATION_GROUP_IN_OUT_SIDE_ROLE_HANDLER: (state, payload) => {
    state.decentralizationGroupCustoms = payload
  },
  GET_DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP_HANDLER: (state, payload) => {
    state.decentralizationActionInOutSideGroup = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
