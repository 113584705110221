<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card>
      <material-card
        :title="isNull(decentralizationGroupId) ? $t('decentralizationGroup.addNewDecentralizationGroup') : $t('decentralizationGroup.updateDecentralizationGroup')"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                md12
              >
                <v-text-field
                  v-model="name"
                  :rules="[ruleRequiredValue]"
                  :label="$t('actions.name')"
                  style="margin-right: 5px;"
                  class="success-input required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import validationRules from 'utils/validationRules'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      isShowModal: false,
      name: null,
      decentralizationGroupId: null,
      valid: false
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    /**
     * Required value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (decentralizationGroup) {
      this.decentralizationGroupId = decentralizationGroup && decentralizationGroup.id
      this.name = decentralizationGroup && decentralizationGroup.decentralizationGroupName
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          name: this.name
        }
        if (!functionUtils.isNull(this.decentralizationGroupId)) {
          this.UPDATE_DECENTRALIZATION_GROUP({ id: this.decentralizationGroupId, ...filter }).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.setDefaultData()
              this.GET_DECENTRALIZATION_GROUP_LIST()
            }.bind(this)
          )
        } else {
          this.ADD_DECENTRALIZATION_GROUP(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.setDefaultData()
              this.GET_DECENTRALIZATION_GROUP_LIST()
            }.bind(this)
          )
        }
      }
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isShowModal = false
      this.name = null
      this.decentralizationGroupId = null
    },
    ...mapActions([
      'ADD_DECENTRALIZATION_GROUP',
      'ON_SHOW_TOAST',
      'GET_DECENTRALIZATION_GROUP_LIST',
      'UPDATE_DECENTRALIZATION_GROUP'
    ])
  }
}

</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
