import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

// state
const state = {
  bannerSliderList: [],
  voucherProductList: [],
  voucherProductOrdersList: [],
  footersConfig: [],
  promotionCodes: [],
  carts: [],
  voucherOrderCartList: [],
  baoKimPaymentList: []
}

// getters
const getters = {
  BANNER_SLIDER_LIST_DATA: state => {
    return state.bannerSliderList
  },
  VOUCHER_PRODUCT_LIST_DATA: state => {
    return state.voucherProductList
  },
  VOUCHER_PRODUCT_ORDERS_LIST_DATA: state => {
    return state.voucherProductOrdersList
  },
  FOOTERS_CONFIG_LIST_DATA: state => {
    return state.footersConfig
  },
  VOUCHER_PRODUCT_PROMOTION_CODES_DATA: state => {
    return state.promotionCodes
  },
  CARTS_DATA: state => {
    return state.carts
  },
  GET_VOUCHER_ORDER_CART_LIST_DATA: state => {
    return state.voucherOrderCartList
  },
  GET_BAO_KIM_PAYMENT_LIST_DATA: state => {
    return state.baoKimPaymentList
  }
}

// actions
const actions = {
  GET_LIST_BANNER_SLIDER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/slider/`,
        payload,
        function (res) {
          context.commit('GET_LIST_BANNER_SLIDER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_BANNER_SLIDER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/slider/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_BANNER_SLIDER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/portal/${currentEntityId()}/slider/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_BANNER_SLIDER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/${currentEntityId()}/slider/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_BANNER_SLIDER_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/slider/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUBLIC_BANNER_PORTAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/slider/public_banner/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  TO_DO_INACTIVE_BANNER_PORTAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/slider/inactive_banner/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONFIG_LAYOUT_PORTAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PORTAL_LAYOUT_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/get_layout_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_ACTIVE_KEY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/create_active_key/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ACTIVE_KEY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/get_active_key/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_DOMAIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/create_domain/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DOMAIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/get_domain/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DOMAIN_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/${payload.id}/get_domain_detail/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_DOMAIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/${payload.id}/update_domain/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_DOMAIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/${payload.id}/delete_domain/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_VOUCHER_PRODUCT_CATEGORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_CATEGORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_CATEGORY_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_VOUCHER_PRODUCT_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_VOUCHER_PRODUCT_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_PRODUCT_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_WORKPIECE_LIST_OF_VOUCHER_PRODUCT_ALREADY_EXIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/get_workpiece_list_of_voucher_product_already_exist/`,
        payload,
        function (res) {
          context.commit('GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_QUANTITY_VOUCHER_PRODUCT_AVAILABLE_OF_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/get_quantity_voucher_product_available_of_entity/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_VOUCHER_PRODUCT_AGENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/create_product_agent_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUBLIC_VOUCHER_PRODUCT_ON_STORE_CHANNEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/public_voucher_product_on_store_channel/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_CATEGORIES_OF_ENTITY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/get_categories_on_portal/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_ORDERS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/get_voucher_product_orders/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_PRODUCT_ORDERS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  CREATE_FOOTER_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/footer/config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_FOOTERS_CONFIG_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/footer/config/`,
        payload,
        function (res) {
          context.commit('GET_FOOTERS_CONFIG_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_FOOTER_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/portal/${currentEntityId()}/footer/config/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_FOOTER_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/${currentEntityId()}/footer/config/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_PROMOTION_CODES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/promotion/code/voucher/product/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_PRODUCT_PROMOTION_CODES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_VOUCHER_PRODUCT_PROMOTION_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/promotion/code/voucher/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_VOUCHER_PRODUCT_PROMOTION_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/${currentEntityId()}/promotion/code/voucher/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_VOUCHER_PRODUCT_PROMOTION_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/portal/${currentEntityId()}/promotion/code/voucher/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CANCEL_VOUCHER_PRODUCT_ORDER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/cancel_order_product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CHECK_PROMOTION_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/promotion/code/voucher/product/verify/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  TAKE_BACK_VOUCHERS_ON_PORTAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/take_back_vouchers_on_portal/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REMOVE_VOUCHER_FROM_STORE_CHANNEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/remove_vouchers_from_store_channel/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PAYMENT_CONFIRMATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/payment_confirmation/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_VOUCHERS_ON_PRODUCT_PORTAL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/get_vouchers_on_product_portal/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VOUCHER_PRODUCT_IN_ORDERS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/get_voucher_product_in_orders/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_PRODUCT_ORDERS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PAYMENT_CONFIRMATION_BULK: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/payment_confirmation_bulk/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        500000
      )
    })
  },
  GET_PAYMENT_METHODS_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PAYMENT_METHOD_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/get_payment_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_BANK_TRANSFER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/get_bank_transfer_list/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_UPDATE_PAYMENT_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/create_payment_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_UPDATE_BANK_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/create_bank_transfer_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_BANK_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/${payload.id}/delete_bank_transfer_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  IMPORT_DOMAIN_EXCEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/layout/config/import_domain_excel/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ON_APPROVE_ORDER_BY_EXCEL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/approve_by_excel/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  UPDATE_PRIORITY_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/category/voucher/product/update_priority_category/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_PRODUCT_TO_CART: (context, payload) => {
    context.commit('ADD_PRODUCT_TO_CART_HANDLER', payload)
  },
  UPDATE_PRODUCT_QUANTITY: (context, payload) => {
    context.commit('UPDATE_PRODUCT_QUANTITY_HANDLER', payload)
  },
  REMOVE_PRODUCT_FROM_CART: (context, payload) => {
    context.commit('REMOVE_PRODUCT_FROM_CART_HANDLER', payload)
  },
  REMOVE_ALL_PRODUCT_FROM_CART: (context) => {
    context.commit('REMOVE_ALL_PRODUCT_FROM_CART_HANDLER')
  },
  GET_PRODUCT_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/${payload.id}/get_detail_from_3rd/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  //
  CREATE_DELIVERY_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/delivery/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DELIVERY_INFO_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/delivery/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PAYMENT_METHODS_IN_ENTITY_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/payment/methods/get_payment_methods_list_on_portal/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ORDER_VOUCHER_PRODUCT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/order/voucher/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  REQUEST_MOMO_PAYURL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/payments/online_payment/request_momo_payurl/`, // TODO: entity
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_VOUCHER_ORDER_CART_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/voucher_order_cart/get_voucher_order_cart_list/`,
        payload,
        function (res) {
          context.commit('GET_VOUCHER_ORDER_CART_LIST', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_VOUCHER_ORDER_CART: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/voucher_order_cart/create_voucher_order_cart/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  DELETE_VOUCHER_ORDER_CART: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/${currentEntityId()}/voucher_order_cart/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_VOUCHER_ORDER_CART: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/${currentEntityId()}/voucher_order_cart/${payload.id}/update_quantity_voucher_in_cart/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_BAO_KIM_PAYMENT_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/bao_kim_payment/get_payment_list/`,
        payload,
        function (res) {
          context.commit('GET_BAO_KIM_PAYMENT_LIST', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_BAO_KIM_PAYMENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/bao_kim_payment/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        60000
      )
    })
  },
  GET_BANK_TRANSFER_PAYMENT_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/bank_transfer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  },
  CREATE_PRODUCT_ISSUANCE_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/create_product_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRODUCT_ISSUANCE_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/voucher/product/get_product_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        },
        600000
      )
    })
  }
}

// mutations
const mutations = {
  GET_VOUCHER_PRODUCT_LIST_HANDLER: (state, payload) => {
    let voucherProductList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherProductList = payload.results ? { results: voucherProductList, paginate: page } : payload.data
  },
  GET_LIST_BANNER_SLIDER_HANDLER: (state, payload) => {
    let bannerSliderList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.bannerSliderList = payload.results ? { results: bannerSliderList, paginate: page } : payload.data
  },
  GET_VOUCHER_PRODUCT_ORDERS_HANDLER: (state, payload) => {
    let voucherProductOrdersList = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.voucherProductOrdersList = payload.results ? { results: voucherProductOrdersList, paginate: page } : payload.data
  },
  GET_FOOTERS_CONFIG_LIST_HANDLER: (state, payload) => {
    let footersConfig = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.footersConfig = payload.results ? { results: footersConfig, paginate: page } : payload.data
  },
  GET_VOUCHER_PRODUCT_PROMOTION_CODES_HANDLER: (state, payload) => {
    let promotionCodes = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.promotionCodes = payload.results ? { results: promotionCodes, paginate: page } : payload.data
  },
  REMOVE_ALL_PRODUCT_FROM_CART_HANDLER: (state) => {
    state.carts = []
    sessionStorage.setItem('carts', JSON.stringify(state.carts))
  },
  REMOVE_PRODUCT_FROM_CART_HANDLER: (state, payload) => {
    let cartList = sessionStorage.getItem('carts')
    cartList = JSON.parse(cartList)
    cartList.splice(payload, 1)
    state.carts = cartList
    sessionStorage.setItem('carts', JSON.stringify(state.carts))
  },
  UPDATE_PRODUCT_QUANTITY_HANDLER: (state, payload) => {
    let cartList = sessionStorage.getItem('carts')
    cartList = JSON.parse(cartList)
    if (cartList && cartList.length !== 0) {
      state.carts = cartList
    }
    let isExist = false
    for (let i = 0, size = state.carts.length; i < size; i++) {
      if (state.carts[i].id === payload.id) {
        state.carts[i].quantity = payload.quantity
        isExist = true
      }
    }
    if (isExist) {
      sessionStorage.setItem('carts', JSON.stringify(state.carts))
    }
  },
  ADD_PRODUCT_TO_CART_HANDLER: (state, payload) => {
    let cartList = sessionStorage.getItem('carts')
    cartList = JSON.parse(cartList)
    if (cartList && cartList.length !== 0) {
      state.carts = cartList
    }
    let isExist = false
    for (let i = 0, size = state.carts.length; i < size; i++) {
      // eslint-disable-next-line eqeqeq
      if (state.carts[i].id == payload.id) {
        state.carts[i].quantity += payload.quantity
        isExist = true
      }
    }
    if (!isExist) {
      state.carts.push(payload)
    }
    sessionStorage.setItem('carts', JSON.stringify(state.carts))
  },
  //
  GET_VOUCHER_ORDER_CART_LIST: (state, payload) => {
    let voucherOrderCartList = payload
    state.voucherOrderCartList = voucherOrderCartList
  },
  GET_BAO_KIM_PAYMENT_LIST: (state, payload) => {
    let baoKimPaymentList = payload
    state.baoKimPaymentList = baoKimPaymentList
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
