const VoucherHistoryType = {
  CREATE: 1,
  DISTRIBUTE: 2,
  ASSIGNED: 3,
  USED: 4,
  SEND_CONFIRM_USED_REQUEST: 5,
  CUSTOMER_CONFIRM_USED: 6,
  CUSTOMER_CANCEL_USED: 7,
  CUSTOMER_RETURN_VOUCHER: 8,
  CUSTOMER_ASSIGNED_VOUCHER_TO_CUSTOMER: 9,
  DELETED: 10,
  USED_FROM_THIRD_PARTIES: 11,
  PROMOTION_USED: 12,
  CREATE_PROMOTION_VOUCHER: 13,
  RECONCILIATION_APPROVED: 14,
  RECONCILIATION_REJECTED: 15,
  LOCKED_USING: 16,
  CONFIRMED_USE_LOCKED: 17,
  REFUND: 18,
  ADMIN_SYSTEM_DELIVERY_VOUCHER_OTHER_CUSTOMER: 19,
  DISABLE_VOUCHER: 20,
  ENABLE_VOUCHER: 21,
  RESERVED_ORDER: 22,
  ACCOUNTANT_CANCEL_ORDER: 23,
  ACCOUNTANT_CONFIRM_ORDER: 24,
  PUBLISHED_ONTO_STORE_CHANNEL: 25,
  TAKE_BACK_FROM_STORE_CHANNEL: 26,
  PURCHASE_PAYMENT_STORE_CHANNEL: 27,
  CANCEL_PAYMENT_STORE_CHANNEL: 28,
  EXTEND_EXPIRATION: 29
}

export default VoucherHistoryType
