import axios from 'axios'
import { Promise } from 'es6-promise'

// state
const state = {
}

// getters
const getters = {
}

const getAdRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    baseURL: process.env.VUE_APP_AGENT_AD_URL,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const instance = axios.create(config)
  instance
    .get(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      reject(error)
    })
}

// actions
const actions = {
  GET_LIST_PROVINCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      getAdRequest(
        `api/v1/country/${payload.id}/city/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_LIST_DISTRICT: (context, payload) => {
    return new Promise((resolve, reject) => {
      getAdRequest(
        `api/v1/city/${payload.id}/district/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_LIST_WARD: (context, payload) => {
    return new Promise((resolve, reject) => {
      getAdRequest(
        `api/v1/district/${payload.id}/ward/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ADDRESS_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      getAdRequest(
        `api/v1/details/get_address/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
