<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('user.addNewUser')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <!-- <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="username"
                  :label="$t('login.username')"
                  :name="$t('login.username')"
                  prepend-icon="mdi-account"
                  type="text"
                  class="required"
                />
              </v-flex> -->
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidEmail]"
                  v-model="email"
                  :label="$t('common.email')"
                  :name="$t('common.email')"
                  prepend-icon="mdi-email"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidFullname]"
                  v-model="fullName"
                  :label="$t('user.fullname')"
                  :name="$t('user.fullname')"
                  prepend-icon="mdi-account-group"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  id="password"
                  :rules="[ruleRequiredValue, ruleValidPassword]"
                  v-model="password"
                  :label="$t('login.password')"
                  :name="$t('login.password')"
                  prepend-icon="mdi-lock"
                  type="password"
                  class="required"
                />
              </v-flex>
              <v-flex
                v-if="!isAdmin()"
                xs12
                sm8
                md6>
                <v-select
                  v-model="roleSelected"
                  :items="entityRoles"
                  :rules="[ruleRequiredValue]"
                  :placeholder="$t('user.privilege')"
                  prepend-icon="mdi-account-switch"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleValidCode]"
                  v-model="identification"
                  :label="$t('users.identification')"
                  :name="$t('users.identification')"
                  prepend-icon="mdi-card-account-details"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="birtDateFormatted"
                      :label="$t('user.dob')"
                      persistent-hint
                      prepend-icon="mdi-cake"
                      @blur="date = parseDate(birtDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="birtDate"
                    no-title
                    @input="menu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleValidPhoneNumber]"
                  v-model="phone"
                  :label="$t('user.phone')"
                  :name="$t('user.phone')"
                  prepend-icon="mdi-cellphone-iphone"
                  type="number"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-select
                  v-model="gender"
                  :items="genderList"
                  :label="$t('user.gender')"
                  prepend-icon="mdi-gender-transgender"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm(false)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onForceRegister"
    />
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import validationRules from 'utils/validationRules'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import LoginFromType from 'enum/loginFromType'
import ConfirmModal from 'Components/ConfirmModal'
// import EntityRoleType from 'enum/entityRoleType'
export default {
  components: {
    ConfirmModal
  },
  data () {
    return {
      valid: false,
      isShowModal: false,
      birtDate: null,
      birtDateFormatted: null,
      genderList: stringUtils.GenderList,
      username: null,
      password: null,
      birthDate: null,
      gender: null,
      fullName: null,
      phone: null,
      menu: false,
      currentPage: 1,
      email: null,
      identification: null,
      isLoading: false,
      confirmModalTitle: '',
      entityRoles: [],
      roleSelected: null
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birtDate)
    },
    ...mapGetters([
      'GET_SNACK_BAR',
      'ENTITY_ROLE_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    ENTITY_ROLE_DATA () {
      let data = this.ENTITY_ROLE_DATA
      this.entityRoles = []
      for (let i = 0, size = data.length; i < size; i++) {
        let entityRoleObj = {
          value: data[i].id,
          text: data[i].name
        }
        this.entityRoles.push(entityRoleObj)
      }
    },
    birtDate (val) {
      this.birtDateFormatted = this.formatDate(this.birtDate)
    }
  },
  methods: {
    /**
     * Get Role of Logged In User
     */
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Force register
     */
    onForceRegister: function () {
      this.onConfirm(true)
    },
    /**
     * Required value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    ruleValidFullname (value) {
      return validationRules.ruleValidFullname(value)
    },
    ruleValidPassword (value) {
      return validationRules.ruleValidPassword(value)
    },
    ruleValidCode (value) {
      return validationRules.ruleValidCode(value)
    },
    ruleValidPhoneNumber (value) {
      return validationRules.ruleValidPhoneNumber(value)
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (currentPage) {
      this.isShowModal = true
      this.currentPage = currentPage
      this.roleSelected = null
      let filter = {
        params: {
          entityType: sessionStorage.getItem('entityType')
        }
      }
      this.GET_ENTITY_ROLE_LIST(filter)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Get user list
     */
    getUserList: function () {
      let filter = {
        params: {
          page: this.currentPage
        }
      }
      this.GET_USER_LIST(filter)
    },
    /**
     * Confirm
     */
    onConfirm: function (forceRegister) {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        if (!functionUtils.validateEmail(this.email.trim())) {
          this.ON_SHOW_TOAST({
            'message': this.$t('validation.invalidEmail'),
            'styletype': ToastType.ERROR
          })
        } else {
          let firstLastName = functionUtils.getFirstLastName(this.fullName)
          let entityId = sessionStorage.getItem('entityId')
          let filter = {
            username: this.email.trim(),
            email: this.email.trim(),
            password: this.password,
            lastName: firstLastName.lastName,
            firstName: firstLastName.firstName,
            birthDate: this.birtDateFormatted,
            phone: this.phone,
            gender: this.gender,
            fromDomainType: LoginFromType.ADMIN,
            entityId: entityId,
            customerUrl: stringUtils.CUSTOMER_URL,
            identification: this.identification,
            forceRegister: forceRegister,
            roleInEntity: this.roleSelected
          }
          this.isLoading = true
          this.REGISTER_ACCOUNT(filter).then(
            function () {
              this.isLoading = false
              this.username = null
              this.password = null
              this.fullName = null
              this.birthDate = null
              this.birtDateFormatted = null
              this.phone = null
              this.gender = null
              this.email = null
              this.identification = null
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.isShowModal = false
              this.getUserList()
            }.bind(this)
          ).catch(
            function (error) {
              this.isLoading = false
              if (!functionUtils.isNull(error.response.data.errorText)) {
                if (error.response.data.errorText === 'register.confirmRegisterUserDeleted') {
                  this.confirmModalTitle = this.$t(error.response.data.errorText)
                  this.$refs.confirmModal.onShowModal()
                  return
                }
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  styletype: ToastType.ERROR
                })
              } else if (!functionUtils.isNull(error.response.data.message) && error.response.data.message.includes('(identification)')) {
                this.ON_SHOW_TOAST({
                  'message': this.$t('users.errorText.indentAlreadyExist'),
                  styletype: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  styletype: ToastType.ERROR
                })
              }
            }.bind(this)
          )
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'REGISTER_ACCOUNT',
      'GET_USER_LIST',
      'GET_ENTITY_ROLE_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
