import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

// state
const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  GET_VI_VET_SESSION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_vi_viet_session/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PAYMENT_ORDER_ON_VI_VIET: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/${currentEntityId()}/evoucher/payment_order_on_vi_viet/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_VI_VET_PAYMENT_RESULTS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/${currentEntityId()}/evoucher/get_vi_viet_payment_results/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_LIEN_VIET_DELIVERY_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/thirdparty/lienviet/delivery/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_LIEN_VIET_DELIVERY_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/thirdparty/lienviet/delivery/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONFIRM_ORDER_ON_VI_VIET: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/thirdparty/lienviet/order/confirm_on_vi_viet/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_MERCHANT_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/thirdparty/merchant/get_merchant_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  VERIFY_ACTIVE_KEY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/user/verify_active_key/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  VERIFY_EMAIL_DOMAIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/authen/verify_email_domain/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CHECK_USER_IS_ACTIVATED: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/authen/${payload.id}/check_user_is_activated/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
