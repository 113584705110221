<template>
  <v-dialog
    v-model="isShowModal"
    max-width="600px">
    <v-card>
      <material-card
        :title="$t('common.notification')"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <span>{{ title }}</span>
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      isShowModal: false
    }
  },
  methods: {
    /**
     * Confirm
     */
    onConfirm: function (event) {
      this.isShowModal = false
      event.preventDefault()
      this.$emit('onConfirm')
    },
    /**
     * Show modal
     */
    onShowModal: function (meta) {
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    }
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
</style>
