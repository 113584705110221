<template>
  <v-dialog
    v-model="isShowModal"
    max-width="212mm">
    <layout-print-voucher
      :customer-name="$t('printVoucher.customerName')"
      :customer-email="'customer@sample.com'"
      :voucher-name="voucher.name"
      :voucher-price="voucher.price"
      :voucher-type="voucher.type"
      :voucher-serial="'VOUCHER-SERIAL'"
      :activation-code="'VOUCHER-QR-CODE'"
      :supplier-name="voucher.supplierName"
      :supplier-hotline="voucher.supplierHotline"
      :supplier-website="voucher.supplierWebsite"
      :expired-date="voucher.expiredDate"
      :voucher-description="voucher.description"
      :voucher-term-of-conditions="voucher.conditionsOfUse"
      :voucher-details="voucher.details"
      :logo-supplier-url="voucher.logoSupplier"
      :logo-agent-url="voucher.logoAgent"
      :background-url="backgroundImageUrl"
      :second-background-url="secondBackgroundUrl"
      :is-hide-price="voucher.isHidePrice"
    />
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
// import functionUtils from 'utils/functionUtils'
import LayoutPrintVoucher from 'Components/LayoutPrintVoucher.vue'
import PrintTemplateItemKind from 'enum/printTemplateItemKind'
export default {
  components: {
    LayoutPrintVoucher
  },
  data () {
    return {
      isShowModal: false,
      voucher: {
        supplierName: null,
        supplierHotline: null,
        supplierWebsite: null,
        name: null,
        price: null,
        type: null,
        expiredDate: null,
        description: null,
        conditionsOfUse: null,
        details: null,
        logoSupplier: null,
        logoAgent: null
      },
      backgroundImageUrl: null,
      secondBackgroundUrl: null
    }
  },
  created () {
    this.supplierName = sessionStorage.getItem('entityName')
    this.supplierImage = sessionStorage.getItem('entityImage')
  },
  methods: {
    /**
     * Show modal
     */
    onShowModal: function (voucherInfo, printTemplateId) {
      this.isShowModal = true

      this.voucher.supplierName = voucherInfo.supplierName
      this.voucher.supplierHotline = voucherInfo.supplierHotline
      this.voucher.supplierWebsite = voucherInfo.supplierWebsite
      this.voucher.name = voucherInfo.name
      this.voucher.price = voucherInfo.price
      this.voucher.type = voucherInfo.type
      this.voucher.isHidePrice = voucherInfo.isHidePrice
      this.voucher.expiredDate = voucherInfo.expiredDate
      this.voucher.description = voucherInfo.description
      this.voucher.conditionsOfUse = voucherInfo.conditionsOfUse
      this.voucher.details = voucherInfo.details
      this.voucher.logoSupplier = voucherInfo.logoSupplier
      this.voucher.logoAgent = voucherInfo.logoAgent

      let filter = {
        params: {
          templateId: printTemplateId
        }
      }
      this.GET_PRINT_TEMPLATE_ITEM_CONFIG(filter).then(
        function (res) {
          let data = res.data
          this.backgroundImageUrl = null
          this.secondBackgroundUrl = null
          data.forEach(e => {
            if (e.kind === PrintTemplateItemKind.BACKGROUND_IMAGE) {
              this.backgroundImageUrl = e.value
            } else if (e.kind === PrintTemplateItemKind.BACKGROUND_2ND_IMAGE) {
              this.secondBackgroundUrl = e.value
            }
          })
        }.bind(this)
      )
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    // getRandomCode: function (length) {
    //   return functionUtils.randomString(length)
    // },
    ...mapActions([
      'GET_PRINT_TEMPLATE_ITEM_CONFIG'
    ])
  }
}

</script>

<style lang="scss" scoped>
</style>
