<template>
  <div class="text-xs-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :width="400"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          slot="activator"
          :value="inputValue"
          :disabled="disabled"
          :label="$t('voucherReport.period')"
          readonly
          v-on="on"
        />
      </template>
      <v-card>
        <v-card-text>
          <div class="content p-0 m-0">
            <v-list
              v-if="!noPresets"
              class="mr-4">
              <v-list-tile
                v-for="(preset, index) in presets"
                :style="isPresetActive[index] ? 'background-color: #e5e5e5;' : ''"
                v-model="isPresetActive[index]"
                :key="index"
                @click="selectPreset(index)"
              >
                <v-list-tile-content>
                  {{ $t(preset.label) }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-date-picker
              v-show="type != 'year'"
              v-model="startDate"
              :type="type"
              width="300"
              class="mt-2 mr-2"
              year-icon="mdi-calendar-blank"
              prev-icon="mdi-skip-previous"
              next-icon="mdi-skip-next"
              locale="vi"
            />
            <v-list
              v-show="type == 'year'"
              class="mr-2"
              style="max-height: 350px; overflow: auto; width: 250px; border: 1px solid #e5e5e5;">
              <v-list-tile
                v-for="(year, index) in years"
                :key="year + index"
                @click="selectFromYear(year)">
                <v-list-tile-content :style="year == startDate && 'color: green;'">
                  {{ year }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <!-- <v-date-picker
              v-show="type != 'year'"
              v-model="endDate"
              :type="type"
              width="300"
              class="mt-2 ml-2"
              year-icon="mdi-calendar-blank"
              prev-icon="mdi-skip-previous"
              next-icon="mdi-skip-next"
            />
            <v-list
              v-show="type == 'year'"
              class="ml-2"
              style="max-height: 350px; overflow: auto; width: 250px; border: 1px solid #e5e5e5;">
              <v-list-tile
                v-for="(year, index) in years"
                :key="year + index"
                :disabled="year < startDate"
                @click="selectToYear(year)">
                <v-list-tile-content :style="year == endDate && 'color: green;'">
                  {{ year }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list> -->
          </div>
        </v-card-text>
        <v-card-actions style="border-top: 1px solid #e5e5e5;">
          <v-spacer/>
          <v-btn
            flat
            @click="reset">{{ $t('common.delete') }}</v-btn>
          <v-btn
            flat
            @click="menu = false">{{
              $t('common.cancel')
            }}</v-btn>
          <v-btn
            color="primary"
            @click="applyRange"
          >{{ $t('common.agree') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import {
  ISO_FORMAT
} from 'utils/constants'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    presets: {
      type: Array,
      default: () => {
        return []
      }
    },
    noPresets: {
      type: Boolean,
      default: false
    },
    displayFormat: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    menu: false,
    startDate: format(new Date(), ISO_FORMAT),
    // endDate: format(addDays(new Date(), 7), ISO_FORMAT),
    type: 'date',
    years: []
  }),
  computed: {
    inputValue () {
      if (!this.startDate) {
        return ''
      }
      const start = this.displayFormat
        ? this.formatDate(this.startDate, this.displayFormat)
        : this.startDate
      // const end = this.displayFormat
      //   ? this.formatDate(this.endDate, this.displayFormat)
      //   : this.endDate
      return `${start}`
    },
    isPresetActive () {
      return this.presets.map(
        preset => preset.type === this.type
      )
    }
  },
  created () {
    this.$emit('input', { start: this.startDate, type: this.type })
    this.years = []
    let currentYear = 2021
    let year = 1
    while (this.years.length <= 30) {
      this.years.push(((currentYear - 10) + year).toString())
      year += 1
    }
  },
  methods: {
    /**
     * Select year
     */
    selectFromYear: function (year) {
      this.startDate = year
    },
    // selectToYear: function (year) {
    //   this.endDate = year
    // },
    /**
     * Reset
     */
    reset: function () {
      this.startDate = null
      // this.endDate = null
    },
    /**
     * Emit the input event with the updated range data.
     * This makes v-model work.
     */
    applyRange () {
      this.menu = false
      this.emitRange()
    },
    formatDate (date, fmt) {
      return format(parse(date, ISO_FORMAT, new Date()), fmt)
    },
    /**
     * Select preset
     */
    selectPreset: function (index) {
      this.type = this.presets[index].type
      // this.startDate = this.presets[index].range[0]
      // this.endDate = this.presets[index].range[1]
    },
    /**
     * Show date picker
     */
    onShowDatePicker: function () {
      this.menu = true
    },
    emitRange () {
      this.$emit('input', { start: this.startDate, type: this.type })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
}
</style>
