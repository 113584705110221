import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

const state = {
  batchJobs: [],
  scheduleExecuteList: []
}

// getters
const getters = {
  BATCH_JOB_DATA: state => {
    return state.batchJobs
  },
  SCHEDULE_EXECUTE_DATA: state => {
    return state.scheduleExecuteList
  }
}

// actions
const actions = {
  GET_BATCH_JOBS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/batch_job/`,
        payload,
        function (res) {
          context.commit('GET_BATCH_JOBS_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SCHEDULE_EXECUTE_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/schedule_execute/`,
        payload,
        function (res) {
          context.commit('GET_SCHEDULE_EXECUTE_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_SCHEDULE_EXECUTE_FOR_BATCH_JOB: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/schedule_execute/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_BATCH_JOBS_HANDLER (state, payload) {
    state.batchJobs = payload
  },
  GET_SCHEDULE_EXECUTE_LIST_HANDLER (state, payload) {
    state.scheduleExecuteList = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
