import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

// state
const state = {
  smtp_project: [],
  smtp_config: [],
  generalConfig: []
}

// getters
const getters = {
  GET_CONFIG_SMTP_EMAIL_SERVER_DATA: (state, payload) => {
    return state.smtp_config
  },
  GET_SMTP_PROJECT_DATA: (state, payload) => {
    return state.smtp_project
  },
  GET_GENERAL_CONFIG_HANDLER: (state, payload) => {
    return state.generalConfig
  }
}

const actions = {
  GET_GENERAL_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_general_config/`,
        payload,
        function (res) {
          context.commit('GET_GENERAL_CONFIG_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_GENERAL_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/update_general_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_smtp_project/`,
        payload,
        function (res) {
          context.commit('GET_SMTP_PROJECT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/put_smtp_project/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CONFIG_SMTP_EMAIL_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_config_smtp_email_server/`,
        payload,
        function (res) {
          context.commit('GET_CONFIG_SMTP_EMAIL_SERVER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_CONFIG_SMTP_EMAIL_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/${currentEntityId()}/customizationtable/put_config_smtp_email_server/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_SMTP_EMAIL_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/${currentEntityId()}/customizationtable/put_smtp_email_password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_LIST_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_list_smtp_project/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SAVE_SMS_CONFIG_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/save_sms_config_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMS_CONFIG_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_sms_config_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ON_CHANGE_PASSWORD_FOR_SERVICE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/change_password_for_service/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_GENERAL_CONFIG_HANDLER: (state, payload) => {
    state.generalConfig = payload
  },
  GET_SMTP_PROJECT: (state, payload) => {
    state.smtp_project = payload
  },
  GET_CONFIG_SMTP_EMAIL_SERVER: (state, payload) => {
    state.smtp_config = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
