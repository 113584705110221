import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VDaterange from './VDaterange.vue'

const requireComponent = require.context(
  '@/components', true, /\.vue$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})
const VuetifyDaterangePicker = {
  install (Vue) {
    Vue.component(VDaterange.name, VDaterange)
  }
}
export default VuetifyDaterangePicker
export { VDaterange }
